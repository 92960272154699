import { useTheme,Container, Divider, Paper, styled, Table, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Typography, Box, Snackbar, TableBody, Fab } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MuiAlert from '@mui/material/Alert';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { getDataReturPembelianDetails, setMessagePembelian } from '../../../config/redux/actions';
import { format } from 'date-fns';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { useHistory } from 'react-router-dom';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'grey',
        color: theme.palette.common.black
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
const RincianReturPembelian = () => {
    const theme = useTheme();
    const boxWapper = {
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        marginTop:'20px',
        [theme.breakpoints.up('sm')]:{
            width:'100%',
            maxHeight:'400px',
            height:'100%',
        },
        [theme.breakpoints.down('lg')]:{
            width:'100%',
            height:'100%',
        }
      }
  const paperTable = {
    marginTop:'10px',
    [theme.breakpoints.up('sm')]:{
        width:'90%',
        height:'400px',
    }, 
    [theme.breakpoints.down('sm')]:{
        width:'100%',
        height:'360px'
    },
    scrollbarWidth:'thin'
}
    const {dataReturPembelianById,pembelianMessage,dataReturPembelianDetails}=useSelector(state=>state.returReducers);
    const {dataLogin} =useSelector(state=>state.penggunaReducers);
    const [token,setToken] = useState(dataLogin.token);
    const [openSnackbar,setOpensnackbar]=useState(false);
    let {fakturretur}=useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    useEffect(()=>{
        dispatch(getDataReturPembelianDetails(fakturretur,token,setOpensnackbar));
    },[dispatch,token,fakturretur])
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(setMessagePembelian(''));
        }, 100);
      }
      const daftarReturPembelianDetails = (
        <TableBody>
                {dataReturPembelianDetails.map((returDet) => (
                    <StyledTableRow key={returDet.idreturdistributordetails}>
                    <StyledTableCell width={120} align="center">{returDet.idbarang}</StyledTableCell>
                    <StyledTableCell width={200} align="center">{returDet.namabarang}</StyledTableCell>
                    <StyledTableCell width={120} align="center">{returDet.satuan}</StyledTableCell>
                    <StyledTableCell width={120} align="center">{new Intl.NumberFormat().format(returDet.jumlah)}</StyledTableCell>
                    <StyledTableCell width={120} align="center">{new Intl.NumberFormat().format(returDet.harga)}</StyledTableCell>
                    <StyledTableCell width={120} align="center">{returDet.potongan}</StyledTableCell>
                    <StyledTableCell width={120} align="center">{new Intl.NumberFormat().format(returDet.ppn)}</StyledTableCell>
                    <StyledTableCell width={120} align="center">{new Intl.NumberFormat().format(returDet.subtotal)}</StyledTableCell>
                    <StyledTableCell width={120} align="center">{returDet.asalretur}</StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
    )
  return (
    <Container maxWidth='xl' sx={{marginTop:'50px'}}>
        <Typography variant='h6' pl={1} mt={1}>Rincian Retur Pembelian</Typography>
        <Divider/>
        <Box sx={boxWapper}>
        <Box sx={{width:'90%',display:'flex',justifyContent:'space-between'}}>
            <Typography>Tanggal : {dataReturPembelianById.tanggal !== undefined ? format(new Date(dataReturPembelianById.tanggal),'dd/MM/yyyy') : ''}</Typography>
            <Typography>Faktur Retur : {dataReturPembelianById.fakturreturdistributor}</Typography>
            <Typography>Faktur Pembelian : {dataReturPembelianById.fakturpembelian}</Typography>
            <Typography>Distributor : {dataReturPembelianById.nama}</Typography>
        </Box>
        <TableContainer component={Paper} sx={paperTable}>
            <Table stickyHeader aria-label="customized table" size='small'>
                <TableHead>
                    <TableRow>
                        <StyledTableCell width={120} align="center">ID Barang</StyledTableCell>
                        <StyledTableCell width={200} align="center">Nama Barang</StyledTableCell>
                        <StyledTableCell width={120} align="center">Satuan</StyledTableCell>
                        <StyledTableCell width={120} align="center">Qty</StyledTableCell>
                        <StyledTableCell width={120} align="center">Harga</StyledTableCell>
                        <StyledTableCell width={120} align="center">Potongan</StyledTableCell>
                        <StyledTableCell width={120} align="center">PPN</StyledTableCell>
                        <StyledTableCell width={120} align="center">Jumlah</StyledTableCell>
                        <StyledTableCell width={120} align="center">Asal Retur</StyledTableCell>
                    </TableRow>
                </TableHead>
                    {daftarReturPembelianDetails} 
            </Table>
        </TableContainer>
            <Box sx={{width:'90%',display:'flex',justifyContent:'flex-end'}}>
                <Box sx={{display:'flex',flexDirection:'column'}}>
                    <Typography>Total</Typography>
                </Box>
                <Box sx={{display:'flex',flexDirection:'column',marginLeft:'3px',marginRight:'3px'}}>
                    <Typography>:</Typography>
                </Box>
                <Box sx={{display:'flex',flexDirection:'column'}}>
                    <Typography>{new Intl.NumberFormat().format(dataReturPembelianById.total)}</Typography>
                </Box>
            </Box>
        </Box>
        <Snackbar 
            open={openSnackbar} 
            autoHideDuration={1000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={pembelianMessage === "Transaksi penjualan berhasil" ? "success" : "error"}
                >
                    {pembelianMessage}
                    </Alert>
            </Snackbar>
            <Fab sx={{position: 'fixed',bottom: theme.spacing(5),left: theme.spacing(3)}} size="small" color="primary" aria-label="add" onClick={()=>history.push('/laporan/returpembelian')}>
                <ArrowBackIcon/>
            </Fab>
    </Container>
  )
}

export default RincianReturPembelian