import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Box, Button, Dialog, DialogContent, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { editLastHargaPenjualan } from '../../../config/redux/actions';

  const kelPPN = [
    {
      value: 'NON PPN',
      label: 'NON PPN',
    },
    {
      value: 'PPN',
      label: 'PPN',
    }
  ];
const LastHargaDialog = ({openLastHargaDialog,closeLastHargaDialog,...rest}) => {
    const paperInput={
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        width:"250px",
        height:'100%',
        borderRadius:'10px',
        marginTop:'10px'
    }
    const dispatch = useDispatch();
    const [disPPN,setDisPPN]=useState(true);
    const validationSchema = yup.object().shape({
        kelompok:yup.string().required(),
        ppn:yup.number().min(0,"Nilai PPN tidak boleh kurang dari 0")
    })
    const formik=useFormik({
        enableReinitialize:true,
        initialValues:{
            kelompok:'NON PPN',
            ppn:0
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const data = {
                'Kelompok':values.kelompok,
                'PPN':values.ppn
            }
            dispatch(editLastHargaPenjualan(data));
            closeLastHargaDialog();
        }
    })
    const KelompokHandleChange = (e) =>{
        if(e.target.value==="NON PPN"){
            formik.setFieldValue('kelompok',e.target.value);
            formik.setFieldValue('ppn',0);
            setDisPPN(true);
        }else if(e.target.value === "PPN"){
            formik.setFieldValue('kelompok',e.target.value);
            formik.setFieldValue('ppn',0);
            setDisPPN(false);
        }
    }
  return (
    <Dialog open={openLastHargaDialog} {...rest}>
    <DialogContent>
        <Box sx={paperInput}>
                <TextField
                id='kelompok'
                select
                name='kelompok'
                variant="standard" 
                label="Kelompok PPN"
                sx={{width:'150px',marginRight:'10px'}}
                SelectProps={{
                    native: true,
                    }}
                value={formik.values.kelompok}
                error={formik.touched.kelompok && Boolean(formik.errors.kelompok)}
                helperText={formik.touched.kelompok && formik.errors.kelompok}
                onChange={(e)=>KelompokHandleChange(e)}
                >   
                    <option aria-label="None" value=""/>
                    {kelPPN.map((option)=>(
                        <option key ={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </TextField>
                <TextField
                id="ppn"
                name='ppn'
                label="PPN"
                variant="standard"
                disabled={disPPN}
                sx={{width:'150px',marginRight:'10px'}}
                value={formik.values.ppn}
                error={formik.touched.ppn && Boolean(formik.errors.ppn)}
                helperText={formik.touched.ppn && formik.errors.ppn}
                onChange={formik.handleChange}
                />
                <Box sx={{width:'100%',display:'flex',justifyContent:'space-between',marginTop:'10px'}}>
                    <Button variant="contained" color='primary' onClick={formik.handleSubmit}>OK</Button>
                    <Button variant="contained" color='error' onClick={closeLastHargaDialog}>Keluar</Button>
                </Box>
            </Box>
    </DialogContent>
    </Dialog>
  )
}

export default LastHargaDialog