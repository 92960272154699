import { Button, Dialog, DialogContent, Divider, Snackbar, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import * as yup from 'yup';
import { useFormik } from 'formik';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { editFakturPajak, setEmptyPPNMessage } from '../../../config/redux/actions';
import Cookies from 'js-cookie';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
const EditPPNMasukan = ({openPPNMasukan,handleClosePPNMasukan,idppn,fakturpajak,status,tglawal,tglakhir,...rest}) => {
    const {dataLogin} =useSelector(state=>state.penggunaReducers);
    const {ppnMessage} = useSelector(state=>state.ppnReducers);
    const [openSnackbar,setOpensnackbar]=useState(false);
    const dispatch = useDispatch();
    const [token,setToken] = useState(dataLogin.token);
    if(token ===''){
      setToken(Cookies.get('u_tkn'));
    }
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(setEmptyPPNMessage(''));
            handleClosePPNMasukan();
        }, 100);
    }
    const jenisVerifikasi = [
        {'label':'Pending','value':'Pending'},
        {'label':'Verifikasi','value':'Verifikasi'},
        {'label':'Terverifikasi','value':'Terverifikasi'}
    ]
    const schemaValidation = yup.object().shape({
        'fakturpajak':yup.string().required("Faktur pajak tidak boleh kosong"),
        'status':yup.string().required("Status verifikasi belum dipilih")
    })
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            fakturpajak:fakturpajak||'',
            status:status||''
        },
        validationSchema:schemaValidation,
        onSubmit:(values)=>{
            const data = new URLSearchParams();
            data.append('fakturpajak',values.fakturpajak);
            data.append('status',values.status);
            dispatch(editFakturPajak(idppn,data,token,setOpensnackbar));
        }
    })
  return (
    <Dialog open={openPPNMasukan} {...rest}>
        <DialogContent>
            <Box sx={{display:'flex',flexDirection:'column'}}>
                <Typography>Ubah PPN Masukan</Typography>
                <Divider/>
                <TextField
                id='fakturpajak'
                name='fakturpajak'
                label="Faktur Pajak"
                variant='standard'
                sx={{marginTop:'10px'}}
                value={formik.values.fakturpajak}
                error={formik.touched.fakturpajak && Boolean(formik.errors.fakturpajak)}
                helperText={formik.touched.fakturpajak && formik.errors.fakturpajak}
                onChange={formik.handleChange}
                />
                <TextField
                id='status'
                select
                name='status'
                variant="standard" 
                label="Status"
                
                SelectProps={{
                    native: true,
                    }}
                value={formik.values.status}
                error={formik.touched.status && Boolean(formik.errors.status)}
                helperText={formik.touched.status && formik.errors.status}
                onChange={formik.handleChange}
                >   
                    <option aria-label="None" value=""/>
                    {jenisVerifikasi.map((option)=>(
                        <option key ={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </TextField>
                <Box sx={{width:'200px',display:'flex',justifyContent:'space-between',marginTop:'10px'}}>
                <Button variant='contained' color='primary' onClick={formik.handleSubmit}>Simpan</Button>
                <Button variant='contained' color='error' onClick={handleClosePPNMasukan}>Keluar</Button>
                </Box>
            </Box>
        </DialogContent>
        <Snackbar 
            open={openSnackbar} 
            autoHideDuration={1000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={ppnMessage === 'Perubahan faktur pajak berhasil'? 'success':'error'}
                >
                    {ppnMessage}
                    </Alert>
            </Snackbar>
    </Dialog>
  )
}

export default EditPPNMasukan