import { IconButton, TextField,Autocomplete, TableCell, TableRow, tableCellClasses, styled, TableContainer, Paper, Table, TableHead, useTheme, Snackbar, TableBody, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React,{useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AddTodo, DeleteData } from '../../../components/assets'
import { addReturToCart, deleteBarangFromCartRetur,emptyListBarang, emptyArrSatuan, getLastBuyPrice, getSatuanKonversiList, setReturMessage, searchStokRetur, emptySatuanStokRetur, getSatuanStokRetur, getAllBarang, getLastStokQty } from '../../../config/redux/actions';
import Cookies from 'js-cookie';
import * as yup from 'yup';
import { useFormik } from 'formik';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'grey',
        color: theme.palette.common.black
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const ReturPembelian = ({iddist,asalRetur,totalPembelian}) => {
    const theme = useTheme();
    const paperTable = {
        marginTop:'10px',
        [theme.breakpoints.up('sm')]:{
            width:'100%',
            height:'350px',
        }, 
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            height:'360px'
        },
        scrollbarWidth:'thin'
    }
    const pembelianInput={
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        [theme.breakpoints.up('lg')]:{
            width:"100%",
            height:'100%',
            borderRadius:'10px',
            marginTop:'10px'
        },
        [theme.breakpoints.down('lg')]:{
            width:'100%',
            height:'100%',
            borderRadius:'10px',
            marginTop:'10px'
        }
    }
    const dispatch = useDispatch();
    const {dataLogin} =useSelector(state=>state.penggunaReducers);
    const {dataBarang} = useSelector(state=>state.barangReducers);
    const {dataStokRetur,satuanStokRetur}=useSelector(state=>state.stokreturReducers);
    const {arrSatuanKonversi}=useSelector(state=>state.konversiReducers);
    const {returMessage,cartRetur,lastBuy}=useSelector(state=>state.returReducers);
    // const {dataStokTerakhir}=useSelector(state=>state.stokbarangReducers);
    const {lastStokQty} = useSelector(state=>state.penjualanReducers);
    const [token,setToken] = useState(dataLogin.token);
    const [openSnackbar,setOpensnackbar]=useState(false);
    const [search,setSearch]=useState("");
    const [satuanText,setSatuanText]=useState('');
    const [countRetur,setCountRetur]=useState(1);
    const [totalRetur,setTotalRetur]=useState(0);
    const [totalFinal,setTotalFinal]=useState(0);
    const validationSchema = yup.object().shape({
        'idbarang':yup.string().required('ID Barang tidak boleh kosong'),
        'namabarang':yup.string().required('Nama barang tidak boleh kosong'),
        'idsatuan':yup.string().required('Satuan belum dipilih'),
        'qty':yup.number().required('Qty tidak boleh kosong').min(1,'Qty tidak boleh kurang dari 0')
    })
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            idbarang:'',
            namabarang:'',
            idsatuan:'',
            qty:'',
            potongan:''
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const existBarang = cartRetur.filter(barang =>barang.idbarang === values.idbarang && barang.idsatuan === values.idsatuan && barang.ref === asalRetur);
            if(asalRetur==='Stok Toko'){
                if(lastStokQty.stok<values.qty){
                    dispatch(setReturMessage('Stok Barang tidak mencukupi'));
                    setOpensnackbar(true);
                }
                else if(lastStokQty.stok>=values.qty){
                    if(existBarang.length>0){
                        dispatch(setReturMessage('Barang dengan satuan ini telah terdaftar'));
                        setOpensnackbar(true);
                        formik.handleReset();
                    }else{
                        if(values.potongan!==""){
                            let tempPotongan = parseFloat(values.harga) * parseInt(values.potongan)/100;
                            let tempHarga = parseFloat(values.harga)-tempPotongan;
                            setCountRetur(countRetur+1);
                            const data = {
                                'id':countRetur,
                                'idbarang':values.idbarang,
                                'namabarang':values.namabarang,
                                'idsatuan':values.idsatuan,
                                'satuan':satuanText,
                                'qty':values.qty,
                                'harga':values.harga,
                                'kelompok':lastBuy.kelompok,
                                'ppn':lastBuy.ppn,
                                'potongan':values.potongan !== "" ? values.potongan : 0,
                                'jumlah':Math.ceil(parseInt(values.qty) * tempHarga),
                                'ref':asalRetur
                            }
                            dispatch(addReturToCart(data,setOpensnackbar));
                        }else if(values.potongan===""){
                            setCountRetur(countRetur+1);
                            const data = {
                                'id':countRetur,
                                'idbarang':values.idbarang,
                                'namabarang':values.namabarang,
                                'idsatuan':values.idsatuan,
                                'satuan':satuanText,
                                'qty':values.qty,
                                'harga':values.harga,
                                'kelompok':lastBuy.kelompok,
                                'ppn':lastBuy.ppn,
                                'potongan':values.potongan !== "" ? values.potongan : 0,
                                'jumlah':Math.ceil(parseInt(values.qty) * values.harga),
                                'ref':asalRetur
                            }
                            dispatch(addReturToCart(data,setOpensnackbar));
                        }
                        formik.handleReset();
                    }
                }
            }
            else if(asalRetur==='Stok Retur'){
                const getQty = satuanStokRetur.filter(satuan =>satuan.idsatuan === values.idsatuan);
                if(getQty[0].qty<values.qty){
                    dispatch(setReturMessage('Stok retur tidak mencukupi'));
                    setOpensnackbar(true);
                }
                else if(getQty[0].qty>=values.qty){
                    if(existBarang.length>0){
                        dispatch(setReturMessage('Barang dengan satuan ini telah terdaftar'));
                        setOpensnackbar(true);
                        formik.handleReset();
                    }else{
                        if(values.potongan!==""){
                            let tempPotongan = parseFloat(values.harga) * parseInt(values.potongan)/100;
                            let tempHarga = parseFloat(values.harga)-tempPotongan;
                            setCountRetur(countRetur+1);
                            const data = {
                                'id':countRetur,
                                'idbarang':values.idbarang,
                                'namabarang':values.namabarang,
                                'idsatuan':values.idsatuan,
                                'satuan':satuanText,
                                'qty':values.qty,
                                'harga':values.harga,
                                'kelompok':lastBuy.kelompok,
                                'ppn':lastBuy.ppn,
                                'potongan':values.potongan !== "" ? values.potongan : 0,
                                'jumlah':Math.ceil(parseInt(values.qty) * tempHarga),
                                'ref':asalRetur
                            }
                            dispatch(addReturToCart(data,setOpensnackbar));
                        }else if(values.potongan===""){
                            setCountRetur(countRetur+1);
                            const data = {
                                'id':countRetur,
                                'idbarang':values.idbarang,
                                'namabarang':values.namabarang,
                                'idsatuan':values.idsatuan,
                                'satuan':satuanText,
                                'qty':values.qty,
                                'harga':values.harga,
                                'kelompok':lastBuy.kelompok,
                                'ppn':lastBuy.ppn,
                                'potongan':values.potongan !== "" ? values.potongan : 0,
                                'jumlah':Math.ceil(parseInt(values.qty) * values.harga),
                                'ref':asalRetur
                            }
                            dispatch(addReturToCart(data,setOpensnackbar));
                        }
                        formik.handleReset();
                    }
                }
            }
        }
    })
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    useEffect(()=>{
        if(asalRetur==='Stok Toko'){
            dispatch(getAllBarang(search,1,token,setOpensnackbar));
        }else if(iddist===""){
            dispatch(emptyListBarang());
        }
        if(cartRetur.length>0){
            let totalRetur=cartRetur.reduce((Retur,TotalJ)=>{
                return Retur + TotalJ.jumlah
            },0);
            setTotalRetur(totalRetur);
            setTotalFinal(totalPembelian-totalRetur);
        }else{
            setTotalRetur(0);
            setTotalFinal(totalPembelian-totalRetur);
        }
        },[iddist,token,asalRetur,totalRetur,cartRetur,totalPembelian,formik.values.namabarang,dispatch])
    const handleClickDeleteRetur =(id)=>{
        dispatch(deleteBarangFromCartRetur(id));
    }
    const daftarRetur = (
        <TableBody>
                {cartRetur.slice(0).reverse().map((retur) => (
                    <StyledTableRow key={retur.id}>
                    <StyledTableCell width={120} align="center">{retur.idbarang}</StyledTableCell>
                    <StyledTableCell width={200} align="center">{retur.namabarang}</StyledTableCell>
                    <StyledTableCell width={120} align="center">{retur.satuan}</StyledTableCell>
                    <StyledTableCell width={120} align="center">{new Intl.NumberFormat().format(retur.qty)}</StyledTableCell>
                    <StyledTableCell width={120} align="center">{new Intl.NumberFormat().format(retur.harga)}</StyledTableCell>
                    <StyledTableCell width={120} align="center">{new Intl.NumberFormat().format(retur.ppn)}</StyledTableCell>
                    <StyledTableCell width={120} align="center">{new Intl.NumberFormat().format(retur.potongan)}</StyledTableCell>
                    <StyledTableCell width={120} align="center">{new Intl.NumberFormat().format(retur.jumlah)}</StyledTableCell>
                    <StyledTableCell width={120} align="center">{retur.ref}</StyledTableCell>
                    <StyledTableCell width={20} align="center">
                        <Tooltip title="Hapus data">
                            <IconButton 
                            onClick={()=>handleClickDeleteRetur(retur.id)}
                            >
                                <img src={DeleteData} alt="deleteData"/>
                            </IconButton>
                        </Tooltip>
                    </StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
    )
    const handleChangeSatuan = (e)=>{
        setSatuanText(e.target.options[e.target.selectedIndex].text);
        formik.setFieldValue('idsatuan',e.target.value);
        const data={
            'idbarang':formik.values.idbarang,
            'idsatuan':e.target.value,
            'qty':formik.values.qty||0
        }
        dispatch(getLastBuyPrice(data,token,formik));
        dispatch(getLastStokQty(data,token,setOpensnackbar));
        setOpensnackbar(false);
        if(asalRetur==='Stok Retur'){
            const getQty = satuanStokRetur.filter(satuan =>satuan.idsatuan === e.target.value);
            formik.setFieldValue('qty',getQty[0].qty);
        }
    }
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(setReturMessage(''));
        }, 100);
    }
    const handleSearchBarang = (e)=>{
        setSearch(e.target.value);
        if(asalRetur==="Stok Retur"){
            dispatch(searchStokRetur(e.target.value,1,token,setOpensnackbar));
        }else if(asalRetur==="Stok Toko"){
            dispatch(getAllBarang(e.target.value,1,token,setOpensnackbar));
        }
      }
      const handleChangeQty = (e) =>{
        if(asalRetur==="Stok Toko"){
            const data={
                'idbarang':formik.values.idbarang,
                'idsatuan':formik.values.idsatuan,
                'qty':e.target.value
            }
            dispatch(getLastStokQty(data,token,setOpensnackbar));
            setOpensnackbar(false);
            formik.setFieldValue("qty",e.target.value);
        }else{
            formik.setFieldValue("qty",e.target.value);
        }
      }
  return (
      <Box sx={{width:'100%',marginTop:'10px',marginBottom:"20px"}}>
        <Box sx={{width:'100%',display:'flex'}}>
            <TextField
            id="idbarang"
            name='idbarang'
            label="ID Barang"
            variant="standard"
            sx={{marginRight:'10px'}}
            disabled={true}
            value={formik.values.idbarang}
            error={formik.touched.idbarang && Boolean(formik.errors.idbarang)}
            helperText={formik.touched.idbarang && formik.errors.idbarang}
            onChange={formik.handleChange}
            />
            <Autocomplete
            id="namabarang"
            selectOnFocus
            name='namabarang'
            variant="standard"
            // inputValue={formik.values.namabarang||""}
            sx={{marginRight:'10px'}}
            options= {asalRetur==="Stok Toko"?dataBarang:dataStokRetur}
            getOptionLabel= {(option) => "("+ option.idbarang +") "+option.namabarang}
            isOptionEqualToValue={(option,value)=>option.id===value.id}
            onChange={(event, newValue) => {
                    if(newValue!==null){
                        formik.setFieldValue('idbarang',newValue.idbarang);
                        formik.setFieldValue('namabarang',newValue.namabarang);
                        if(asalRetur==="Stok Toko"){
                            dispatch(getSatuanKonversiList(newValue.idbarang,token,""));
                            dispatch(emptyArrSatuan());
                        }else if(asalRetur==="Stok Retur"){
                            dispatch(getSatuanStokRetur(newValue.idbarang,token,setOpensnackbar));
                            dispatch(emptySatuanStokRetur());
                        }
                        formik.setFieldValue('idsatuan',"");
                    }else{
                        formik.setFieldValue('idbarang',"");
                        formik.setFieldValue('namabarang',"");
                        if(asalRetur==="Stok Toko"){
                            dispatch(emptyArrSatuan());
                        }else if(asalRetur==="Stok Retur"){
                            dispatch(emptySatuanStokRetur());
                        }
                        formik.setFieldValue('idsatuan',"");
                        formik.setFieldValue('harga',"");
                        formik.setFieldValue('potongan',"");
                        formik.setFieldValue('qty',"");
                    }
              }}
            renderInput={(params)=><TextField
                id='namabarang'
                name='namabarang'
                label="Nama Barang"
                 variant='standard' 
                 sx={{width:'200px'}} 
                 {...params} 
                value={formik.values.namabarang}
                error={formik.touched.namabarang && Boolean(formik.errors.namabarang)}
                helperText={formik.touched.namabarang && formik.errors.namabarang}
                onChange={(e)=>handleSearchBarang(e)}
                 />}
            />
            <TextField
            id='idsatuan'
            select
            name='idsatuan'
            variant="standard" 
            label="Satuan"
            sx={{width:'100px',marginRight:'10px'}}
            SelectProps={{
                native: true,
                }}
            value={formik.values.idsatuan}
            error={formik.touched.idsatuan && Boolean(formik.errors.idsatuan)}
            helperText={formik.touched.idsatuan && formik.errors.idsatuan}
            onChange={(e)=>handleChangeSatuan(e)}
            >   
                <option aria-label="None" value=""/>
                {asalRetur==="Stok Toko" ? arrSatuanKonversi.map((option)=>(
                    <option key ={option.idsatuan} value={option.idsatuan}>
                        {option.satuan}
                    </option>
                )): satuanStokRetur.map((option)=>(
                    <option key ={option.idsatuan} value={option.idsatuan}>
                        {option.satuan}
                    </option>
                ))}
            </TextField>
            <TextField
            id="qty"
            name='qty'
            label="Qty"
            variant="standard"
            sx={{width:'80px',marginRight:'10px'}}
            value={formik.values.qty||""}
            error={formik.touched.qty && Boolean(formik.errors.qty)}
            helperText={formik.touched.qty && formik.errors.qty}
            onChange={(e)=>handleChangeQty(e)}
            />
            <TextField
            id="harga"
            name='harga'
            label="Harga"
            variant="standard"
            disabled={true}
            sx={{width:'100px',marginRight:'10px'}}
            value={formik.values.harga||""}
            error={formik.touched.harga && Boolean(formik.errors.harga)}
            helperText={formik.touched.harga && formik.errors.harga}
            onChange={formik.handleChange}
            />
            <TextField
            id="potongan"
            name='potongan'
            label="Potongan"
            variant="standard"
            placeholder='%'
            sx={{width:'85px',marginRight:'10px'}}
            value={formik.values.potongan||""}
            error={formik.touched.potongan && Boolean(formik.errors.potongan)}
            helperText={formik.touched.potongan && formik.errors.potongan}
            onChange={formik.handleChange}
            />
            <IconButton sx={{display:'flex',marginTop:'15px'}} onClick={formik.handleSubmit}>
                <img src={AddTodo} alt="addRetur"/>
            </IconButton>
            
        </Box>
        <Box sx={pembelianInput}>
            <TableContainer component={Paper} sx={paperTable}>
                <Table stickyHeader aria-label="customized table" size='small'>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell width={120} align="center">ID Barang</StyledTableCell>
                            <StyledTableCell width={200} align="center">Nama Barang</StyledTableCell>
                            <StyledTableCell width={120} align="center">Satuan</StyledTableCell>
                            <StyledTableCell width={120} align="center">Qty</StyledTableCell>
                            <StyledTableCell width={120} align="center">Harga</StyledTableCell>
                            <StyledTableCell width={120} align="center">PPN</StyledTableCell>
                            <StyledTableCell width={120} align="center">Potongan(%)</StyledTableCell>
                            <StyledTableCell width={120} align="center">Jumlah</StyledTableCell>
                            <StyledTableCell width={120} align="center">Ref</StyledTableCell>
                            <StyledTableCell width={20} align="center"/>
                        </TableRow>
                    </TableHead>
                        {daftarRetur} 
                </Table>
            </TableContainer>
            <Box sx={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
                    <Box sx={{width:'200px',textAlign:'right'}}>
                        <Typography>Total Retur:</Typography>
                    </Box>
                    <Box sx={{width:'180px',textAlign:'right'}}>
                        <Typography>Rp {new Intl.NumberFormat().format(totalRetur)}</Typography>
                    </Box>
                </Box>
                <Box sx={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
                    <Box sx={{width:'200px',textAlign:'right'}}>
                        <Typography>Total:</Typography>
                    </Box>
                    <Box sx={{width:'180px',textAlign:'right'}}>
                        <Typography>Rp {new Intl.NumberFormat().format(totalFinal)}</Typography>
                    </Box>
                </Box>
        </Box>
        <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={returMessage === "Barang berhasil dimasukkan ke daftar retur" ? "success" : "error"}
                >
                    {returMessage}
                    </Alert>
            </Snackbar>
      </Box>
  )
}

export default ReturPembelian