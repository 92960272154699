import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Autocomplete, Box, Container, Divider, Fab, IconButton, Paper, Snackbar, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography,useTheme } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { format } from 'date-fns';
import { getAllPelanggan, getDataPenjualanPelanggan, setEmptyPenjualanPelanggan, setPenjualanMessage } from '../../../config/redux/actions';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import { useHistory } from 'react-router-dom';
import { PrintIcon } from '../../../components/assets';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'grey',
      color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const LaporanPelangganPage = () => {
  const theme = useTheme();
  const boxWapper = {
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    [theme.breakpoints.up('sm')]:{
        width:'100%',
        maxHeight:'500px',
        height:'100%',
    },
    [theme.breakpoints.down('lg')]:{
        width:'100%',
        height:'100%',
    }
  }
  const paperInput={
      display:'flex',
      justifyContent:'center',
      [theme.breakpoints.up('sm')]:{
          width:"90%",
          height:'100%',
          borderRadius:'10px',
          marginTop:'10px'
      },
      [theme.breakpoints.down('sm')]:{
          width:'100%',
          height:'100%',
          borderRadius:'10px',
          marginTop:'10px'
      }
  }
  const paperTable = {
    marginTop:'10px',
    [theme.breakpoints.up('sm')]:{
        width:'80%',
        height:'400px',
    }, 
    [theme.breakpoints.down('sm')]:{
        width:'100%',
        height:'360px'
    },
    scrollbarWidth:'thin'
}
  const {penjualanPelanggan,penjualanMessage,returPelanggan}=useSelector(state=>state.penjualanReducers);
  const {dataPelanggan}=useSelector(state=>state.pelangganReducers);
  const {dataLogin} =useSelector(state=>state.penggunaReducers);
  const [token,setToken] = useState(dataLogin.token);
  const [openSnackbar,setOpensnackbar]=useState(false);
  const [tanggalawal,setTanggalawal]=useState(new Date());
  const [tanggalakhir,setTanggalakhir]=useState(new Date());
  const [idpelanggan,setIdpelanggan]=useState('');
  const [totalPenjualan,setTotalPenjualan]=useState(0);
  const [totalRetur,setTotalRetur]=useState(0);
  const [total,setTotal]=useState(0);
  
  const dispatch = useDispatch();
  if(token ===''){
    setToken(Cookies.get('u_tkn'));
  }
  useEffect(()=>{
      if(dataPelanggan.length<1){
        dispatch(getAllPelanggan("",1,token,""));
      }
      if(penjualanPelanggan.length>0){
        let totalPenjualan=penjualanPelanggan.reduce((Penjualan,TotalP)=>{
            if(TotalP.status==="Berhasil"){
              Penjualan += TotalP.total
            }
            return Penjualan
        },0);
        setTotalPenjualan(totalPenjualan);
      }else{
          setTotalPenjualan(0);
      }
      if(returPelanggan.length>0){
        let totalRetur=returPelanggan.reduce((Retur,TotalR)=>{
          if(TotalR.status==="Berhasil")
          {
            Retur += TotalR.total
          }
            return Retur
        },0);
        setTotalRetur(totalRetur);
      }else{
        setTotalRetur(0);
      }
      setTotal(parseFloat(totalPenjualan)-parseFloat(totalRetur));
      if(tanggalawal>tanggalakhir){
        dispatch(setPenjualanMessage('Tanggal tidak sesuai'));
        setOpensnackbar(true);
        setTanggalawal(new Date());
        setTanggalakhir(new Date());
      }
  },[tanggalawal,tanggalakhir,penjualanPelanggan,dataPelanggan.length,totalPenjualan,returPelanggan,totalRetur,dispatch,token])
  const handleCloseSnackbar = (event,reason) =>{
    if(reason === 'clickaway'){
        return;
    }
    setOpensnackbar(false);
    setTimeout(() => {
        dispatch(setPenjualanMessage(''));
    }, 100);
  }
  const handleChangeTanggalAwal = (e)=>{
    setTanggalawal(e);
  }
  const handleChangeTanggalAkhir = (e)=>{
    setTanggalakhir(e);
  }
  // const handleChangePelanggan = (e) =>{
  //   setIdpelanggan(e.target.value);
  // }
  const history = useHistory();
  const handleClickBack = () =>{
      dispatch(setEmptyPenjualanPelanggan());
      history.push('/laporan/');
  }
  const handleClickButton = ()=>{
    const data={
      'tanggalawal':format(tanggalawal,"yyyy-MM-dd"),
      'tanggalakhir':format(tanggalakhir,"yyyy-MM-dd"),
      'idpelanggan':idpelanggan
    }
    dispatch(getDataPenjualanPelanggan(data,token,setOpensnackbar))
  }
  const onSearchPelanggan = (e)=>{
    dispatch(getAllPelanggan(e.target.value,1,token,""));
  }
  const handlePrintFaktur = (faktur) =>{
    history.push(`/laporan/penjualanpelanggan/${faktur}`);
  }
  const tableDataPenjualan = (
    <TableBody>
        {penjualanPelanggan.map((penjualan) => (
            <StyledTableRow key={penjualan.fakturpenjualan}>
            <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{format(new Date(penjualan.tanggal),'dd/MM/yyyy')}</Typography></StyledTableCell>
            <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{penjualan.fakturpenjualan}</Typography></StyledTableCell>
            <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{penjualan.alias === "" ? penjualan.nama : penjualan.nama+"("+penjualan.alias+")"}</Typography></StyledTableCell>
            <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{new Intl.NumberFormat().format(penjualan.totalitem)}</Typography></StyledTableCell>
            <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{new Intl.NumberFormat().format(penjualan.total)}</Typography></StyledTableCell>
            <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{penjualan.status}</Typography></StyledTableCell>
            <StyledTableCell width={50} align="center">
            <Tooltip title="Rincian Penjualan">
              <IconButton 
              onClick={()=>handlePrintFaktur(penjualan.fakturpenjualan)}
              >
                <img src={PrintIcon} alt="print"/>
              </IconButton>
            </Tooltip>
            </StyledTableCell>
            </StyledTableRow>
        ))}
    </TableBody>
)

const tableDataRetur = (
  <TableBody>
      {returPelanggan.map((retur) => (
          <StyledTableRow key={retur.fakturreturpelanggan}>
          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{format(new Date(retur.tanggal),'dd/MM/yyyy')}</Typography></StyledTableCell>
          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{retur.fakturreturpelanggan}</Typography></StyledTableCell>
          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{retur.fakturpenjualan}</Typography></StyledTableCell>
          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{retur.nama}</Typography></StyledTableCell>
          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{new Intl.NumberFormat().format(retur.total)}</Typography></StyledTableCell>
          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{retur.status}</Typography></StyledTableCell>
          </StyledTableRow>
      ))}
  </TableBody>
)
const headerTableRetur=(
  <Fragment>
    <Box sx={boxWapper}>
  <TableContainer component={Paper} sx={paperTable}>
      <Table stickyHeader aria-label="customized table" size='small'>
          <TableHead>
          <TableRow>
              <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Tanggal</Typography></StyledTableCell>
              <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Faktur Retur</Typography></StyledTableCell>
              <StyledTableCell width={200} align="center"><Typography sx={{fontSize:12}}>Faktur Penjualan</Typography></StyledTableCell>
              <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Nama</Typography></StyledTableCell>
              <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Total</Typography></StyledTableCell>
              <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Status</Typography></StyledTableCell>
          </TableRow>
          </TableHead>
              {tableDataRetur} 
      </Table>
    </TableContainer>
    <Box sx={{width:'80%',display:'flex',justifyContent:'flex-end'}}>
      <Typography>Total Retur : {new Intl.NumberFormat().format(totalRetur)}</Typography>
    </Box>
    </Box>
    </Fragment>
)
const totalText =(
  <Fragment>
  <Box sx={{width:'90%',display:'flex',justifyContent:'flex-end'}}>
  <Typography>Total : {new Intl.NumberFormat().format(total)}</Typography>
  </Box>
  </Fragment>
)
  return (
    <Container maxWidth='xl' sx={{marginTop:'50px'}}>
        <Typography variant='h6' pl={1} mt={1}>Laporan Penjualan</Typography>
        <Divider/>
        <Box sx={boxWapper}>
          <Box sx={paperInput}>
          <Autocomplete
                  id="idpelanggan"
                  selectOnFocus
                  name='idpelanggan'
                  label="Pelanggan"
                  variant="standard"
                  sx={{width:'200px',marginRight:'10px'}}
                  options= {dataPelanggan}
                  getOptionLabel= {(option) => option.nama}
                  isOptionEqualToValue={(option,value)=>option.id===value.id}
                  onChange={(event, newValue) => {
                          if(newValue!==null){
                              setIdpelanggan(newValue.idpelanggan);
                          }else{
                              setIdpelanggan('');
                          }
                    }}
                  renderInput={(params)=><TextField
                      id='idpelanggan'
                      name='idpelanggan'
                      variant='standard' 
                      {...params} 
                      label="Pelanggan"
                      value={idpelanggan}
                      onChange={(e)=>onSearchPelanggan(e)}
                      />}
                  />
          {/* <TextField
          id='laporan'
          select
          name='laporan'
          variant="standard" 
          label="Pelanggan"
          style={{marginRight:'10px'}}
          SelectProps={{
              native: true,
              }}
          value={idpelanggan}
          onChange={(e)=>handleChangePelanggan(e)}
          >   
               <option aria-label="None" value="" />
              {dataPelanggan.map((option)=>(
                  <option key ={option.idpelanggan} value={option.idpelanggan}>
                      {option.nama}
                  </option>
              ))}
          </TextField> */}
          <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
          label="Tanggal Awal"
          inputFormat="dd/MM/yyyy"
          renderInput={(params) => <TextField {...params} sx={{width:'150px',marginRight:'20px'}} variant="standard"/>}
          value={tanggalawal}
          onChange={(e)=>handleChangeTanggalAwal(e)}
          />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
          label="Tanggal Akhir"
          inputFormat="dd/MM/yyyy"
          renderInput={(params) => <TextField {...params} sx={{width:'150px',marginRight:'10px'}} variant="standard"/>}
          value={tanggalakhir}
          onChange={(e)=>handleChangeTanggalAkhir(e)}
          />
          </LocalizationProvider>
          <Tooltip title="Tampilkan Data">
              <IconButton onClick={()=>handleClickButton()}>
                  <ContentPasteSearchIcon/>
              </IconButton>
          </Tooltip>
          </Box>
          <TableContainer component={Paper} sx={paperTable}>
                  <Table stickyHeader aria-label="customized table" size='small'>
                      <TableHead>
                      <TableRow>
                          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Tanggal</Typography></StyledTableCell>
                          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>No Faktur</Typography></StyledTableCell>
                          <StyledTableCell width={200} align="center"><Typography sx={{fontSize:12}}>Pelanggan</Typography></StyledTableCell>
                          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Total Item</Typography></StyledTableCell>
                          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Total</Typography></StyledTableCell>
                          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Status</Typography></StyledTableCell>
                          <StyledTableCell width={20} align="center"></StyledTableCell>
                      </TableRow>
                      </TableHead>
                          {tableDataPenjualan} 
                  </Table>
               </TableContainer>
               <Box sx={{width:'80%',display:'flex',justifyContent:'flex-end'}}>
                  <Typography>Total Penjualan : {new Intl.NumberFormat().format(totalPenjualan)}</Typography>
               </Box>
        </Box>
               {returPelanggan.length>0?headerTableRetur:null}
               {returPelanggan.length>0?totalText:null}
        <Snackbar 
            open={openSnackbar} 
            autoHideDuration={1000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={penjualanMessage === "Transaksi penjualan berhasil" ? "success" : "error"}
                >
                    {penjualanMessage}
                    </Alert>
            </Snackbar>
            <Fab sx={{position: 'fixed',bottom: theme.spacing(5),left: theme.spacing(3)}} size="small" color="primary" aria-label="add" onClick={()=>handleClickBack()}>
                <ArrowBackIcon/>
            </Fab>
    </Container>
  )
}

export default LaporanPelangganPage