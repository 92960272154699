const homeInitialState ={
    openDrawer:false,
    statusServer:false,
    timeConnection:0,
    clickMenu:'Home'
}

const homeReducers = (state=homeInitialState,action)=>{
    switch(action.type){
        case 'SET OPEN DRAWER':{
            return{
                ...state,
                openDrawer:action.payload
            }
        }
        case 'SET STATUS SERVER':{
            return{
                ...state,
                statusServer:action.payload
            }
        }
        case 'SET TIME SERVER':{
            return{
                ...state,
                timeConnection:action.payload
            }
        }
        case 'SET CLICK MENU':{
            return{
                ...state,
                clickMenu:action.payload
            }
        }
        default: return state
    }
}

export default homeReducers;