import {Container,Typography,styled, useTheme,Divider,Box, TableContainer, Table, TableHead, TableRow, TableCell, tableCellClasses, Paper, TableBody, Tooltip, IconButton, Fab} from '@mui/material';
import React,{useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import XLSX from 'xlsx'
import { NewData} from '../../../components/assets';
import { addBarangExcel } from '../../../config/redux/actions';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosOutlined';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'grey',
        color: theme.palette.common.black
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
const BarangDariExcel = () => {
  const theme = useTheme();
    const paperTable = {
        marginTop:'10px',
        [theme.breakpoints.up('sm')]:{
            width:'40%',
            height:'420px',
        }, 
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            height:'360px'
        },
        scrollbarWidth:'thin'
    }
    const inputFile = {
        display:'flex',
        marginTop:'20px',
        [theme.breakpoints.up('sm')]:{
            width:'40%',
        }, 
        [theme.breakpoints.down('sm')]:{
            width:'100%',
        },
    }
    const [lockInputFile,setLockInputFile]=useState(false);
    const {dataBarangExcel} = useSelector(state=>state.barangReducers);
    const dispatch = useDispatch();
    const history=useHistory();
    const readExcel = (file) =>{
        const promise = new Promise((resolve,reject)=>{
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload= (e)=>{
                const bufferArray=e.target.result;
                const wb=XLSX.read(bufferArray,{type:'buffer'});

                const wsname=wb.SheetNames[0];
                const ws=wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws);
                resolve(data);
            }
            fileReader.onerror=(error)=>{
                reject(error);
            }
        })
        promise.then((d)=>{
            dispatch(addBarangExcel(d));
            setLockInputFile(true);
        })
    }
    const handleChange = (e) =>{
        readExcel(e.target.files[0]);
    }
    const handleAddBarang = (id,nama)=>{
        history.push(`/barang/baru/excel/${id}/${nama}`);
    }
    const tableBarang = (
        <TableBody>
            {dataBarangExcel.map((barangs) => (
                <StyledTableRow key={barangs.id}>
                <StyledTableCell width={150} align="center">{barangs.id}</StyledTableCell>
                <StyledTableCell width={200} align="center">{barangs.nama}</StyledTableCell>
                <StyledTableCell width={50} align="center">
                     <Tooltip title="Tambah Barang" >
                        <IconButton 
                        onClick={()=>handleAddBarang(barangs.id,barangs.nama)}
                        >
                            <img src={NewData} alt="editData"/>
                        </IconButton>
                     </Tooltip>
                </StyledTableCell>
                </StyledTableRow>
            ))}
        </TableBody>
    )
  return (
      <Container maxWidth='xl' sx={{marginTop:'100px'}}>
            <Typography variant='h6' pl={1} mt={1}>Pendaftaran Barang (EXCEL)</Typography>
            <Divider/>
        <Box sx={{marginTop:'5px',width:'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
          <Box sx={inputFile}>
            <Typography sx={{marginRight:'20px'}}>Silahkan Pilih File</Typography>
                <input 
                disabled={lockInputFile}
                type="file"
                accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={(e)=>handleChange(e)}/>
          </Box>
        <TableContainer component={Paper} sx={paperTable}>
                <Table stickyHeader aria-label="customized table" size='small'>
                    <TableHead>
                    <TableRow>
                        <StyledTableCell width={150} align="center">ID Barang</StyledTableCell>
                        <StyledTableCell width={200} align="center">Nama Barang</StyledTableCell>
                        <StyledTableCell width={50} align="center"/>
                    </TableRow>
                    </TableHead>
                        {tableBarang} 
                </Table>
            </TableContainer>
            </Box>
            <Fab sx={{position: 'fixed',bottom: theme.spacing(5),left: theme.spacing(3)}} size="small" color="primary" aria-label="add" onClick={()=>history.push('/barang')}>
            <ArrowBackIcon/>
        </Fab>
    </Container>
    
  )
}

export default BarangDariExcel;
