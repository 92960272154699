import {useTheme,Fade,ListItemButton, styled, ListItemText, ListItemIcon, Paper, List } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {useSelector,useDispatch} from 'react-redux';
import { OpenLeftDrawer, setClickMenu } from '../../../config/redux/actions/homeActions';
import { CustomerIcon, DaftarHarga, HomeIcon, ListOrder, PembelianIcon, PenjualanIcon,  PPNIcon, ProductIcon, Sales, StokIcon, SupplierIcon, UnitIcon, UserIcon } from '../../assets';
import { MenuList } from '../../molecules';
import {Link, useHistory} from 'react-router-dom';
import Cookies from 'js-cookie';
import { emptyFormDraft, emptyFormPenjualan, emptyPembelianForm, emptyUserDetails, getDetailsPengguna } from '../../../config/redux/actions';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const LeftMenu = () => {
    const {openDrawer,clickMenu} = useSelector(state=>state.homeReducers);
    const dispatch = useDispatch();
    const StyledMenuList = styled(MenuList)(
        ` 
          &:hover, &.Mui-focusVisible {
            color: #82141b
          }`
        );
        const StyledList = styled(List)(
            `& .MuiListItemButton-root {
                paddingLeft: 24,
                paddingRight: 24,
              }`,
              `& .MuiListItemIcon-root: {
                minWidth: 0,
                marginRight: 16,
              }`,
              `& .MuiSvgIcon-root: {
                fontSize: 20,
              }`,
              `&:hover, &.Mui-focusVisible {
                color: #82141b
              }`
            );
    const theme = useTheme();
    const container = {
        [theme.breakpoints.up('sm')]:{
            position:'fixed',
            width:'25%',
            maxWidth:'250px',
            height:'100vh',
            backgroundColor:'#5C3925',
            paddingTop:'40px',
            zIndex:'1000'
        },[theme.breakpoints.down('sm')]:{
            width:'40%',
            maxWidth:'400px',
            height:'100vh',
            backgroundColor:'#5C3925',
            paddingTop:'40px',
            position:'fixed',
            zIndex:'1000'
        },
    }
    const handleClick = ()=>{
        dispatch(OpenLeftDrawer(!openDrawer));
    }
    const history = useHistory();
    const {dataLogin,detailsPengguna} =useSelector(state=>state.penggunaReducers);
    const [uid,setUid]  = useState(dataLogin.u_name);
    const [token,setToken] = useState(dataLogin.token);
    const [Pengguna,setPengguna] = useState(false);
    const [Pelanggan,setPelanggan] = useState(false);
    const [Distributor,setDistributor] = useState(false);
    const [StokBarang,setStokBarang] = useState(false);
    const [Barang,setBarang] = useState(false);
    const [Harga,setHarga] = useState(false);
    const [Pembelian,setPembelian] = useState(false);
    const [Penjualan,setPenjualan] = useState(false);
    const [listOrder,setListOrder]=useState(false);
    // const [retur,setRetur] = useState(false);
    const [Laporan,setLaporan] = useState(false);
    const [open,setOpen]=useState(false);
    
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
        setUid(Cookies.get('u_name'));
    }
    useEffect(()=>{
        if(detailsPengguna===''){
            dispatch(getDetailsPengguna(uid,token,"",history));
        }
        detailsPengguna.m_pengguna === 'true' ? setPengguna(false) : setPengguna(true);
        detailsPengguna.m_pelanggan === 'true' ? setPelanggan(false) : setPelanggan(true);
        detailsPengguna.m_distributor === 'true' ? setDistributor(false) : setDistributor(true);
        detailsPengguna.m_stokbarang === 'true' ? setStokBarang(false) : setStokBarang(true);
        detailsPengguna.m_barang === 'true' ? setBarang(false) : setBarang(true);
        detailsPengguna.m_harga === 'true' ? setHarga(false) : setHarga(true);
        detailsPengguna.m_pembelian === 'true' ? setPembelian(false) : setPembelian(true);
        detailsPengguna.m_penjualan === 'true' ? setPenjualan(false) : setPenjualan(true);
        detailsPengguna.m_listorder === 'true' ? setListOrder(false) : setListOrder(true);
        // detailsPengguna.m_retur === 'true' ? setRetur(false) : setRetur(true);
        detailsPengguna.m_laporan === 'true' ? setLaporan(false) : setLaporan(true);
    },[dispatch,uid,token,detailsPengguna])

    const handleClickHalamanUtama = ()=>{
        dispatch(setClickMenu(""));
        dispatch(emptyUserDetails());
        handleClick();
    }
    const handleClickPembelian = ()=>{
        dispatch(setClickMenu("pembelian"));
        dispatch(emptyPembelianForm());
        history.push('/pembelian');
        handleClick();
    }
    const handleClickPenjualan = ()=>{
        dispatch(setClickMenu("transaksi"));
        dispatch(emptyFormDraft());
        dispatch(emptyFormPenjualan());
        history.push('/penjualan');
        handleClick();
    }
    const handleClickPengguna = () =>{
        dispatch(setClickMenu("pengguna"));
        handleClick();
    }
    const handleClickDistributor = () =>{
        dispatch(setClickMenu("distributor"));
        handleClick();
    }
    const handleClickPelanggan = () =>{
        dispatch(setClickMenu("pelanggan"));
        handleClick();
    }
    const handleClickBarang = () =>{
        dispatch(setClickMenu("barang"));
        handleClick();
    }
    const handleClickHargaBarang = () =>{
       dispatch(setClickMenu("hargabarang"));
       handleClick();
    }
    const handleClickSatuan = () =>{
        dispatch(setClickMenu("satuan"));
        handleClick();
    }
    const handleClickStokBarang = () =>{
        dispatch(setClickMenu("stokbarang"));
        handleClick();
    }
    // const handleClickStokRetur = () =>{
    //     dispatch(setClickMenu("stokretur"));
    //     handleClick();
    // }
    const handleClickPPN = () =>{
        dispatch(setClickMenu("ppn"));
        handleClick();
    }
    const handleClickLaporan = () =>{
        dispatch(setClickMenu("laporan"));
        handleClick();
    }
    const handleClickListOrder=()=>{
        dispatch(setClickMenu("transaksi"));
        handleClick();
    }
    useEffect(()=>{
        dispatch(setClickMenu(window.location.pathname.split('/')[1]));
    },[dispatch])
    
    const dataTransaksi = [
        {icon:<img src={Sales} alt="sales"/>, 
        label:'Penjualan', 
        value:Penjualan, 
        link:'/penjualan',
        click:handleClickPenjualan},
        {icon:<img src={ListOrder} alt="listOrder"/>, 
        label:'List Order',
        value:listOrder, 
        link:'/listorder',
        click:handleClickListOrder}
    ]
    return (
        <Fade in={openDrawer}>
        {/* <Drawer
        variant='temporary'
        open={openDrawer}
        onClick={handleClick}
        ModalProps={{keepMounted:true}}
        > */}
            <Paper  sx={container}>
                <StyledList component="nav" disablePadding>
                    {clickMenu!=="" ?
                        <Link to='/' style={{textDecoration:'none',color:'black'}}>
                            <StyledMenuList disabled={false} label="Halaman Utama" icon={HomeIcon} alt="dashboard" handleClick={handleClickHalamanUtama}/>
                        </Link>:
                        <StyledMenuList style={{backgroundColor:'#5C3925'}} label="Halaman Utama" icon={HomeIcon} alt="dashboard"/>
                    }
                    {clickMenu!=="pengguna" ?
                    <Link style={{textDecoration:"none",color:'black'}} to={Pengguna === false ? "/pengguna" :"/"}>
                        <StyledMenuList disabled={Pengguna} label="Pengguna" icon={UserIcon} alt="userMenu" handleClick={handleClickPengguna} />
                    </Link>:
                        <StyledMenuList style={{backgroundColor:'#5C3925'}} label="Pengguna" icon={UserIcon} alt="userMenu" />
                    }
                    {clickMenu!=="distributor"?
                        <Link style={{textDecoration:"none",color:'black'}} to={Distributor === false ? "/distributor" :"/"}>
                            <StyledMenuList disabled={Distributor} label="Distributor" icon={SupplierIcon} alt="supplierMenu" handleClick={handleClickDistributor}/>
                        </Link>:
                            <StyledMenuList style={{backgroundColor:'#5C3925'}} label="Distributor" icon={SupplierIcon} alt="supplierMenu"/>
                    }
                    {clickMenu!=="pelanggan"?
                        <Link style={{textDecoration:"none",color:'black'}} to={Pelanggan === false ? "/pelanggan" :"/"}>
                            <StyledMenuList disabled={Pelanggan} label="Pelanggan" icon={CustomerIcon} alt="customerMeru" handleClick={handleClickPelanggan}/>
                        </Link>:
                        <StyledMenuList style={{backgroundColor:'#5C3925'}} label="Pelanggan" icon={CustomerIcon} alt="customerMeru"/>
                    }
                    {clickMenu!=="barang"?
                        <Link style={{textDecoration:"none",color:'black'}} to={Barang === false ? "/barang" :"/"}>
                            <StyledMenuList disabled={Barang} label="Barang" icon={ProductIcon} alt="productMenu" handleClick={handleClickBarang}/>
                        </Link>
                        : <StyledMenuList style={{backgroundColor:'#5C3925'}} label="Barang" icon={ProductIcon} alt="productMenu"/>
                    }
                    {clickMenu!=="hargabarang"?
                        <Link style={{textDecoration:"none",color:'black'}} to={Harga === false ? "/hargabarang" :"/"}>
                            <StyledMenuList disabled={Harga} label="Daftar Harga" icon={DaftarHarga} alt="daftarHargaMenu" handleClick={handleClickHargaBarang}/>
                        </Link>:
                        <StyledMenuList style={{backgroundColor:'#5C3925'}} label="Daftar Harga" icon={DaftarHarga} alt="daftarHargaMenu"/>
                    }
                    {clickMenu!=="satuan"?
                    <Link style={{textDecoration:"none",color:'black'}} to={Barang === false ? "/satuan" :"/"}>
                        <StyledMenuList disabled={Barang} label="Satuan" icon={UnitIcon} alt="unitMenu" handleClick={handleClickSatuan}/>
                    </Link>
                    :  <StyledMenuList style={{backgroundColor:'#5C3925'}} label="Satuan" icon={UnitIcon} alt="unitMenu"/>
                    }
                    {clickMenu!=="pembelian" ?
                        <Link style={{textDecoration:"none",color:'black'}} to={Pembelian === false ? "/pembelian" :"/"}>
                            <StyledMenuList disabled={Pembelian} label="Pembelian" icon={PembelianIcon} alt="pembelianMenu" handleClick={()=>handleClickPembelian()}/>
                        </Link>:
                            <StyledMenuList style={{backgroundColor:'#5C3925'}} label="Pembelian" icon={PembelianIcon} alt="pembelianMenu"/>
                    }
                    {clickMenu!=="stokbarang"?
                        <Link style={{textDecoration:"none",color:'black'}} to={StokBarang === false ? "/stokbarang" :"/"}>
                            <StyledMenuList disabled={StokBarang} label="Stok Barang" icon={StokIcon} alt="stokMenu" handleClick={handleClickStokBarang}/>
                        </Link>:
                        <StyledMenuList style={{backgroundColor:'#5C3925'}} label="Stok Barang" icon={StokIcon} alt="stokMenu"/>
                    }
                    </StyledList>
                    <ListItemButton
                    alignItems='center'
                    onClick={()=>setOpen(!open)}
                    sx={{
                        color:'white',
                        // px:3,
                        // pt:2.5,
                        // pb:open?0:2.5,
                    '&:hover,&:focus':{'&svg':{opacity:open?1:0}}
                    }}
                    >
                    <img src={PenjualanIcon} alt="transaksiIcon"/>
                    <ListItemText primary="Transaksi"
                    primaryTypographyProps={{
                        fontSize:17,
                        fontWeight:'bold',
                        lineHeight:'10px',
                        mb:'2px',
                        marginLeft:'15px'
                    }}/>
                    <KeyboardArrowDownIcon
                    sx={{
                        transform:open?'rotate(-180deg)' : 'rotate(0)',
                        transition:'0.2s'
                    }}
                    />
                    </ListItemButton>
                    {open &&
                    dataTransaksi.map((item)=>(
                        <Link style={{textDecoration:"none",color:'white'}} to={item.value === false ? item.link :"/"}>
                            <ListItemButton
                            key={item.label}
                            sx={{minHeight:32,marginLeft:'10px', color:'rgba(255,255,255,.8)'}}
                            onClick={item.click}>
                                <ListItemIcon sx={{maxWidth:'20px',color: 'inherit'}}>
                                    {item.icon}
                                </ListItemIcon>
                                <ListItemText
                                    sx={{marginLeft:'-30px'}}
                                    primary={item.label}
                                    primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                                />
                            </ListItemButton>
                        </Link>
                    ))}
                    {detailsPengguna.hakakses === 'Pemilik' && clickMenu!=="ppn" ? 
                        <Link style={{textDecoration:"none",color:'black'}} to='/ppn'>
                            <StyledMenuList disabled={Laporan} label="PPN" icon={PPNIcon} alt="ppnMenu" handleClick={handleClickPPN}/>
                        </Link> : detailsPengguna.hakakses === 'Pemilik' && clickMenu==="ppn" ?
                         <StyledMenuList style={{backgroundColor:'#5C3925'}} label="PPN" icon={PPNIcon} alt="ppnMenu"/>
                         :null}
                        {detailsPengguna.hakakses === 'Pemilik' && clickMenu!=="laporan" ? 
                        <Link style={{textDecoration:"none",color:'black'}} to='/laporan'>
                            <StyledMenuList disabled={Laporan} label="Laporan" icon={DaftarHarga} alt="laporanMenu" handleClick={handleClickLaporan}/>
                        </Link> : detailsPengguna.hakakses === 'Pemilik' && clickMenu==="laporan" ? 
                            <StyledMenuList style={{backgroundColor:'#5C3925'}} label="Laporan" icon={DaftarHarga} alt="laporanMenu" /> 
                        : null}
            </Paper>
        {/* <Box sx={container}>
                <Typography pt={1} pl={1} variant='h6' color="whitesmoke">Master Data</Typography>
                {clickMenu!=="" ?
                <Link to='/' style={{textDecoration:'none',color:'black'}}>
                    <StyledMenuList disabled={false} label="Halaman Utama" icon={HomeIcon} alt="dashboard" handleClick={handleClickHalamanUtama}/>
                </Link>:
                    <StyledMenuList style={{backgroundColor:'#82141b'}} label="Halaman Utama" icon={HomeIcon} alt="dashboard"/>
                }
                {clickMenu!=="pengguna" ?
                <Link style={{textDecoration:"none",color:'black'}} to={Pengguna === false ? "/pengguna" :"/"}>
                    <StyledMenuList disabled={Pengguna} label="Pengguna" icon={UserIcon} alt="userMenu" handleClick={handleClickPengguna} />
                </Link>:
                    <StyledMenuList style={{backgroundColor:'#82141b'}} label="Pengguna" icon={UserIcon} alt="userMenu" />
                }
                {clickMenu!=="distributor"?
                    <Link style={{textDecoration:"none",color:'black'}} to={Distributor === false ? "/distributor" :"/"}>
                        <StyledMenuList disabled={Distributor} label="Distributor" icon={SupplierIcon} alt="supplierMenu" handleClick={handleClickDistributor}/>
                    </Link>:
                        <StyledMenuList style={{backgroundColor:'#82141b'}} label="Distributor" icon={SupplierIcon} alt="supplierMenu"/>
                 }
                 {clickMenu!=="pelanggan"?
                    <Link style={{textDecoration:"none",color:'black'}} to={Pelanggan === false ? "/pelanggan" :"/"}>
                        <StyledMenuList disabled={Pelanggan} label="Pelanggan" icon={CustomerIcon} alt="customerMeru" handleClick={handleClickPelanggan}/>
                    </Link>:
                    <StyledMenuList style={{backgroundColor:'#82141b'}} label="Pelanggan" icon={CustomerIcon} alt="customerMeru"/>
                }
                {clickMenu!=="barang"?
                    <Link style={{textDecoration:"none",color:'black'}} to={Barang === false ? "/barang" :"/"}>
                        <StyledMenuList disabled={Barang} label="Barang" icon={ProductIcon} alt="productMenu" handleClick={handleClickBarang}/>
                    </Link>
                    : <StyledMenuList style={{backgroundColor:'#82141b'}} label="Barang" icon={ProductIcon} alt="productMenu"/>
                }
                {clickMenu!=="hargabarang"?
                    <Link style={{textDecoration:"none",color:'black'}} to={Harga === false ? "/hargabarang" :"/"}>
                        <StyledMenuList disabled={Harga} label="Daftar Harga" icon={DaftarHarga} alt="daftarHargaMenu" handleClick={handleClickHargaBarang}/>
                    </Link>:
                    <StyledMenuList style={{backgroundColor:'#82141b'}} label="Daftar Harga" icon={DaftarHarga} alt="daftarHargaMenu"/>
                }
                {clickMenu!=="satuan"?
                    <Link style={{textDecoration:"none",color:'black'}} to={Barang === false ? "/satuan" :"/"}>
                        <StyledMenuList disabled={Barang} label="Satuan" icon={UnitIcon} alt="unitMenu" handleClick={handleClickSatuan}/>
                    </Link>
                :  <StyledMenuList style={{backgroundColor:'#82141b'}} label="Satuan" icon={UnitIcon} alt="unitMenu"/>
                }
                {clickMenu!=="pembelian" ?
                    <Link style={{textDecoration:"none",color:'black'}} to={Pembelian === false ? "/pembelian" :"/"}>
                        <StyledMenuList disabled={Pembelian} label="Pembelian" icon={PembelianIcon} alt="pembelianMenu" handleClick={()=>handleClickPembelian()}/>
                    </Link>:
                        <StyledMenuList style={{backgroundColor:'#82141b'}} label="Pembelian" icon={PembelianIcon} alt="pembelianMenu"/>
                }
                {clickMenu!=="stokbarang"?
                    <Link style={{textDecoration:"none",color:'black'}} to={StokBarang === false ? "/stokbarang" :"/"}>
                        <StyledMenuList disabled={StokBarang} label="Stok Barang" icon={StokIcon} alt="stokMenu" handleClick={handleClickStokBarang}/>
                    </Link>:
                    <StyledMenuList style={{backgroundColor:'#82141b'}} label="Stok Barang" icon={StokIcon} alt="stokMenu"/>
                }
                <ListItemButton
                alignItems='flex-start'
                onClick={()=>setOpen(true)}
                sx={{
                    px:3,
                    pt:2.5,
                    pb:open?0:2.5,
                '&:hover,&:focus':{'&svg':{opacity:open?1:0}}
                }}
                >
                    <ListItemText primary="Transaksi"
                    primaryTypographyProps={{
                        fontSize:35,
                        fontWeight:'medium',
                        lineHeight:'20px',
                        mb:'2px'
                    }}
                    secondary="Daftar Pesanan, Penjualan"
                    secondaryTypographyProps={{
                        noWrap:true,
                        fontSize:12,
                        lineHeight:'16px',
                        color:open?'rgba(0,0,0,0)' : 'rgba(255,255,255,0.5)'
                    }}
                    sx={{my:0}}/>
                    <KeyboardArrowDown
                    sx={{
                        mr:-1,
                        opacity:0,
                        transform:open?'rotate(-180deg)' : 'rotate(0)',
                        transition:'0.2s'
                    }}/>
                </ListItemButton>
                {open &&
                dataTransaksi.map((item)=>(
                    <ListItemButton
                    key={item.label}
                    sx={{py:0,minHeight:32, color:'rgba(255,255,255,.8)'}}>
                        <ListItemIcon sx={{ color: 'inherit' }}>
                            {item.icon}
                        </ListItemIcon>
                        <ListItemText
                            primary={item.label}
                            primaryTypographyProps={{ fontSize: 14, fontWeight: 'medium' }}
                        />
                    </ListItemButton>
                ))}
                {clickMenu!=="penjualan" ?
                    <Link style={{textDecoration:"none",color:'black'}} to={Penjualan === false ? "/penjualan" : "/"}>
                        <StyledMenuList 
                        disabled={Penjualan} 
                        label="Transaksi" 
                        icon={PenjualanIcon} 
                        alt="penjualanMenu" 
                        handleClick={()=>handleClickPenjualan()}/>
                    </Link>:
                        <StyledMenuList style={{backgroundColor:'#82141b'}} label="Transaksi" icon={PenjualanIcon} alt="penjualanMenu"/>
                }
                {clickMenu!=="stokretur" ?
                    <Link style={{textDecoration:"none",color:'black'}} to={Retur === false ? "/stokretur" : "/"}>
                        <StyledMenuList disabled={Retur} label="Stok Retur" icon={ReturIcon} alt="returMenu" handleClick={handleClickStokRetur}/>
                    </Link>:
                    <StyledMenuList style={{backgroundColor:'#82141b'}} label="Stok Retur" icon={ReturIcon} alt="returMenu"/>
                }
                {detailsPengguna.hakakses === 'Pemilik' && clickMenu!=="ppn" ? 
                <Link style={{textDecoration:"none",color:'black'}} to='/ppn'>
                    <StyledMenuList disabled={Laporan} label="PPN" icon={PPNIcon} alt="ppnMenu" handleClick={handleClickPPN}/>
                </Link> : detailsPengguna.hakakses === 'Pemilik' && clickMenu==="ppn" ?
                 <StyledMenuList style={{backgroundColor:'#82141b'}} label="PPN" icon={PPNIcon} alt="ppnMenu"/>
                 :null}
                {detailsPengguna.hakakses === 'Pemilik' && clickMenu!=="laporan" ? 
                <Link style={{textDecoration:"none",color:'black'}} to='/laporan'>
                    <StyledMenuList disabled={Laporan} label="Laporan" icon={DaftarHarga} alt="laporanMenu" handleClick={handleClickLaporan}/>
                </Link> : detailsPengguna.hakakses === 'Pemilik' && clickMenu==="laporan" ? 
                    <StyledMenuList style={{backgroundColor:'#82141b'}} label="Laporan" icon={DaftarHarga} alt="laporanMenu" /> 
                : null}
            </Box> */}
            </Fade>
        // </Drawer>
    )
}

export default LeftMenu
