import { Button, Checkbox, CircularProgress, Container, Divider, FormControlLabel,IconButton, FormGroup, Snackbar, TextField, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import { useFormik } from 'formik';
import React, { useState } from 'react'
import * as yup from 'yup';
import MuiAlert from '@mui/material/Alert';
import { addNewPengguna, emptyUserMessage } from '../../../config/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });


const PenggunaBaru = () => {
    const hakAkses = [
        {
            value: 'null',
            label: "",
          },
          {
            value: 'Pemilik',
            label: 'Pemilik',
          },
          {
            value: 'Super User',
            label: 'Super User',
          },
          {
            value: 'Gudang',
            label: 'Gudang',
          },
          {
            value: 'Kasir',
            label: 'Kasir',
          },
          {
            value: 'Admin',
            label: 'Admin',
          }
    ];
    const theme = useTheme();
    const boxWapper = {
        display:'flex',
        justifyContent:'center',
        [theme.breakpoints.up('sm')]:{
            width:'100%',
            maxHeight:'500px',
            height:'100%',
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            height:'100%',
        }
    }
    const paperInput={
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        // border:'1px solid black',
        [theme.breakpoints.up('sm')]:{
            width:"35%",
            height:'100%',
            borderRadius:'10px',
            marginTop:'10px'
        },
        [theme.breakpoints.down('sm')]:{
            width:'90%',
            height:'100%',
            borderRadius:'10px',
            marginTop:'10px'
        }
    }
    const checkBoxWapper={
        [theme.breakpoints.up('sm')]:{
            width:'80%',
            marginTop:'10px',
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        },
        [theme.breakpoints.down('sm')]:{
            width:'55%',
            marginTop:'10px',
            display:'flex',
            flexDirection:'column'
        }
    }
    const [valueSelect,setValueSelect] = useState('null');
    const {penggunaMessage,isLoading,dataLogin} = useSelector(state=>state.penggunaReducers);
    const [openSnackbar,setOpensnackbar]=useState(false);
    const [token,setToken] = useState(dataLogin.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const dispatch = useDispatch();
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(emptyUserMessage(''));
        }, 100);
    }
    const history = useHistory();
    const schemaValidation = yup.object().shape({
        idpengguna:yup.string().required("ID Pengguna tidak boleh kosong").matches(/^(?=.{6,10}$)(?![_.])[a-zA-Z0-9._]/,"Id pengguna harus terdiri dari 6 sampai 10 karakter dan tidak boleh mengandung karakter _ atau ."),
        nama:yup.string().required("Nama tidak boleh kosong"),
        password:yup.string().required("Password tidak boleh kosong").matches(
            /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
            "Kata sandi harus terdiri dari 8 karakter, 1 huruf besar, 1 huruf kecil, 1 nomor dan 1 karakter spesial"
          ),
        ulangpassword:yup.string()
        .oneOf([yup.ref('password'), null], 'Ulang kata sandi tidak sesuai'),
        hakakses:yup.string().required("Hak Akses tidak boleh kosong"),
    })
    const formik = useFormik({
        initialValues:{
            idpengguna:'',
            nama:'',
            password:'',
            ulangpassword:'',
            hakakses:'',
        },
        validationSchema:schemaValidation,
        onSubmit:(values)=>{
            const data = new URLSearchParams();
            data.append('idpengguna',values.idpengguna);
            data.append('nama',values.nama);
            data.append('password',values.password);
            data.append('hakakses',values.hakakses);
            data.append('modul_pengguna',Pengguna);
            data.append('modul_pelanggan',Pengguna);
            data.append('modul_distributor',Distributor);
            data.append('modul_stokbarang',StokBarang);
            data.append('modul_barang',Barang);
            data.append('modul_harga',Harga);
            data.append('modul_pembelian',Pembelian);
            data.append('modul_penjualan',Penjualan);
            data.append('modul_retur',Retur);
            data.append('modul_laporan',Laporan);
            dispatch(addNewPengguna(data,token,setOpensnackbar,history));
            
        }
    })
    const [Pengguna,setPengguna] = useState(false);
    const [Pelanggan,setPelanggan] = useState(false);
    const [Distributor,setDistributor] = useState(false);
    const [StokBarang,setStokBarang] = useState(false);
    const [Barang,setBarang] = useState(false);
    const [Harga,setHarga] = useState(false);
    const [Pembelian,setPembelian] = useState(false);
    const [Penjualan,setPenjualan] = useState(false);
    const [Retur,setRetur] = useState(false);
    const [Laporan,setLaporan] = useState(false);
    const [showPassword,setShowPassword]=useState(false);
    const [showUlangPassword,setShowUlangPassword] = useState(false);
    const onSelectHandleChange = (e)=>{
        setValueSelect(e.target.value);
        formik.setFieldValue('hakakses',e.target.value);
        if(e.target.value === ''){
            setPengguna(false);
            setPelanggan(false);
            setDistributor(false);
            setStokBarang(false);
            setBarang(false);
            setHarga(false);
            setPembelian(false);
            setPenjualan(false);
            setRetur(false);
            setLaporan(false);
        }
        else if(e.target.value === 'Pemilik'){
            setPengguna(true);
            setPelanggan(true);
            setDistributor(true);
            setStokBarang(true);
            setBarang(true);
            setHarga(true);
            setPembelian(true);
            setPenjualan(true);
            setRetur(true);
            setLaporan(true);
        }else if(e.target.value === 'Super User'){
            setPengguna(false);
            setPelanggan(true);
            setDistributor(true);
            setStokBarang(true);
            setBarang(true);
            setHarga(false);
            setPembelian(true);
            setPenjualan(true);
            setRetur(true);
            setLaporan(false);
        }else if(e.target.value === 'Gudang'){
            setPengguna(false);
            setPelanggan(false);
            setDistributor(false);
            setStokBarang(true);
            setBarang(true);
            setHarga(false);
            setPembelian(false);
            setPenjualan(false);
            setRetur(true);
            setLaporan(false);
        }
        else if(e.target.value === 'Kasir'){
            setPengguna(false);
            setPelanggan(true);
            setDistributor(false);
            setStokBarang(false);
            setBarang(false);
            setHarga(false);
            setPembelian(false);
            setPenjualan(true);
            setRetur(false);
            setLaporan(false);
        }else if(e.target.value === 'Admin'){
            setPengguna(false);
            setPelanggan(true);
            setDistributor(true);
            setStokBarang(true);
            setBarang(true);
            setHarga(false);
            setPembelian(true);
            setPenjualan(false);
            setRetur(true);
            setLaporan(false);
        }
    }
    const handleClickShowPassword = () =>{
        setShowPassword(!showPassword);
    }
    
    const handleClickShowUlangPassword = () =>{
        setShowUlangPassword(!showUlangPassword);
    }
    return (
        <Container maxWidth='xl' sx={{marginTop:'100px'}}>
        <Typography variant='h6' pl={1} mt={1}>Pendaftaran Pengguna</Typography>
        <Divider/>
        <Box sx={boxWapper}>
            <Box sx={paperInput}>
            <TextField
                    id="idpengguna"
                    name="idpengguna"
                    label="ID Pengguna"
                    variant="standard"
                    style={{width:'80%',marginTop:'10px'}}
                    value={formik.values.idpengguna}
                    error={formik.touched.idpengguna && Boolean(formik.errors.idpengguna)}
                    helperText={formik.touched.idpengguna && formik.errors.idpengguna}
                    onChange={formik.handleChange}
                    />
                    <TextField
                    id="nama"
                    name="nama"
                    label="nama"
                    variant="standard"
                    style={{width:'80%',marginTop:'10px'}}
                    value={formik.values.nama}
                    error={formik.touched.nama && Boolean(formik.errors.nama)}
                    helperText={formik.touched.nama && formik.errors.nama}
                    onChange={formik.handleChange}
                    />
                    <Box sx={{width:'80%',display:'flex',alignItems:'flex-end',marginTop:'2px'}}>
                    <TextField
                    id="password"
                    label="Kata Sandi"
                    type={showPassword ? 'text':'password'}
                    variant="standard"
                    style={{width:'85%'}}
                    value={formik.values.password}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    onChange={formik.handleChange}
                    />
                    <IconButton 
                    arial-label='toggle-password'
                    onClick={handleClickShowPassword}
                    >
                    {showPassword?<Visibility/>:<VisibilityOff/>}
                    </IconButton>
                    </Box>
                    <Box sx={{width:'80%',display:'flex',alignItems:'flex-end',marginTop:'2px'}}>
                    <TextField
                    id="ulangpassword"
                    label="Ulang Kata Sandi"
                    type={showUlangPassword ? 'text':'password'}
                    variant="standard"
                    style={{width:'85%'}}
                    value={formik.values.ulangpassword}
                    error={formik.touched.ulangpassword && Boolean(formik.errors.ulangpassword)}
                    helperText={formik.touched.ulangpassword && formik.errors.ulangpassword}
                    onChange={formik.handleChange}
                    />
                    <IconButton 
                    arial-label='toggle-password'
                    onClick={handleClickShowUlangPassword}
                    >
                    {showUlangPassword?<Visibility/>:<VisibilityOff/>}
                    </IconButton>
                    </Box>
                   
                    <TextField
                    id='hakakses'
                    select
                    name='hakakses'
                    variant="standard" 
                    label="Hak Akses"
                    style={{width:'80%',marginTop:'10px'}}
                    SelectProps={{
                        native: true,
                      }}
                    value={valueSelect}
                    error={formik.touched.hakakses && Boolean(formik.errors.hakakses)}
                    helperText={formik.touched.hakakses && formik.errors.hakakses}
                    onChange={(e)=>onSelectHandleChange(e)}
                    >
                        {hakAkses.map((option)=>(
                            <option key ={option.value} value={option.label}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                    <Box sx={checkBoxWapper}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox
                            id='m_pengguna'
                            name='m_pengguna'
                            checked={Pengguna}
                            onClick={()=>setPengguna(!Pengguna)}
                            value={Pengguna}/>} label="Modul Pengguna"/>
                            <FormControlLabel control={<Checkbox
                            id='m_pelanggan'
                            name='m_pelanggan'
                            value={Pelanggan}
                            checked={Pelanggan}
                            onClick={()=>setPelanggan(!Pelanggan)}
                            />} label="Modul Pelanggan"/>
                            <FormControlLabel control={<Checkbox
                            id='m_distributor'
                            name='m_distributor'
                            value={Distributor}
                            checked={Distributor}
                            onClick={()=>setDistributor(!Distributor)}
                            />} label="Modul Distributor"/>
                            <FormControlLabel control={<Checkbox
                             id='m_stokbarang'
                             name='m_stokbarang'
                             value={StokBarang}
                            checked={StokBarang}
                            onClick={()=>setStokBarang(!StokBarang)}
                             />} label="Modul Stok Barang"/>
                            <FormControlLabel control={<Checkbox
                            id='m_barang'
                            name='m_barang'
                            value={Barang}
                            checked={Barang}
                            onClick={()=>setBarang(!Barang)}
                            />} label="Modul Barang"/>
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox
                            id='m_harga'
                            name='m_harga'
                            value={Harga}
                            checked={Harga}
                            onClick={()=>setHarga(!Harga)}
                            />} label="Modul Harga"/>
                            <FormControlLabel control={<Checkbox
                            id='m_pembelian'
                            name='m_pembelian'
                            value={Pembelian}
                            checked={Pembelian}
                            onClick={()=>setPembelian(!Pembelian)}
                            />} label="Modul Pembelian"/>
                            <FormControlLabel control={<Checkbox
                            id='m_penjualan'
                            name='m_penjualan'
                            value={Penjualan}
                            checked={Penjualan}
                            onClick={()=>setPenjualan(!Penjualan)}
                            />} label="Modul Penjualan"/>
                            <FormControlLabel control={<Checkbox
                            id='m_retur'
                            name='m_retur'
                            value={Retur}
                            checked={Retur}
                            onClick={()=>setRetur(!Retur)}
                            />} label="Modul Retur"/>
                            <FormControlLabel control={<Checkbox
                            id='m_laporan'
                            name='m_laporan'
                            value={Laporan}
                            checked={Laporan}
                            onClick={()=>setLaporan(!Laporan)}
                            />} label="Modul Laporan"/>
                        </FormGroup>
                    </Box>
                    <Button variant='contained' sx={{marginTop:'10px',marginBottom:'10px',width:'100px'}} onClick={formik.handleSubmit} disabled={isLoading}>{isLoading === true ? <CircularProgress color='primary' size={25}/> : "SIMPAN"}</Button>
            </Box>
        </Box>
        <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={penggunaMessage === 'Berhasil mendaftarkan pengguna baru' ? "success" : "error"}
                >
                    {penggunaMessage}
                    </Alert>
            </Snackbar>
        </Container>
    )
}

export default PenggunaBaru
