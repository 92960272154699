import axios from 'axios';
import { apiUrl } from '../..';

export const getLastBuyPrice = (data,token,formik) =>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION RETUR'
    })
    axios.post(`${apiUrl.url}stokbarang/lastBuy`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`
          }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESSED GET LAST BUY PRICE',
            payload:{
                'lastPrice':ResponseAPI.data,
                'ppn':ResponseAPI.ppn,
                'kelompok':ResponseAPI.kelompok
            }
          })
          const tempPrice=Math.ceil(ResponseAPI.data);
          formik.setFieldValue('harga',tempPrice);
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION RETUR',
          payload:error.message
        })
      })
}
export const getLastPrice = (data,token,setLastPrice) =>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION RETUR'
    })
    axios.post(`${apiUrl.url}stokbarang/lastBuy`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`
          }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        if(ResponseAPI.kelompok==="PPN"){
            const nilaiPPN=parseInt(ResponseAPI.ppn)/100;
            const hitungPPN=parseInt(ResponseAPI.data)*nilaiPPN;
            const newHarga = Math.ceil(parseInt(ResponseAPI.data)+hitungPPN);
            setLastPrice(newHarga);
        }else if(ResponseAPI.kelompok==="NON PPN"){
            setLastPrice(parseInt(ResponseAPI.data));
        }
        
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION RETUR',
          payload:error.message
        })
      })
}
export const emptyLastBuy = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY LAST BUY PRICE'
    })
}

export const addReturToCart = (data,setOpensnackbar) =>(dispatch)=>{
    dispatch({
        type:'ADD RETUR TO CART',
        payload:data
    })
    dispatch({
        type:'SET RETUR MESSAGE',
        payload:'Barang berhasil dimasukkan ke daftar retur'
    })
    setOpensnackbar(true);
}
export const setReturMessage = (message) =>(dispatch)=>{
    dispatch({
        type:'SET RETUR MESSAGE',
        payload:message
    })
}
export const deleteBarangFromCartRetur = (id) =>(dispatch)=>{
    dispatch({
        type:'SET DELETE CART RETUR',
        payload:id
    })
}
export const emptyCartRetur = () =>(dispatch)=>{
    dispatch({
        type:'SET EMPTY CART RETUR'
    })
}
export const postRetur =(data,token,setTotalRetur,setTotalFinal)=>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION RETUR'
    })
    axios.post(`${apiUrl.url}returpembelian/`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`
          }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESSED POST RETUR',
            payload:ResponseAPI.message
          })
        dispatch({
            type:'SET EMPTY CART RETUR'
        })
        setTotalRetur(0);
        setTotalFinal(0);
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION RETUR',
          payload:error.message
        })
      })
}
export const getDataReturPembelian = (data,token,setOpensnackbar) =>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION RETUR'
    })
    axios.post(`${apiUrl.url}returpembelian/getall/`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`
          }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESSED GET RETUR PEMBELIAN',
            payload:ResponseAPI.data
          })
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION RETUR',
          payload:error.message
        })
        setOpensnackbar(true);
      })
} 
export const getDataReturPembelianDetails = (faktruretur,token,setOpensnackbar) =>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION RETUR'
    })
    axios.get(`${apiUrl.url}returpembelian/${faktruretur}`,{
        headers:{
            "Authorization" : `Bearer ${token}`
          }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        axios.get(`${apiUrl.url}returpembelian/details/${faktruretur}`,{
            headers:{
                "Authorization" : `Bearer ${token}`
              }
        })
        .then(resDet=>{
            const ResponseAPIDet = resDet.data;
            dispatch({
                type:'SUCCESSED GET RETUR PEMBELIAN DETAILS',
                payload:ResponseAPI.data[0],
                details:ResponseAPIDet.data
              })
        })
        .catch(error=>{
            dispatch({
              type:'FAILED ACTION RETUR',
              payload:error.message
            })
            setOpensnackbar(true);
          })
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION RETUR',
          payload:error.message
        })
        setOpensnackbar(true);
      })
} 