import React, { useEffect, useState } from 'react'
import {Container,Typography,styled, useTheme,Divider,Box, TextField, TableContainer, Table, TableHead, TableRow, TableCell, tableCellClasses, Paper, TableBody, Tooltip, IconButton, Snackbar} from '@mui/material';
import Cookies from 'js-cookie';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteData, EditData } from '../../../components/assets';
import { useHistory } from 'react-router-dom';
import { deleteSatuan, emptySatuanMessage, getAllSatuan, getDetailsSatuan } from '../../../config/redux/actions';
import { DeleteDialog,TablePagination } from '../../../components/molecules';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'grey',
        color: theme.palette.common.black
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
const SatuanPage = () => {
    const theme = useTheme();
    const paperTable = {
        marginTop:'10px',
        [theme.breakpoints.up('sm')]:{
            width:'50%',
            height:'370px',
        }, 
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            height:'360px'
        },
        scrollbarWidth:'thin'
    }
    const boxSearch = {
        [theme.breakpoints.up('sm')]:{
            display:'flex',
            justifyContent:'flex-end',
            width:'50%',
        }, 
        [theme.breakpoints.down('sm')]:{
            display:'flex',
            justifyContent:'flex-end',
            width:'100%',
        }
    }
    const {dataSatuan,pageSatuan,satuanMessage,isLoading} = useSelector(state=>state.satuanReducers);
    const {dataLogin} =useSelector(state=>state.penggunaReducers);
    const history = useHistory();
    const dispatch = useDispatch();
    const [token,setToken] = useState(dataLogin.token);
    const [search,setSearch] = useState('');
    const [counter,setCounter] = useState(1);
    const [openSnackbar,setOpensnackbar]=useState(false);
    const [openDialog,setOpenDialog]=useState(false);
    const [messageDialog,setMessageDialog]=useState('');
    const [tempIdSatuan,setTempIdSatuan] = useState('');
    const handleChangeSearch = (e) =>{
        setSearch(e.target.value);
        dispatch(getAllSatuan(e.target.value,counter,token,setOpensnackbar));
    }
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    useEffect(()=>{
        dispatch(getAllSatuan(search,counter,token,setOpensnackbar));
    },[search,counter,token,dispatch])
    const handlePrev = () =>{
        setCounter(counter <= 1? 1 : counter - 1);
    }
    const handleNext = () =>{
        setCounter(counter === pageSatuan.totalPage ? pageSatuan.totalPage : counter + 1);
    }
    const handleEditSatuan = (idsatuan) =>{
        dispatch(getDetailsSatuan(idsatuan,token,setOpensnackbar));
        history.push(`/satuan/ubah/${idsatuan}`);
    }
    const handleOpenDialog = (idsatuan,satuan)=>{
        setMessageDialog(`Apakah anda yakin akan menghapus data ${satuan}(${idsatuan})?`);
        setTempIdSatuan(idsatuan);
        setOpenDialog(true);
    }
    const handleCloseDialog = ()=>{
        setOpenDialog(false);
    }
    
    const handleDeleteSatuan = () =>{
        dispatch(deleteSatuan(tempIdSatuan,token,setOpensnackbar,search,counter,setOpenDialog));
    }
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(emptySatuanMessage(''));
        }, 100);
    }
    const tableSatuan = (
        <TableBody>
            {dataSatuan.map((satuans) => (
                <StyledTableRow key={satuans.idsatuan}>
                <StyledTableCell width={120} align="center">
                    {satuans.idsatuan}
                </StyledTableCell>
                <StyledTableCell width={120} align="center">{satuans.satuan}</StyledTableCell>
                <StyledTableCell width={60} align="center">
                    <Tooltip title="Ubah data" onClick={()=>handleEditSatuan(satuans.idsatuan)}>
                        <IconButton>
                            <img src={EditData} alt="editData"/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Hapus data">
                        <IconButton onClick={()=>handleOpenDialog(satuans.idsatuan,satuans.satuan)}>
                            <img src={DeleteData} alt="deleteData"/>
                        </IconButton>
                    </Tooltip>
                </StyledTableCell>
                </StyledTableRow>
            ))}
        </TableBody>
    )
    return (
        <Container maxWidth='xl' sx={{marginTop:'50px'}}>
            <Typography variant='h6' pl={1} mt={1}>Data Satuan</Typography>
            <Divider/>
            <Box sx={{marginTop:'5px',width:'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            <Box sx={boxSearch}>
                <TextField
                id='search'
                name='search'
                label='Cari Satuan'
                variant='standard'
                onChange={handleChangeSearch}
                />
            </Box>
            <TableContainer component={Paper} sx={paperTable}>
                <Table stickyHeader aria-label="customized table" size='small'>
                    <TableHead>
                    <TableRow>
                        <StyledTableCell width={120} align="center">ID Satuan</StyledTableCell>
                        <StyledTableCell width={120} align="center">Satuan</StyledTableCell>
                        <StyledTableCell width={60} align="center"/>
                    </TableRow>
                    </TableHead>
                        {tableSatuan} 
                </Table>
            </TableContainer>
            <Box sx={boxSearch}>
            <TablePagination
            page={counter}
            totalPage={pageSatuan.totalPage >= 1 ? pageSatuan.totalPage : pageSatuan.currentPage}
            handlePrev={handlePrev}
            handleNext={handleNext}
            hiddenButton={false}
            onButtonClick={()=>history.push('/satuan/baru')}
            />
            </Box>
            </Box>
            <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={satuanMessage === 'Penghapusan data satuan berhasil'? 'success':'error'}
                >
                    {satuanMessage}
                    </Alert>
            </Snackbar>
            <DeleteDialog 
            openDialog={openDialog} 
            message={messageDialog}
            handleCloseDialog={handleCloseDialog}
            handleClick={handleDeleteSatuan}
            isLoading={isLoading}
            textHapus={"Ya"}
            textKeluar={"Tidak"}/>
        </Container>
    )
}

export default SatuanPage
