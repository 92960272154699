import { Box, Container } from '@mui/material'
import React from 'react'
import {useTheme} from '@mui/material/styles';
import { SignUpCard } from '../../../components/organism';
import { loginBGDesktop, loginBGMobile } from '../../../components/assets';

const Signup = () => {
    const theme = useTheme();
    const container = {
        [theme.breakpoints.up('sm')]:{
            backgroundImage:`url(${loginBGDesktop})`,
            backgroundRepeat:'no-repeat',
            backgroundPosition:'center',
            width:'100%',
            height:'100vh'
        },
        [theme.breakpoints.down('sm')]:{
            display:'flex',
            alignItems:'center',
            backgroundImage:`url(${loginBGMobile})`,
            backgroundRepeat:'no-repeat',
            backgroundPosition:'center center',
            backgroundAttachment:"fixed",
            backgroundSize: "cover",
            width:'100vw',
            height:'100vh'
        },
    };
    const boxContainer = {
        [theme.breakpoints.up('md')]:{
            display:'flex',
            justifyContent:'right', 
            marginRight:'50px',
            alignItems:'center',
            height:'100vh'
        },
        [theme.breakpoints.down('md')]:{
            // border:'1px solid black',
            display:'relative',
            width:'100%',
            justifyContent:'center',
            paddingTop:'20%'
        }
    }
    return (
        <div>
            <Container component='div' maxWidth='xl' sx={container}>
                <Box sx={boxContainer}>
                    <SignUpCard/>
                </Box>
            </Container>
        </div>
    )
}

export default Signup