const barangInitialValues = {
    isLoading:false,
    dataBarang:[],
    pageBarang : {
        currentPage:'1',
        totalPage:'1'
    },
    barangDetails:[],
    messageBarang:[],
    todoSatuan:[],
    arrSatuanBarang:[],
    FormEditStokAwal:[],
    dataHargaBarang:[],
    pageHargaBarang : {
        currentPage:'1',
        totalPage:'1'
    },
    dataBarangExcel:[],
    listDataBarang:[],
    arrBarcode:[],
    dataPPNBarang:[],
    pagePPNBarang : {
        currentPage:'1',
        totalPage:'1'
    }
}
const barangReducers = (state=barangInitialValues,action)=>{
    switch(action.type){
        case 'EMPTY BARANG MESSAGE':{
            return{
                ...state,
                messageBarang:action.payload
            }
        }
        case 'EMPTY DATA BARANG':{
            return{
                ...state,
                dataBarang:[],
                pageBarang:{
                    currentPage:'1',
                    totalPage:'1'
                }
            }
        }
        case 'UPDATE PAGE BARANG':{
            return {
                ...state,
                pageBarang:action.payload
            }
        }
        case 'UPDATE DATA BARANG':{
            return{
                ...state,
                isLoading:false,
                dataBarang:action.payload
            }
        }
        case 'FETCH GET ALL BARANG':{
            return{
                ...state,
                isLoading:true
            }
        }
        case 'FAILED GET ALL BARANG':{
            return{
                ...state,
                isLoading:false,
                messageBarang:action.payload
            }
        }
        case 'FETCH ACTION BARANG':{
            return{
                ...state,
                isLoading:true
            }
        }
        case 'SUCCESSED ACTION BARANG':{
            return{
                ...state,
                isLoading:false,
                messageBarang:action.payload
            }
        }
        case 'FAILED ACTION BARANG':{
            return{
                ...state,
                isLoading:false,
                messageBarang:action.payload
            }
        }
        case 'SUCCESSED GET BARANG DETAILS':{
            return{
                ...state,
                isLoading:false,
                barangDetails:action.payload
            }
        }
        case 'EMPTY BARANG DETAILS':{
            return{
                ...state,
                barangDetails:[]
            }
        }
        case 'SUCCESSED GET SATUAN BARANG DETAILS':{
            return{
                ...state,
                isLoading:false,
                arrSatuanBarang:action.payload,
                messageBarang:action.message
            }
        }
        case 'EMPTY ARRAY SATUAN BARANG DETAILS':{
            return{
                ...state,
                arrSatuanBarang:[]
            }
        }
        case 'ADD BARANG EXCEL':{
            return{
                ...state,
                dataBarangExcel:action.payload
            }
        }
        case 'EMPTY BARANG EXCEL':{
            return{
                ...state,
                dataBarangExcel:[]
            }
        }
        case 'DELETE BARANG EXCEL':{
            return{
                ...state,
                dataBarangExcel: state.dataBarangExcel.filter(barang=>barang.id.toString() !== action.payload)
            }
        }
        case 'ADD TODO SATUAN':{
            return{
                ...state,
                todoSatuan:[...state.todoSatuan,action.payload]
            }
        }
        case 'FAILED ADD TODO SATUAN':{
            return{
                ...state,
                messageBarang:action.message
            }
        }
        case 'DELETE TODO SATUAN':{
            return{
                ...state,
                todoSatuan: state.todoSatuan.filter(satuan=>satuan.idsatuan !== action.payload)
            }
        }
        case 'EMPTY TODO SATUAN':{
            return{
                ...state,
                todoSatuan:[]
            }
        }
        case 'SET FORM EDIT STOK AWAL':{
            return{
                ...state,
                FormEditStokAwal:action.payload
            }
        }
        case 'UPDATE PAGE HARGA BARANG':{
            return {
                ...state,
                pageHargaBarang:action.payload
            }
        }
        case 'UPDATE DATA HARGA BARANG':{
            return{
                ...state,
                isLoading:false,
                dataHargaBarang:action.payload
            }
        }
        case 'SUCCESSED GET DATA BARANG LIST':{
            return{
                ...state,
                isLoading:false,
                listDataBarang:action.payload
            }
        }
        case 'EMPTY LIST BARANG':{
            return{
                ...state,
                isLoading:false,
                listDataBarang:[]
            }
        }
        case 'SUCCESSED GET DATA BARCODE':{
            return{
                ...state,
                isLoading:false,
                arrBarcode:action.payload,
                messageBarang:action.message
            }
        } 
        case 'SUCCESSED POST DATA BARCODE':{
            return{
                ...state,
                isLoading:false,
                messageBarang:action.payload
            }
        }
        case 'SUCCESSED DELETE DATA BARCODE':{
            return{
                ...state,
                isLoading:false,
                messageBarang:action.payload
            }
        }
        case 'SET MESSAGE BARANG':{
            return{
                ...state,
                messageBarang:action.payload
            }
        }
        case 'UPDATE PAGE PPN BARANG':{
            return {
                ...state,
                pagePPNBarang:action.payload
            }
        }
        case 'UPDATE DATA PPN BARANG':{
            return{
                ...state,
                isLoading:false,
                dataPPNBarang:action.payload
            }
        }
        default: return state
    }
}
export default barangReducers;