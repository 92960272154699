import { Backdrop, Button,  CircularProgress, Container, Divider, Fab, Snackbar, TextField, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import * as yup from 'yup';
import MuiAlert from '@mui/material/Alert';
import { emptySatuanMessage, getDetailsSatuan, updateSatuan } from '../../../config/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { useHistory, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosOutlined';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });


const UbahSatuan = () => {
    const theme = useTheme();
    const boxWapper = {
        display:'flex',
        justifyContent:'center',
        [theme.breakpoints.up('sm')]:{
            width:'100%',
            maxHeight:'500px',
            height:'100%',
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            height:'100%',
        }
    }
    const paperInput={
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        // border:'1px solid black',
        [theme.breakpoints.up('sm')]:{
            width:"35%",
            height:'100%',
            borderRadius:'10px',
            marginTop:'10px'
        },
        [theme.breakpoints.down('sm')]:{
            width:'90%',
            height:'100%',
            borderRadius:'10px',
            marginTop:'10px'
        }
    }
    const {satuanMessage,isLoading,detailsSatuan} = useSelector(state=>state.satuanReducers);
    const {dataLogin} = useSelector(state=>state.penggunaReducers);
    const [openSnackbar,setOpensnackbar]=useState(false);
    const [token,setToken] = useState(dataLogin.token);
    let {idsatuan} = useParams();
    const history = useHistory();
   
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const dispatch = useDispatch();
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(emptySatuanMessage(''));
        }, 100);
    }
    useEffect(()=>{
        if(detailsSatuan.length<1){
            dispatch(getDetailsSatuan(idsatuan,token,setOpensnackbar))
        }
    },[detailsSatuan,idsatuan,token,dispatch])
    const schemaValidation = yup.object().shape({
        satuan:yup.string().required("Satuan tidak boleh kosong")
    })
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            satuan:detailsSatuan.satuan||''
        },
        validationSchema:schemaValidation,
        onSubmit:(values)=>{
            const data = new URLSearchParams();
            data.append('satuan',values.satuan);
            dispatch(updateSatuan(idsatuan,data,token,setOpensnackbar,history));
        }
    })
    return (
        <Container maxWidth='xl' sx={{marginTop:'100px'}}>
        <Typography variant='h6' pl={1} mt={1}>Perubahan Data Satuan</Typography>
        <Divider/>
        <Box sx={boxWapper}>
            <Box sx={paperInput}>
            <TextField
                    id="satuan"
                    name="satuan"
                    label="Satuan"
                    variant="standard"
                    style={{width:'80%',marginTop:'10px'}}
                    value={formik.values.satuan}
                    error={formik.touched.satuan && Boolean(formik.errors.satuan)}
                    helperText={formik.touched.satuan && formik.errors.satuan}
                    onChange={formik.handleChange}
                    />
                    
                    <Button variant='contained' sx={{marginTop:'10px',marginBottom:'10px',width:'100px'}} onClick={formik.handleSubmit} disabled={isLoading}>SIMPAN</Button>
            </Box>
        </Box>
        <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={satuanMessage === 'Perubahan data satuan berhasil' ? "success" : "error"}
                >
                    {satuanMessage}
                    </Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Fab sx={{position: 'fixed',bottom: theme.spacing(5),left: theme.spacing(3)}} size="small" color="primary" aria-label="add" onClick={()=>history.push('/satuan')}>
            <ArrowBackIcon/>
        </Fab>
        </Container>
    )
}

export default UbahSatuan
