import React, { useEffect, useState } from 'react'
import MuiAlert from '@mui/material/Alert';
import { Autocomplete, IconButton, Paper, Snackbar, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { addReturPenjualan, addToCartReturPenjualan, delDraftReturDetails, deleteItemTukarBarang, deleteReturPenjualan, emptyArrSatuan, getAllBarang, getHargaJualTerakhir, getLastStokQty, getSatuanKonversiList, setReturPenjualanMessage } from '../../../config/redux/actions';
import { useFormik } from 'formik';
import { AddTodo, DeleteData } from '../../../components/assets';
import LastHargaDialog from '../../../components/molecules/lastHargaDialog';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'grey',
        color: theme.palette.common.black
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

const ReturPenjualan = ({idpel,totalPenjualan}) => {
    const theme = useTheme();
    const paperTable = {
        marginTop:'10px',
        [theme.breakpoints.up('sm')]:{
            width:'100%',
            height:'350px',
        }, 
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            height:'360px'
        },
        scrollbarWidth:'thin'
    }
    const tipeRetur = [
        {
          value: 'Barang Rusak',
          label: 'Barang Rusak',
        },
        {
          value: 'Barang Bagus',
          label: 'Barang Bagus',
        },
        {
            value:'Tukar Barang',
            label:'Tukar Barang'
        }
      ];
    const {dataBarang} = useSelector(state=>state.barangReducers);
    const {arrSatuanKonversi}=useSelector(state=>state.konversiReducers);
    const {lastStokQty} = useSelector(state=>state.penjualanReducers);
    const {returpenjualanMessage,cartReturPenjualan,lastHargaJual,fakturReturPelanggan,statusDraftRetur} = useSelector(state=>state.returpenjualanReducers);
    const [openSnackbar,setOpensnackbar]=useState(false);
    const dispatch=useDispatch();
    const {dataLogin} =useSelector(state=>state.penggunaReducers);
    const [token,setToken] = useState(dataLogin.token);
    const [satuanText,setSatuanText]=useState('');
    const [search,setSearch]=useState("");
    const [totalRetur,setTotalRetur]=useState(0);
    const [openLastHarga,setOpenLastHarga]=useState(false);
    const [statusRetur,SetStatusRetur]=useState("Otomatis");
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const validationSchema = yup.object().shape({
        idbarang:yup.string().required('ID Barang tidak boleh kosong'),
        namabarang:yup.string().required('Nama barang tidak boleh kosong'),
        idsatuan:yup.string().required('Satuan belum dipilih'),
        harga:yup.number(),
        qty:yup.number().required('Qty tidak boleh kosong').min(1,'Qty tidak boleh kurang dari 0'),
        jumlah:yup.number(),
        tiperetur:yup.string().required("Tipe retur belum dipilih")
    })
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            idbarang:'',
            namabarang:'',
            idsatuan:'',
            harga:'',
            qty:'',
            jumlah:'',
            tiperetur:''
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            if(lastHargaJual.Kelompok !== undefined){
                if(statusRetur==="Manual" && values.tiperetur==="Barang Bagus"){
                        const data = {
                            'id':cartReturPenjualan.length,
                            'idbarang':values.idbarang,
                            'namabarang':values.namabarang,
                            'idsatuan':values.idsatuan,
                            'satuan':satuanText,
                            'kelompok':lastHargaJual.Kelompok,
                            'ppn':lastHargaJual.PPN,
                            'harga':values.harga,
                            'qty':values.qty,
                            'jumlah':values.jumlah,
                            'tiperetur':values.tiperetur
                        }
                        dispatch(addReturPenjualan(data,token,statusDraftRetur,fakturReturPelanggan));
                        formik.handleReset();
                }else if(statusRetur==="Manual" && values.tiperetur==="Tukar Barang"){
                    // dispatch(setReturPenjualanMessage('Untuk barang yang belum pernah dibeli tidak dapat tukar barang'));
                    // setOpensnackbar(true);
                        const dataLastStok = {
                            'idbarang':values.idbarang,
                            'idsatuan':values.idsatuan,
                            'qty':values.qty
                          }
                        dispatch(getLastStokQty(dataLastStok,token,setOpensnackbar));
                    if(parseInt(lastStokQty.stok) >= parseInt(values.qty)){
                        const data = {
                            'id':cartReturPenjualan.length,
                            'idbarang':values.idbarang,
                            'namabarang':values.namabarang,
                            'idsatuan':values.idsatuan,
                            'satuan':satuanText,
                            'kelompok':lastHargaJual.Kelompok,
                            'ppn':lastHargaJual.PPN,
                            'harga':values.harga,
                            'qty':values.qty,
                            'jumlah':0,
                            'tiperetur':values.tiperetur
                        }
                        dispatch(addToCartReturPenjualan(data,token,setOpensnackbar));
                        dispatch(addReturPenjualan(data,token,statusDraftRetur,fakturReturPelanggan));
                        formik.handleReset();
                    }else {
                        dispatch(setReturPenjualanMessage('Stok barang tidak mencukup untuk dilakukan tukar barang'));
                        setOpensnackbar(true);
                    }
                }
                else if(statusRetur==="Manual" && values.tiperetur==="Barang Rusak"){
                    const data = {
                        'id':cartReturPenjualan.length,
                        'idbarang':values.idbarang,
                        'namabarang':values.namabarang,
                        'idsatuan':values.idsatuan,
                        'satuan':satuanText,
                        'kelompok':lastHargaJual.Kelompok,
                        'ppn':lastHargaJual.PPN,
                        'harga':values.harga,
                        'qty':values.qty,
                        'jumlah':values.jumlah,
                        'tiperetur':values.tiperetur,
                      }
                      dispatch(addReturPenjualan(data,token,statusDraftRetur,fakturReturPelanggan));
                      formik.handleReset();
                }
                else if(statusRetur==="Otomatis"){
                    if(values.tiperetur==="Tukar Barang"){
                        const dataLastStok = {
                            'idbarang':values.idbarang,
                            'idsatuan':values.idsatuan,
                            'qty':values.qty
                          }
                          dispatch(getLastStokQty(dataLastStok,token,setOpensnackbar));
                        if(parseInt(lastStokQty.stok) >= parseInt(values.qty)){
                            const data = {
                            'id':cartReturPenjualan.length,
                            'idbarang':values.idbarang,
                            'namabarang':values.namabarang,
                            'idsatuan':values.idsatuan,
                            'satuan':satuanText,
                            'kelompok':lastHargaJual.Kelompok,
                            'ppn':lastHargaJual.PPN,
                            'harga':values.harga,
                            'qty':values.qty,
                            'jumlah':0,
                            'tiperetur':values.tiperetur
                            }
                            dispatch(addToCartReturPenjualan(data,token,setOpensnackbar))
                            dispatch(addReturPenjualan(data,token,statusDraftRetur,fakturReturPelanggan));
                            formik.handleReset();
                        }else {
                            dispatch(setReturPenjualanMessage('Stok barang tidak mencukup untuk dilakukan tukar barang'));
                            setOpensnackbar(true);
                        }
                    }else if(values.tiperetur==="Barang Bagus"){
                        const data = {
                        'id':cartReturPenjualan.length,
                        'idbarang':values.idbarang,
                        'namabarang':values.namabarang,
                        'idsatuan':values.idsatuan,
                        'satuan':satuanText,
                        'kelompok':lastHargaJual.Kelompok,
                        'ppn':lastHargaJual.PPN,
                        'harga':values.harga,
                        'qty':values.qty,
                        'jumlah':values.jumlah,
                        'tiperetur':values.tiperetur
                        }
                        dispatch(addReturPenjualan(data,token,statusDraftRetur,fakturReturPelanggan));
                    }else if(values.tiperetur==="Barang Rusak"){
                        const data = {
                        'id':cartReturPenjualan.length,
                        'idbarang':values.idbarang,
                        'namabarang':values.namabarang,
                        'idsatuan':values.idsatuan,
                        'satuan':satuanText,
                        'kelompok':lastHargaJual.Kelompok,
                        'ppn':lastHargaJual.PPN,
                        'harga':values.harga,
                        'qty':values.qty,
                        'jumlah':values.jumlah,
                        'tiperetur':values.tiperetur
                        }
                        dispatch(addReturPenjualan(data,token,statusDraftRetur,fakturReturPelanggan));
                    }
                      formik.handleReset();
                }
            }
            else if(lastHargaJual.Kelompok === undefined){
                dispatch(setReturPenjualanMessage('Data yang dimasukkan tidak valid'));
                setOpensnackbar(true);
                formik.handleReset();
            }
        }
    })
    useEffect(()=>{
        if(cartReturPenjualan.length>0){
            let totalRetur=cartReturPenjualan.reduce((Retur,TotalP)=>{
                return Retur + TotalP.jumlah
            },0);
            setTotalRetur(totalRetur);
        }else if(cartReturPenjualan.length<1){
            setTotalRetur(0);
        }
    },[cartReturPenjualan])
    const handleSearchBarang = (e)=>{
        setSearch(e.target.value);
        dispatch(getAllBarang(search,1,token,""));
      }
    const handleChangeSatuan = (e)=>{
        setSatuanText(e.target.options[e.target.selectedIndex].text);
        formik.setFieldValue('idsatuan',e.target.value);
        const data={
            'idpelanggan':idpel,
            'idbarang':formik.values.idbarang,
            'idsatuan':e.target.value,
        }
        dispatch(getHargaJualTerakhir(data,token,formik,setOpensnackbar,setOpenLastHarga,SetStatusRetur));
        formik.setFieldValue('qty',0);
        formik.setFieldValue('jumlah',0);
      }
      const handleCloseOpenLastHarga = () =>{
          setOpenLastHarga(false);
      }
      const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(setReturPenjualanMessage(''));
        }, 100);
      }
      const handleChangeQty = (e) =>{
        if(e.target.value !== "" ){
          let tempJumlah = parseInt(e.target.value) * parseFloat(formik.values.harga);
          formik.setFieldValue('qty',e.target.value);
          formik.setFieldValue('jumlah',tempJumlah);
          if(formik.values.tiperetur==="Tukar Barang"){
            const dataLastStok = {
                'idbarang':formik.values.idbarang,
                'idsatuan':formik.values.idsatuan,
                'qty':e.target.value
              }
              dispatch(getLastStokQty(dataLastStok,token,setOpensnackbar));
          }
        }else if(e.target.value === ""){
          formik.setFieldValue('qty',e.target.value);
          formik.setFieldValue('jumlah',0);
        }

    }
    const handleClickDeleteReturPenjualan = (id,idreturpelanggandetails,idbarang,idsatuan,qty,tipeRetur) =>{
        if(statusDraftRetur==="No"){
            if(tipeRetur === "Tukar Barang"){
                const data = {
                    'idbarang':idbarang,
                    'idsatuan':idsatuan,
                    'qty':qty
                  }
                dispatch(deleteItemTukarBarang(data,token,setOpensnackbar));
                dispatch(deleteReturPenjualan(id));
            }else{
                dispatch(deleteReturPenjualan(id));
            }
        }else if(statusDraftRetur==="Yes"){
            if(tipeRetur === "Tukar Barang"){
                const data = {
                    'idbarang':idbarang,
                    'idsatuan':idsatuan,
                    'qty':qty
                  }
                dispatch(deleteItemTukarBarang(data,token,setOpensnackbar));
                dispatch(delDraftReturDetails(id,idreturpelanggandetails,token,setOpensnackbar));
            }else{
                dispatch(delDraftReturDetails(id,idreturpelanggandetails,token,setOpensnackbar));
            }
        }
    }
    const handleChangeTipeRetur = (e) =>{
        if(e.target.value==="Tukar Barang"){
            const dataLastStok = {
                'idbarang':formik.values.idbarang,
                'idsatuan':formik.values.idsatuan,
                'qty':formik.values.qty
              }
              dispatch(getLastStokQty(dataLastStok,token,setOpensnackbar));
        }
        formik.setFieldValue('tiperetur',e.target.value);
    }
    const daftarReturPenjualan = (
        <TableBody>
                {cartReturPenjualan.slice(0).reverse().map((retur) => (
                    <StyledTableRow key={retur.id}>
                    <StyledTableCell width={120} align="center">{retur.idbarang}</StyledTableCell>
                    <StyledTableCell width={200} align="center">{retur.namabarang}</StyledTableCell>
                    <StyledTableCell width={120} align="center">{retur.satuan}</StyledTableCell>
                    <StyledTableCell width={120} align="center">{new Intl.NumberFormat().format(retur.qty)}</StyledTableCell>
                    <StyledTableCell width={120} align="center">{new Intl.NumberFormat().format(retur.harga)}</StyledTableCell>
                    <StyledTableCell width={120} align="center">{new Intl.NumberFormat().format(retur.jumlah)}</StyledTableCell>
                    <StyledTableCell width={120} align="center">{retur.tiperetur}</StyledTableCell>
                    <StyledTableCell width={20} align="center">
                        <Tooltip title="Hapus data">
                            <IconButton 
                            onClick={()=>handleClickDeleteReturPenjualan(retur.id,retur.idreturpelanggandetails,retur.idbarang,retur.idsatuan,retur.qty,retur.tiperetur)}
                            >
                                <img src={DeleteData} alt="deleteData"/>
                            </IconButton>
                        </Tooltip>
                    </StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
      )
  return (
    <Box sx={{width:'100%',marginTop:'10px',marginBottom:"20px"}}>
        <Box sx={{width:'100%',display:'flex'}}>
            <TextField
            id="idbarang"
            name='idbarang'
            label="ID Barang"
            variant="standard"
            sx={{marginRight:'10px'}}
            disabled={true}
            value={formik.values.idbarang}
            error={formik.touched.idbarang && Boolean(formik.errors.idbarang)}
            helperText={formik.touched.idbarang && formik.errors.idbarang}
            onChange={formik.handleChange}
            />
            <Autocomplete
                  id="namabarang"
                  name='namabarang'
                  variant="standard"
                  sx={{marginRight:'10px'}}
                  options= {dataBarang}
                  getOptionLabel= {(option) => option.namabarang}
                  isOptionEqualToValue={(option,value)=>option.id===value.id}
                  onChange={(event, newValue) => {
                          if(newValue!==null){
                              formik.setFieldValue('idbarang',newValue.idbarang);
                              formik.setFieldValue('namabarang',newValue.namabarang);
                              dispatch(getSatuanKonversiList(newValue.idbarang,token,""));
                              formik.setFieldValue('idsatuan',"");
                          }else{
                            formik.setFieldValue('idbarang',"");
                            formik.setFieldValue('namabarang',"");
                            formik.setFieldValue('harga',"");
                            formik.setFieldValue('idsatuan',"");
                            dispatch(emptyArrSatuan());
                          }
                    }}
                  renderInput={(params)=><TextField
                      id='namabarang'
                      name='namabarang'
                      variant='standard' 
                      sx={{width:'250px'}} 
                      {...params} 
                      label="Nama Barang"
                      value={formik.values.namabarang}
                      error={formik.touched.namabarang && Boolean(formik.errors.namabarang)}
                      helperText={formik.touched.namabarang && formik.errors.namabarang}
                      onChange={(e)=>handleSearchBarang(e)}
                      />}
                  />
            <TextField
            id='idsatuan'
            select
            name='idsatuan'
            variant="standard" 
            label="Satuan"
            sx={{width:'100px',marginRight:'10px'}}
            SelectProps={{
                native: true,
                }}
            value={formik.values.idsatuan}
            error={formik.touched.idsatuan && Boolean(formik.errors.idsatuan)}
            helperText={formik.touched.idsatuan && formik.errors.idsatuan}
            onChange={(e)=>handleChangeSatuan(e)}
            >   
                <option aria-label="None" value=""/>
                {arrSatuanKonversi.map((option)=>(
                    <option key ={option.idsatuan} value={option.idsatuan}>
                        {option.satuan}
                    </option>
                ))}
            </TextField>
            <TextField
            id="harga"
            name='harga'
            label="Harga"
            variant="standard"
            sx={{width:'100px',marginRight:'10px'}}
            // disabled={true}
            value={formik.values.harga||""}
            error={formik.touched.harga && Boolean(formik.errors.harga)}
            helperText={formik.touched.harga && formik.errors.harga}
            onChange={formik.handleChange}
            />
            <TextField
            id="qty"
            name='qty'
            label="Qty"
            variant="standard"
            sx={{width:'50px',marginRight:'10px'}}
            value={formik.values.qty}
            error={formik.touched.qty && Boolean(formik.errors.qty)}
            helperText={formik.touched.qty && formik.errors.qty}
            onChange={(e)=>handleChangeQty(e)}
            />
            <TextField
            id="jumlah"
            name='jumlah'
            label="Jumlah"
            variant="standard"
            disabled={true}
            sx={{width:'100px',marginRight:'10px'}}
            value={formik.values.jumlah}
            error={formik.touched.jumlah && Boolean(formik.errors.jumlah)}
            helperText={formik.touched.jumlah && formik.errors.jumlah}
            onChange={formik.handleChange}
            />
            <TextField
            id='tiperetur'
            select
            name='tiperetur'
            variant="standard" 
            label="Tipe Retur"
            sx={{width:'150px',marginRight:'10px'}}
            SelectProps={{
                native: true,
                }}
            value={formik.values.tiperetur}
            error={formik.touched.tiperetur && Boolean(formik.errors.tiperetur)}
            helperText={formik.touched.tiperetur && formik.errors.tiperetur}
            onChange={(e)=>handleChangeTipeRetur(e)}
            >   
                <option aria-label="None" value=""/>
                {tipeRetur.map((option)=>(
                    <option key ={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </TextField>
            <IconButton sx={{display:'flex',marginTop:'15px'}} onClick={formik.handleSubmit}>
                    <img src={AddTodo} alt="addRetur"/>
                  </IconButton>
        </Box>
        <TableContainer component={Paper} sx={paperTable}>
                <Table stickyHeader aria-label="customized table" size='small'>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell width={120} align="center">ID Barang</StyledTableCell>
                            <StyledTableCell width={200} align="center">Nama Barang</StyledTableCell>
                            <StyledTableCell width={120} align="center">Satuan</StyledTableCell>
                            <StyledTableCell width={120} align="center">Qty</StyledTableCell>
                            <StyledTableCell width={120} align="center">Harga</StyledTableCell>
                            <StyledTableCell width={120} align="center">Jumlah</StyledTableCell>
                            <StyledTableCell width={120} align="center">Tipe Retur</StyledTableCell>
                            <StyledTableCell width={20} align="center"/>
                        </TableRow>
                    </TableHead>
                        {daftarReturPenjualan} 
                </Table>
            </TableContainer>
            <Box sx={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
                    <Box sx={{width:'200px',textAlign:'right'}}>
                        <Typography>Total Penjualan:</Typography>
                    </Box>
                    <Box sx={{width:'180px',textAlign:'right'}}>
                        <Typography>{new Intl.NumberFormat().format(totalPenjualan)}</Typography>
                    </Box>
                </Box>
            <Box sx={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
                    <Box sx={{width:'200px',textAlign:'right'}}>
                        <Typography>Total Retur:</Typography>
                    </Box>
                    <Box sx={{width:'180px',textAlign:'right'}}>
                        <Typography>{new Intl.NumberFormat().format(totalRetur)}</Typography>
                    </Box>
                </Box>
        <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={returpenjualanMessage === "Barang berhasil dimasukkan ke daftar retur" ? "success" : "error"}
                >
                    {returpenjualanMessage}
                    </Alert>
            </Snackbar>
            <LastHargaDialog openLastHargaDialog={openLastHarga} closeLastHargaDialog={handleCloseOpenLastHarga}/>
    </Box>
  )
}

export default ReturPenjualan