const satuanInitialState = {
    isLoading:false,
    pageSatuan : {
        currentPage:'1',
        totalPage:'1'
    },
    dataSatuan:[],
    detailsSatuan:'',
    satuanMessage:'',
    dataComboSatuan:[]
}
const satuanReducers = (state=satuanInitialState,action)=>{
    switch(action.type){
        case 'EMPTY SATUAN MESSAGE':{
            return{
                ...state,
                satuanMessage:action.payload
            }
        }
        case 'UPDATE PAGE SATUAN':{
            return {
                ...state,
                pageSatuan:action.payload
            }
        }
        case 'UPDATE DATA SATUAN':{
            return{
                ...state,
                isLoading:false,
                dataSatuan:action.payload
            }
        }
        case 'FETCH GET ALL SATUAN':{
            return{
                ...state,
                isLoading:true
            }
        }
        case 'FAILED GET ALL SATUAN':{
            return{
                ...state,
                isLoading:false,
                satuanMessage:action.payload
            }
        }
        case 'FETCH ACTION SATUAN':{
            return{
                ...state,
                isLoading:true
            }
        }
        case 'SUCCESSED ACTION SATUAN':{
            return{
                ...state,
                isLoading:false,
                satuanMessage:action.payload
            }
        }
        case 'FAILED ACTION SATUAN':{
            return{
                ...state,
                isLoading:false,
                satuanMessage:action.payload
            }
        }
        case 'SUCCESSED GET SATUAN DETAILS':{
            return{
                ...state,
                isLoading:false,
                detailsSatuan:action.payload,
                satuanMessage:action.message
            }
        }
        case 'SUCCESSED GET SATUAN FOR COMBO':{
            return{
                ...state,
                isloading:false,
                dataComboSatuan:action.payload
            }
        }
        default: return state
    }
}
export default satuanReducers;