import axios from 'axios';
import { apiUrl } from '../..';

export const getAllSatuan = (search,page,token,setOpensnackbar) =>(dispatch)=>{
    dispatch({
      type:'FETCH GET ALL SATUAN',
    })
    axios.get(`${apiUrl.url}satuan/?search=${search}&page=${page}&perPage=50`,{
          headers:{
            "Authorization" : `Bearer ${token}`
          }
      })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
          type:'UPDATE DATA SATUAN', 
          payload:ResponseAPI.data
       })
        dispatch({
          type:'UPDATE PAGE SATUAN',
          payload:{
            currentPage: ResponseAPI.current_page,
            totalPage: Math.ceil(ResponseAPI.totalData/ResponseAPI.per_page)
          }
        })
        
    })
    .catch(error=>{
      dispatch({
        type:'FAILED GET ALL SATUAN',
        payload:error.message
      })
      setOpensnackbar(true);
    })
  }
  export const emptySatuanMessage = (message) =>(dispatch)=>{
    dispatch({
      type:'EMPTY SATUAN MESSAGE',
      payload:message
    })
  }
export const addNewSatuan = (data,token,setOpensnackbar,history)=>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION SATUAN'
  })
  setTimeout(()=>{
    axios.post(`${apiUrl.url}satuan/`,data,{
      headers:{
        "Authorization" : `Bearer ${token}`,
        'content-type':'application/x-www-form-urlencoded'
      }
    })
    .then(res=>{
      const ResponseApi = res.data;
      dispatch({
        type:'SUCCESSED ACTION SATUAN',
        payload:ResponseApi.message
      })
      if(ResponseApi.message==='Berhasil menambahkan data satuan'){
        setOpensnackbar(true);
        setTimeout(()=>{
          dispatch(getAllSatuan('',1,token,setOpensnackbar));
          history.push('/satuan');
        },2000)
      }else{
        setOpensnackbar(true);
      }
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION SATUAN',
          payload:error.message
        })
        setOpensnackbar(true);
    })
  },2000)
  
}

export const deleteSatuan = (idsatuan,token,setOpensnackbar,search,counter,setOpenDialog)=>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION SATUAN'
  })
  setTimeout(()=>{
    axios.delete(`${apiUrl.url}satuan/${idsatuan}`,{
      headers:{
        "Authorization" : `Bearer ${token}`,
      }
    })
    .then(res=>{
      const ResponseApi = res.data;
      dispatch({
        type:'SUCCESSED ACTION SATUAN',
        payload:ResponseApi.message
      })
      if(ResponseApi.message === 'Penghapusan data satuan berhasil'){
        dispatch(getAllSatuan(search,counter,token,''));
        setOpensnackbar(true);
        setOpenDialog(false);
      }else{
        setOpensnackbar(true);
        setOpenDialog(false);
      }
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION SATUAN',
          payload:error.message
        })
        setOpensnackbar(true);
    })
  })
}

export const getDetailsSatuan = (idsatuan,token,setOpensnackbar)=>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION SATUAN'
  })
  axios.get(`${apiUrl.url}satuan/${idsatuan}`,{
    headers:{
      "Authorization" : `Bearer ${token}`,
    }
  })
  .then(res=>{
    const ResponseApi = res.data;
    dispatch({
      type:'SUCCESSED GET SATUAN DETAILS',
      payload:{
          'satuan':ResponseApi.data.satuan,
      },
      message:ResponseApi.message
    })
  })
  .catch(error=>{
    dispatch({
      type:'FAILED ACTION SATUAN',
      payload:error.message
    })
    setOpensnackbar(true);
  })
}

export const updateSatuan = (idsatuan,data,token,setOpensnackbar,history)=>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION SATUAN'
  })
  setTimeout(()=>{
    axios.patch(`${apiUrl.url}satuan/${idsatuan}`,data,{
      headers:{
        "Authorization" : `Bearer ${token}`,
        'content-type':'application/x-www-form-urlencoded'
      }
    })
    .then(res=>{
      const ResponseApi = res.data;
      dispatch({
        type:'SUCCESSED ACTION SATUAN',
        payload:ResponseApi.message
      })
      if(ResponseApi.message==='Perubahan data satuan berhasil'){
        setOpensnackbar(true);
        setTimeout(()=>{
          dispatch(getAllSatuan('',1,token,setOpensnackbar));
          history.push('/satuan');
        },1000)
      }else{
        setOpensnackbar(true);
      }
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION SATUAN',
          payload:error.message
        })
        setOpensnackbar(true);
    })
  },2000)
  
}
export const getAllSatuanForCombo = (token,setOpensnackbar) =>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION SATUAN',
  })
  axios.get(`${apiUrl.url}satuan/all`,{
        headers:{
          "Authorization" : `Bearer ${token}`
        }
    })
  .then(res=>{
      const ResponseAPI = res.data;
      dispatch({
        type:'SUCCESSED GET SATUAN FOR COMBO', 
        payload:ResponseAPI.data
     })
      
  })
  .catch(error=>{
    dispatch({
      type:'FAILED ACTION SATUAN',
      payload:error.message
    })
    setOpensnackbar(true);
  })
}
export const getSatuanForHarga = (token,setOpensnackbar) =>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION SATUAN',
  })
  axios.get(`${apiUrl.url}satuan/duspcs`,{
        headers:{
          "Authorization" : `Bearer ${token}`
        }
    })
  .then(res=>{
      const ResponseAPI = res.data;
      dispatch({
        type:'SUCCESSED GET SATUAN FOR COMBO', 
        payload:ResponseAPI.data
     })
      
  })
  .catch(error=>{
    dispatch({
      type:'FAILED ACTION SATUAN',
      payload:error.message
    })
    setOpensnackbar(true);
  })
}