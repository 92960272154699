const returPenjualanInitialValue = {
    isLoading:false,
    returpenjualanMessage:'',
    lastHargaJual:'',
    cartReturPenjualan:[],
    dataReturPenjualanDetails:[],
    dataReturPenjualan:[],
    dataAllReturPenjualan:[],
    fakturReturPelanggan:"",
    statusDraftRetur:'No'
}

const returpenjualanReducers = (state=returPenjualanInitialValue,action)=>{
    switch(action.type){
        case 'FETCH ACTION RETUR PENJUALAN':{
            return{
                ...state,
                isLoading:true
            }
        }
        case 'SET RETUR PENJUALAN MESSAGE':{
            return{
                ...state,
                returpenjualanMessage:action.payload
            }
        }
        case 'SUCCESSED GET HARGA TERAKHIR JUAL':{
            return{
                ...state,
                isLoading:false,
                lastHargaJual:action.payload,
                returpenjualanMessage:action.message
            }
        } 
        case 'SUCCESSED ACTION RETUR PENJUALAN':{
            return{
                ...state,
                isLoading:false,
                returpenjualanMessage:action.payload
            }
        }
        case 'FAILED ACTION RETUR PENJUALAN':{
            return{
                ...state,
                isLoading:false,
                returpenjualanMessage:action.payload
            }
        }
        case 'ADD RETUR PENJUALAN':{
            return{
                ...state,
                cartReturPenjualan:[...state.cartReturPenjualan,action.payload]
            }
        }
        case 'DELETE RETUR PENJUALAN':{
            return{
                ...state,
                cartReturPenjualan:state.cartReturPenjualan.filter(retur=>retur.id !== action.payload)
            }
        }
        case 'EMPTY CART RETUR PENJUALAN':{
            return{
                ...state,
                cartReturPenjualan:[]
            }
        }
        case 'SUCCESSED POST RETUR PENJUALAN':{
            return{
                ...state,
                isLoading:false,
                returpenjualanMessage:action.payload
            }
        }
        case 'SUCCESSED GET DATA RETUR PENJUALAN':{
            return{
                ...state,
                isLoading:false,
                dataReturPenjualan:action.payload,
                dataReturPenjualanDetails:action.details
            }
        }
        case 'SUCCESSED GET ALL DATA RETUR PENJUALAN':{
            return{
                ...state,
                isLoading:false,
                dataAllReturPenjualan:action.payload
            }
        }
        case 'EMPTY ALL DATA RETUR PENJUALAN':{
            return{
                ...state,
                dataAllReturPenjualan:[],
                dataReturPenjualan:[],
                dataReturPenjualanDetails:[]
            }
        }
        case 'EDIT HARGA TERAKHIR JUAL':{
            return{
                ...state,
                isLoading:false,
                lastHargaJual:action.payload
            }
        } 
        case 'SUCCESSED GET FAKTUR RETUR PELANGGAN':{
            return{
                ...state,
                isLoading:false,
                fakturReturPelanggan:action.payload,
                statusDraftRetur:action.statusRetur
            }
        }
        case 'SUCCESSED DELETE DRAFT PENJUALAN DETIALS':{
            return{
                ...state,
                isLoading:false,
                returpenjualanMessage:action.payload
            }
        }
        default: return state
    }
}

export default returpenjualanReducers;