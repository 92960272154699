import { Backdrop, Button,  CircularProgress,  Dialog, Divider, Snackbar, TextField, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import * as yup from 'yup';
import MuiAlert from '@mui/material/Alert';
import { emptyBarangMessage, getAllDistributorForCombo, updateBarang } from '../../../config/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });


const UbahBarang = ({openUbahBarang,closeUbahBarang,idbarang,namabarang,...rest}) => {
    const theme = useTheme();
    const boxWapper = {
        display:'flex',
        justifyContent:'center',
        [theme.breakpoints.up('sm')]:{
            width:'100%',
            maxHeight:'500px',
            height:'100%',
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            height:'100%',
        }
    }
    const paperInput={
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        // border:'1px solid black',
        [theme.breakpoints.up('sm')]:{
            width:"70%",
            height:'100%',
            borderRadius:'10px',
            marginTop:'10px'
        },
        [theme.breakpoints.down('sm')]:{
            width:'90%',
            height:'100%',
            borderRadius:'10px',
            marginTop:'10px'
        }
    }
    const boxDialog={
        height:'100%',
        marginTop:'10px',
        marginLeft:'10px',
        marginRight:'10px',
        [theme.breakpoints.up('sm')]:{
            width:'500px',
        },
        [theme.breakpoints.down('sm')]:{
            width:'90%',
        }
    }
    const {messageBarang,isLoading,barangDetails} = useSelector(state=>state.barangReducers);
    const {dataComboDistributor}=useSelector(state=>state.distributorReducers);
    const {dataLogin} = useSelector(state=>state.penggunaReducers);
    const [openSnackbar,setOpensnackbar]=useState(false);
    const [token,setToken] = useState(dataLogin.token);
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(getAllDistributorForCombo("",token,setOpensnackbar));
    },[token,dispatch,barangDetails])
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        closeUbahBarang();
        setTimeout(() => {
            dispatch(emptyBarangMessage(''));
        }, 100);
    }
    const onSelectDistributorHandleChange = (e) =>{
        formik.setFieldValue('iddistributor',e.target.value);
    }
    const schemaValidation = yup.object().shape({
        namabarang:yup.string().required("Nama tidak boleh kosong"),
        iddistributor:yup.string().required("Distributor belum dipilih")
    })
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            namabarang:namabarang||'',
            iddistributor:barangDetails.iddistributor||'',
        },
        validationSchema:schemaValidation,
        onSubmit:(values)=>{
            const data = new URLSearchParams();
            data.append('idbarang',idbarang);
            data.append('namabarang',values.namabarang);
            data.append('iddistributor',values.iddistributor);
            dispatch(updateBarang(data,token,setOpensnackbar,history));
        }
    })
    return (
        <Dialog open={openUbahBarang}{...rest}>
        <Box sx={boxDialog}>
        <Typography variant='h6' pl={1} mt={1}>Perubahan Data Barang</Typography>
        <Divider/>
        <Box sx={boxWapper}>
            <Box sx={paperInput}>
            <TextField
                    id="namabarang"
                    name="namabarang"
                    label="Nama Barang"
                    variant="standard"
                    style={{width:'100%',marginTop:'10px'}}
                    value={formik.values.namabarang}
                    error={formik.touched.namabarang && Boolean(formik.errors.namabarang)}
                    helperText={formik.touched.namabarang && formik.errors.namabarang}
                    onChange={formik.handleChange}
                    />
                    <TextField
                    id='iddistributor'
                    select
                    name='iddistributor'
                    variant="standard" 
                    label="Distributor"
                    style={{width:'100%',marginTop:'10px'}}
                    SelectProps={{
                        native: true,
                        }}
                    value={formik.values.iddistributor}
                    error={formik.touched.iddistributor && Boolean(formik.errors.iddistributor)}
                    helperText={formik.touched.iddistributor && formik.errors.iddistributor}
                    onChange={(e)=>onSelectDistributorHandleChange(e)}
                    >   
                        <option aria-label="None" value="" />
                        {dataComboDistributor.map((option)=>(
                            <option key ={option.iddistributor} value={option.iddistributor}>
                                {option.nama}
                            </option>
                        ))}
                    </TextField>
                    <Box sx={{width:'80%',display:'flex',justifyContent:'space-between'}}>
                        <Button variant='contained' sx={{marginTop:'10px',marginBottom:'10px',width:'100px'}} onClick={formik.handleSubmit} disabled={isLoading}>SIMPAN</Button>
                        <Button variant='contained' color='error' sx={{marginTop:'10px',marginBottom:'10px',width:'100px'}} onClick={closeUbahBarang} disabled={isLoading}>BATAL</Button>
                    </Box>
                </Box>
        </Box>
        <Snackbar 
            open={openSnackbar} 
            autoHideDuration={3000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={messageBarang === 'Perubahan data barang berhasil' ? "success" : "error"}
                >
                    {messageBarang}
                    </Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
            
        </Box>
        </Dialog>
    )
}

export default UbahBarang
