import { Button, Checkbox, CircularProgress, Dialog, DialogContent, Divider, FormControl, FormControlLabel, Radio, RadioGroup, Snackbar, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState,useEffect } from 'react';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import * as yup from 'yup';
import { addNewBarang, emptyBarangMessage,getAllSatuanForCombo } from '../../../config/redux/actions';
import { useFormik } from 'formik';
import { format } from 'date-fns';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const BarangBaruDialog = ({openBarangBaru,closeBarangBaru,iddist,namadist,...rest}) => {
    const paperInput={
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        width:"250px",
        height:'100%',
        borderRadius:'10px',
        marginTop:'10px'
    }
    const widthTodo ={
        width:"100%",
        marginTop:'10px'
    }
    const dispatch=useDispatch();
    const {dataLogin} =useSelector(state=>state.penggunaReducers);
    const {dataComboSatuan}=useSelector(state=>state.satuanReducers);
    const {messageBarang,isLoading} = useSelector(state=>state.barangReducers);
    const {timeConnection} = useSelector(state=>state.homeReducers);
    const [token,setToken] = useState(dataLogin.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    useEffect(()=>{
        dispatch(getAllSatuanForCombo(token,setOpensnackbar));
    },[token,dispatch])
    const [autoId,setAutoId]=useState(false); 
    const [disabledIdBarang,setDisabledIdBarang]=useState(false);
    const [valueRadio, setValueRadio] = useState('PPN');
    const [openSnackbar,setOpensnackbar]=useState(false);
    const handleChangeChackeAutoId = (e)=>{
        setAutoId(!autoId);
        setDisabledIdBarang(e.target.checked);
    }
    const handleChange = (event) => {
        setValueRadio(event.target.value);
        formik.setFieldValue('ppn',event.target.value);
    };
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(emptyBarangMessage(''));
            formik.handleReset();
            setAutoId(false);
            setDisabledIdBarang(false);
            setValueRadio("NON PPN");
        }, 100);
    }
    const onSelectSatuanHandleChange = (e) =>{
        formik.setFieldValue('idsatuan',e.target.value);
    }
    const schemaValidation = yup.object().shape({
        idbarang:yup.string(),
        namabarang:yup.string().required('Nama barang tidak boleh kosong'),
        idsatuan:yup.string(),
        hargadasar:yup.number().min(0,'Nilai harga jual tidak boleh kurang dari 0'),
        hargagrosir:yup.number().min(0,'Nilai harga grosir tidak boleh kurang dari 0')
    })
    const formik = useFormik({
        initialValues:{
            idbarang:'',
            namabarang:'',
            ppn:'PPN',
            idsatuan:'',
            hargadasar:'',
            hargagrosir:'',
        },
        validationSchema:schemaValidation,
        onSubmit:(values)=>{
            const data = {
                'idbarang':values.idbarang,
                'namabarang':values.namabarang,
                'iddistributor':iddist,
                'tanggal':format(new Date(),'yyyy-MM-dd'),
                'ppn':values.ppn,
                'listsatuan':[{
                    'idsatuan':values.idsatuan,
                    'hargadasar':values.hargadasar,
                    'hargagrosir':values.hargagrosir,
                    'stokawal':0,
                    'modalawal':0
                }]
            }
            dispatch(addNewBarang("",data,token,setOpensnackbar,"","pembelian",closeBarangBaru));
        }
    })
  return (
      <Dialog open={openBarangBaru} {...rest}>
    <DialogContent>
    <Box sx={paperInput}>
        <Typography variant='h6'>Pendaftaran Barang</Typography>
        <Divider sx={{width:'100%'}}/>
        <Box sx={{width:'100%',marginTop:'10px',display:'flex',justifyContent:'center',alignItems:'flex-end'}}>
            <Checkbox
            size="small"
            sx={{marginTop:'7px'}}
            id='autoid'
            name='autoid'
            checked={autoId}
            onClick={(e)=>handleChangeChackeAutoId(e)}
            value={autoId}/>
            <TextField
            id="idbarang"
            name="idbarang"
            label="ID Barang"
            variant="standard"
            style={{width:'100%'}}
            disabled={disabledIdBarang}
            value={formik.values.idbarang}
            error={formik.touched.idbarang && Boolean(formik.errors.idbarang)}
            helperText={formik.touched.idbarang && formik.errors.idbarang}
            onChange={formik.handleChange}
            />
        </Box>
            <TextField
            id="namabarang"
            name="namabarang"
            label="Nama Barang"
            variant="standard"
            style={{width:'100%',marginTop:'10px'}}
            value={formik.values.namabarang}
            error={formik.touched.namabarang && Boolean(formik.errors.namabarang)}
            helperText={formik.touched.namabarang && formik.errors.namabarang}
            onChange={formik.handleChange}
            />
            <TextField
            id='iddistributor'
            name='iddistributor'
            variant="standard" 
            label="Distributor"
            style={{width:'100%',marginTop:'10px'}}
            disabled={true}
            value={namadist}
            />
            <FormControl sx={{width:'100%',marginTop:'10px',display:'flex'}}>
                <RadioGroup 
                row 
                name="ppn"
                value={valueRadio}
                onChange={handleChange}>
                    <FormControlLabel value="PPN" control={<Radio />} label="PPN" />
                    <FormControlLabel value="NON PPN" control={<Radio />} label="NON PPN" />
                </RadioGroup>
            </FormControl>
                <TextField
                id='idsatuan'
                select
                name='idsatuan'
                variant="standard" 
                label="Satuan"
                sx={widthTodo}
                SelectProps={{
                    native: true,
                  }}
                value={formik.values.idsatuan}
                error={formik.touched.idsatuan && Boolean(formik.errors.idsatuan)}
                helperText={formik.touched.idsatuan && formik.errors.idsatuan}
                onChange={(e)=>onSelectSatuanHandleChange(e)}
                >   
                    <option aria-label="None" value="" />
                    {dataComboSatuan.map((option)=>(
                        <option key ={option.idsatuan} value={option.idsatuan}>
                            {option.satuan}
                        </option>
                    ))}
                </TextField>
                <TextField
                id="hargadasar"
                name="hargadasar"
                label="Harga Jual"
                variant="standard"
                sx={widthTodo}
                value={formik.values.hargadasar}
                error={formik.touched.hargadasar && Boolean(formik.errors.hargadasar)}
                helperText={formik.touched.hargadasar && formik.errors.hargadasar}
                onChange={formik.handleChange}
                />
                <TextField
                id="hargagrosir"
                name="hargagrosir"
                label="Harga Grosir"
                variant="standard"
                sx={widthTodo}
                value={formik.values.hargagrosir}
                error={formik.touched.hargagrosir && Boolean(formik.errors.hargagrosir)}
                helperText={formik.touched.hargagrosir && formik.errors.hargagrosir}
                onChange={formik.handleChange}
                />
            <Box sx={{width:'100%',display:'flex',justifyContent:'space-between',marginTop:'10px'}}>
                <Button 
                variant="contained" 
                color='primary' 
                disabled={timeConnection<=100 ? isLoading:true}
                onClick={formik.handleSubmit}
                 >
                     {isLoading === true ? <CircularProgress color='primary' size={25}/> : "SIMPAN"}
                </Button>
                <Button variant="contained" color='error' onClick={closeBarangBaru}>Keluar</Button>
            </Box>
    </Box>
    </DialogContent>
    <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={messageBarang === 'Penambahan data barang berhasil' ? "success" : "error"}
                >
                    {messageBarang}
                    </Alert>
            </Snackbar>
    </Dialog>
  )
};

export default BarangBaruDialog;
