import {useTheme, Box, Container, Divider, Paper, Snackbar, styled, Table, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Typography, TableBody, Tooltip, IconButton, Fab, Radio } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { format } from 'date-fns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { getDataPembelian, setMessagePembelian,pembatalanPembelian, setEmptyDataPembelian } from '../../../config/redux/actions';
import { DeleteData,DisabledDeleteData, DetailsIcon, LaporanPembelianFull } from '../../../components/assets';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosOutlined';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import { DeleteDialog } from '../../../components/molecules';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'grey',
        color: theme.palette.common.black
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
const LaporanPembelianPage = () => {
    const theme = useTheme();
  const boxWapper = {
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    [theme.breakpoints.up('sm')]:{
        width:'100%',
        maxHeight:'500px',
        height:'100%',
    },
    [theme.breakpoints.down('lg')]:{
        width:'100%',
        height:'100%',
    }
  }
  const paperInput={
      display:'flex',
      justifyContent:'center',
      [theme.breakpoints.up('sm')]:{
          width:"90%",
          height:'100%',
          borderRadius:'10px',
          marginTop:'10px'
      },
      [theme.breakpoints.down('sm')]:{
          width:'100%',
          height:'100%',
          borderRadius:'10px',
          marginTop:'10px'
      }
  }
  const paperTable = {
    marginTop:'10px',
    [theme.breakpoints.up('sm')]:{
        width:'90%',
        height:'400px',
    }, 
    [theme.breakpoints.down('sm')]:{
        width:'100%',
        height:'360px'
    },
    scrollbarWidth:'thin'
}
    const {dataPembelian,pembelianMessage,isLoading}=useSelector(state=>state.pembelianReducers);
    const {dataLogin} =useSelector(state=>state.penggunaReducers);
    const [token,setToken] = useState(dataLogin.token);
    const [openSnackbar,setOpensnackbar]=useState(false);
    const [tanggalawal,setTanggalawal]=useState(new Date());
    const [tanggalakhir,setTanggalakhir]=useState(new Date());
    const [totalPembelian,setTotalPembelian]=useState(0);
    const [openDialog,setOpenDialog]=useState(false);
    const [messageDialog,setMessageDialog]=useState('');
    const [tempNoFaktur,setNoFaktur] = useState('');
    const [findBy,setFindBy]=useState('tanggal');
    const [disabledTanggal,setDisabledTanggal]=useState(false);
    const [disabledFaktur,setDisabledFaktur]=useState(true);
    const [faktur,setFaktur]=useState('');
    const dispatch = useDispatch();
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const history=useHistory();
    useEffect(()=>{
      if(findBy==="tanggal"){
        if(dataPembelian.length<1 && format(tanggalawal,"yyyy-MM-dd") === format(new Date(),"yyyy-MM-dd")){
          const data={
            'tglawal':format(tanggalawal,"yyyy-MM-dd"),
            'tglakhir':format(tanggalakhir,"yyyy-MM-dd"),
            'findby':findBy,
            'faktur':""
          }
          dispatch(getDataPembelian(data,token,setOpensnackbar))
        }
      }
        if(tanggalawal>tanggalakhir){
            dispatch(setMessagePembelian('Tanggal tidak sesuai'));
            setOpensnackbar(true);
            setTanggalawal(new Date());
            setTanggalakhir(new Date());
        }
        if(dataPembelian.length>0){
          let totalPembelian=dataPembelian.reduce((Pembelian,TotalP)=>{
              if(TotalP.status==="Berhasil"){
                Pembelian += TotalP.total
              }
              return Pembelian
          },0);
          setTotalPembelian(totalPembelian);
      }else{
          setTotalPembelian(0);
      }
    },[totalPembelian,dataPembelian,dispatch,token])
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(setMessagePembelian(''));
        }, 100);
      }
      const handleChangeTanggalAwal = (e)=>{
        setTanggalawal(e);
      }
      const handleChangeTanggalAkhir = (e)=>{
        setTanggalakhir(e);
      }
      const handleClickDetailsPembelian = (faktur)=>{
        history.push(`/laporan/pembelian/${encodeURIComponent(faktur)}`)
      }
      const handleClickLaporanPembelianLengkap = (faktur)=>{
        history.push(`/laporan/pembelianlengkap/${encodeURIComponent(faktur)}`);
      }
      const tableDataPembelian = (
        <TableBody>
            {dataPembelian.map((pembelian) => (
                <StyledTableRow key={pembelian.fakturpembelian}>
                <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{format(new Date(pembelian.tanggal),'dd/MM/yyyy')}</Typography></StyledTableCell>
                <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{pembelian.fakturpembelian}</Typography></StyledTableCell>
                <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{pembelian.pembayaran}</Typography></StyledTableCell>
                <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{pembelian.nama}</Typography></StyledTableCell>
                <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{pembelian.ketdiskon}</Typography></StyledTableCell>
                <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{new Intl.NumberFormat().format(pembelian.total)}</Typography></StyledTableCell>
                <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{pembelian.status}</Typography></StyledTableCell>
                <StyledTableCell width={150} align="center">
                  <Tooltip title="Rekap Pembelian">
                    <IconButton 
                    onClick={()=>handleClickLaporanPembelianLengkap(pembelian.fakturpembelian)}
                    >
                      <img src={LaporanPembelianFull} alt="print"/>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Lihat Transaksi">
                    <IconButton 
                    onClick={()=>handleClickDetailsPembelian(pembelian.fakturpembelian)}
                    >
                      <img src={DetailsIcon} alt="print"/>
                    </IconButton>
                  </Tooltip>
                  {pembelian.status==="Berhasil"?
                  <Tooltip title="Batalkan Pembelian">
                    <IconButton 
                    onClick={()=>handleClickPembatalanPembelian(pembelian.fakturpembelian)}
                    >
                      <img src={DeleteData} alt="details"/>
                    </IconButton>
                  </Tooltip>:
                   <IconButton 
                   disabled={true}
                   onClick={()=>handleClickPembatalanPembelian(pembelian.fakturpembelian)}
                   >
                     <img src={DisabledDeleteData} alt="details"/>
                   </IconButton>
                  }
                  </StyledTableCell>
                </StyledTableRow>
            ))}
        </TableBody>
      )
      const handleClickButton = ()=>{
        if(findBy==="tanggal"){
          const data={
            'tglawal':format(tanggalawal,"yyyy-MM-dd"),
            'tglakhir':format(tanggalakhir,"yyyy-MM-dd"),
            'findby':findBy,
            'faktur':""
          }
          dispatch(getDataPembelian(data,token,setOpensnackbar))
        }else if(findBy==="faktur"){
          const data={
            'tglawal':null,
            'tglakhir':null,
            'findby':findBy,
            'faktur':faktur
          }
          dispatch(getDataPembelian(data,token,setOpensnackbar))
        }
      }
      const handleClickPembatalanPembelian = (faktur) =>{
        setMessageDialog(`Apakah anda yakin akan membatalkan pembelian ${faktur}?`);
        setNoFaktur(faktur);
        setOpenDialog(true);
      }
      const handlePembatalanPembelian = () =>{
        const data = {
          'fakturpembelian':tempNoFaktur,
          'tanggal':format(new Date(),"yyyy-MM-dd"),
          'tglawal':format(tanggalawal,"yyyy-MM-dd"),
          'tglakhir':format(tanggalakhir,"yyyy-MM-dd"),
          'findby':findBy,
          'faktur':""
        }
          dispatch(pembatalanPembelian(data,token,setOpensnackbar,setOpenDialog));
      }
      const handleCloseDialog = ()=>{
        setOpenDialog(false);
      }
      const handleClickBack = () =>{
        dispatch(setEmptyDataPembelian());
        history.push('/laporan/');
      }
      const handleChangeRadio = (e) =>{
        setFindBy(e.target.value);
        if(e.target.value==="tanggal"){
          dispatch(setEmptyDataPembelian());
          setFaktur("");
          setDisabledTanggal(false);
          setDisabledFaktur(true);
        }else if(e.target.value==="faktur"){
          dispatch(setEmptyDataPembelian());
          setTanggalawal(new Date());
          setTanggalakhir(new Date());
          setDisabledTanggal(true);
          setDisabledFaktur(false);
        }
      }
      const handleChangeFaktur = (e) =>{
        setFaktur(e.target.value);
      }
  return (
    <Container maxWidth='xl' sx={{marginTop:'50px'}}>
        <Typography variant='h6' pl={1} mt={1}>Laporan Pembelian</Typography>
        <Divider/>
        <Box sx={boxWapper}>
          <Box sx={paperInput}>
          <Radio
              style={{marginTop:'15px'}}
              checked={findBy === 'tanggal'}
              onChange={handleChangeRadio}
              value="tanggal"
              name="tanggal-radio"
            />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
          label="Tanggal Awal"
          inputFormat="dd/MM/yyyy"
          renderInput={(params) => <TextField {...params} sx={{width:'150px',marginRight:'20px'}} variant="standard"/>}
          value={tanggalawal}
          disabled={disabledTanggal}
          onChange={(e)=>handleChangeTanggalAwal(e)}
          />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
          label="Tanggal Akhir"
          inputFormat="dd/MM/yyyy"
          renderInput={(params) => <TextField {...params} sx={{width:'150px',marginRight:'10px'}} variant="standard"/>}
          value={tanggalakhir}
          disabled={disabledTanggal}
          onChange={(e)=>handleChangeTanggalAkhir(e)}
          />
          </LocalizationProvider>
          <Radio
              style={{marginTop:'15px'}}
              checked={findBy === 'faktur'}
              onChange={handleChangeRadio}
              value="faktur"
              name="faktur-radio"
            />
            <TextField
            variant='standard'
            id="fakturSearch"
            label="Pencarian Faktur"
            disabled={disabledFaktur}
            value={faktur}
            onChange={handleChangeFaktur}
            />
          <Tooltip title="Tampilkan Data">
              <IconButton onClick={()=>handleClickButton()}>
                  <ContentPasteSearchIcon/>
              </IconButton>
          </Tooltip>
          </Box>
          <TableContainer component={Paper} sx={paperTable}>
                  <Table stickyHeader aria-label="customized table" size='small'>
                      <TableHead>
                      <TableRow>
                          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Tanggal</Typography></StyledTableCell>
                          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>No Faktur</Typography></StyledTableCell>
                          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Pembayaran</Typography></StyledTableCell>
                          <StyledTableCell width={200} align="center"><Typography sx={{fontSize:12}}>Distributor</Typography></StyledTableCell>
                          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Diskon</Typography></StyledTableCell>
                          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Total</Typography></StyledTableCell>
                          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Status</Typography></StyledTableCell>
                          <StyledTableCell width={150} align="center"></StyledTableCell>
                      </TableRow>
                      </TableHead>
                          {tableDataPembelian} 
                  </Table>
               </TableContainer>
               <Box sx={{width:'90%',display:'flex',justifyContent:'flex-end'}}>
                  <Typography>Total Pembelian : {new Intl.NumberFormat().format(totalPembelian)}</Typography>
               </Box>
        </Box>
        <Snackbar 
            open={openSnackbar} 
            autoHideDuration={1000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={pembelianMessage === "Pembatalan pembelian berhasil" ? "success" : "error"}
                >
                    {pembelianMessage}
                    </Alert>
            </Snackbar>
            <DeleteDialog 
            openDialog={openDialog} 
            message={messageDialog}
            handleCloseDialog={handleCloseDialog}
            handleClick={handlePembatalanPembelian}
            isLoading={isLoading}
            textHapus={"Ya"}
            textKeluar={"Tidak"}/>
            <Fab sx={{position: 'fixed',bottom: theme.spacing(5),left: theme.spacing(3)}} size="small" color="primary" aria-label="add" onClick={()=>handleClickBack()}>
                <ArrowBackIcon/>
            </Fab>
    </Container>
  )
}

export default LaporanPembelianPage