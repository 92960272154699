import { Backdrop, Box, Button, Checkbox, CircularProgress, Container, Divider, FormControlLabel, FormGroup, Snackbar, TextField, Typography, useTheme } from '@mui/material'
import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { emptyUserMessage, getDetailsPengguna, updatePengguna } from '../../../config/redux/actions';
import MuiAlert from '@mui/material/Alert';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { useFormik } from 'formik';
import UbahPassword from '../UbahPassword';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
const UbahPengguna = () => {
    const hakAkses = [
        {
            value: 'null',
            label: "",
          },
          {
            value: 'Pemilik',
            label: 'Pemilik',
          },
          {
            value: 'Super User',
            label: 'Super User',
          },
          {
            value: 'Gudang',
            label: 'Gudang',
          },
          {
            value: 'Kasir',
            label: 'Kasir',
          },
          {
            value: 'Admin',
            label: 'Admin',
          }
    ];
    const theme = useTheme();
    const boxWapper = {
        display:'flex',
        justifyContent:'center',
        [theme.breakpoints.up('sm')]:{
            width:'100%',
            maxHeight:'500px',
            height:'100%',
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            height:'100%',
        }
    }
    const paperInput={
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        // border:'1px solid black',
        [theme.breakpoints.up('sm')]:{
            width:"35%",
            height:'100%',
            borderRadius:'10px',
            marginTop:'10px'
        },
        [theme.breakpoints.down('sm')]:{
            width:'90%',
            height:'100%',
            borderRadius:'10px',
            marginTop:'10px'
        }
    }
    const checkBoxWapper={
        [theme.breakpoints.up('sm')]:{
            width:'80%',
            marginTop:'10px',
            display:'flex',
            justifyContent:'center',
            alignItems:'center'
        },
        [theme.breakpoints.down('sm')]:{
            width:'55%',
            marginTop:'10px',
            display:'flex',
            flexDirection:'column'
        }
    }
    
    const {penggunaMessage,isLoading,dataLogin,detailsPengguna} = useSelector(state=>state.penggunaReducers);
    const [Pengguna,setPengguna] = useState(false);
    const [Pelanggan,setPelanggan] = useState(false);
    const [Distributor,setDistributor] = useState(false);
    const [StokBarang,setStokBarang] = useState(false);
    const [Barang,setBarang] = useState(false);
    const [Harga,setHarga] = useState(false);
    const [Pembelian,setPembelian] = useState(false);
    const [Penjualan,setPenjualan] = useState(false);
    const [Retur,setRetur] = useState(false);
    const [Laporan,setLaporan] = useState(false);
    const [openSnackbar,setOpensnackbar]=useState(false);
    const [token,setToken] = useState(dataLogin.token);
    const [openPasswordDialog,setOpenPasswordDialog] = useState(false);
    const dispatch=useDispatch();
    const history = useHistory();
    let{idpengguna} = useParams();
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(emptyUserMessage(''));
        }, 100);
    }
    const schemaValidation = yup.object().shape({
        idpengguna:yup.string().required("ID Pengguna tidak boleh kosong").matches(/^(?=.{6,10}$)(?![_.])[a-zA-Z0-9._]/,"Id pengguna harus terdiri dari 6 sampai 10 karakter dan tidak boleh mengandung karakter _ atau ."),
        nama:yup.string().required("Nama tidak boleh kosong"),
        hakakses:yup.string().required("Hak Akses tidak boleh kosong"),
    })
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            idpengguna:idpengguna || '',
            nama:detailsPengguna.nama || '',
            hakakses:detailsPengguna.hakakses||'',
        },
        validationSchema:schemaValidation,
        onSubmit:(values)=>{
            const data = new URLSearchParams();
            data.append('nama',values.nama);
            data.append('password',values.password);
            data.append('hakakses',values.hakakses);
            data.append('modul_pengguna',Pengguna);
            data.append('modul_pelanggan',Pelanggan);
            data.append('modul_distributor',Distributor);
            data.append('modul_stokbarang',StokBarang);
            data.append('modul_barang',Barang);
            data.append('modul_harga',Harga);
            data.append('modul_pembelian',Pembelian);
            data.append('modul_penjualan',Penjualan);
            data.append('modul_retur',Retur);
            data.append('modul_laporan',Laporan);
            dispatch(updatePengguna(idpengguna,data,token,setOpensnackbar,history));
        }
    })
    useEffect(()=>{
        if(detailsPengguna===''){
            dispatch(getDetailsPengguna(idpengguna,token,setOpensnackbar));
        }
        detailsPengguna.m_pengguna === 'true' ? setPengguna(true) : setPengguna(false);
        detailsPengguna.m_pelanggan === 'true' ? setPelanggan(true) : setPelanggan(false);
        detailsPengguna.m_distributor === 'true' ? setDistributor(true) : setDistributor(false);
        detailsPengguna.m_stokbarang === 'true' ? setStokBarang(true) : setStokBarang(false);
        detailsPengguna.m_barang === 'true' ? setBarang(true) : setBarang(false);
        detailsPengguna.m_harga === 'true' ? setHarga(true) : setHarga(false);
        detailsPengguna.m_pembelian === 'true' ? setPembelian(true) : setPembelian(false);
        detailsPengguna.m_penjualan === 'true' ? setPenjualan(true) : setPenjualan(false);
        detailsPengguna.m_retur === 'true' ? setRetur(true) : setRetur(false);
        detailsPengguna.m_laporan === 'true' ? setLaporan(true) : setLaporan(false);
    },[idpengguna,token,dispatch,detailsPengguna]);
    const onSelectHandleChange = (e)=>{
        formik.setFieldValue('hakakses',e.target.value);
        if(e.target.value === ''){
            setPengguna(false);
            setPelanggan(false);
            setDistributor(false);
            setStokBarang(false);
            setBarang(false);
            setHarga(false);
            setPembelian(false);
            setPenjualan(false);
            setRetur(false);
            setLaporan(false);
        }
        else if(e.target.value === 'Pemilik'){
            setPengguna(true);
            setPelanggan(true);
            setDistributor(true);
            setStokBarang(true);
            setBarang(true);
            setHarga(true);
            setPembelian(true);
            setPenjualan(true);
            setRetur(true);
            setLaporan(true);
        }else if(e.target.value === 'Super User'){
            setPengguna(false);
            setPelanggan(true);
            setDistributor(true);
            setStokBarang(true);
            setBarang(true);
            setHarga(false);
            setPembelian(true);
            setPenjualan(true);
            setRetur(true);
            setLaporan(false);
        }else if(e.target.value === 'Gudang'){
            setPengguna(false);
            setPelanggan(false);
            setDistributor(false);
            setStokBarang(true);
            setBarang(true);
            setHarga(false);
            setPembelian(false);
            setPenjualan(false);
            setRetur(true);
            setLaporan(false);
        }
        else if(e.target.value === 'Kasir'){
            setPengguna(false);
            setPelanggan(true);
            setDistributor(false);
            setStokBarang(false);
            setBarang(false);
            setHarga(false);
            setPembelian(false);
            setPenjualan(true);
            setRetur(false);
            setLaporan(false);
        }else if(e.target.value === 'Admin'){
            setPengguna(false);
            setPelanggan(true);
            setDistributor(true);
            setStokBarang(true);
            setBarang(true);
            setHarga(false);
            setPembelian(true);
            setPenjualan(false);
            setRetur(true);
            setLaporan(false);
        }
    }
    const handleOpenPasswordDialog = () =>{
        setOpenPasswordDialog(true);
    }
    const handleClosePasswordDialog = () =>{
        setOpenPasswordDialog(false);
    }
    return (
        <Container maxWidth='xl' sx={{marginTop:'100px'}}>
        <Typography variant='h6' pl={1} mt={1}>Perubahan Data Pengguna</Typography>
        <Divider/>
        <Box sx={boxWapper}>
            <Box sx={paperInput}>
            <TextField
                    id="idpengguna"
                    name="idpengguna"
                    label="ID Pengguna"
                    variant="standard"
                    disabled={true}
                    value={idpengguna}
                    style={{width:'80%',marginTop:'10px'}}
                    />
                    <TextField
                    id="nama"
                    name="nama"
                    label="nama"
                    variant="standard"
                    style={{width:'80%',marginTop:'10px'}}
                    value={formik.values.nama}
                    error={formik.touched.nama && Boolean(formik.errors.nama)}
                    helperText={formik.touched.nama && formik.errors.nama}
                    onChange={formik.handleChange}
                    />
                    <Button 
                    size='small' 
                    style={{width:'80%',marginTop:'10px'}} 
                    variant='contained'
                    onClick={handleOpenPasswordDialog}>
                        Perubahaan Kata Sandi
                        </Button>
                    <TextField
                    id='hakakses'
                    select
                    name='hakakses'
                    variant="standard" 
                    label="Hak Akses"
                    style={{width:'80%',marginTop:'10px'}}
                    SelectProps={{
                        native: true,
                      }}
                    value={formik.values.hakakses}
                    error={formik.touched.hakakses && Boolean(formik.errors.hakakses)}
                    helperText={formik.touched.hakakses && formik.errors.hakakses}
                    onChange={(e)=>onSelectHandleChange(e)}
                    >
                        {hakAkses.map((option)=>(
                            <option key ={option.value} value={option.label}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                    <Box sx={checkBoxWapper}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox
                            id='m_pengguna'
                            name='m_pengguna'
                            checked={Pengguna}
                            onClick={()=>setPengguna(!Pengguna)}
                            value={Pengguna}/>} label="Modul Pengguna"/>
                            <FormControlLabel control={<Checkbox
                            id='m_pelanggan'
                            name='m_pelanggan'
                            value={Pelanggan}
                            checked={Pelanggan}
                            onClick={()=>setPelanggan(!Pelanggan)}
                            />} label="Modul Pelanggan"/>
                            <FormControlLabel control={<Checkbox
                            id='m_distributor'
                            name='m_distributor'
                            value={Distributor}
                            checked={Distributor}
                            onClick={()=>setDistributor(!Distributor)}
                            />} label="Modul Distributor"/>
                            <FormControlLabel control={<Checkbox
                             id='m_stokbarang'
                             name='m_stokbarang'
                             value={StokBarang}
                            checked={StokBarang}
                            onClick={()=>setStokBarang(!StokBarang)}
                             />} label="Modul Stok Barang"/>
                            <FormControlLabel control={<Checkbox
                            id='m_barang'
                            name='m_barang'
                            value={Barang}
                            checked={Barang}
                            onClick={()=>setBarang(!Barang)}
                            />} label="Modul Barang"/>
                        </FormGroup>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox
                            id='m_harga'
                            name='m_harga'
                            value={Harga}
                            checked={Harga}
                            onClick={()=>setHarga(!Harga)}
                            />} label="Modul Harga"/>
                            <FormControlLabel control={<Checkbox
                            id='m_pembelian'
                            name='m_pembelian'
                            value={Pembelian}
                            checked={Pembelian}
                            onClick={()=>setPembelian(!Pembelian)}
                            />} label="Modul Pembelian"/>
                            <FormControlLabel control={<Checkbox
                            id='m_penjualan'
                            name='m_penjualan'
                            value={Penjualan}
                            checked={Penjualan}
                            onClick={()=>setPenjualan(!Penjualan)}
                            />} label="Modul Penjualan"/>
                            <FormControlLabel control={<Checkbox
                            id='m_retur'
                            name='m_retur'
                            value={Retur}
                            checked={Retur}
                            onClick={()=>setRetur(!Retur)}
                            />} label="Modul Retur"/>
                            <FormControlLabel control={<Checkbox
                            id='m_laporan'
                            name='m_laporan'
                            value={Laporan}
                            checked={Laporan}
                            onClick={()=>setLaporan(!Laporan)}
                            />} label="Modul Laporan"/>
                        </FormGroup>
                    </Box>
                    <Button variant='contained' 
                    sx={{marginTop:'10px',marginBottom:'10px',width:'100px'}} 
                    onClick={formik.handleSubmit} 
                    disabled={isLoading}>SIMPAN</Button>
            </Box>
        </Box>     
        <UbahPassword openPasswordDialog={openPasswordDialog} iduser={idpengguna} handleClosePasswordDialog={handleClosePasswordDialog}/>
        <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={penggunaMessage === 'Perubahan data pengguna berhasil' ? "success" : "error"}
                >
                    {penggunaMessage}
                    </Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    )
}

export default UbahPengguna
