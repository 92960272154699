const ppnInitialValues = {
    isLoading:false,
    dataPPN:[],
    pagePPN:{
        currentPage:'1',
        totalPage:'1'
    },
    ppnMessage:''
}

const ppnReducers = (state=ppnInitialValues,action)=>{
    switch(action.type){
        case 'FETCH ACTION PPN':{
            return{
                ...state,
                isLoading:true
            }
        }
        case 'UPDATE PAGE PPN':{
            return {
                ...state,
                pagePPN:action.payload
            }
        }
        case 'UPDATE DATA PPN':{
            return{
                ...state,
                isLoading:false,
                dataPPN:action.payload
            }
        }
        case 'EMPTY DATA PPN':{
            return{
                ...state,
                dataPPN:[],
                pagePPN:{
                    currentPage:'1',
                    totalPage:'1'
                }
            }
        }
        case 'FAILED GET DATA PPN':{
            return{
                ...state,
                isLoading:false,
                ppnMessage:action.payload
            }
        }
        case 'SET MESSAGE PPN':{
            return{
                ...state,
                ppnMessage:action.payload
            }
        }
        case 'FAILED ACTION PPN':{
            return{
                ...state,
                isLoading:false,
                ppnMessage:action.payload
            }
        }
        case 'SUCCESSED ACTION PPN':{
            return{
                ...state,
                isLoading:false,
                ppnMessage:action.payload
            }
        }
        default: return state
    }
}

export default ppnReducers;