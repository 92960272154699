import { Container,Snackbar, Typography,Box, List, Button, Fab } from '@mui/material'
import React, { useEffect, useState , useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getDataPenjualanToPrint,setPenjualanMessage, getDataReturPenjualan, setEmptyPenjualanPelanggan } from '../../../config/redux/actions'
import Cookies from 'js-cookie';
import { useReactToPrint } from 'react-to-print';
import { format } from 'date-fns';
import MuiAlert from '@mui/material/Alert';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { useHistory } from 'react-router-dom'
import { useTheme } from '@mui/system'
// import {LogoStruk} from '../../../components/assets';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
const StrukPerPelanggan = React.forwardRef ((props,ref) => {
    const {penjualanToPrint,detailsPenjualanToPrint,penjualanMessage} = useSelector(state=>state.penjualanReducers);
    const {dataReturPenjualan,dataReturPenjualanDetails}=useSelector(state=>state.returpenjualanReducers);
    const {dataLogin} =useSelector(state=>state.penggunaReducers);
    const [token,setToken] = useState(dataLogin.token);
    const [openSnackbar,setOpensnackbar]=useState(false);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
      }
      const componentRef = useRef();
      const handlePrint = useReactToPrint({
          content:()=>componentRef.current,
        });
        const handleClickPrint = () =>{
          handlePrint();
          // setTimeout(()=>{
          //   history.push('/historyPenjualan');
          // },2000)
      }
    let {fakturpenjualan} = useParams();
    const dispatch = useDispatch();
    const theme = useTheme();
    useEffect(()=>{
        const data={
            'fakturpenjualan':fakturpenjualan,
            'tempPPN':"ALL",
            'kelompok':"ALL"
        }
            dispatch(getDataPenjualanToPrint(data,token,setOpensnackbar));
            dispatch(getDataReturPenjualan(data,token,setOpensnackbar,handlePrint));
    },[dispatch,token,fakturpenjualan])
    const history = useHistory();
    const handleClickBack = () =>{
        history.push('/laporan/pelanggan');
        dispatch(setEmptyPenjualanPelanggan());
    }
const handleCloseSnackbar = (event,reason) =>{
    if(reason === 'clickaway'){
        return;
    }
    setOpensnackbar(false);
    setTimeout(() => {
        dispatch(setPenjualanMessage(''));
    }, 100);
  }
  var time = new Date().toLocaleTimeString();
  const returCart = (
      <Box sx={{marginTop:'10px'}}>
      <Typography variant='body2'><b>DAFTAR RETUR</b></Typography>
      <Box sx={{marginTop:'10px',borderBottom:'1px dotted black'}}/>
                {dataReturPenjualanDetails.map(((dataRetur,index)=>(
                    <List key={dataRetur.idreturpelanggandetails} disablePadding>
                        <Box sx={{width:'100%',p:0}}>
                            <Typography variant='body2' align='left'><b>{dataRetur.namabarang}({dataRetur.tipeharga})</b></Typography>
                        </Box>
                        <Box sx={{width:'100%',display:'flex',justifyContent:'space-between',p:0}}>
                            <Typography variant='body2' align="left"><b>{new Intl.NumberFormat().format(dataRetur.harga) || '0'} x {dataRetur.qty  || '0'} {dataRetur.satuan}</b></Typography>
                            <Typography variant='body2' align="right"><b>{new Intl.NumberFormat().format(dataRetur.jumlah) || '0'}</b></Typography>
                        </Box>
                      </List>
                )))} 
                <Box sx={{marginTop:'10px',borderBottom:'1px dotted black'}}/>
        <Box sx={{width:'100%',display:'flex',alignItems:'flex-end',justifyContent:'flex-end'}}>
            <Box sx={{display:'flex',flexDirection:'column',marginRight:'2px'}}>
                <Typography variant="body2">
                    Total
                </Typography>
            </Box>
            <Box sx={{display:'flex',flexDirection:'column',marginRight:'2px'}}>
                <Typography variant="body2">
                    :
                </Typography>
            </Box>
            <Box sx={{display:'flex',flexDirection:'column',marginRight:'2px'}}>
                <Typography variant="body2">
                <b>{dataReturPenjualanDetails.length>0 ? new Intl.NumberFormat().format(dataReturPenjualan.total) : '0'}</b>
                </Typography>
            </Box>
        </Box>
        
    </Box>
  )
  return (
    <Container maxWidth='xl' sx={{marginTop:'50px',display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
        <Box sx={{width:'75mm',display:'flex',flexDirection:'column'}} ref={componentRef}>
            <Box sx={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center'}}>
                {/* <Box sx={{width:'3cm', height:'2cm'}}>
                    <img style={{width:'100%', height:'100%'}} src={LogoStruk} alt="logostruk"/>
                </Box> */}
               <Box sx={{width:'100%',height:'100%',display:'flex',flexDirection:'column',textAlign:'center'}}>
                   <Typography><b>JAYA MAKMUR</b></Typography>
                   <Typography variant='body2'><b>Jl Aria Cikondang No.21</b></Typography>
                   <Typography variant='body2'><b>0856-2480-5558</b></Typography>
               </Box>
            </Box>
            <Box sx={{width:'100%',borderBottom:'1px dotted black'}}/>
            <Box sx={{width:'100%',display:"flex",flexDirection:'column',justifyContent:'space-between',marginTop:'10px'}}>
                <Typography variant='caption'>Tanggal : {penjualanToPrint.tanggal !== undefined ? format(new Date(penjualanToPrint.tanggal),'dd MMM yyyy')+", "+time : ''}</Typography>
                <Typography variant='caption'>No Faktur : {penjualanToPrint.fakturpenjualan}</Typography>
                <Typography variant='caption'>Pelanggan :{penjualanToPrint.alias === "" ? penjualanToPrint.nama : penjualanToPrint.nama+"("+penjualanToPrint.alias+")"}</Typography>
            </Box>
            <Box sx={{width:'100%',borderBottom:'1px dotted black'}}/>
                    {detailsPenjualanToPrint.map(((detailsToPrints,index)=>(
                        <List key={detailsToPrints.idpenjualandetails} disablePadding>
                            <Box sx={{width:'100%',p:0}}>
                            <Typography variant='body2' align='left'>{detailsToPrints.namabarang}</Typography>
                            </Box>
                            <Box sx={{width:'100%',display:'flex',justifyContent:'space-between',p:0}}>
                            <Typography variant='body2' align="left">{new Intl.NumberFormat().format(detailsToPrints.harga) || '0'} ({new Intl.NumberFormat().format(detailsToPrints.diskon) || '0'}) x {detailsToPrints.qty  || '0'} {detailsToPrints.satuan}</Typography>
                            <Typography variant='body2' align="right">{new Intl.NumberFormat().format(detailsToPrints.jumlah) || '0'}</Typography>
                            </Box>
                            </List>
                    )))}
                <Box sx={{width:'100%',display:'flex',alignItems:'flex-end',borderTop:'1px dotted black',justifyContent:'flex-end',marginTop:'5px'}}>
                    <Box sx={{display:'flex',flexDirection:'column',marginRight:'2px'}}>
                        <Typography variant="body1">
                            Total Item
                        </Typography>
                        <Typography variant="body1">
                            Total
                        </Typography>
                    </Box>
                    <Box sx={{display:'flex',flexDirection:'column',marginRight:'2px'}}>
                        <Typography variant="body1">
                            :
                        </Typography>
                        <Typography variant="body1">
                            :
                        </Typography>
                    </Box>
                    <Box sx={{display:'flex',flexDirection:'column',marginRight:'2px'}}>
                        <Typography variant="body1" textAlign="center">
                            {new Intl.NumberFormat().format(penjualanToPrint.totalitem) || '0'}
                        </Typography>
                        <Typography variant="body1">
                        {new Intl.NumberFormat().format(penjualanToPrint.total) || '0'}
                        </Typography>
                    </Box>
                </Box>
                {dataReturPenjualanDetails.length>0 ? returCart : null}
                <Box sx={{display:'flex',justifyContent:'flex-end',alignItems:'flex-end',marginTop:'5px'}}>
                <Typography variant="body1">
                        Total Tagihan
                </Typography>
                <Typography variant="body1">
                        :
                </Typography>
                <Typography variant="body1">
                    <b>{dataReturPenjualan !== undefined ? new Intl.NumberFormat().format(parseFloat(penjualanToPrint.total)-parseFloat(dataReturPenjualan.total)) : new Intl.NumberFormat().format(penjualanToPrint.total)}</b>
                </Typography>
                </Box>
        </Box>
        <Box sx={{width:'100%',display:'flex',justifyContent:'center'}}>
            <Button variant='contained' color='primary' onClick={handleClickPrint}>Print</Button>
            </Box>
            <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={penjualanMessage === "Transaksi penjualan berhasil" ? "success" : "error"}
                >
                    {penjualanMessage}
                    </Alert>
            </Snackbar>
            <Fab sx={{position: 'fixed',bottom: theme.spacing(5),left: theme.spacing(3)}} size="small" color="primary" aria-label="add" onClick={()=>handleClickBack()}>
            <ArrowBackIcon/>
        </Fab>
    </Container>
  )
})

export default StrukPerPelanggan