import { Backdrop, Button,  CircularProgress,  Dialog, Divider, Snackbar, TextField, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import * as yup from 'yup';
import MuiAlert from '@mui/material/Alert';
import { emptyBarangMessage, getAllDistributorForCombo, updateHargaBarang } from '../../../config/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });


const UbahHarga = ({openDialogUbah,closeDialogUbah,idsatuan,idbarang}) => {
    const theme = useTheme();
    const boxWapper = {
        display:'flex',
        justifyContent:'center',
        [theme.breakpoints.up('sm')]:{
            width:'100%',
            maxHeight:'500px',
            height:'100%',
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            height:'100%',
        }
    }
    const paperInput={
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        // border:'1px solid black',
        [theme.breakpoints.up('sm')]:{
            width:"50%",
            height:'100%',
            borderRadius:'10px',
            marginTop:'10px'
        },
        [theme.breakpoints.down('sm')]:{
            width:'90%',
            height:'100%',
            borderRadius:'10px',
            marginTop:'10px'
        }
    }
    const {messageBarang,isLoading,barangDetails} = useSelector(state=>state.barangReducers);
    const {dataLogin} = useSelector(state=>state.penggunaReducers);
    const [openSnackbar,setOpensnackbar]=useState(false);
    const [token,setToken] = useState(dataLogin.token);
    const dispatch = useDispatch();
    useEffect(()=>{
        // if(barangDetails.length<1){
        //     dispatch(getDetailsSatuanBarang(idbarang,idsatuan,token,setOpensnackbar))
        // }
        dispatch(getAllDistributorForCombo("",token,setOpensnackbar));
    },[token,dispatch,idbarang,barangDetails,idsatuan])
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(emptyBarangMessage(''));
        }, 100);
    }
    const schemaValidation = yup.object().shape({
        hargadasar:yup.number().required("Harga Jual tidak boleh kosong").min(0,"Harga Jual tidak boleh kurang dari 0"),
        hargagrosir:yup.number().required("Harga Grosir tidak boleh kosong").min(0,"Harga Grosir tidak boleh kurang dari 0")
    })
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            idsatuanbarang:barangDetails.idsatuanbarang||'',
            hargadasar:barangDetails.hargadasar||'',
            hargagrosir:barangDetails.hargagrosir||'',
        },
        validationSchema:schemaValidation,
        onSubmit:(values)=>{
            const data = new URLSearchParams();
            data.append('idsatuanbarang',values.idsatuanbarang);
            data.append('hargadasar',values.hargadasar);
            data.append('hargagrosir',values.hargagrosir);
            dispatch(updateHargaBarang(data,token,setOpensnackbar,closeDialogUbah));
        }
    })
    return (
        <Dialog open={openDialogUbah} maxWidth='xl' sx={{marginTop:'100px'}}>
        <Typography variant='h6' pl={1} mt={1}>Perubahan Harga {barangDetails.namabarang} ({barangDetails.satuan})</Typography>
        <Divider/>
        <Box sx={boxWapper}>
            <Box sx={paperInput}>
            <TextField
                id="idsatuanbarang"
                name="idsatuanbarang"
                label="ID Satuan Barang"
                variant="standard"
                disabled={true}
                style={{width:'100%',marginTop:'10px'}}
                value={formik.values.idsatuanbarang}
                />
            <TextField
                    id="hargadasar"
                    name="hargadasar"
                    label="Harga Eceran"
                    variant="standard"
                    style={{width:'100%',marginTop:'10px'}}
                    value={formik.values.hargadasar}
                    error={formik.touched.hargadasar && Boolean(formik.errors.hargadasar)}
                    helperText={formik.touched.hargadasar && formik.errors.hargadasar}
                    onChange={formik.handleChange}
                    />
                   <TextField
                    id="hargagrosir"
                    name="hargagrosir"
                    label="Harga Grosir"
                    variant="standard"
                    style={{width:'100%',marginTop:'10px'}}
                    value={formik.values.hargagrosir}
                    error={formik.touched.hargagrosir && Boolean(formik.errors.hargagrosir)}
                    helperText={formik.touched.hargagrosir && formik.errors.hargagrosir}
                    onChange={formik.handleChange}
                    />
                    <Box sx={{width:'100%',display:'flex',justifyContent:'center'}}>
                        <Button variant='contained' sx={{marginTop:'10px',marginBottom:'10px',marginRight:'10px',width:'100px'}} onClick={formik.handleSubmit} disabled={isLoading}>SIMPAN</Button>
                        <Button variant='contained' color='error' sx={{marginTop:'10px',marginBottom:'10px',width:'100px'}} onClick={closeDialogUbah} disabled={isLoading}>BATAL</Button>
                    </Box>
            </Box>
        </Box>
        <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={messageBarang === 'Perubahan harga barang berhasil' ? "success" : "error"}
                >
                    {messageBarang}
                    </Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
            {/* <Fab sx={{position: 'fixed',bottom: theme.spacing(5),left: theme.spacing(3)}} size="small" color="primary" aria-label="add" onClick={()=>history.push('/hargabarang/ubah')}>
            <ArrowBackIcon/>
        </Fab> */}
        </Dialog>
    )
}

export default UbahHarga
