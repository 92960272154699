import axios from 'axios';
import { apiUrl } from '../..';

export const getAllBarang = (search,page,token,setOpensnackbar) =>(dispatch)=>{
    dispatch({
      type:'FETCH GET ALL BARANG',
    })
    axios.get(`${apiUrl.url}barang/?search=${search}&page=${page}&perPage=50`,{
          headers:{
            "Authorization" : `Bearer ${token}`
          }
      })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
          type:'UPDATE DATA BARANG', 
          payload:ResponseAPI.data
       })
        dispatch({
          type:'UPDATE PAGE BARANG',
          payload:{
            currentPage: ResponseAPI.current_page,
            totalPage: Math.ceil(ResponseAPI.totalData/ResponseAPI.per_page)
          }
        })
        
    })
    .catch(error=>{
      dispatch({
        type:'FAILED GET ALL BARANG',
        payload:error.message
      })
      setOpensnackbar(true);
    })
  }
  export const searchForSales = (search,page,token,setOpensnackbar,perPage) =>(dispatch)=>{
    dispatch({
      type:'FETCH GET ALL BARANG',
    })
    axios.get(`${apiUrl.url}barang/?search=${search}&page=${page}&perPage=${perPage}`,{
          headers:{
            "Authorization" : `Bearer ${token}`
          }
      })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
          type:'UPDATE DATA BARANG', 
          payload:ResponseAPI.data
       })
        dispatch({
          type:'UPDATE PAGE BARANG',
          payload:{
            currentPage: ResponseAPI.current_page,
            totalPage: Math.ceil(ResponseAPI.totalData/ResponseAPI.per_page)
          }
        })
        
    })
    .catch(error=>{
      dispatch({
        type:'FAILED GET ALL BARANG',
        payload:error.message
      })
      setOpensnackbar(true);
    })
  }
  export const getBarangByDist = (id,search,page,token,setOpensnackbar) =>(dispatch)=>{
    dispatch({
      type:'FETCH GET ALL BARANG',
    })
    const tempId = {
      'id':id
    }
    axios.post(`${apiUrl.url}barang/dist/?search=${search}&page=${page}&perPage=50`,tempId,{
        headers:{
          "Authorization" : `Bearer ${token}`,
        }
      })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
          type:'UPDATE DATA HARGA BARANG', 
          payload:ResponseAPI.data
       })
        dispatch({
          type:'UPDATE PAGE HARGA BARANG',
          payload:{
            currentPage: ResponseAPI.current_page,
            totalPage: Math.ceil(ResponseAPI.totalData/ResponseAPI.per_page)
          }
        })
        
    })
    .catch(error=>{
      dispatch({
        type:'FAILED GET ALL BARANG',
        payload:error.message
      })
      setOpensnackbar(true);
    })
  }
export const emptyBarangMessage = (message) =>(dispatch)=>{
dispatch({
    type:'EMPTY BARANG MESSAGE',
    payload:message
})
}
export const emptyDataBarang = () =>(dispatch)=>{
  dispatch({
      type:'EMPTY DATA BARANG'
  })
  }
export const addNewBarang = (id,data,token,setOpensnackbar,history,status,handleClose)=>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION BARANG'
  })
  setTimeout(()=>{
    axios.post(`${apiUrl.url}barang/`,data,{
      headers:{
        "Authorization" : `Bearer ${token}`,
      }
    })
    .then(res=>{
      const ResponseApi = res.data;
      dispatch({
        type:'SUCCESSED ACTION BARANG',
        payload:ResponseApi.message
      })
      if(ResponseApi.message==='Penambahan data barang berhasil'){
        setOpensnackbar(true);
        setTimeout(()=>{
          if(status==='singleinput'){
            history.push('/barang');
            dispatch(getAllBarang('',1,token,setOpensnackbar));
          }else if(status==='fromexcel'){
            dispatch({
              type:'DELETE BARANG EXCEL',
              payload:id
            })
            history.push('/barang/excel')
          }else if(status==='pembelian'){
            handleClose();
          }
          dispatch({
            type:'EMPTY TODO SATUAN'
          })
        },2000)
      }else{
        setOpensnackbar(true);
      }
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION BARANG',
          payload:error.message
        })
        setOpensnackbar(true);
    })
  },2000)
  
}

export const deleteBarang = (idbarang,token,setOpensnackbar,search,counter,setOpenDialog)=>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION BARANG'
  })
  setTimeout(()=>{
    axios.delete(`${apiUrl.url}barang/${idbarang}`,{
      headers:{
        "Authorization" : `Bearer ${token}`,
      }
    })
    .then(res=>{
      const ResponseApi = res.data;
      dispatch({
        type:'SUCCESSED ACTION BARANG',
        payload:ResponseApi.message
      })
      if(ResponseApi.message === 'Penghapusan data barang berhasil'){
        dispatch(getAllBarang(search,counter,token,''));
        setOpensnackbar(true);
        setOpenDialog(false);
      }else{
        setOpensnackbar(true);
        setOpenDialog(false);
      }
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION BARANG',
          payload:error.message
        })
        setOpensnackbar(true);
    })
  },2000)
}

export const getDetailsBarang = (idbarang,token,setOpensnackbar)=>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION BARANG'
  })
  const data={
    'idbarang':idbarang
  }
  axios.post(`${apiUrl.url}barang/details/`,data,{
    headers:{
      "Authorization" : `Bearer ${token}`
    }
  })
  .then(res=>{
    const ResponseApi = res.data;
    dispatch({
      type:'SUCCESSED GET BARANG DETAILS',
      payload:{
          'idbarang':ResponseApi.data.idbarang,
          'namabarang':ResponseApi.data.namabarang,
          'iddistributor':ResponseApi.data.iddistributor,
          'nama':ResponseApi.data.nama
      },
      message:ResponseApi.message
    })
    setOpensnackbar(false);
  })
  .catch(error=>{
      dispatch({
        type:'FAILED ACTION BARANG',
        payload:error.message
      })
      setOpensnackbar(true);
  })
}
export const updateBarang = (data,token,setOpensnackbar,history)=>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION BARANG'
  })
  setTimeout(()=>{
    axios.patch(`${apiUrl.url}barang/edit/`,data,{
      headers:{
        "Authorization" : `Bearer ${token}`,
        'content-type':'application/x-www-form-urlencoded'
      }
    })
    .then(res=>{
      const ResponseApi = res.data;
      dispatch({
        type:'SUCCESSED ACTION BARANG',
        payload:ResponseApi.message
      })
      if(ResponseApi.message==='Perubahan data barang berhasil'){
        setOpensnackbar(true);
        setTimeout(()=>{
          dispatch(getAllBarang('',1,token,setOpensnackbar));
          history.push('/barang');
        },1000)
      }else{
        setOpensnackbar(true);
      }
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION BARANG',
          payload:error.message
        })
        setOpensnackbar(true);
    })
  },2000)
  
}
export const updateHargaBarang = (data,token,setOpensnackbar,closeDialogUbah)=>(dispatch)=>{
    dispatch({
      type:'FETCH ACTION BARANG'
    })
    setTimeout(()=>{
      axios.patch(`${apiUrl.url}barang/editharga/`,data,{
        headers:{
          "Authorization" : `Bearer ${token}`,
          'content-type':'application/x-www-form-urlencoded'
        }
      })
      .then(res=>{
        const ResponseApi = res.data;
        dispatch({
          type:'SUCCESSED ACTION BARANG',
          payload:ResponseApi.message
        })
        if(ResponseApi.message==='Perubahan harga barang berhasil'){
          setOpensnackbar(true);
          setTimeout(()=>{
            dispatch(getAllBarang('',1,token,setOpensnackbar));
            closeDialogUbah();
          },1000)
        }else{
          setOpensnackbar(true);
        }
      })
      .catch(error=>{
          dispatch({
            type:'FAILED ACTION BARANG',
            payload:error.message
          })
          setOpensnackbar(true);
      })
    },2000)
    
  }
  export const getDetailsSatuanBarang = (idbarang,idsatuan,token,setOpensnackbar)=>(dispatch)=>{
    const data={
      'idbarang':idbarang,
      'idsatuan':idsatuan
    }
    dispatch({
      type:'FETCH ACTION BARANG'
    })
    axios.post(`${apiUrl.url}barang/byid/`,data,{
      headers:{
        "Authorization" : `Bearer ${token}`,
      }
    })
    .then(res=>{
      const ResponseApi = res.data;
      dispatch({
        type:'SUCCESSED GET BARANG DETAILS',
        payload:{
            'idsatuanbarang':ResponseApi.data.idsatuanbarang,
            'idbarang':ResponseApi.data.idbarang,
            'namabarang':ResponseApi.data.namabarang,
            'satuan':ResponseApi.data.satuan,
            'hargadasar':ResponseApi.data.hargadasar,
            'hargagrosir':ResponseApi.data.hargagrosir,
            'stokawal':ResponseApi.data.stokawal,
            'modalawal':ResponseApi.data.modalawal
        },
        message:ResponseApi.message
      })
    })
    .catch(error=>{
      dispatch({
        type:'FAILED ACTION BARANG',
        payload:error.message
      })
      setOpensnackbar(true);
    })
  }
  export const getAllSatuanBarang = (idbarang,token,setOpensnackbar)=>(dispatch)=>{
    const tempId = new URLSearchParams();
    tempId.append('idbarang',idbarang);
    dispatch({
      type:'FETCH ACTION BARANG'
    })
    axios.post(`${apiUrl.url}barang/rincian`,tempId,{
      headers:{
        "Authorization" : `Bearer ${token}`,
      }
    })
    .then(res=>{
      const ResponseApi = res.data;
      if(ResponseApi.data.length>0){
        dispatch({
          type:'SUCCESSED GET SATUAN BARANG DETAILS',
          payload:ResponseApi.data,
          message:ResponseApi.message
        })
      }else{
        dispatch({
          type:'FAILED ACTION BARANG',
          message:ResponseApi.message
        })
        dispatch({
          type:'EMPTY ARRAY SATUAN BARANG DETAILS'
        })
      }
    })
    .catch(error=>{
      dispatch({
        type:'FAILED ACTION BARANG',
        payload:error.message
      })
      setOpensnackbar(true);
    })
  }
  export const setFormStokAwalBarang = (idbarang,idsatuanbarang,stokawal,modalawal)=>(dispatch)=>{
    dispatch({
      type:'SET FORM EDIT STOK AWAL',
      payload:{
        'idbarang':idbarang,
        'idsatuanbarang':idsatuanbarang,
        'stokawal':stokawal,
        'modalawal':modalawal
      }
    })
  }
  export const updateStokAwalBarang = (idbarang,data,token,setOpensnackbar,handleCloseDialog)=>(dispatch)=>{
    dispatch({
      type:'FETCH ACTION BARANG'
    })
    setTimeout(()=>{
      axios.patch(`${apiUrl.url}barang/editstokawal/`,data,{
        headers:{
          "Authorization" : `Bearer ${token}`,
          'content-type':'application/x-www-form-urlencoded'
        }
      })
      .then(res=>{
        const ResponseApi = res.data;
        dispatch({
          type:'SUCCESSED ACTION BARANG',
          payload:ResponseApi.message
        })
        if(ResponseApi.message==='Perubahan saldo awal barang berhasil'){
          setOpensnackbar(true);
          setTimeout(()=>{
            dispatch(getAllSatuanBarang(idbarang,token,setOpensnackbar));
            setOpensnackbar(false);
            handleCloseDialog();
          },1000)
        }else{
          setOpensnackbar(true);
        }
      })
      .catch(error=>{
          dispatch({
            type:'FAILED ACTION BARANG',
            payload:error.message
          })
          setOpensnackbar(true);
      })
    },2000)
    
  }
  export const updateHargaBarangByDistributor = (iddistributor,data,token,setOpensnackbar,history,setLoadingHitung,formik)=>(dispatch)=>{
    dispatch({
      type:'FETCH ACTION BARANG'
    })
    setLoadingHitung(true);
    setTimeout(()=>{
      axios.patch(`${apiUrl.url}barang/updateharga/${iddistributor}`,data,{
        headers:{
          "Authorization" : `Bearer ${token}`,
          'content-type':'application/x-www-form-urlencoded'
        }
      })
      .then(res=>{
        const ResponseApi = res.data;
        dispatch({
          type:'SUCCESSED ACTION BARANG',
          payload:ResponseApi.message
        })
        if(ResponseApi.message==='Perubahan data barang berhasil'){
          setOpensnackbar(true);
          setTimeout(()=>{
            dispatch(getAllBarang('',1,token,setOpensnackbar));
            history.push('/barang');
          },1000)
        }else{
          setOpensnackbar(true);
        }
        formik.handleReset();
        setLoadingHitung(false);
      })
      .catch(error=>{
          dispatch({
            type:'FAILED ACTION BARANG',
            payload:error.message
          })
          setOpensnackbar(true);
          setLoadingHitung(false);
      })
    },2000)
  }

  export const addToDoSatuan = (idsatuan,satuan,hargadasar,hargagrosir,stokawal,modalawal,formikTodo)=>(dispatch)=>{
    dispatch({
      type:'ADD TODO SATUAN',
      payload:{
        'idsatuan':idsatuan,
        'satuan':satuan,
        'hargadasar':hargadasar,
        'hargagrosir':hargagrosir,
        'stokawal':stokawal,
        'modalawal':modalawal
      }
    })
    formikTodo.setFieldValue('idsatuan',"");
    formikTodo.setFieldValue('stokawal',"");
    formikTodo.setFieldValue('modalawal',"");
    formikTodo.setFieldValue('hargadasar',"");
    formikTodo.setFieldValue('hargagrosir',"");
  }
  export const addBarangExcel = (data)=>(dispatch)=>{
    dispatch({
      type:'ADD BARANG EXCEL',
      payload:data
    })
  }
  export const emptyBarangExcel = ()=>(dispatch)=>{
    dispatch({
      type:'EMPTY BARANG EXCEL'
    })
  }
  
  export const emptyBarangDetails = ()=>(dispatch)=>{
    dispatch({
      type:'EMPTY BARANG DETAILS'
    })
  }
  export const deleteTodoSatuan = (idsatuan)=>(dispatch)=>{
    dispatch({
      type:'DELETE TODO SATUAN',
      payload:idsatuan
    })
  }
  export const errorMessageTodo = (message,setOpensnackbar) =>(dispatch)=>{
    dispatch({
      type:'FAILED ADD TODO SATUAN',
      message:message
    })
    setOpensnackbar(true)
  }
  export const deleteSatuanBarang = (idbarang,IdSatuanBarang,token,setOpensnackbar,setOpenDeleteDialog)=>(dispatch)=>{
    dispatch({
      type:'FETCH ACTION BARANG'
    })
    setTimeout(()=>{
      axios.delete(`${apiUrl.url}satuanbarang/${IdSatuanBarang}`,{
        headers:{
          "Authorization" : `Bearer ${token}`,
          'content-type':'application/x-www-form-urlencoded'
        }
      })
      .then(res=>{
        const ResponseApi = res.data;
        dispatch({
          type:'SUCCESSED ACTION BARANG',
          payload:ResponseApi.message
        })
        if(ResponseApi.message === 'Penghapusan data satuan barang berhasil'){
          setOpensnackbar(true);
          setOpenDeleteDialog(false);
        }else{
          setOpensnackbar(true);
          setOpenDeleteDialog(false);
        }
      })
      .catch(error=>{
          dispatch({
            type:'FAILED ACTION BARANG',
            payload:error.message
          })
          setOpensnackbar(true);
      })
    },2000)
  }
export const addNewSatuanBarang = (idbarang,data,token,setOpensnackbar)=>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION BARANG'
  })
  setTimeout(()=>{
    axios.post(`${apiUrl.url}satuanbarang/`,data,{
      headers:{
        "Authorization" : `Bearer ${token}`,
      }
    })
    .then(res=>{
      const ResponseApi = res.data;
      dispatch({
        type:'SUCCESSED ACTION BARANG',
        payload:ResponseApi.message
      })
      if(ResponseApi.message==='Penambahan data satuan barang berhasil'){
        setOpensnackbar(true);
        setTimeout(()=>{
          dispatch(getAllSatuanBarang(idbarang,token,setOpensnackbar))
          dispatch({
            type:'EMPTY TODO SATUAN'
          })
        },2000)
      }else{
        setOpensnackbar(true);
      }
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION BARANG',
          payload:error.message
        })
        setOpensnackbar(true);
    })
  },2000)
  
}
export const emptyToDoSatuan = () =>(dispatch) =>{
  dispatch({
    type:'EMPTY TODO SATUAN'
  })
}
export const getListBarangByDistributor = (iddist,search,token,status) =>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION BARANG'
  })
  if(status==='barang'){
    axios.get(`${apiUrl.url}barang/list/${iddist}/?search=${search}`,{
      headers:{
        "Authorization" : `Bearer ${token}`,
      }
    })
    .then((res)=>{
      const ResponseAPI=res.data;
      dispatch({
        type:'SUCCESSED GET DATA BARANG LIST',
        payload:ResponseAPI.data
      })
    })
    .catch(error=>{
      dispatch({
        type:'FAILED ACTION BARANG',
        payload:error.message
      })
    })
  }
}
export const emptyListBarang = () =>(dispatch) =>{
  dispatch({
    type:'EMPTY LIST BARANG'
  })
}

export const getDataBarcode = (data,token,setOpensnackbar) =>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION BARANG'
  })
  axios.post(`${apiUrl.url}satuanbarang/idbarang/`,data,{
    headers:{
      "Authorization" : `Bearer ${token}`,
    }
  })
  .then((res)=>{
    const ResponseAPI=res.data;
    dispatch({
      type:'SUCCESSED GET DATA BARCODE',
      payload:ResponseAPI.data,
      message:ResponseAPI.message
    })
  })
  .catch(error=>{
    dispatch({
      type:'FAILED ACTION BARANG',
      payload:error.message
    })
    setOpensnackbar(true);
  })
}
export const postDataBarcode = (data,token,setOpensnackbar) =>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION BARANG'
  })
  axios.post(`${apiUrl.url}satuanbarang/barcode/`,data,{
    headers:{
      "Authorization" : `Bearer ${token}`,
    }
  })
  .then((res)=>{
    const ResponseAPI=res.data;
    dispatch({
      type:'SUCCESSED POST DATA BARCODE',
      payload:ResponseAPI.message
    })
    setOpensnackbar(true);
  })
  .catch(error=>{
    dispatch({
      type:'FAILED ACTION BARANG',
      payload:error.message
    })
    setOpensnackbar(true);
  })
}
export const deleteDataBarcode = (data,token,setOpensnackbar,handleCloseDialog) =>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION BARANG'
  })
  axios.post(`${apiUrl.url}satuanbarang/barcode/`,data,{
    headers:{
      "Authorization" : `Bearer ${token}`,
    }
  })
  .then((res)=>{
    const ResponseAPI=res.data;
    dispatch({
      type:'SUCCESSED POST DATA BARCODE',
      payload:ResponseAPI.message
    })
    setOpensnackbar(true);
    handleCloseDialog();
  })
  .catch(error=>{
    dispatch({
      type:'FAILED ACTION BARANG',
      payload:error.message
    })
    setOpensnackbar(true);
  })
}
export const setBarangMessage = (message,setOpensnackbar)=>(dispatch)=>{
  dispatch({
    type: 'SET MESSAGE BARANG',
    payload:message
  })
  setOpensnackbar(true);
}
export const getBarangPPNByDist = (id,search,page,token,setOpensnackbar) =>(dispatch)=>{
  dispatch({
    type:'FETCH GET ALL BARANG',
  })
  const tempId = {
    'id':id
  }
  axios.post(`${apiUrl.url}barang/distppn/?search=${search}&page=${page}&perPage=50`,tempId,{
      headers:{
        "Authorization" : `Bearer ${token}`,
      }
    })
  .then(res=>{
      const ResponseAPI = res.data;
      dispatch({
        type:'UPDATE DATA PPN BARANG', 
        payload:ResponseAPI.data
     })
      dispatch({
        type:'UPDATE PAGE PPN BARANG',
        payload:{
          currentPage: ResponseAPI.current_page,
          totalPage: Math.ceil(ResponseAPI.totalData/ResponseAPI.per_page)
        }
      })
      
  })
  .catch(error=>{
    dispatch({
      type:'FAILED GET ALL BARANG',
      payload:error.message
    })
    setOpensnackbar(true);
  })
}
export const updateSinglePPN = (data,token,setOpensnackbar,handleCloseDialog)=>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION BARANG'
  })
  setTimeout(()=>{
    axios.post(`${apiUrl.url}barang/editPPNBarang`,data,{
      headers:{
        "Authorization" : `Bearer ${token}`,
      }
    })
    .then(res=>{
      const ResponseApi = res.data;
      dispatch({
        type:'SUCCESSED ACTION BARANG',
        payload:ResponseApi.message
      })
      setOpensnackbar(true);
      if(ResponseApi.message === 'Perubahan PPN barang berhasil'){
        setTimeout(()=>{
          handleCloseDialog();
        },500)
      }
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION BARANG',
          payload:error.message
        })
        setOpensnackbar(true);
    })
  },2000)
}

export const updateAllPPN = (data,token,setOpensnackbar,handleCloseDialog)=>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION BARANG'
  })
  setTimeout(()=>{
    axios.post(`${apiUrl.url}barang/EditPPNBarangByDist`,data,{
      headers:{
        "Authorization" : `Bearer ${token}`,
      }
    })
    .then(res=>{
      const ResponseApi = res.data;
      dispatch({
        type:'SUCCESSED ACTION BARANG',
        payload:ResponseApi.message
      })
      setOpensnackbar(true);
      if(ResponseApi.message === 'Perubahan PPN barang berhasil'){
        setTimeout(()=>{
          handleCloseDialog();
        },500)
      }
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION BARANG',
          payload:error.message
        })
        setOpensnackbar(true);
    })
  },2000)
}
