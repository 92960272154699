import axios from 'axios';
import { format } from 'date-fns';
import { apiUrl } from '../..';

export const getHargaJualTerakhir = (data,token,formik,setOpensnackbar,setOpenLastHarga,SetStatusRetur) =>(dispatch) =>{
    dispatch({
        type:'FETCH ACTION RETUR PENJUALAN'
    })
    axios.post(`${apiUrl.url}returpenjualan/getharga`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        if(ResponseAPI.message==='Berhasil mengambil data terakhir penjualan'){
            dispatch({
                type:'SUCCESSED GET HARGA TERAKHIR JUAL',
                payload:{
                    'PPN':ResponseAPI.data[0].PPN,
                    'Kelompok':ResponseAPI.data[0].Kelompok,
                    'Harga':ResponseAPI.data[0].Harga,
                },
                message:ResponseAPI.message
            })
            setOpenLastHarga(false);
            SetStatusRetur("Otomatis");
            formik.setFieldValue('harga',ResponseAPI.data[0].Harga)
        }else{
            dispatch({
                type:'SUCCESSED GET HARGA TERAKHIR JUAL',
                payload:"",
                message:ResponseAPI.message
            })
            SetStatusRetur("Manual")
            setOpenLastHarga(true);
            setOpensnackbar(true);
        }
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION RETUR PENJUALAN',
          payload:error.message
        })
        setOpensnackbar(true);
    })
}
export const setReturPenjualanMessage = (message) => (dispatch)=>{
    dispatch({
        type:'SET RETUR PENJUALAN MESSAGE',
        payload:message
    })
}
export const addReturPenjualan = (data,token,statusDraftRetur,fakturReturPelanggan) =>(dispatch)=>{
    if(statusDraftRetur==="No"){
        dispatch({
            type:'ADD RETUR PENJUALAN',
            payload:data
        })
    }else if(statusDraftRetur==="Yes"){
        const insertSingle={
            'tanggal':format(Date.now(),'yyyy-MM-dd'),
            'fakturreturpelanggan':fakturReturPelanggan,
            'idbarang':data.idbarang,
            'namabarang':data.namabarang,
            'idsatuan':data.idsatuan,
            'satuan':data.satuan,
            'kelompok':data.kelompok,
            'tempPPN':data.ppn,
            'harga':data.harga,
            'qty':data.qty,
            'jumlah':data.jumlah,
            'tipeharga':data.tiperetur
        }
        axios.post(`${apiUrl.url}returpenjualan/insertsingle`,insertSingle,{
            headers:{
                "Authorization" : `Bearer ${token}`,
            }
        })
        .then(res=>{
            const ResponseAPI=res.data;
            const newData={
                'id':data.id,
                'idreturpelanggandetails':ResponseAPI.idretur,
                'idbarang':data.idbarang,
                'namabarang':data.namabarang,
                'idsatuan':data.idsatuan,
                'satuan':data.satuan,
                'kelompok':data.kelompok,
                'ppn':data.ppn,
                'harga':data.harga,
                'qty':data.qty,
                'jumlah':data.jumlah,
                'tiperetur':data.tiperetur
            }
            dispatch({
                type:'ADD RETUR PENJUALAN',
                payload:newData
            })
        })
    }

}
export const addToCartReturPenjualan = (data,token,setOpensnackbar)=>(dispatch)=>{
    const editBooking={
        'idbarang':data.idbarang,
        'idsatuan':data.idsatuan,
        'qty':data.qty,
        'status':"Tambah"
    }
    axios.post(`${apiUrl.url}satuanbarang/singleeditbooking`,editBooking,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseAPI=res.data;
        dispatch({
            type:'SUCCESSED ACTION RETUR PENJUALAN',
            payload:ResponseAPI.message
        })
        })
        .catch(error=>{
        dispatch({
            type:'FAILED ACTION RETUR PENJUALAN',
            payload:error.message
        })
        setOpensnackbar(true);
    })
}
export const deleteItemTukarBarang = (data,token,setOpensnackbar) =>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION RETUR PENJUALAN'
      })
    const editBooking={
        'idbarang':data.idbarang,
        'idsatuan':data.idsatuan,
        'qty':data.qty,
        'status':"Kurang"
      }
      axios.post(`${apiUrl.url}satuanbarang/singleeditbooking`,editBooking,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
        })
      .then(res=>{
          const ResponseAPI=res.data;
          dispatch({
            type:'SUCCESSED ACTION RETUR PENJUALAN',
            payload:ResponseAPI.message
          })
        })
        .catch(error=>{
          dispatch({
            type:'FAILED ACTION RETUR PENJUALAN',
            payload:error.message
          })
          setOpensnackbar(true);
        })
}
export const setEmptyCartReturPenjualan = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY CART RETUR PENJUALAN'
    })
}
export const postReturPenjualan = (data,token,setOpensnackbar,setCheckRetur)=>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION RETUR PENJUALAN'
    })
    axios.post(`${apiUrl.url}returpenjualan/`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESSED POST RETUR PENJUALAN',
            payload:ResponseAPI.message
        })
        dispatch({
            type:'EMPTY CART RETUR PENJUALAN'
        })
        dispatch({
            type:'EMPTY ALL DATA RETUR PENJUALAN'
        })
        setOpensnackbar(true);
        setCheckRetur(false);
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION RETUR PENJUALAN',
          payload:error.message
        })
        setOpensnackbar(true);
    })
}

export const getDataReturPenjualan = (data,token,setOpensnackbar,handlePrint,handleClickBack)=>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION RETUR PENJUALAN'
    })
    axios.get(`${apiUrl.url}returpenjualan/${data.fakturpenjualan}`,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        axios.post(`${apiUrl.url}returpenjualan/dataretur/`,data,{
            headers:{
                "Authorization" : `Bearer ${token}`,
            }
        })
        .then(resDet=>{
            const ResponseAPIDet = resDet.data;
            dispatch({
                type:'SUCCESSED GET DATA RETUR PENJUALAN',
                payload:ResponseAPI.data[0],
                details:ResponseAPIDet.data
            })
            setTimeout(() => {
                handlePrint();
                handleClickBack();
            }, 1500);
        })
        .catch(error=>{
            dispatch({
            type:'FAILED ACTION RETUR PENJUALAN',
            payload:error.message
            })
            setOpensnackbar(true);
        })
    })
    .catch(error=>{
        dispatch({
        type:'FAILED ACTION RETUR PENJUALAN',
        payload:error.message
        })
        setOpensnackbar(true);
    })
}
export const getReportDataReturPenjualan = (data,token,setOpensnackbar)=>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION RETUR PENJUALAN'
    })
    axios.get(`${apiUrl.url}returpenjualan/${data.fakturpenjualan}`,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        axios.post(`${apiUrl.url}returpenjualan/dataretur/`,data,{
            headers:{
                "Authorization" : `Bearer ${token}`,
            }
        })
        .then(resDet=>{
            const ResponseAPIDet = resDet.data;
            dispatch({
                type:'SUCCESSED GET DATA RETUR PENJUALAN',
                payload:ResponseAPI.data[0],
                details:ResponseAPIDet.data
            })
        })
        .catch(error=>{
            dispatch({
            type:'FAILED ACTION RETUR PENJUALAN',
            payload:error.message
            })
            setOpensnackbar(true);
        })
    })
    .catch(error=>{
        dispatch({
        type:'FAILED ACTION RETUR PENJUALAN',
        payload:error.message
        })
        setOpensnackbar(true);
    })
}
export const getAllDataRetur = (data,token,setOpensnackbar)=>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION RETUR PENJUALAN'
    })
    axios.post(`${apiUrl.url}returpenjualan/getall`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESSED GET ALL DATA RETUR PENJUALAN',
            payload:ResponseAPI.data,
        })
    })
    .catch(error=>{
        dispatch({
        type:'FAILED ACTION RETUR PENJUALAN',
        payload:error.message
        })
        setOpensnackbar(true);
    })
}
export const setEmptyAddDataReturPenjualan = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY ALL DATA RETUR PENJUALAN'
    })
}
export const editLastHargaPenjualan = (data) =>(dispatch)=>{
    dispatch({
        type:'EDIT HARGA TERAKHIR JUAL',
        payload:data
    })
}

export const deleteReturPenjualan = (id) =>(dispatch)=>{
    dispatch({
        type:'DELETE RETUR PENJUALAN',
        payload:id
    })
}
export const delDraftReturDetails = (id,idreturpelanggandetails,token,setOpensnackbar) => (dispatch)=>{
    dispatch({
        type:'FETCH ACTION RETUR PENJUALAN'
    })
    const data = {
        'idreturpelanggandetails':idreturpelanggandetails
    }
    axios.post(`${apiUrl.url}returpenjualan/delReturDraft`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESSED DELETE DRAFT PENJUALAN DETIALS',
            payload:ResponseAPI.message
        })
        dispatch(deleteReturPenjualan(id));
    })
    .catch(error=>{
        dispatch({
        type:'FAILED ACTION RETUR PENJUALAN',
        payload:error.message
        })
        setOpensnackbar(true);
    })
}
export const transferDraftReturPenjualan = (data,token,setOpensnackbar,setCheckRetur)=>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION RETUR PENJUALAN'
    })
    axios.post(`${apiUrl.url}returpenjualan/transferfromdraft`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESSED POST RETUR PENJUALAN',
            payload:ResponseAPI.message
        })
        dispatch({
            type:'EMPTY CART RETUR PENJUALAN'
        })
        dispatch({
            type:'EMPTY ALL DATA RETUR PENJUALAN'
        })
        setOpensnackbar(true);
        setCheckRetur(false);
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION RETUR PENJUALAN',
          payload:error.message
        })
        setOpensnackbar(true);
    })
}
export const editReturPenjualan = (data,token,setOpensnackbar,setCheckRetur)=>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION RETUR PENJUALAN'
    })
    axios.post(`${apiUrl.url}returpenjualan/editStatus`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESSED POST RETUR PENJUALAN',
            payload:ResponseAPI.message
        })
        dispatch({
            type:'EMPTY CART RETUR PENJUALAN'
        })
        dispatch({
            type:'EMPTY ALL DATA RETUR PENJUALAN'
        })
        setOpensnackbar(true);
        setCheckRetur(false);
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION RETUR PENJUALAN',
          payload:error.message
        })
        setOpensnackbar(true);
    })
}