import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {Box,Button, CircularProgress, TextField, Snackbar, IconButton} from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { emptyBarangMessage, setBarangMessage, updateStokAwalBarang } from '../../../config/redux/actions';
import CloseIcon from '@mui/icons-material/Close';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const UbahSaldoDialog = ({handleCloseDialog,handleClick,openDialog,...rest}) => {
    const {FormEditStokAwal,messageBarang,isLoading} = useSelector(state=>state.barangReducers);
    const {dataLogin} = useSelector(state=>state.penggunaReducers);
    const [openSnackbar,setOpensnackbar]=useState(false);
    const [token,setToken] = useState(dataLogin.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const dispatch=useDispatch();
    const {idbarang,idsatuanbarang,stokawal,modalawal}=FormEditStokAwal;
    const schemaValidation = yup.object().shape({
        stokawal:yup.number().required("Stok awal tidak boleh kosong"),
        modalawal:yup.number().required("Modal awal tidak boleh kosong").min(0,"Stok awal tidak boleh kurang dari 0")
    })
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            stokawal:stokawal||'',
            modalawal:modalawal||'',
        },
        validationSchema:schemaValidation,
        onSubmit:(values)=>{
            if(values.stokawal>0 && values.modalawal<=0){
                dispatch(emptyBarangMessage(''));
                dispatch(setBarangMessage("Modal awal tidak boleh kosong",setOpensnackbar));
            }else if(values.stokawal>=0 && values.modalawal>=0){
                const data = new URLSearchParams();
                data.append('idsatuanbarang',idsatuanbarang);
                data.append('stokawal',values.stokawal);
                data.append('modalawal',values.modalawal);
                dispatch(updateStokAwalBarang(idbarang,data,token,setOpensnackbar,handleCloseDialog));
            }
        }
    })
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(emptyBarangMessage(''));
        }, 100);
    }
    return (
        <Dialog
            open={openDialog}
            {...rest}
        >
            <Box sx={{width:'100%',display:'flex',justifyContent:'flex-end'}}>
                <IconButton onClick={handleCloseDialog}>
                <CloseIcon size='small' sx={{color:'red'}}/>
                </IconButton>
            </Box>
            <DialogContent>
                <TextField
                id="stokawal"
                nama="stokawal"
                label="Stok Awal"
                variant="standard"
                sx={{width:'100px',marginRight:'10px'}}
                value={formik.values.stokawal}
                error={formik.touched.stokawal && Boolean(formik.errors.stokawal)}
                helperText={formik.touched.stokawal && formik.errors.stokawal}
                onChange={formik.handleChange}
                />
                <TextField
                id="modalawal"
                nama="modalawal"
                label="Modal Awal"
                variant="standard"
                sx={{width:'100px',marginRight:'10px'}}
                value={formik.values.modalawal}
                error={formik.touched.modalawal && Boolean(formik.errors.modalawal)}
                helperText={formik.touched.modalawal && formik.errors.modalawal}
                onChange={formik.handleChange}
                />
                <Box sx={{width:'100%',display:'flex',justifyContent:'center',marginTop:'20px'}}>
                    <Button variant="contained" color="primary" onClick={formik.handleSubmit} disabled={isLoading}>{isLoading === true ?  <CircularProgress color='primary' size={25}/> :"SIMPAN"}</Button>
                </Box>
            </DialogContent>
            <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={messageBarang === 'Perubahan saldo awal barang berhasil' ? "success" : "error"}
                >
                    {messageBarang}
                    </Alert>
            </Snackbar>
        </Dialog>
    )
}

export default UbahSaldoDialog
