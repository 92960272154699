import React
// , { useEffect, useState } 
from 'react'
import {Container, useTheme} from '@mui/material';
// import {UserMenuIcon,SupplierMenuIcon,CustomerMenuIcon,ItemMenuIcon,StokbarangMenuIcon,SatuanMenuIcon, PembelianMenuIcon, PPNMenuIcon, HargaMenuIcon, DaftarHargaMenuIcon, ReturMenuIcon, PenjualanMenuIcon} from '../../components/assets';
// import { CardMenu } from '../../components/molecules';
// import { useDispatch, useSelector } from 'react-redux';
// import Cookies from 'js-cookie';
// import { emptyFormDraft, emptyFormPenjualan, emptyPembelianForm, getDetailsPengguna, setClickMenu } from '../../config/redux/actions';
// import { Link } from 'react-router-dom';
// import { useHistory } from 'react-router-dom';

const MenuPage = () => {
    const theme = useTheme();
    const container ={
        [theme.breakpoints.up('sm')]:{
            width:'100%',
            height:'100vh',
            display:'flex',
            flexDirection:'column',
            paddingTop:'40px',
            // justifyContent:'center',
            alignItems:'center',
            // marginLeft:'2%',
            // marginRight:'2%',
            border:'1px solid black'
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center',
            marginTop:'80px',
            border:'1px solid black'
        }
    }
    // const paperList={
    //     [theme.breakpoints.up('sm')]:{
    //         width:'1050px',
    //         height:'140px',
    //         display:'flex',
    //         justifyContent:'center',
    //         marginTop:'20px',
    //     },
    //     [theme.breakpoints.down('sm')]:{
    //         width:'150px',
    //         height:'900px',
    //         display:'flex',
    //         flexDirection:'column',
    //         justifyContent:'center',
    //         marginTop:'20px'
    //     }
    // }
    // const {dataLogin,detailsPengguna} =useSelector(state=>state.penggunaReducers);
    // const [uid,setUid]  = useState(dataLogin.u_name);
    // const [token,setToken] = useState(dataLogin.token);
   
    // if(token ===''){
    //     setToken(Cookies.get('u_tkn'));
    //     setUid(Cookies.get('u_name'));
    // }
    // const dispatch = useDispatch();
    // const history = useHistory();
    // useEffect(()=>{
        
    // },[])
    
    return (
        <Container component='div' maxWidth='xl' sx={container}>
            Dashboard
            
        </Container>
    )
}

export default MenuPage
