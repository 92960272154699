import React, { useEffect, useState } from 'react'
import {Container,Typography,styled, useTheme,Divider,Box, TextField, TableContainer, Table, TableHead, TableRow, TableCell, tableCellClasses, Paper, TableBody, Tooltip, IconButton, Snackbar, Button, Autocomplete} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { emptyBarangMessage, getAllDistributorForCombo, getBarangPPNByDist, setBarangMessage } from '../../../config/redux/actions';
import MuiAlert from '@mui/material/Alert';
import { TablePagination } from '../../../components/molecules';
import { EditData } from '../../../components/assets';
import UbahPPNBarangDialog from '../../../components/molecules/UbahPPNBarangDialog';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'grey',
        color: theme.palette.common.black
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
const PerubahanPPN = () => {
    const theme = useTheme();
    const textBoxCss = {
        [theme.breakpoints.up('sm')]:{
            display:'flex',
            width:'20%',
            marginRight:'20px'
        }, 
        [theme.breakpoints.down('sm')]:{
            display:'flex',
            width:'100%',
        }
    }
    const paperTable = {
        marginTop:'10px',
        [theme.breakpoints.up('sm')]:{
            width:'75%',
            height:'370px',
        }, 
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            height:'360px'
        },
        scrollbarWidth:'thin'
    }
    const boxSearch = {
        [theme.breakpoints.up('sm')]:{
            display:'flex',
            justifyContent:'flex-end',
            width:'75%',
        }, 
        [theme.breakpoints.down('sm')]:{
            display:'flex',
            justifyContent:'flex-end',
            width:'100%',
        }
    }
  
  const [namaDist,setNamaDist]=useState('');
  const [valueID,setValueID]=useState('');
  const {dataLogin} =useSelector(state=>state.penggunaReducers);
  const {dataPPNBarang,pagePPNBarang,messageBarang} = useSelector(state=>state.barangReducers);
  const [token,setToken] = useState(dataLogin.token);
  const {dataComboDistributor}=useSelector(state=>state.distributorReducers);
  const [openSnackbar,setOpensnackbar]=useState(false);
  const [statusEditPPN,setStatusEditPPN] =useState("");
  const [openEditPPN,setOpenEditPPN] = useState(false);
  const [tempIdBarang,setTempIdBarang]=useState("");
  const [tempIdSatuan,setTempIdSatuan]=useState("");
  const [tempIdDist,setTempIdDist]=useState("");
  const [tempNamaDist,setTempNamaDist]=useState("");
  const [search,setSearch] = useState('');
  const [counter,setCounter] = useState(1);

  const dispatch = useDispatch();
  if(token ===''){
      setToken(Cookies.get('u_tkn'));
  }
  const handlePrev = () =>{
    setCounter(counter <= 1? 1 : counter - 1);
    }
    const handleNext = () =>{
        setCounter(counter === pagePPNBarang.totalPage ? pagePPNBarang.totalPage : counter + 1);
    }
  const schemaValidation = Yup.object().shape({
    iddistributor:Yup.string().required("Distributor belum dipilih"),
    kelompokpajak:Yup.string().required('Nama barang tidak boleh kosong'),
    perubahanby:Yup.string().required('Nama distributor belum dipilih'),
})
  const formik = useFormik({
      initialValues:{
          iddistributor:'',
          kelompokpajak:'',
          perubahanby:''
      },
      validationSchema:schemaValidation,
      onSubmit:(values)=>{
        
      }
  })
  useEffect(()=>{
    if(dataComboDistributor.length<1){
        dispatch(getAllDistributorForCombo("",token,setOpensnackbar));
    }
    dispatch(getBarangPPNByDist(valueID,search,counter,token,setOpensnackbar));
  },[dispatch,counter,search,token,dataComboDistributor])
  const onSearchDistributor = (e)=>{
    if(dataComboDistributor.length<1){
        dispatch(getAllDistributorForCombo(e.target.value,token,setOpensnackbar));
    }
  }
  const handleChangeSearch = (e) =>{
    setSearch(e.target.value);
    setCounter(1);
    dispatch(getBarangPPNByDist(valueID,e.target.value,counter,token,setOpensnackbar));
  }
  const openUbahPPNBarang = (idbarang,idsatuan)=>{
    setStatusEditPPN("Single");
    setTempIdBarang(idbarang);
    setTempIdSatuan(idsatuan);
    setOpenEditPPN(true);
  }
  const closeUbahPPNBarang = (e)=>{
      setStatusEditPPN("");
      setTempIdBarang("");
      setTempIdSatuan("");
      setTempIdDist("");
      setTempNamaDist("");
      setOpenEditPPN(false);
      dispatch(getBarangPPNByDist(valueID,search,counter,token,setOpensnackbar));
  }
  const openAllUbahPPNBarang = ()=>{
      if(namaDist===""){
        dispatch(setBarangMessage("Distributor belum dipilih",setOpensnackbar));
      }else{
        setStatusEditPPN("All");
        setTempIdDist(valueID);
        setTempNamaDist(namaDist);
        setOpenEditPPN(true);
      }
  }
const handleCloseSnackbar = (event,reason) =>{
    if(reason === 'clickaway'){
        return;
    }
    setOpensnackbar(false);
    setTimeout(() => {
        dispatch(emptyBarangMessage(''));
    }, 100);
}
  const tableBarang = (
    <TableBody>
        {dataPPNBarang.map((barangs) => (
            <StyledTableRow key={barangs.idsatuanbarang}>
            <StyledTableCell width={200} align="center">{barangs.idbarang}</StyledTableCell>
            <StyledTableCell width={200} align="center">{barangs.namabarang}</StyledTableCell>
            <StyledTableCell width={200} align="center" key={barangs.iddistributor}>{barangs.nama}</StyledTableCell>
            <StyledTableCell width={150} align="center" key={barangs.idsatuan}>{barangs.satuan}</StyledTableCell>
            <StyledTableCell width={150} align="center">{barangs.kelompok}</StyledTableCell>
            <StyledTableCell width={60} align="center">
                <Tooltip title="Ubah Kelompok" >
                    <IconButton 
                    onClick={()=>openUbahPPNBarang(barangs.idbarang,barangs.idsatuan)}
                    >
                        <img src={EditData} alt="editData"/>
                    </IconButton>
                 </Tooltip>
            </StyledTableCell>
            </StyledTableRow>
        ))}
    </TableBody>
)
  return (
    <Container maxWidth='xl' sx={{marginTop:'100px'}}>
    <Typography variant='h6' pl={1} mt={1}>Perubahan Data PPN</Typography>
    <Divider/>
    <Box sx={{marginTop:'5px',width:'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
    <Box sx={boxSearch}>
    <Autocomplete
        id="iddistributor"
        selectOnFocus
        name='iddistributor'
        label="Distributor"
        variant="standard"
        sx={textBoxCss}
        options= {dataComboDistributor}
        getOptionLabel= {(option) => option.nama}
        isOptionEqualToValue={(option,value)=>option.id===value.id}
        onChange={(event, newValue) => {
                if(newValue!==null){
                    formik.setFieldValue('iddistributor',newValue.iddistributor);
                    setValueID(newValue.iddistributor);
                    setNamaDist(newValue.nama);
                    dispatch(getBarangPPNByDist(newValue.iddistributor,"",counter,token,setOpensnackbar));
                    setCounter(1);
                }else{
                    formik.setFieldValue('iddistributor','');
                    setValueID("");
                    setNamaDist("");
                    dispatch(getBarangPPNByDist("","",counter,token,setOpensnackbar));
                    setCounter(1);
                }
        }}
        renderInput={(params)=><TextField
            id='iddistributor'
            name='iddistributor'
            variant='standard' 
            {...params} 
            label="Distributor"
            value={formik.values.iddistributor}
            error={formik.touched.iddistributor && Boolean(formik.errors.iddistributor)}
            helperText={formik.touched.iddistributor && formik.errors.iddistributor}
            onChange={(e)=>onSearchDistributor(e)}
            />}
        />
            <TextField
                id='search'
                name='search'
                label='Cari Barang'
                variant='standard'
                onChange={(e)=>handleChangeSearch(e)}
                />
            </Box>
            <TableContainer component={Paper} sx={paperTable}>
                <Table stickyHeader aria-label="customized table" size='small'>
                    <TableHead>
                    <TableRow>
                        <StyledTableCell width={200} align="center">ID Barang</StyledTableCell>
                        <StyledTableCell width={200} align="center">Nama Barang</StyledTableCell>
                        <StyledTableCell width={200} align="center">Distributor</StyledTableCell>
                        <StyledTableCell width={150} align="center">Satuan</StyledTableCell>
                        <StyledTableCell width={150} align="center">Kelompok</StyledTableCell>
                        <StyledTableCell width={60} align="center"/>
                    </TableRow>
                    </TableHead>
                        {tableBarang} 
                </Table>
            </TableContainer>
            <Box sx={boxSearch}>
            <TablePagination
            page={counter}
            totalPage={pagePPNBarang.totalPage >= 1 ? pagePPNBarang.totalPage : pagePPNBarang.currentPage}
            handlePrev={handlePrev}
            handleNext={handleNext}
            hiddenButton={true}
            />
            </Box>
            <Button variant='contained' color='primary' onClick={()=>openAllUbahPPNBarang()}>Ubah untuk semua barang</Button>
            </Box>
            <Snackbar 
            open={openSnackbar} 
            autoHideDuration={2000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={messageBarang === 'Perubahan kenaikan harga berhasil' ? 'success':'error'}
                >
                    {messageBarang}
                    </Alert>
            </Snackbar>
        <UbahPPNBarangDialog 
        openEditPPNDialog={openEditPPN} 
        closeEditPPNDialog={closeUbahPPNBarang} 
        valueStatusEditPPN={statusEditPPN} 
        tempIdBarang={tempIdBarang}
        tempIdSatuan={tempIdSatuan}
        tempIdDist={tempIdDist}
        tempNamaDist={tempNamaDist}/>
    </Container>
  )
}

export default PerubahanPPN