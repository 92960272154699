import axios from 'axios';
import Cookies from 'js-cookie';
import { apiUrl } from '../..';

export const getCountUser = ()=>(dispatch)=>{
  axios.get(`${apiUrl.url}pengguna/total/`)
  .then(res=>{
    const ResponseApi = res.data;
    dispatch({
      type:'GET TOTAL USER',
      payload:ResponseApi.data
    })
  })
  .catch(err=>{
    console.log(err);
  })
}

export const loginUser = (data,history,setOpensnackbar) => (dispatch) =>{
    dispatch({
      type:'FETCH LOGIN',
      payload:true
    })
    setTimeout(()=>{
      axios.post(`${apiUrl.url}pengguna/login/`,data)
      .then(res=>{ 
        const ResponseApi = res.data;
          if(res){
            if(ResponseApi.Login === true){
              dispatch({
                type:'LOGIN SUCCESSED',
                payload:{
                  nama:ResponseApi.data.nama,
                  u_name : ResponseApi.data.idpengguna,
                  token:ResponseApi.data.token,
                },
                message:ResponseApi.message
              })
              setOpensnackbar(true);
              Cookies.set('u_tkn',ResponseApi.data.token,{expires:1,path:'/'});
              Cookies.set('nama',ResponseApi.data.nama,{expires:1,path:'/'});
              Cookies.set('u_name',ResponseApi.data.idpengguna,{expires:1,path:'/'});
              dispatch(getDetailsPengguna(ResponseApi.data.idpengguna,ResponseApi.data.token,""));
              history.push('/');
            }else if(ResponseApi.Login === false){
              dispatch({
                type:'LOGIN FAILED',
                payload:{
                  nama:'',
                  username : ''
                },
                message:ResponseApi.message
              })
              setOpensnackbar(true);
              localStorage.removeItem('token');
            }
          }
      })
      .catch(error=>{
        if(error.response){
          if(error.response.status === 401){
            dispatch({
                type:'LOGIN FAILED',
                payload:{
                  nama:'',
                  username : ''
                },
                message:'ID Pengguna atau Password salah'
              })
              setOpensnackbar(true);
              localStorage.removeItem('token');
          }else if(error.response.status === 404){
            dispatch({
                type:'LOGIN FAILED',
                payload:{
                  nama:'',
                  username : ''
                },
                message:'ID Pengguna atau Password salah'
              })
              setOpensnackbar(true);
              localStorage.removeItem('token');
          }
          else if(error.response.status === 500){
            dispatch({
                type:'LOGIN FAILED',
                payload:{
                  nama:'',
                  username : ''
                },
                message:error.message
              })
              setOpensnackbar(true);
              localStorage.removeItem('token');
          }
        }else if(error.request){
          dispatch({
              type:'LOGIN FAILED',
              payload:{
                nama:'',
                username : ''
              },
              message:error.message,
          })
          setOpensnackbar(true);
          localStorage.removeItem('token');
      }
      })
    },2000)
      
  }
  export const otoritasUser = (data,setOpensnackbar,validationSuccess) => (dispatch) =>{
    dispatch({
      type:'FETCH LOGIN',
      payload:true
    })
    setTimeout(()=>{
      axios.post(`${apiUrl.url}pengguna/otoritas/`,data)
      .then(res=>{ 
        const ResponseApi = res.data;
          if(res){
            if(ResponseApi.Login === true){
              dispatch({
                type:'OTORITAS SUCCESSED',
                message:ResponseApi.message
              })
              setOpensnackbar(true);
              setTimeout(()=>{
                validationSuccess();
                dispatch({
                  type:'EMPTY USER MESSAGE',
                  payload:""
                })
              },1000)
            }else if(ResponseApi.Login === false){
              dispatch({
                type:'LOGIN FAILED',
                message:ResponseApi.message
              })
              setOpensnackbar(true);
            }
          }
      })
      .catch(error=>{
        if(error.response){
          if(error.response.status === 401){
            dispatch({
                type:'LOGIN FAILED',
                message:"Akses otoritas ditolak"
              })
              setOpensnackbar(true);
          }else if(error.response.status === 404){
            dispatch({
                type:'LOGIN FAILED',
                message:"Akses otoritas ditolak"
              })
              setOpensnackbar(true);
          }
          else if(error.response.status === 500){
            dispatch({
                type:'LOGIN FAILED',
                payload:{
                  nama:'',
                  username : ''
                },
                message:error.message
              })
              setOpensnackbar(true);
          }
        }else if(error.request){
          dispatch({
              type:'LOGIN FAILED',
              payload:{
                nama:'',
                username : ''
              },
              message:error.message,
          })
          setOpensnackbar(true);
      }
      })
    },2000)
      
  }
export const createNewUser = (data,setOpenSnackbar,history) =>(dispatch)=>{
  axios.post(`${apiUrl.url}pengguna/firstuser`,data,{
    headers:{
      'content-type':'application/x-www-form-urlencoded'
    }
  })
  .then(res=>{
    const ResponseAPI = res.data;
    if(ResponseAPI.message==='Pengguna telah terdaftar'){
      dispatch({
        type:'SUCCESSED ADD NEW PENGGUNA',
        payload:ResponseAPI.message
      })
      setOpenSnackbar(true);
    }else if(ResponseAPI.message==='Berhasil mendaftarkan pengguna baru'){
      dispatch({
        type:'SUCCESSED ADD NEW PENGGUNA',
        payload:ResponseAPI.message
      })
      setOpenSnackbar(true);
      setTimeout(()=>{
        dispatch(getCountUser());
        history.push('/login');
      },2000)
    }
  })
  .catch(error=>{
    console.log(error);
    const ResponseAPI = error.data;
    dispatch({
      type:'FAILED ADD NEW PENGGUNA',
      payload:ResponseAPI.message
    })
    setOpenSnackbar(true);
  })
}
export const emptyUserMessage = (message) =>(dispatch)=>{
  dispatch({
    type:'EMPTY USER MESSAGE',
    payload:message
  })
}
export const emptyUserLogin = () =>(dispatch)=>{
  dispatch({
    type:'EMPTY USER LOGIN',
    payload:{
      nama:"",
      u_name : "",
      token:"",
    }
  })
}

export const emptyUserDetails = () =>(dispatch)=>{
  dispatch({
    type:'EMPTY PENGGUNA DETAILS'
  })
}
export const getAllPengguna = (search,page,token,setOpensnackbar) =>(dispatch)=>{
  dispatch({
    type:'FETCH GET ALL PENGGUNA',
  })
  axios.get(`${apiUrl.url}pengguna/?search=${search}&page=${page}&perPage=50`,{
        headers:{
          "Authorization" : `Bearer ${token}`
        }
    })
  .then(res=>{
      const ResponseAPI = res.data;
      dispatch({
        type:'UPDATE DATA PENGGUNA', 
        payload:ResponseAPI.data
     })
      dispatch({
        type:'UPDATE PAGE PENGGUNA',
        payload:{
          currentPage: ResponseAPI.current_page,
          totalPage: Math.ceil(ResponseAPI.totalData/ResponseAPI.per_page)
        }
      })
  })
  .catch(error=>{
    dispatch({
      type:'FAILED GET ALL PENGGUNA',
      payload:error.message
    })
    setOpensnackbar(true);
  })
}

export const addNewPengguna = (data,token,setOpensnackbar,history)=>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION PENGGUNA'
  })
  setTimeout(()=>{
    axios.post(`${apiUrl.url}pengguna/new`,data,{
      headers:{
        "Authorization" : `Bearer ${token}`,
        'content-type':'application/x-www-form-urlencoded'
      }
    })
    .then(res=>{
      const ResponseApi = res.data;
      dispatch({
        type:'SUCCESSED ACTION PENGGUNA',
        payload:ResponseApi.message
      })
      if(ResponseApi.message==='Berhasil mendaftarkan pengguna baru'){
        setOpensnackbar(true);
        setTimeout(()=>{
          dispatch(getAllPengguna('',1,token,setOpensnackbar));
          history.push('/pengguna');
        },2000)
      }else{
        setOpensnackbar(true);
      }
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION PENGGUNA',
          payload:error.message
        })
        setOpensnackbar(true);
    })
  },2000)
  
}

export const deletePengguna = (idpengguna,token,setOpensnackbar,search,counter,setOpenDialog)=>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION PENGGUNA'
  })
  setTimeout(()=>{
    axios.delete(`${apiUrl.url}pengguna/${idpengguna}`,{
      headers:{
        "Authorization" : `Bearer ${token}`,
      }
    })
    .then(res=>{
      const ResponseApi = res.data;
      dispatch({
        type:'SUCCESSED ACTION PENGGUNA',
        payload:ResponseApi.message
      })
      if(ResponseApi.message === 'Penghapusan data pengguna berhasil'){
        dispatch(getAllPengguna(search,counter,token,''));
        setOpensnackbar(true);
        setOpenDialog(false);
      }else{
        setOpensnackbar(true);
      }
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION PENGGUNA',
          payload:error.message
        })
        setOpensnackbar(true);
    })
  })
}

export const getDetailsPengguna = (idpengguna,token,setOpensnackbar,history)=>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION PENGGUNA'
  })
  axios.get(`${apiUrl.url}pengguna/${idpengguna}`,{
    headers:{
      "Authorization" : `Bearer ${token}`,
    }
  })
  .then(res=>{
    const ResponseApi = res.data;
    dispatch({
      type:'SUCCESSED GET PENGGUNA DETAILS',
      payload:{
          'nama':ResponseApi.data.nama,
          'hakakses':ResponseApi.data.hakakses,
          'm_pengguna':ResponseApi.data.m_pengguna,
          'm_pelanggan':ResponseApi.data.m_pelanggan,
          'm_distributor':ResponseApi.data.m_distributor,
          'm_stokbarang':ResponseApi.data.m_stokbarang,
          'm_barang':ResponseApi.data.m_barang,
          'm_harga':ResponseApi.data.m_harga,
          'm_pembelian':ResponseApi.data.m_pembelian,
          'm_penjualan':ResponseApi.data.m_penjualan,
          'm_retur':ResponseApi.data.m_retur,
          'm_laporan':ResponseApi.data.m_laporan,
      },
    })
  })
  .catch(error=>{
    if(error.response.status === 400){
      dispatch({
        type:'EMPTY PENGGUNA DETAILS'
      })
      Cookies.remove('u_name');
      Cookies.remove('u_tkn');
      dispatch({
        type:'EMPTY USER LOGIN',
        payload:{
          nama:"",
          u_name : "",
          token:"",
        }
      })
      history.push('/login');
    }else{
      dispatch({
        type:'FAILED ACTION PENGGUNA',
        payload:error.message
      })
      setOpensnackbar(true);
    }
  })
}

export const updatePengguna = (idpengguna,data,token,setOpensnackbar,history)=>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION PENGGUNA'
  })
  setTimeout(()=>{
    axios.patch(`${apiUrl.url}pengguna/${idpengguna}`,data,{
      headers:{
        "Authorization" : `Bearer ${token}`,
        'content-type':'application/x-www-form-urlencoded'
      }
    })
    .then(res=>{
      const ResponseApi = res.data;
      dispatch({
        type:'SUCCESSED ACTION PENGGUNA',
        payload:ResponseApi.message
      })
      if(ResponseApi.message==='Perubahan data pengguna berhasil'){
        setOpensnackbar(true);
        setTimeout(()=>{
          dispatch(getAllPengguna('',1,token,setOpensnackbar));
          history.push('/pengguna');
        },1000)
      }else{
        setOpensnackbar(true);
      }
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION PENGGUNA',
          payload:error.message
        })
        setOpensnackbar(true);
    })
  },2000)
}

export const ubahPassword = (iduser,data,token,setOpensnackbar,formik) =>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION PENGGUNA'
  })
  setTimeout(()=>{
    axios.patch(`${apiUrl.url}pengguna/password/${iduser}`,data,{
      headers:{
        "Authorization" : `Bearer ${token}`,
        'content-type':'application/x-www-form-urlencoded'
      }
    })
    .then(res=>{
        const ResponseApi = res.data;
        dispatch({
          type:'SUCCESSED ACTION PENGGUNA',
          payload:ResponseApi.message
        })
        setOpensnackbar(true);
        formik.handleReset();
    })
    .catch(error=>{
      dispatch({
        type:'FAILED ACTION PENGGUNA',
        payload:error.message
      })
      setOpensnackbar(true);
    })
  },2000)
}