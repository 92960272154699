import axios from 'axios';
import { apiUrl } from '../..';

export const getLastStokBarang = (data,token)=>(dispatch)=>{
    dispatch({
        type:'ACTION STOK BARANG'
    })
    axios.post(`${apiUrl.url}stokbarang/laststok`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`
          }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESSED GET LAST STOK BARANG',
            payload:{
              'stokbarang':ResponseAPI.data[0].stokbarang,
              'kelompok':ResponseAPI.data[0].kelompok,
              'modalbarang':ResponseAPI.data[0].modalbarang,
              'saldo':ResponseAPI.data[0].saldo
            }
          })
    })
    .catch(error=>{
        dispatch({
          type:'FAILED GET LAST STOK BARANG',
          payload:error.message
        })
      })
}
export const getAllStokBarang = (search,page,token,setOpensnackbar) =>(dispatch)=>{
  dispatch({
    type:'ACTION STOK BARANG'
  })
  axios.get(`${apiUrl.url}stokbarang/?search=${search}&page=${page}&perPage=50`,{
    headers:{
      "Authorization" : `Bearer ${token}`
    }
  })
  .then(res=>{
    const ResponseAPI = res.data;
    dispatch({
      type:'UPDATE DATA STOK BARANG',
      payload:ResponseAPI.data
    })
    dispatch({
      type:'UPDATE PAGE STOK BARANG',
      payload:{
        currentPage: ResponseAPI.current_page,
        totalPage: Math.ceil(ResponseAPI.totalData/ResponseAPI.per_page)
      }
    })
  }) 
  .catch(error=>{
    dispatch({
      type:'FAILED GET ALL STOK BARANG',
      payload:error.message
    })
    setOpensnackbar(true);
  })
}
export const setStokBarangMessage = (message)=>(dispatch)=>{
  dispatch({
    type:'SET MESSAGE STOK BARANG',
    payload:message
  })
}
export const getRincianStokBarang = (data,page,token,setOpensnackbar) =>(dispatch)=>{
  dispatch({
    type:'ACTION STOK BARANG'
  })
  axios.post(`${apiUrl.url}stokbarang/periode/?page=${page}&perPage=50`,data,{
    headers:{
      "Authorization" : `Bearer ${token}`
    }
  })
  .then(res=>{
    const ResponseAPI = res.data;
    dispatch({
      type:'UPDATE DATA RINCIAN STOK BARANG',
      payload:ResponseAPI.data
    })
    dispatch({
      type:'UPDATE PAGE RINCIAN STOK BARANG',
      payload:{
        currentPageRincian: ResponseAPI.current_page,
        totalPageRincian: Math.ceil(ResponseAPI.totalData/ResponseAPI.per_page)
      }
    })
  }) 
  .catch(error=>{
    dispatch({
      type:'FAILED GET RINCIAN STOK BARANG',
      payload:error.message
    })
    setOpensnackbar(true);
  })
}
export const getListTahun = (token,setOpensnackbar) =>(dispatch)=>{
  dispatch({
    type:'ACTION STOK BARANG'
  })
  axios.get(`${apiUrl.url}stokbarang/listtahun`,{
    headers:{
      "Authorization" : `Bearer ${token}`
    }
  })
  .then(res=>{
    const ResponseAPI = res.data;
    dispatch({
      type:'SUCCESSED GET LIST TAHUN',
      payload:ResponseAPI.data
    })
  }) 
  .catch(error=>{
    dispatch({
      type:'FAILED ACTION STOK BARANG',
      payload:error.message
    })
    setOpensnackbar(true);
  })
}
export const getRincianStokBarangByTgl = (data,page,token,setOpensnackbar) =>(dispatch)=>{
  dispatch({
    type:'ACTION STOK BARANG'
  })
  axios.post(`${apiUrl.url}stokbarang/tanggal/?page=${page}&perPage=50`,data,{
    headers:{
      "Authorization" : `Bearer ${token}`
    }
  })
  .then(res=>{
    const ResponseAPI = res.data;
    dispatch({
      type:'UPDATE DATA RINCIAN STOK BARANG',
      payload:ResponseAPI.data
    })
    dispatch({
      type:'UPDATE PAGE RINCIAN STOK BARANG',
      payload:{
        currentPageRincian: ResponseAPI.current_page,
        totalPageRincian: Math.ceil(ResponseAPI.totalData/ResponseAPI.per_page)
      }
    })
  }) 
  .catch(error=>{
    dispatch({
      type:'FAILED GET RINCIAN STOK BARANG',
      payload:error.message
    })
    setOpensnackbar(true);
  })
}
export const emptyListRincianStokBarang = () =>(dispatch)=>{
  dispatch({
    type:'EMPTY LIST RINCIAN STOK BARANG'
  })
} 

export const editStokAkhirBarang = (data,token,setOpensnackbar)=>(dispatch)=>{
  dispatch({
    type:'ACTION STOK BARANG'
  })
  axios.post(`${apiUrl.url}stokbarang/updatestok`,data,{
    headers:{
      "Authorization" : `Bearer ${token}`
    }
  })
  .then(res=>{
    const ResponseAPI = res.data;
    dispatch({
      type:'SUCCESSED EDIT STOK AKHIR',
      payload:ResponseAPI.message
    })
    setOpensnackbar(true);
  }) 
  .catch(error=>{
    dispatch({
      type:'FAILED ACTION STOK BARANG',
      payload:error.message
    })
    setOpensnackbar(true);
  })
}
export const addStateIdSatuanBarang = (data) => (dispatch)=>{
  dispatch({
    type:'GET ID SATUAN BARANG',
    payload : data
  })
}
export const emptyStateIdSatuanBarang = () => (dispatch)=>{
  dispatch({
    type:'EMPTY ID SATUAN BARANG'
  })
}
export const getRincianStokBarangByAll = (data,page,token,setOpensnackbar) =>(dispatch)=>{
  dispatch({
    type:'ACTION STOK BARANG'
  })
  axios.post(`${apiUrl.url}stokbarang/all/?page=${page}&perPage=50`,data,{
    headers:{
      "Authorization" : `Bearer ${token}`
    }
  })
  .then(res=>{
    const ResponseAPI = res.data;
    dispatch({
      type:'UPDATE DATA RINCIAN STOK BARANG',
      payload:ResponseAPI.data
    })
    dispatch({
      type:'UPDATE PAGE RINCIAN STOK BARANG',
      payload:{
        currentPageRincian: ResponseAPI.current_page,
        totalPageRincian: Math.ceil(ResponseAPI.totalData/ResponseAPI.per_page)
      }
    })
  }) 
  .catch(error=>{
    dispatch({
      type:'FAILED GET RINCIAN STOK BARANG',
      payload:error.message
    })
    setOpensnackbar(true);
  })
}
export const getLastStokBarangKoreksi = (data,token)=>(dispatch)=>{
  dispatch({
      type:'ACTION STOK BARANG'
  })
  dispatch({
    type:'EMPTY LAST STOK BARANG KOREKSI'
  })
  axios.post(`${apiUrl.url}stokbarang/laststok`,data,{
      headers:{
          "Authorization" : `Bearer ${token}`
        }
  })
  .then(res=>{
      const ResponseAPI = res.data;
      dispatch({
          type:'SUCCESSED GET LAST STOK BARANG KOREKSI',
          payload:{
            'stokbarang':ResponseAPI.data[0].stokbarang,
            'kelompok':ResponseAPI.data[0].kelompok,
            'modalbarang':ResponseAPI.data[0].modalbarang,
            'saldo':ResponseAPI.data[0].saldo,
            'bookingstok':ResponseAPI.data[0].bookingstok
          }
        })
  })
  .catch(error=>{
      dispatch({
        type:'FAILED GET LAST STOK BARANG',
        payload:error.message
      })
    })
}
export const postKoreksiStokBarang = (data,token,setOpensnackbar,handleCloseDialog)=>(dispatch)=>{
  dispatch({
      type:'ACTION STOK BARANG'
  })
  axios.post(`${apiUrl.url}stokbarang/koreksi`,data,{
      headers:{
          "Authorization" : `Bearer ${token}`
        }
  })
  .then(res=>{
      const ResponseAPI = res.data;
      dispatch({
          type:'SUCCESSED KOREKSI STOK BARANG',
          payload:ResponseAPI.message
        })
      setOpensnackbar(true);
      setTimeout(()=>{
        handleCloseDialog();
      },2000)
  })
  .catch(error=>{
      dispatch({
        type:'FAILED ACTION STOK BARANG',
        payload:error.message
      })
    })
}
export const syncBookingStok = (token,setOpensnackbar)=>(dispatch)=>{
  dispatch({
    type:'ACTION STOK BARANG'
  })
  axios.get(`${apiUrl.url}sync/syncbookingstok`,{
    headers:{
        "Authorization" : `Bearer ${token}`
      }
  })
  .then(res=>{
    const ResponseAPI = res.data;
      dispatch({
          type:'SUCCESSED KOREKSI STOK BARANG',
          payload:ResponseAPI.message
      })
      setOpensnackbar(true);
  })
  .catch(error=>{
    dispatch({
      type:'FAILED ACTION STOK BARANG',
      payload:error.message
    })
    setOpensnackbar(true);
  })
}