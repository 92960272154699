const stokreturInitalValue = {
    isLoading:false,
    dataStokRetur:[],
    pageStokRetur : {
        currentPage:'1',
        totalPage:'1'
    },
    stokreturMessage:'',
    satuanStokRetur:[]
}

const stokreturReducers = (state=stokreturInitalValue,action)=>{
     switch(action.type){
        case 'FECTH ACTION STOK RETUR':{
             return{
                 ...state,
                 isLoading:true
             }
         }
        case 'EMPTY STOK RETUR MESSAGE':{
            return{
                ...state,
                stokreturMessage:action.payload
            }
        }
        case 'EMPTY LIST STOK RETUR':{
            return{
                ...state,
                isLoading:false,
                dataStokRetur:[]
            }
        } 
        case 'FAILED GET DATA STOK RETUR':{
            return{
                ...state,
                stokreturMessage:action.message
            }
        }
        case 'UPDATE PAGE STOK RETUR':{
            return {
                ...state,
                pageStokRetur:action.payload
            }
        }
        case 'UPDATE DATA STOK RETUR':{
            return{
                ...state,
                isLoading:false,
                dataStokRetur:action.payload
            }
        }
        case 'SUCCESSED GET SATUAN STOK RETUR':{
            return{
                ...state,
                isLoading:false,
                satuanStokRetur:action.payload
            }
        }
        case 'SUCCESSED EDIT STOK RETUR':{
            return{
                ...state,
                isLoading:false,
                stokreturMessage:action.payload
            }
        }
        case 'EMPTY SATUAN STOK RETUR':{
            return{
                ...state,
                isLoading:false,
                satuanStokRetur:[]
            }
        }
        default: return state
    }
}

export default stokreturReducers;