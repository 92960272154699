import { Button, Dialog, DialogContent, Divider, IconButton, Snackbar, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import { useDispatch, useSelector } from 'react-redux';
import MuiAlert from '@mui/material/Alert';
import { emptyUserMessage, ubahPassword } from '../../../config/redux/actions';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const UbahPassword = ({openPasswordDialog,handleClosePasswordDialog,iduser,...rest}) => {
    
const [showPasswordBaru,setShowPasswordBaru]=useState(false);
const [showUlangPassword,setShowUlangPassword]=useState(false);
const {dataLogin,penggunaMessage} = useSelector(state=>state.penggunaReducers);
const [openSnackbar,setOpensnackbar]=useState(false);
const [token,setToken] = useState(dataLogin.token);
if(token ===''){
    setToken(Cookies.get('u_tkn'));
}
const handleClickShowPassword = () =>{
    setShowPasswordBaru(!showPasswordBaru);
}
const handleClickShowUlangPassword = () =>{
    setShowUlangPassword(!showUlangPassword);
}
const dispatch=useDispatch();
const schemaValidation = yup.object().shape({
    password:yup.string().required("Password tidak boleh kosong").matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        "Kata sandi harus terdiri dari 8 karakter, 1 huruf besar, 1 huruf kecil, 1 nomor dan 1 karakter spesial"
      ),
    ulangpassword:yup.string()
    .oneOf([yup.ref('password'), null], 'Ulang kata sandi tidak sesuai')
})
const formik = useFormik({
    initialValues:{
        password:'',
        ulangpassword:''
    },
    validationSchema:schemaValidation,
    onSubmit:(values)=>{
        const data = new URLSearchParams();
        data.append('newPassword',values.password);
        dispatch(ubahPassword(iduser,data,token,setOpensnackbar,formik));
    }
})
const handleCloseSnackbar = (event,reason) =>{
    if(reason === 'clickaway'){
        return;
    }
    setOpensnackbar(false);
    setTimeout(() => {
        dispatch(emptyUserMessage(''));
        handleClosePasswordDialog();
        formik.handleReset();
    }, 100);
}
const handleClose = () =>{
    handleClosePasswordDialog();
    formik.handleReset();
}
  return (
    <Dialog open={openPasswordDialog} {...rest}>
        <DialogContent>
            <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:"center"}}>
                <Typography variant='h6'>Ubah Password Pengguna</Typography>
                <Divider sx={{width:'100%'}}/>
                <Box sx={{width:'100%',display:'flex',alignItems:"flex-end"}}>
                    <TextField
                    id="password"
                    name="password"
                    label="Password Baru"
                    variant="standard"
                    type={showPasswordBaru ? 'text':'password'}
                    style={{width:'80%',marginTop:'10px'}}
                    value={formik.values.password}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    onChange={formik.handleChange}
                    />
                    <IconButton 
                    arial-label='toggle-password'
                    onClick={handleClickShowPassword}
                    >
                    {showPasswordBaru?<Visibility/>:<VisibilityOff/>}
                    </IconButton>
                </Box>
                <Box sx={{width:'100%',display:'flex',alignItems:"flex-end"}}>
                <TextField
                id="ulangpassword"
                name="ulangpassword"
                label="Ulangi Password"
                type={showUlangPassword ? 'text':'password'}
                variant="standard"
                style={{width:'80%',marginTop:'10px'}}
                value={formik.values.ulangpassword}
                error={formik.touched.ulangpassword && Boolean(formik.errors.ulangpassword)}
                helperText={formik.touched.ulangpassword && formik.errors.ulangpassword}
                onChange={formik.handleChange}
                />
                    <IconButton 
                    arial-label='toggle-password'
                    onClick={handleClickShowUlangPassword}
                    >
                    {showUlangPassword?<Visibility/>:<VisibilityOff/>}
                    </IconButton>
                </Box>
            </Box>
            <Box sx={{display:'flex',justifyContent:'space-between',marginTop:'10px'}}>
                <Button variant='contained' color="primary" onClick={formik.handleSubmit}>Simpan</Button>
                <Button variant='contained' color="error" onClick={handleClose}>Batal</Button>
            </Box>
        </DialogContent>    
        <Snackbar 
            open={openSnackbar} 
            autoHideDuration={1000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={penggunaMessage === 'Perubahan password berhasil' ? "success" : "error"}
                >
                    {penggunaMessage}
                    </Alert>
            </Snackbar>
    </Dialog>
  )
}

export default UbahPassword