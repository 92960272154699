import axios from 'axios';
import { apiUrl } from '../..';

export const getDataPPN = (data,page,token,setOpensnackbar)=>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PPN'
    })
    axios.post(`${apiUrl.url}ppn/?page=${page}&perPage=50`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`
          }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
          type:'UPDATE DATA PPN', 
          payload:ResponseAPI.data
       })
        dispatch({
          type:'UPDATE PAGE PPN',
          payload:{
            currentPage: ResponseAPI.current_page,
            totalPage: Math.ceil(ResponseAPI.totalData/ResponseAPI.per_page)
          }
        })
        
    })
    .catch(error=>{
      dispatch({
        type:'FAILED GET DATA PPN',
        payload:error.message
      })
      setOpensnackbar(true);
    })
}

export const setEmptyPPNMessage = (message) =>(dispatch)=>{
    dispatch({
        type:'SET MESSAGE PPN',
        payload:message
    })
}
export const editFakturPajak = (idppn,data,token,setOpensnackbar)=>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION PPN'
  })
  axios.patch(`${apiUrl.url}ppn/${idppn}`,data,{
    headers:{
      "Authorization" : `Bearer ${token}`
    }
  })
  .then(res=>{
    const ResponseAPI = res.data;
    dispatch({
      type:'SUCCESSED ACTION PPN', 
      payload:ResponseAPI.message
   })
    setOpensnackbar(true);
  })
  .catch(error=>{
    dispatch({
      type:'FAILED ACTION PPN',
      payload:error.message
    })
    setOpensnackbar(true);
  })
}