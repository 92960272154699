const penggunaInitialState = {
    isLoading:false,
    totalUser:'',
    pagePengguna : {
        currentPage:'1',
        totalPage:'1'
    },
    dataLogin:{
        nama:'',
        u_name:'',
        token:''
    },
    dataPengguna:[],
    detailsPengguna:'',
    penggunaMessage:'',
}

const penggunaReducers = (state=penggunaInitialState,action)=>{
    switch(action.type){
        case 'GET TOTAL USER':{
            return{
                ...state,
                totalUser:action.payload
            }
        }
        case 'FETCH LOGIN':{
            return{
                ...state,
                isLoading:action.payload
            }
        }
        case 'LOGIN SUCCESSED':{
            return{
                ...state,
                isLoading:false,
                dataLogin:action.payload,
                penggunaMessage:action.message
            }
        }
        case 'OTORITAS SUCCESSED':{
            return{
                ...state,
                isLoading:false,
                penggunaMessage:action.message
            }
        }
        case 'LOGIN FAILED':{
            return{
                ...state,
                isLoading:false,
                penggunaMessage:action.message
            }
        }
        case 'SUCCESSED ADD NEW PENGGUNA':{
            return{
                ...state,
                isLoading:false,
                penggunaMessage:action.payload
            }
        }
        case 'FAILED ADD NEW PENGGUNA':{
            return{
                ...state,
                isLoading:false,
                penggunaMessage:action.payload
            }
        }
        case 'EMPTY USER MESSAGE':{
            return{
                ...state,
                penggunaMessage:action.payload
            }
        }
        case 'UPDATE PAGE PENGGUNA':{
            return {
                ...state,
                pagePengguna:action.payload
            }
        }
        case 'UPDATE DATA PENGGUNA':{
            return{
                ...state,
                isLoading:false,
                dataPengguna:action.payload
            }
        }
        case 'FETCH GET ALL PENGGUNA':{
            return{
                ...state,
                isLoading:true
            }
        }
        case 'FAILED GET ALL PENGGUNA':{
            return{
                ...state,
                isLoading:false,
                penggunaMessage:action.payload
            }
        }
        case 'EMPTY USER LOGIN':{
            return{
                ...state,
                dataLogin:action.payload
            }
        }
        case 'FETCH ACTION PENGGUNA':{
            return{
                ...state,
                isLoading:true
            }
        }
        case 'SUCCESSED ACTION PENGGUNA':{
            return{
                ...state,
                isLoading:false,
                penggunaMessage:action.payload
            }
        }
        case 'FAILED ACTION PENGGUNA':{
            return{
                ...state,
                isLoading:false,
                penggunaMessage:action.payload
            }
        }
        case 'SUCCESSED GET PENGGUNA DETAILS':{
            return{
                ...state,
                isLoading:false,
                detailsPengguna:action.payload
            }
        }
        case 'EMPTY PENGGUNA DETAILS':{
            return{
                ...state,
                isLoading:false,
                detailsPengguna:""
            }
        }
        default: return state
    }
}
export default penggunaReducers;