export const OpenLeftDrawer = (open) => (dispatch) =>{
    dispatch({
        type : 'SET OPEN DRAWER',
        payload : open
    })
}

export const setStatusServer = (value) => (dispatch)=>{
    dispatch({
        type:'SET STATUS SERVER',
        payload:value
    })
}
export const setTimeServer = (value)=>(dispatch)=>{
    dispatch({
        type:'SET TIME SERVER',
        payload:value
    })
}
export const setClickMenu = (value)=>(dispatch)=>{
    dispatch({
        type:'SET CLICK MENU',
        payload:value
    })
}