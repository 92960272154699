import React, { useEffect, useState } from 'react'
import Cookies from 'js-cookie'
import { useDispatch, useSelector } from 'react-redux'
import {Route, useHistory} from 'react-router-dom';
import { getCountUser } from '../redux/actions/penggunaActions';

const ProtectedRoutes = ({component:Component,...rest}) => {
    const {dataLogin} = useSelector(state=>state.penggunaReducers);
    const [user,setUser] = useState(dataLogin.u_name);
    const history = useHistory();
    if(user === ''){
        setUser(Cookies.get('u_name'));
    }
    const dispatch = useDispatch();
    const {totalUser} = useSelector(state=>state.penggunaReducers);
    useEffect(()=>{
        if(totalUser === ''){
            dispatch(getCountUser());
        }
    },[totalUser,dispatch])
    return (
        <Route {...rest} render={
            props=>{
                if(!user&&totalUser>0){
                    history.push("/login");
                }else{
                    return <Component {...rest} {...props}/>
                }
            }
        }/>
    )
}

export default ProtectedRoutes
