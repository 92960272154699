const konversiInitalValues={
    dataKonversi:[],
    isLoading:false,
    konversiMessage:'',
    todoKonversi:[],
    arrSatuanKonversi:[]
}

const konversiReducers = (state=konversiInitalValues,action)=>{
    switch(action.type){
        case 'GET ALL KONVERSI':{
            return{
                ...state,
                isLoading:false,
                dataKonversi:action.payload
            }
        }
        case 'FETCH ACTION KONVERSI':{
            return{
                ...state,
                isLoading:true
            }
        }
        case 'SUCCESSED ACTION KONVERSI':{
            return{
                ...state,
                isLoading:false,
                konversiMessage:action.payload
            }
        }
        case 'FAILED ACTION KONVERSI':{
            return{
                ...state,
                isLoading:false,
                konversiMessage:action.payload
            }
        }
        case 'EMPTY KONVERSI MESSAGE':{
            return{
                ...state,
                konversiMessage:''
            }
        }
        case 'SUCCESSED GET SATUAN KONVERSI':{
            return{
                ...state,
                isLoading:false,
                arrSatuanKonversi:action.payload
            }
        }
        case 'FAILED GET SATUAN KONVERSI':{
            return{
                ...state,
                isLoading:false,
                arrSatuanKonversi:action.payload
            }
        }
        case 'EMPTY ARRAY SATUAN KONVERSI':{
            return{
                ...state,
                arrSatuanKonversi:[]
            }
        }
        case 'FAILED ADD KONVERSI':{
            return{
                ...state,
                konversiMessage:action.payload
            }
        }
        default: return state
    }
}
export default konversiReducers;