const stokbarangInitialvalues={
    isLoading:false,
    dataStokTerakhir:'',
    stokbarangMessage:'',
    dataStokBarang:[],
    pageStokBarang : {
        currentPage:'1',
        totalPage:'1'
    },
    dataRincianStokBarang:[],
    pageRincianStokBarang : {
        currentPageRincian:'1',
        totalPageRincian:'1'
    },
    listTahun:[],
    dataRincian : {
        idsatuanbarang:'',
        namabarang:''
    },
    dataStokKoreksi:'',
}

const stokbarangReducers = (state=stokbarangInitialvalues,action)=>{
    switch(action.type){
        case 'ACTION STOK BARANG':{
            return{
                ...state,
                isLoading:true
            }
        }
        case 'SUCCESSED GET LAST STOK BARANG':{
            return{
                ...state,
                isLoading:false,
                dataStokTerakhir:action.payload
            }
        }
        case 'EMPTY LAST STOK BARANG':{
            return{
                ...state,
                dataStokTerakhir:''
            }
        }
        case 'UPDATE PAGE STOK BARANG':{
            return {
                ...state,
                pageStokBarang:action.payload
            }
        }
        case 'UPDATE DATA STOK BARANG':{
            return{
                ...state,
                isLoading:false,
                dataStokBarang:action.payload
            }
        }
        case 'FAILED GET ALL STOK BARANG':{
            return{
                ...state,
                isLoading:true,
                stokbarangMessage:action.payload
            }
        }
        case 'FAILED GET LAST STOK BARANG':{
            return{
                ...state,
                isLoading:false,
                stokbarangMessage:action.payload
            }
        }
        case 'SET MESSAGE STOK BARANG':{
            return{
                ...state,
                stokbarangMessage:action.payload
            }
        }
        case 'UPDATE PAGE RINCIAN STOK BARANG':{
            return {
                ...state,
                pageRincianStokBarang:action.payload
            }
        }
        case 'UPDATE DATA RINCIAN STOK BARANG':{
            return{
                ...state,
                isLoading:false,
                dataRincianStokBarang:action.payload
            }
        }
        case 'FAILED GET RINCIAN STOK BARANG':{
            return{
                ...state,
                isLoading:true,
                stokbarangMessage:action.payload
            }
        }
        case 'FAILED ACTION STOK BARANG':{
            return{
                ...state,
                isLoading:false,
                stokbarangMessage:action.payload
            }
        }
        case 'SUCCESSED GET LIST TAHUN':{
            return{
                ...state,
                isLoading:false,
                listTahun:action.payload
            }
        }
        case 'SUCCESSED EDIT STOK AKHIR':{
            return{
                ...state,
                isLoading:false,
                stokbarangMessage:action.payload
            }
        }
        case 'EMPTY LIST RINCIAN STOK BARANG':{
            return{
                ...state,
                dataRincianStokBarang:[]
            }
        }
        case 'GET ID SATUAN BARANG':{
            return{
                ...state,
                dataRincian:action.payload
            }
        }
        case 'EMPTY ID SATUAN BARANG':{
            return{
                ...state,
                dataRincian:''
            }
        }
        case 'SUCCESSED GET LAST STOK BARANG KOREKSI':{
            return{
                ...state,
                isLoading:false,
                dataStokKoreksi:action.payload
            }
        }
        case 'EMPTY LAST STOK BARANG KOREKSI':{
            return{
                ...state,
                isLoading:false,
                dataStokKoreksi:[]
            }
        }
        case 'SUCCESSED KOREKSI STOK BARANG':{
            return{
                ...state,
                isLoading:false,
                stokbarangMessage:action.payload
            }
        }
        default:return state
    }
}
export default stokbarangReducers;