import React, { useEffect, useState } from 'react'
import {Container,Typography,styled,Fab, useTheme,Divider,Box,Button, TextField, TableContainer, Table, TableHead, TableRow, TableCell, tableCellClasses, Paper, TableBody, Tooltip, IconButton, Snackbar, CircularProgress} from '@mui/material';
import Cookies from 'js-cookie';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { EditData } from '../../../components/assets';
import { useHistory } from 'react-router-dom';
import { TablePagination } from '../../../components/molecules';
import { emptyBarangMessage, getAllDistributorForCombo, getSatuanForHarga, updateHargaBarangByDistributor, getBarangByDist, getDetailsSatuanBarang, emptyBarangDetails } from '../../../config/redux/actions';
import * as yup from 'yup';
import { useFormik } from 'formik';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosOutlined';
import UbahHarga from '../UbahHarga';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'grey',
        color: theme.palette.common.black
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  
const UbahHargaBarang = () => {
    const jenisPerubahan = [
          {
            value: 'Kenaikan',
            label: 'Kenaikan',
          },
          {
            value: 'Penurunan',
            label: 'Penurunan',
          }
    ];
    const theme = useTheme();
    const paperTable = {
        marginTop:'10px',
        [theme.breakpoints.up('sm')]:{
            width:'75%',
            height:'370px',
        }, 
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            height:'360px'
        },
        scrollbarWidth:'thin'
    }
    const boxSearch = {
        [theme.breakpoints.up('sm')]:{
            display:'flex',
            justifyContent:'flex-end',
            width:'75%',
        }, 
        [theme.breakpoints.down('sm')]:{
            display:'flex',
            justifyContent:'flex-end',
            width:'100%',
        }
    }
    const boxCalculate = {
        [theme.breakpoints.up('sm')]:{
            display:'flex',
            justifyContent:'center',
            width:'65%',
            alignItems:'center'
        }, 
        [theme.breakpoints.down('sm')]:{
            display:'flex',
            justifyContent:'flex-end',
            width:'100%',
            alignItems:'flex-end'
        }
    }
    const {dataHargaBarang,pageHargaBarang,messageBarang} = useSelector(state=>state.barangReducers);
    const {dataLogin} =useSelector(state=>state.penggunaReducers);
    const {dataComboDistributor}=useSelector(state=>state.distributorReducers);
    const history = useHistory();
    const dispatch = useDispatch();
    const [token,setToken] = useState(dataLogin.token);
    const [search,setSearch] = useState('');
    const [counter,setCounter] = useState(1);
    const [openSnackbar,setOpensnackbar]=useState(false);
    const [loadingHitung,setLoadingHitung] = useState(false);
    const [valueID,setValueID]=useState('');
    const [openDialog,setOpenDialog]=useState(false);
    const [idbarangUbah,setIdBarangUbah]=useState('');
    const [idSatuanUbah,setIdSatuanUbah]=useState('');
    
    useEffect(()=>{
        dispatch(getAllDistributorForCombo("",token,setOpensnackbar));
        dispatch(getSatuanForHarga(token,setOpensnackbar));
        dispatch(getBarangByDist(valueID,search,counter,token,setOpensnackbar));
    },[search,counter,token,dispatch,valueID])
    
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const handlePrev = () =>{
        setCounter(counter <= 1? 1 : counter - 1);
    }
    const handleNext = () =>{
        setCounter(counter === pageHargaBarang.totalPage ? pageHargaBarang.totalPage : counter + 1);
    }
    const handleEditHarga = (idbarang,idsatuan) =>{
        dispatch(getDetailsSatuanBarang(idbarang,idsatuan,token,setOpensnackbar));
        setIdBarangUbah(idbarang);
        setIdSatuanUbah(idsatuan);
        setOpenDialog(true);
    }
    const handleCloseDialog = () =>{
        setIdBarangUbah('');
        setIdSatuanUbah('');
        dispatch(emptyBarangDetails());
        setOpenDialog(false);
        dispatch(getBarangByDist(valueID,search,counter,token,setOpensnackbar));
    }
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(emptyBarangMessage(''));
            dispatch(getBarangByDist(valueID,search,counter,token,setOpensnackbar));
        }, 100);
    }
    const tableBarang = (
        <TableBody>
            {dataHargaBarang.map((barangs) => (
                <StyledTableRow key={barangs.idsatuanbarang}>
                <StyledTableCell width={200} align="center">{barangs.idbarang}</StyledTableCell>
                <StyledTableCell width={200} align="center">{barangs.namabarang}</StyledTableCell>
                <StyledTableCell width={200} align="center" key={barangs.iddistributor}>{barangs.nama}</StyledTableCell>
                <StyledTableCell width={150} align="center" key={barangs.idsatuan}>{barangs.satuan}</StyledTableCell>
                <StyledTableCell width={150} align="center">{new Intl.NumberFormat().format(barangs.hargadasar)}</StyledTableCell>
                <StyledTableCell width={150} align="center">{new Intl.NumberFormat().format(barangs.hargagrosir)}</StyledTableCell>
                <StyledTableCell width={60} align="center">
                    <Tooltip title="Ubah Harga" >
                        <IconButton 
                        onClick={()=>handleEditHarga(barangs.idbarang,barangs.idsatuan)}>
                            <img src={EditData} alt="editData"/>
                        </IconButton>
                     </Tooltip>
                </StyledTableCell>
                </StyledTableRow>
            ))}
        </TableBody>
    )
    const schemaValidation = yup.object().shape({
        iddistributor:yup.string().required('Distributor belum dipilih'),
        jenisperubahan:yup.string().required('Jenis perubahan belum dipilih'),
        jumlah:yup.number().required('Jumlah tidak boleh kosong').min(0,"Jumlah tidak boleh kurang dari 0"),
    })
    const formik=useFormik({
        initialValues:{
            iddistributor:'',
            jenisperubahan:'',
            jumlah:'',
        },
        validationSchema:schemaValidation,
        onSubmit:(values)=>{
            const data = new URLSearchParams();
            data.append('jenisperubahan',values.jenisperubahan);
            data.append('jenissatuan',values.jenissatuan);
            data.append('jumlah',values.jumlah);
            dispatch(updateHargaBarangByDistributor(values.iddistributor,data,token,setOpensnackbar,history,setLoadingHitung,formik));
        }
    })
    const handleChangeIdDist = (e)=>{
        dispatch(getBarangByDist(e.target.value,search,counter,token,setOpensnackbar));
        formik.setFieldValue('iddistributor',e.target.value);
        setValueID(e.target.value);
    }
    const handleChangeSearch = (e) =>{
        setSearch(e.target.value);
        dispatch(getBarangByDist(valueID,e.target.value,counter,token,setOpensnackbar));
    }
    return (
        <Container maxWidth='xl' sx={{marginTop:'85px'}}>
            <Typography variant='h6' pl={1} mt={1}>Perubahan Harga Barang</Typography>
            <Divider/>
            <Box sx={{marginTop:'5px',width:'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            <Box sx={boxCalculate}>
                <TextField
                id='iddistributor'
                select
                name='iddistributor'
                variant="standard" 
                label="Distributor"
                style={{width:'16%',marginRight:'5px'}}
                SelectProps={{
                    native: true,
                    }}
                value={formik.values.iddistributor}
                error={formik.touched.iddistributor && Boolean(formik.errors.iddistributor)}
                helperText={formik.touched.iddistributor && formik.errors.iddistributor}
                onChange={(e)=>handleChangeIdDist(e)}
                >   
                    <option aria-label="None" value="" />
                    {dataComboDistributor.map((option)=>(
                        <option key ={option.iddistributor} value={option.iddistributor}>
                            {option.nama}
                        </option>
                    ))}
                </TextField>
                <TextField
                id='jenisperubahan'
                select
                name='jenisperubahan'
                variant="standard"
                style={{width:'17%',marginRight:'5px'}}
                label="Jenis Perubahan"
                SelectProps={{
                    native: true,
                    }}
                value={formik.values.jenisperubahan}
                error={formik.touched.jenisperubahan && Boolean(formik.errors.jenisperubahan)}
                helperText={formik.touched.jenisperubahan && formik.errors.jenisperubahan}
                onChange={formik.handleChange}
                >   <option aria-label="None" value="" />
                    {jenisPerubahan.map((perubahan)=>(
                        <option key ={perubahan.value} value={perubahan.value}>
                            {perubahan.label}
                        </option>
                    ))}
                </TextField>
                <TextField
                id='jumlah'
                name='jumlah'
                label='Jumlah'
                placeholder='%'
                variant='standard'
                style={{width:'16%',marginRight:'5px'}}
                value={formik.values.jumlah}
                error={formik.touched.jumlah && Boolean(formik.errors.jumlah)}
                helperText={formik.touched.jumlah && formik.errors.jumlah}
                onChange={formik.handleChange}
                />
                <Button
                id='buttonCal'
                variant="contained"
                size="small"
                sx={{height:'30px'}}
                onClick={formik.handleSubmit}
                disabled={loadingHitung}
                >{loadingHitung === true ? <CircularProgress color='primary' size={25}/> : "HITUNG"}</Button>
            </Box>
            <Box sx={boxSearch}>
            <TextField
                id='search'
                name='search'
                label='Cari Barang'
                variant='standard'
                onChange={(e)=>handleChangeSearch(e)}
                />
            </Box>
            <TableContainer component={Paper} sx={paperTable}>
                <Table stickyHeader aria-label="customized table" size='small'>
                    <TableHead>
                    <TableRow>
                        <StyledTableCell width={200} align="center">ID Barang</StyledTableCell>
                        <StyledTableCell width={200} align="center">Nama Barang</StyledTableCell>
                        <StyledTableCell width={200} align="center">Distributor</StyledTableCell>
                        <StyledTableCell width={150} align="center">Satuan</StyledTableCell>
                        <StyledTableCell width={150} align="center">Harga Eceran</StyledTableCell>
                        <StyledTableCell width={150} align="center">Harga Grosir</StyledTableCell>
                        <StyledTableCell width={60} align="center"/>
                    </TableRow>
                    </TableHead>
                        {tableBarang} 
                </Table>
            </TableContainer>
            <Box sx={boxSearch}>
            <TablePagination
            page={counter}
            totalPage={pageHargaBarang.totalPage >= 1 ? pageHargaBarang.totalPage : pageHargaBarang.currentPage}
            handlePrev={handlePrev}
            handleNext={handleNext}
            hiddenButton={true}
            />
            </Box>
            </Box>
            <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={messageBarang === 'Perubahan kenaikan harga berhasil' || messageBarang==='Perubahan Penurunan harga berhasil'? 'success':'error'}
                >
                    {messageBarang}
                    </Alert>
            </Snackbar>
            <Fab sx={{position: 'fixed',bottom: theme.spacing(5),left: theme.spacing(3)}} size="small" color="primary" aria-label="add" onClick={()=>history.push('/hargabarang')}>
            <ArrowBackIcon/>
        </Fab>
        <UbahHarga 
        openDialogUbah={openDialog} 
        closeDialogUbah={handleCloseDialog}
        idbarang={idbarangUbah}
        idsatuan={idSatuanUbah}/>
        </Container>
    )
}

export default UbahHargaBarang
