import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {Typography,Box,Button, CircularProgress} from '@mui/material';

const DeleteDialog = ({handleCloseDialog,handleClick,message,openDialog,isLoading,textHapus,textKeluar,...rest}) => {
    
    return (
        <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            {...rest}
        >
            <DialogContent>
                <Typography style={{textAlign:'center'}}>{message}</Typography>
                <Box sx={{width:'100%',display:'flex',justifyContent:'space-between',padding:'5px'}}>
                    <Button variant="contained" color="primary" onClick={handleClick} disabled={isLoading}>{isLoading === true ?  
                    <CircularProgress color='primary' size={25}/> : textHapus === "" ? "HAPUS" : textHapus}
                    </Button>
                    <Button onClick={handleCloseDialog} variant="contained" disabled={isLoading} color="error">
                        {textKeluar==="" ? "Keluar" : textKeluar}
                        </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default DeleteDialog
