import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, List, ListItem, ListItemText, Paper, Snackbar, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addToCartPembelian,addListDiskon, emptyListBarang, getSatuanKonversiList, setMessagePembelian, setEmptyListDiskon, getAllBarang, getLastPrice } from '../../../config/redux/actions';
import Cookies from 'js-cookie';
import { AddTodo, AddToList, DeleteData } from '../../assets';
import MuiAlert from '@mui/material/Alert';
import * as yup from 'yup';
import { useFormik } from 'formik';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'grey',
        color: theme.palette.common.black
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  
const BarangListDialog = ({openDialog,closeDialog,status,iddist,namadist,...rest}) => {
    const {dataLogin} =useSelector(state=>state.penggunaReducers);
    const {dataComboSatuan}=useSelector(state=>state.satuanReducers);
    const {timeConnection} = useSelector(state=>state.homeReducers);
    const {dataBarang} = useSelector(state=>state.barangReducers);
    const {arrSatuanKonversi}=useSelector(state=>state.konversiReducers);
    const {pembelianMessage,listDiskon,cartPembelian}=useSelector(state=>state.pembelianReducers);
    const [search,setSearch] = useState('');
    const [statusDialog,setStatusDialog]=useState(status);
    const [token,setToken] = useState(dataLogin.token);
    const [openSnackbar,setOpensnackbar]=useState(false);
    const [satuanText,setSatuanText]=useState('');
    const [countCart,setCountCart]=useState(0);
    const validationSchema=yup.object().shape({
        'idsatuan':yup.string().required(),
        'harga':yup.number().required("Harga tidak boleh kosong").min(1,"Harga tidak boleh kurang dari 0"),
        'qty':yup.number().required('Jumlah tidak boleh kosong').min(1,"Jumlah tidak boleh kurang dari 1").positive("Jumlah tidak boleh kurang dari 1"),
        'harganet':yup.number().required().min(0,"Harga Nett tidak boleh kurang dari 0"),
        'jumlah':yup.number().required('Subtotal belum terhitung').min(0,"Subtotal tidak boleh kurang dari 0")
    });
    const formik = useFormik({
        initialValues:{
            idbarang:'',
            namabarang:'',
            idsatuan:'',
            harga:'',
            harganet:'',
            qty:'',
            jumlah:''
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const existBarang = cartPembelian.filter(barang =>barang.idbarang === values.idbarang && barang.idsatuan === values.idsatuan);
            if(existBarang.length>0){
                dispatch(setMessagePembelian('Barang dengan satuan ini telah terdaftar'));
                setOpensnackbar(true);
            }else if(formik.values.jumlah<1){
                dispatch(setMessagePembelian('Nilai jumlah pembelian tidak boleh 0'));
                setOpensnackbar(true);
            }else{
                if(parseFloat(values.harga)<parseInt(values.qty)){
                    dispatch(setMessagePembelian('Harga barang lebih kecil dari jumlah barang'));
                    setOpensnackbar(true);
                }else{
                    setCountCart(countCart+1);
                    const data = {
                        'id':countCart,
                        'idbarang':values.idbarang,
                        'namabarang':values.namabarang,
                        'idsatuan':values.idsatuan,
                        'satuan':satuanText,
                        'harga':values.harga,
                        'diskon':listDiskon,
                        'harganet':values.harganet,
                        'qty':values.qty,
                        'jumlah':values.jumlah
                    }
                    dispatch(addToCartPembelian(data,setOpensnackbar));
                    formik.handleReset();
                    dispatch(setEmptyListDiskon());
                    setCountDis(1);
                    setTimeout(()=>{
                        ChangeStatusBarang();
                    },1000)
                }
            }
        }
    })
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const theme = useTheme();
    const paperTable = {
        marginTop:'10px',
        [theme.breakpoints.up('sm')]:{
            width:'100%',
            height:'350px',
        }, 
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            height:'360px'
        },
        scrollbarWidth:'thin'
    }
    const boxSearch = {
        [theme.breakpoints.up('sm')]:{
            display:'flex',
            justifyContent:'flex-end',
            width:'100%',
        }, 
        [theme.breakpoints.down('sm')]:{
            display:'flex',
            justifyContent:'flex-end',
            width:'100%',
        }
    }
    const topupHarga = {
        display:'flex',
        borderRadius:'5px',
        justifyContent:'center',
        width:'180px',
        height:'100%',
        backgroundColor:'#BED6C7'
    }
    const handleChangeSearch = (e) =>{
        setSearch(e.target.value);
        dispatch(getAllBarang(e.target.value,1,token,setOpensnackbar));
    }
    const ChangeStatusAddBarang = () =>{
        setStatusDialog("list");
        dispatch(setMessagePembelian(''));
        setOpensnackbar(false);
    }
    const ChangeStatusBarang = () =>{
        setStatusDialog("barang");
        formik.setFieldValue('idbarang',"");
        formik.setFieldValue('namabarang',"");
        formik.handleReset();
    }
    const handleAddBarang=(idbarang,namabarang)=>{
        ChangeStatusAddBarang();
        formik.setFieldValue('idbarang',idbarang);
        formik.setFieldValue('namabarang',namabarang);
        dispatch(getSatuanKonversiList(idbarang,token,""));
    }
    const dispatch = useDispatch();
    useEffect(()=>{
        if(statusDialog==='barang' && openDialog===true){
            dispatch(getAllBarang(search,1,token,setOpensnackbar));
        }else if(statusDialog==='list' && dataComboSatuan.length<1){
            dispatch(emptyListBarang());
        }
    },[dispatch,statusDialog,openDialog,dataComboSatuan,iddist,search,token])
    const tableBarang=(
        <TableBody>
            {dataBarang.map((barang) => (
                <StyledTableRow key={barang.idbarang}>
                <StyledTableCell width={120} align="center">
                    {barang.idbarang}
                </StyledTableCell>
                <StyledTableCell width={120} align="center">{barang.namabarang}</StyledTableCell>
                <StyledTableCell width={20} align="center">
                    <Tooltip title="Tambah ke keranjang">
                        <IconButton 
                        disabled={timeConnection<=100 ? false : true}
                        onClick={()=>handleAddBarang(barang.idbarang,barang.namabarang)}>
                            <img src={AddToList} alt="addToList"/>
                        </IconButton>
                    </Tooltip>
                </StyledTableCell>
                </StyledTableRow>
            ))}
        </TableBody>
    )
    const viewDataBarang = (
        <div>
            <DialogTitle sx={{textAlign:"center"}}>Data Barang</DialogTitle>
            <DialogContent>
            <Divider/>
            <Box sx={boxSearch}>
                <TextField
                id='search'
                name='search'
                label='Cari Barang'
                variant='standard'
                onChange={handleChangeSearch}
                />
            </Box>
            <TableContainer component={Paper} sx={paperTable}>
                    <Table stickyHeader aria-label="customized table" size='small'>
                        <TableHead>
                        <TableRow>
                        <StyledTableCell width={120} align="center">ID Barang</StyledTableCell>
                        <StyledTableCell width={120} align="center">Nama Barang</StyledTableCell>
                        <StyledTableCell width={20} align="center"/>
                    </TableRow>
                    </TableHead>
                        {dataBarang.length>0 ? tableBarang : null} 
                </Table>
            </TableContainer>
        </DialogContent>
        <DialogActions sx={{display:'flex',justifyContent:'center'}}>
            <Button variant='contained' color='error' onClick={closeDialog}>Keluar</Button>
        </DialogActions>
        </div>
    )
    const jenisDiskon = [
        {'label':'%','value':'%'},
        {'label':'Rp','value':'Rp'}
    ]
    const [labelDiskon,setLabelDiskon]=useState('');
    const [disabledDiskon,setDisabledDIskon] = useState(true);
    const [disabledAddDiskon,setDisabledAddDiskon]=useState(true);
    const [placeholderDiskon,setPlaceholderDiskon]=useState('');
    const [countDis,setCountDis]=useState(1);
    const [listHarga,setListHarga]=useState({
        'hargadasar':0,
        'hargagrosir':0
    });
    const [lastPrice,setLastPrice]=useState(0);
    const validationSchemaDiskon = yup.object().shape({
        'diskon':yup.number().min(0,'Diskon tidak boleh kurang dari 0')
    })
    const formikDiskon = useFormik({
        initialValues:{
            jenisdiskon:'',
            diskon:''
        },
        validationSchema:validationSchemaDiskon,
        onSubmit:(values)=>{
            if(formik.values.harganet===""){
                dispatch(setMessagePembelian('Harga Netto belum terisi'));
                setOpensnackbar(true);
            }else if(formik.values.harga!==""){
                setCountDis(countDis+1);
                dispatch(addListDiskon(countDis,values.jenisdiskon,values.diskon));
                if(values.jenisdiskon==="%" && formik.values.harga===formik.values.harganet){
                    let tempPersen = parseInt(formik.values.harga) * (parseFloat(values.diskon)/100);
                    let tempNett = parseInt(formik.values.harga)-tempPersen;
                    formik.setFieldValue('harganet',tempNett);
                }else if(values.jenisdiskon==="%" && formik.values.harga!==formik.values.harganet){
                    let tempPersen = parseInt(formik.values.harganet) * (parseFloat(values.diskon)/100);
                    let tempNett = parseInt(formik.values.harganet)-tempPersen;
                    formik.setFieldValue('harganet',tempNett);
                }else if(values.jenisdiskon==="Rp" && formik.values.harga===formik.values.harganet){
                    let tempNett = parseInt(formik.values.harga)-parseFloat(values.diskon);
                    formik.setFieldValue('harganet',tempNett);
                }else if(values.jenisdiskon==="Rp" && formik.values.harga!==formik.values.harganet){
                    let tempNett = parseInt(formik.values.harganet)-parseFloat(values.diskon);
                    formik.setFieldValue('harganet',tempNett);
                }
                formikDiskon.setFieldValue('jenisdiskon',"");
                setLabelDiskon("");
                formikDiskon.setFieldValue('diskon',"");
                setDisabledDIskon(true);
                setDisabledAddDiskon(true);
                formik.setFieldValue('qty',"");
                formik.setFieldValue('jumlah',"");
                setPlaceholderDiskon('');
            }
        }
    })
    const handleChangeSatuan = (e)=>{
        setSatuanText(e.target.options[e.target.selectedIndex].text);
        let getSatuan = arrSatuanKonversi.filter(fil=>fil.idsatuan === e.target.options[e.target.selectedIndex].value);
        if(getSatuan.length>0){
            const data ={
                'idbarang':getSatuan[0].idbarang,
                'idsatuan':getSatuan[0].idsatuan
            }
            dispatch(getLastPrice(data,token,setLastPrice));
            setListHarga({
                'hargadasar':getSatuan[0].hargadasar||0,
                'hargagrosir':getSatuan[0].hargagrosir||0
            })
        }
        formik.setFieldValue('idsatuan',e.target.value);
    }
    const handleChangeJenisDiskon = (e) =>{
        formikDiskon.setFieldValue('jenisdiskon',e.target.value);
        if(e.target.value === '%'){
            setLabelDiskon('Jumlah');
            setDisabledDIskon(false);
            setDisabledAddDiskon(false);
            setPlaceholderDiskon('Nilai persentase');
        }else if(e.target.value === 'Rp'){
            setLabelDiskon('Nominal')
            setDisabledDIskon(false);
            setDisabledAddDiskon(false);
            setPlaceholderDiskon('Nominal diskon');
        }else{
            setLabelDiskon("");
            formikDiskon.setFieldValue('diskon',"");
            setDisabledDIskon(true);
            setDisabledAddDiskon(true);
            setPlaceholderDiskon('');
        }
    }
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(setMessagePembelian(''));
        }, 100);
    }
    const handleDeleteDiskon = (id,jenisdiskon,diskon) =>{
        dispatch(setEmptyListDiskon());
        formik.setFieldValue('harganet',formik.values.harga);
        if(countDis===1){
            setCountDis(1);
        }else{
            setCountDis(countDis-1);
        }
    }
    const handleEnterHarga = (e) =>{
        if(e.key === 'Enter'){
            if(listDiskon.length<1){
                formik.setFieldValue('harganet',formik.values.harga);
            }else if(listDiskon.length>0){
                let tempHarga=formik.values.harga;
                let tempNett;
                listDiskon.forEach(dis => {
                    if(dis.jenisdiskon==='%'){
                        let tempPersen = parseInt(tempHarga) * (parseFloat(dis.diskon)/100);
                        tempNett = parseInt(tempHarga)-tempPersen;
                        formik.setFieldValue('harganet',tempNett);
                    }else if(dis.jenisdiskon==="Rp"){
                        tempNett = parseInt(tempHarga)-parseFloat(dis.diskon);
                        formik.setFieldValue('harganet',tempNett);
                    }
                    tempHarga=tempNett;
                });
            }
        }
    }
    const handleEnterJumlah = (e) =>{
        if(e.key === 'Enter'){
            const regex= new RegExp('^[0-9]*$');
            const result = regex.test(formik.values.qty);
            if(result===true){
                let tempJumlah = formik.values.qty * formik.values.harganet;
                formik.setFieldValue('jumlah',tempJumlah);
            }else if(result===false){
                dispatch(setMessagePembelian('Jumlah harus numerik tidak diperbolehkan desimal'));
                setOpensnackbar(true)
                formik.setFieldValue('jumlah',0);
                formik.setFieldValue('qty',0);
            }
        }
    }
    const viewDiskon = (
        <List sx={{width:'100%',display:'flex',justifyContent:'center'}}>
            {listDiskon.map((diskon)=>(
                <ListItem sx={{textAlign:'center'}} key={diskon.id}
                disableGutters>
                    {diskon.jenisdiskon === '%' ? <ListItemText primary={diskon.diskon +diskon.jenisdiskon}/>: <ListItemText primary={new Intl.NumberFormat().format(diskon.diskon)}/>}
                </ListItem>
            ))}
                {listDiskon.length>0 ?
                <IconButton size='small' 
                    onClick={()=>handleDeleteDiskon()}
                    >
                    <img src={DeleteData} alt="addTodo"/>
                </IconButton>: null}
        </List>
    )
    const onChangeHarga = (e) =>{
        formik.setFieldValue('harga',e.target.value);
        formik.setFieldValue('harganet',0);
        formik.setFieldValue('qty',0);
        formik.setFieldValue('jumlah',0);
    }
    const topUpHarga = (
        <Box sx={{width:'100%',height:'100%',display:'flex',justifyContent:'center',marginTop:'10px'}}>
        <Box sx={topupHarga}>
            <Box sx={{display:'flex',flexDirection:'column'}}>
                <Typography variant='caption'>Harga Beli</Typography>
                <Typography variant='caption'>Harga Jual</Typography>
                <Typography variant='caption'>Harga Grosir</Typography>
            </Box>
            <Box sx={{marginLeft:'2px',display:'flex',flexDirection:'column'}}>
                <Typography variant='caption'>: Rp {new Intl.NumberFormat().format(lastPrice)}</Typography>
                <Typography variant='caption'>: Rp {new Intl.NumberFormat().format(listHarga.hargagrosir)}</Typography>
                <Typography variant='caption'>: Rp {new Intl.NumberFormat().format(listHarga.hargadasar)}</Typography>
            </Box>
        </Box>
        </Box>
    )
    const addToCart = (
        <DialogContent>
            <Box sx={{width:'100%',display:'flex',flexDirection:'column'}}>
            <TextField
            id='idbarang'
            name='idbarang'
            disabled={true}
            variant="standard"
            label="ID Barang"
            sx={{marginTop:'10px'}}
            value={formik.values.idbarang}
            error={formik.touched.idbarang && Boolean(formik.errors.idbarang)}
            helperText={formik.touched.idbarang && formik.errors.idbarang}
            onChange={formik.handleChange}
            />
            <TextField
            id='namabarang'
            name='namabarang'
            disabled={true}
            variant="standard"
            label="Nama Barang"
            sx={{marginTop:'10px'}}
            value={formik.values.namabarang}
            error={formik.touched.namabarang && Boolean(formik.errors.namabarang)}
            helperText={formik.touched.namabarang && formik.errors.namabarang}
            onChange={formik.handleChange}
            />
            <TextField
            id='idsatuan'
            select
            name='idsatuan'
            variant="standard" 
            label="Satuan"
            sx={{marginTop:'5px'}}
            SelectProps={{
                native: true,
                }}
            value={formik.values.idsatuan}
            error={formik.touched.idsatuan && Boolean(formik.errors.idsatuan)}
            helperText={formik.touched.idsatuan && formik.errors.idsatuan}
            onChange={(e)=>handleChangeSatuan(e)}
            >   
                <option aria-label="None" value="" />
                {arrSatuanKonversi.map((option)=>(
                    <option key ={option.idsatuan} value={option.idsatuan}>
                        {option.satuan}
                    </option>
                ))}
            </TextField>
            {formik.values.idsatuan!==""?topUpHarga:null}
            <TextField
            id='harga'
            name='harga'
            variant="standard"
            label="Harga"
            placeholder='Tekan enter untuk menghitung'
            // sx={{marginTop:'10px'}}
            value={formik.values.harga}
            error={formik.touched.harga && Boolean(formik.errors.harga)}
            helperText={formik.touched.harga && formik.errors.harga}
            onChange={(e)=>onChangeHarga(e)}
            onKeyDown={handleEnterHarga}
            />
            <Typography sx={{marginTop:'10px'}}>Diskon</Typography>
            <Box sx={{display:'flex',justifyContent:'space-between',alignItems:"flex-end"}}>
                <TextField
                id='jenisdiskon'
                select
                name='jenisdiskon'
                variant="standard"
                value={formikDiskon.values.jenisdiskon}
                error={formikDiskon.touched.jenisdiskon && Boolean(formikDiskon.errors.jenisdiskon)}
                helperText={formikDiskon.touched.jenisdiskon && formikDiskon.errors.jenisdiskon}
                sx={{marginRight:'5px'}}
                SelectProps={{
                    native: true,
                    }}
                onChange={(e)=>handleChangeJenisDiskon(e)}
                >   
                    <option aria-label="None" value="" />
                    {jenisDiskon.map((option)=>(
                    <option key ={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
                </TextField>
                <TextField
                id='diskon'
                name='diskon'
                disabled={disabledDiskon}
                placeholder={placeholderDiskon}
                variant="standard"
                label={labelDiskon}
                value={formikDiskon.values.diskon}
                error={formikDiskon.touched.diskon && Boolean(formikDiskon.errors.diskon)}
                helperText={formikDiskon.touched.diskon && formikDiskon.errors.diskon}
                onChange={formikDiskon.handleChange}
                />
                <IconButton 
                disabled={disabledAddDiskon}
                onClick={formikDiskon.handleSubmit}>
                    <img src={AddTodo} alt="addTodo"/>
                </IconButton>
            </Box>
                {viewDiskon}
            <TextField
            id='harganet'
            name='harganet'
            variant="standard"
            label="Harga Netto"
            disabled={true}
            sx={{marginTop:'10px'}}
            value={formik.values.harganet}
            error={formik.touched.harganet && Boolean(formik.errors.harganet)}
            helperText={formik.touched.harganet && formik.errors.harganet}
            onChange={formik.handleChange}
            />
            <TextField
            id='qty'
            name='qty'
            variant="standard"
            label="Qty"
            placeholder='Tekan enter untuk menghitung'
            sx={{marginTop:'10px'}}
            value={formik.values.qty}
            error={formik.touched.qty && Boolean(formik.errors.qty)}
            helperText={formik.touched.qty && formik.errors.qty}
            onChange={formik.handleChange}
            onKeyDown={handleEnterJumlah}
            />
            <TextField
            id='jumlah'
            name='jumlah'
            disabled={true}
            variant="standard"
            label="Jumlah"
            sx={{marginTop:'10px'}}
            value={formik.values.jumlah}
            error={formik.touched.jumlah && Boolean(formik.errors.jumlah)}
            helperText={formik.touched.jumlah && formik.errors.jumlah}
            onChange={formik.handleChange}
            />
            </Box>
            <Box sx={{width:'100%',marginTop:'10px',display:'flex',justifyContent:'space-between'}}>
                <Button 
                variant='contained' 
                onClick={formik.handleSubmit}
                disabled={timeConnection<=100 ? false : true}
                >Tambah</Button>
                <Button variant='contained' color='error' onClick={ChangeStatusBarang}>Keluar</Button>
            </Box>
            <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={pembelianMessage === 'Barang berhasil dimasukkan ke daftar' ? "success" : "error"}
                >
                    {pembelianMessage}
                    </Alert>
            </Snackbar>
        </DialogContent>
    )
  return (
      <Dialog {...rest} open={openDialog}>
        {/* {addToCart} */}
      {statusDialog === 'barang' ? viewDataBarang : addToCart}
      </Dialog>
  )
};

export default BarangListDialog;
