import { combineReducers } from "redux";
import penggunaReducers from './penggunaReducers';
import homeReducers from './homeReducers';
import distributorReducers from './distributorReducers';
import pelangganReducers from "./pelangganReducers";
import satuanReducers from "./satuanReducers";
import barangReducers from "./barangReducers";
import konversiReducers from "./konversiReducers";
import pembelianReducers from "./pembelianReducers";
import returReducers from "./returReducers";
import stokbarangReducers from "./stokbarangReducers";
import ppnReducers from "./ppnReducers";
import stokreturReducers from './stokreturReducers';
import penjualanReducers from "./penjualanReducers";
import returpenjualanReducers from "./returPenjualanReducers";
import draftReducers from "./draftReducers";
const reducer = combineReducers({
    penggunaReducers,
    homeReducers,
    distributorReducers,
    pelangganReducers,
    satuanReducers,
    barangReducers,
    konversiReducers,
    pembelianReducers,
    returReducers,
    stokbarangReducers,
    ppnReducers,
    stokreturReducers,
    penjualanReducers,
    returpenjualanReducers,
    draftReducers
})
export default reducer;