import { Box, Button, Dialog, DialogContent, Divider, Snackbar, TextField, Typography, useTheme } from '@mui/material'
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { getLastStokBarangKoreksi, postKoreksiStokBarang, setStokBarangMessage } from '../../../config/redux/actions';
import Cookies from 'js-cookie';
import { format } from 'date-fns';
import MuiAlert from '@mui/material/Alert';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const KoreksiStokDialog = ({openKoreksi,closeKoreksi,...rest}) => {
  const tipePerubahan = [
    {
      value: 'Barang Masuk',
      label: 'Barang Masuk',
    },
    {
      value: 'Barang Keluar',
      label: 'Barang Keluar',
    }
    ];
    const theme = useTheme();
    const textBoxCss = {
        [theme.breakpoints.up('sm')]:{
            display:'flex',
            width:'100%',
            marginRight:'20px'
        }, 
        [theme.breakpoints.down('sm')]:{
            display:'flex',
            width:'100%',
        }
    }
    const {dataStokKoreksi,dataRincian,stokbarangMessage,isLoading} = useSelector(state=>state.stokbarangReducers);
    const {dataLogin} =useSelector(state=>state.penggunaReducers);
    const [disabledModal,setDisabledModal] = useState(true);
    const [token,setToken] = useState(dataLogin.token);
    const [nama,setNama] = useState(dataLogin.nama);
    const [openSnackbar,setOpensnackbar]=useState(false);
    if(token ===''){
      setToken(Cookies.get('u_tkn'));
      setNama(Cookies.get('nama'));
    }
    const validationSchema = Yup.object().shape({
      tipeperubahan:Yup.string().required('Tipe perubahan belum dipilih'),
      jumlah:Yup.number().required("Jumlah belum terisi").min(1,"Jumlah tidak boleh kurang dari 1"),
      modal:Yup.number(),
      keterangan:Yup.string().required("Keterangan tidak boleh kosong").matches(/^[a-zA-Z0-9._]/, "Keterangan harus lebih dari 5 kata dan tidak diperbolehkan terdapat simbol")
    })
    const formik = useFormik({
      initialValues:{
        tipeperubahan:'',
        kelompok:'',
        jumlah:'',
        modal:'',
        keterangan:''
      },validationSchema:validationSchema,
      onSubmit:(values)=>{
        let totalModal = values.jumlah * values.modal;
        if(values.tipeperubahan==="Barang Masuk"){
          
          const data = {
            'idbarang' :dataRincian.idbarang,
            'idsatuan' : dataRincian.idsatuan,
            'tanggal' : format(new Date(),'yyyy-MM-dd'),
            'keterangan' : values.keterangan + "(koreksi dilakukan oleh " + nama+")",
            'kelompok' : values.kelompok,
            'qtym' : parseInt(values.jumlah),
            'mm' : values.modal,
            'tm' : totalModal,
            'qtyk' : 0,
            'mk' : 0,
            'tk' : 0,
          }
          dispatch(postKoreksiStokBarang(data,token,setOpensnackbar,handleCloseDialog));
        }
        else if(values.tipeperubahan==="Barang Keluar"){
          let tempStokTersedia = parseInt(dataStokKoreksi.stokbarang)-parseInt(dataStokKoreksi.bookingstok);
          console.log(tempStokTersedia);
          if(dataStokKoreksi.saldo <= 0 && tempStokTersedia >= parseInt(values.jumlah)){
            dispatch(setStokBarangMessage('Tidak dapat melakukan koreksi keluar barang'));
            setOpensnackbar(true);
          }else if(dataStokKoreksi.saldo>0 && tempStokTersedia < parseInt(values.jumlah)){
            dispatch(setStokBarangMessage('Jumlah koreksi stok barang tidak boleh melebihi dari stok tersedia saat ini'));
            setOpensnackbar(true);
          }else if(dataStokKoreksi.saldo>0 && tempStokTersedia >= parseInt(values.jumlah)){
            const data = {
              'idbarang' :dataRincian.idbarang,
              'idsatuan' : dataRincian.idsatuan,
              'tanggal' : format(new Date(),'yyyy-MM-dd'),
              'keterangan' : values.keterangan + "(koreksi dilakukan oleh " + nama+")",
              'kelompok' : values.kelompok,
              'qtym' :0,
              'mm' : 0,
              'tm' : 0,
              'qtyk' : parseInt(values.jumlah),
              'mk' : values.modal,
              'tk' : totalModal,
            }
            dispatch(postKoreksiStokBarang(data,token,setOpensnackbar,handleCloseDialog));
          }
        }
      }
    })
    const dispatch = useDispatch();
    useEffect(()=>{
        const data = {
          'idbarang':dataRincian.idbarang,
          'idsatuan':dataRincian.idsatuan
        }
        dispatch(getLastStokBarangKoreksi(data,token));
    },[dataRincian,dispatch])
    const handleChangeTipePerubahan = (e) =>{
      if(e.target.value === "Barang Masuk"){
        formik.setFieldValue('tipeperubahan',e.target.value);
        formik.setFieldValue('kelompok',dataStokKoreksi.kelompok);
        formik.setFieldValue('modal',Math.round(dataStokKoreksi.modalbarang));
        setDisabledModal(false);
      }else{
        formik.setFieldValue('tipeperubahan',e.target.value);
        formik.setFieldValue('kelompok',dataStokKoreksi.kelompok);
        formik.setFieldValue('modal',Math.round(dataStokKoreksi.modalbarang));
        setDisabledModal(true);
      }
    }
    const handleCloseDialog = () =>{
      formik.handleReset();
      closeKoreksi();
    }
    const handleCloseSnackbar = (event,reason) =>{
      if(reason === 'clickaway'){
          return;
      }
      setOpensnackbar(false);
      setTimeout(() => {
          dispatch(setStokBarangMessage(''));
      }, 100);
    }
  return (
    <Dialog open={openKoreksi} {...rest}>
    <Box sx={{width:'350px',display:'flex'}}>
    <DialogContent>
    <Typography variant='h6' align='center'>Koreksi Stok</Typography>
        <Divider sx={{width:'100%'}}/>
        <TextField
          id='tipeperubahan'
          select
          name='tipeperubahan'
          variant="standard"
          sx={textBoxCss}
          label="Tipe Perubahan"
          SelectProps={{
              native: true,
              }}
          value={formik.values.tipeperubahan}
          error={formik.touched.tipeperubahan && Boolean(formik.errors.tipeperubahan)}
          helperText={formik.touched.tipeperubahan && formik.errors.tipeperubahan}
          onChange={(e)=>{handleChangeTipePerubahan(e)}}
          >   
          <option aria-label="None" value="" />
              {tipePerubahan.map((tipe)=>(
                  <option key ={tipe.value} value={tipe.value}>
                      {tipe.label}
                  </option>
              ))}
          </TextField>
          <TextField
          id='kelompok'
          name='kelompok'
          variant="standard"
          sx={textBoxCss}
          label="Kelompok"
          disabled={true}
          value={formik.values.kelompok}
          error={formik.touched.kelompok && Boolean(formik.errors.kelompok)}
          helperText={formik.touched.kelompok && formik.errors.kelompok}
          onChange={formik.handleChange}
          />
          <TextField
          id='jumlah'
          name='jumlah'
          variant="standard"
          sx={textBoxCss}
          label="Jumlah"
          value={formik.values.jumlah}
          error={formik.touched.jumlah && Boolean(formik.errors.jumlah)}
          helperText={formik.touched.jumlah && formik.errors.jumlah}
          onChange={formik.handleChange}
          />
          <TextField
          id='modal'
          name='modal'
          variant="standard"
          disabled={disabledModal}
          sx={textBoxCss}
          label="Modal"
          value={formik.values.modal}
          error={formik.touched.modal && Boolean(formik.errors.modal)}
          helperText={formik.touched.modal && formik.errors.modal}
          onChange={formik.handleChange}
          />
          <TextField
          multiline
          id='keterangan'
          name='keterangan'
          variant="standard"
          sx={textBoxCss}
          label="Keterangan"
          value={formik.values.keterangan}
          error={formik.touched.keterangan && Boolean(formik.errors.keterangan)}
          helperText={formik.touched.keterangan && formik.errors.keterangan}
          onChange={formik.handleChange}
          />
          <Box sx={{width:'100%',display:'flex',justifyContent:'space-between',marginTop:'20px'}}>
              <Button 
              disabled={isLoading} 
              variant='contained' 
              color='primary' 
              onClick={formik.handleSubmit}
              >
                Proses</Button>
              <Button 
              disabled={isLoading} 
              variant='contained' 
              color='error' 
              onClick={handleCloseDialog}
              >
                Keluar</Button>
          </Box>
        </DialogContent>
        </Box>
        <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={stokbarangMessage === "Koreksi stok barang berhasil disimpan" ? "success" : "error"}
                >
                    {stokbarangMessage}
                    </Alert>
            </Snackbar>
        </Dialog>
  )
}

export default KoreksiStokDialog