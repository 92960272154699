import { Backdrop, Button,  CircularProgress, Container, Divider, Snackbar, TextField, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import * as yup from 'yup';
import MuiAlert from '@mui/material/Alert';
import { emptyDistributorMessage, getDetailsDistributor, updateDistributor } from '../../../config/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { useHistory, useParams } from 'react-router-dom';
import NumberFormat from 'react-number-format';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });


const UbahDistributor = () => {
    const theme = useTheme();
    const boxWapper = {
        display:'flex',
        justifyContent:'center',
        [theme.breakpoints.up('sm')]:{
            width:'100%',
            maxHeight:'500px',
            height:'100%',
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            height:'100%',
        }
    }
    const paperInput={
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        // border:'1px solid black',
        [theme.breakpoints.up('sm')]:{
            width:"35%",
            height:'100%',
            borderRadius:'10px',
            marginTop:'10px'
        },
        [theme.breakpoints.down('sm')]:{
            width:'90%',
            height:'100%',
            borderRadius:'10px',
            marginTop:'10px'
        }
    }
    const {distributorMessage,isLoading,detailsDistributor} = useSelector(state=>state.distributorReducers);
    const {dataLogin} = useSelector(state=>state.penggunaReducers);
    const [openSnackbar,setOpensnackbar]=useState(false);
    const [token,setToken] = useState(dataLogin.token);
    let {iddistributor} = useParams();
    const history = useHistory();
   
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const dispatch = useDispatch();
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(emptyDistributorMessage(''));
        }, 100);
    }
    useEffect(()=>{
        if(detailsDistributor.length<1){
            dispatch(getDetailsDistributor(iddistributor,token,setOpensnackbar))
        }
    },[detailsDistributor,iddistributor,token,dispatch])
    const schemaValidation = yup.object().shape({
        nama:yup.string().required("Nama tidak boleh kosong"),
        alamat:yup.string().required("Alamat tidak boleh kosong"),
        notlp:yup.number().required("Nomor telephone tidak boleh kosong"),
        npwp:yup.string()
    })
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            nama:detailsDistributor.nama||'',
            alamat:detailsDistributor.alamat||'',
            notlp:detailsDistributor.notlp||'',
            npwp:detailsDistributor.npwp||''
        },
        validationSchema:schemaValidation,
        onSubmit:(values)=>{
            const data = new URLSearchParams();
            data.append('nama',values.nama);
            data.append('npwp',values.npwp);
            data.append('alamat',values.alamat);
            data.append('notlp',values.notlp);
            dispatch(updateDistributor(iddistributor,data,token,setOpensnackbar,history));
        }
    })
    return (
        <Container maxWidth='xl' sx={{marginTop:'100px'}}>
        <Typography variant='h6' pl={1} mt={1}>Perubahan Data Distributor</Typography>
        <Divider/>
        <Box sx={boxWapper}>
            <Box sx={paperInput}>
                    <TextField
                    id="nama"
                    name="nama"
                    label="Nama"
                    variant="standard"
                    style={{width:'80%',marginTop:'10px'}}
                    value={formik.values.nama}
                    error={formik.touched.nama && Boolean(formik.errors.nama)}
                    helperText={formik.touched.nama && formik.errors.nama}
                    onChange={formik.handleChange}
                    />
                    <TextField
                    id="npwp"
                    name="npwp"
                    label="NPWP"
                    variant="standard"
                    style={{width:'80%',marginTop:'10px'}}
                    value={formik.values.npwp}
                    error={formik.touched.npwp && Boolean(formik.errors.npwp)}
                    helperText={formik.touched.npwp && formik.errors.npwp}
                    onChange={formik.handleChange}
                    />
                    <TextField
                    multiline
                    id="alamat"
                    name="alamat"
                    label="Alamat"
                    variant="standard"
                    style={{width:'80%',marginTop:'10px'}}
                    value={formik.values.alamat}
                    error={formik.touched.alamat && Boolean(formik.errors.alamat)}
                    helperText={formik.touched.alamat && formik.errors.alamat}
                    onChange={formik.handleChange}
                    />
                    <NumberFormat customInput={TextField} 
                    format="#### ###########"
                    style={{width:'80%',marginTop:'10px'}}
                    id='notlp'
                    name='notlp'
                    label="Nomor Telephone"
                    variant="standard"
                    value={formik.values.notlp}
                    error={formik.touched.notlp && Boolean(formik.errors.notlp)}
                    helperText={formik.touched.notlp && formik.errors.notlp}
                    onChange={formik.handleChange}
                    />
                    <Button variant='contained' sx={{marginTop:'10px',marginBottom:'10px',width:'100px'}} onClick={formik.handleSubmit} disabled={isLoading}>SIMPAN</Button>
            </Box>
        </Box>
        <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={distributorMessage === 'Perubahan data distributor berhasil' ? "success" : "error"}
                >
                    {distributorMessage}
                    </Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Container>
    )
}

export default UbahDistributor
