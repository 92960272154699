import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { Backdrop, Box, Button, CircularProgress, Dialog, Divider, Snackbar, TextField, Typography, useTheme } from '@mui/material';
import { emptyStokReturMessage, singleEditStokRetur } from '../../../config/redux/actions';
import { useFormik } from 'formik';
import * as yup from 'yup';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const UbahStokReturDialog = ({openDialogUbah,closeDialogUbah,tempDataRetur}) => {
  const theme = useTheme();
    const boxWapper = {
        display:'flex',
        justifyContent:'center',
        [theme.breakpoints.up('sm')]:{
            width:'500px',
            maxHeight:'500px',
            height:'100%',
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            height:'100%',
        }
    }
    const paperInput={
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        // border:'1px solid black',
        [theme.breakpoints.up('sm')]:{
            width:"50%",
            height:'100%',
            borderRadius:'10px',
            marginTop:'10px'
        },
        [theme.breakpoints.down('sm')]:{
            width:'90%',
            height:'100%',
            borderRadius:'10px',
            marginTop:'10px'
        }
    }
    const {isLoading,stokreturMessage} =useSelector(state=>state.stokreturReducers);
    const {dataLogin} = useSelector(state=>state.penggunaReducers);
    const [openSnackbar,setOpensnackbar]=useState(false);
    const [token,setToken] = useState(dataLogin.token);
    const dispatch = useDispatch();
    if(token ===''){
      setToken(Cookies.get('u_tkn'));
    }
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(emptyStokReturMessage(''));
        }, 100);
    }
    const schemaValidation = yup.object().shape({
      jumlah:yup.number().required("Kolom Jumlah Harus di Isi").min(0,"Jumlah tidak boleh kurang dari 0"),
    })
  const formik = useFormik({
      enableReinitialize:true,
      initialValues:{
          jumlah:tempDataRetur.jumlah||0
      },
      validationSchema:schemaValidation,
      onSubmit:(values)=>{
          const data = {
            'idbarang':tempDataRetur.idbarang,
            'idsatuan':tempDataRetur.idsatuan,
            'jumlah':values.jumlah,
            'search':tempDataRetur.search,
            'page':tempDataRetur.counter
          };
          dispatch(singleEditStokRetur(data,token,setOpensnackbar,closeDialogUbah));
      }
  })
  return (
    <Dialog open={openDialogUbah} maxWidth='xl' sx={{marginTop:'100px'}}>
        <Typography variant='h6' p={1} mt={1} align='center'>Perubahan Stok Retur</Typography>
        <Typography variant='body' p={1} align='center'>{tempDataRetur.namabarang} ({tempDataRetur.satuan})</Typography>
        <Divider/>
        <Box sx={boxWapper}>
            <Box sx={paperInput}>
              <TextField
                  id="jumlah"
                  name="jumlah"
                  label="Jumlah"
                  variant="standard"
                  inputProps={{min:0,style:{textAlign:'center'}}}
                  style={{width:'50%',marginTop:'10px'}}
                  value={formik.values.jumlah}
                  error={formik.touched.jumlah && Boolean(formik.errors.jumlah)}
                  helperText={formik.touched.jumlah && formik.errors.jumlah}
                  onChange={formik.handleChange}
                  />
              <Box sx={{width:'100%',display:'flex',justifyContent:'center'}}>
                  <Button variant='contained' sx={{marginTop:'10px',marginBottom:'10px',marginRight:'10px',width:'100px'}} 
                  onClick={formik.handleSubmit} 
                  disabled={isLoading}>SIMPAN</Button>
                  <Button variant='contained' color='error' sx={{marginTop:'10px',marginBottom:'10px',width:'100px'}} 
                  onClick={closeDialogUbah} 
                  disabled={isLoading}>BATAL</Button>
              </Box>
            </Box>
        </Box>
        <Snackbar 
            open={openSnackbar} 
            autoHideDuration={1000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={stokreturMessage === 'Perubahan stok retur berhasil' ? "success" : "error"}
                >
                    {stokreturMessage}
                    </Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
    </Dialog>
  )
}

export default UbahStokReturDialog