import axios from 'axios';
import { apiUrl } from '../..';

export const getAllKonversi = (idbarang,token,setOpensnackbar)=>(dispatch)=>{
    const tempId = new URLSearchParams();
    tempId.append('idbarang',idbarang);
    axios.post(`${apiUrl.url}konversi/getDetails/`,tempId,{
        headers:{
            "Authorization" : `Bearer ${token}`,
          }
    })
    .then(res=>{
      const ResponseApi = res.data;
      dispatch({
        type:'GET ALL KONVERSI',
        payload:ResponseApi.data
      })
    })
    .catch(error=>{
      dispatch({
        type:'FETCH ACTION KONVERSI',
        payload:error.message
      })
      setOpensnackbar(true);
    })
  }
  export const addNewKonversi = (data,token,setOpensnackbar,formik)=>(dispatch)=>{
    dispatch({
      type:'FETCH ACTION KONVERSI'
    })
    axios.post(`${apiUrl.url}konversi/`,data,{
      headers:{
        "Authorization" : `Bearer ${token}`,
      }
    })
    .then((res)=>{
       const ResponseApi = res.data;
       dispatch({
         type:'SUCCESSED ACTION KONVERSI',
         payload:ResponseApi.message
       })
       formik.setFieldValue('darisatuan',"");
       formik.setFieldValue('kesatuan',"");
      formik.setFieldValue('jumlah',"");
       setOpensnackbar(true);
    })
    .catch(error=>{
      dispatch({
        type:'FAILED ACTION KONVERSI',
        payload:error.message
      })
      setOpensnackbar(true);
    })
  }
  export const errorMessageKonversi = (message,setOpensnackbar) =>(dispatch)=>{
    dispatch({
      type:'FAILED ADD KONVERSI',
      payload:message
    })
    setOpensnackbar(true)
  }
  export const emptyKonversiMessage = ()=>(dispatch)=>{
    dispatch({
        type:'EMPTY KONVERSI MESSAGE'
      })
  }

export const getSatuanKonversiList = (idbarang,token,setOpensnackbar) =>(dispatch)=>{
  const tempId = new URLSearchParams();
    tempId.append('idbarang',idbarang);
    dispatch({
      type:'FETCH ACTION KONVERSI'
    })
    axios.post(`${apiUrl.url}barang/rincian`,tempId,{
      headers:{
        "Authorization" : `Bearer ${token}`,
      }
    })
    .then(res=>{
      const ResponseApi = res.data;
        dispatch({
          type:'SUCCESSED GET SATUAN KONVERSI',
          payload:ResponseApi.data,
          message:ResponseApi.message
        })
    })
    .catch(error=>{
      dispatch({
        type:'FAILED GET SATUAN KONVERSI',
        payload:error.message
      })
      setOpensnackbar(true);
    })
}
export const deleteKonversiBarang = (idkonversi,token,setOpensnackbar,setOpenDeleteDialog)=>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION KONVERSI'
  })
  setTimeout(()=>{
    axios.delete(`${apiUrl.url}konversi/${idkonversi}`,{
      headers:{
        "Authorization" : `Bearer ${token}`,
        'content-type':'application/x-www-form-urlencoded'
      }
    })
    .then(res=>{
      const ResponseApi = res.data;
      dispatch({
        type:'SUCCESSED ACTION KONVERSI',
        payload:ResponseApi.message
      })
      if(ResponseApi.message === 'Penghapusan data konversi berhasil'){
        setOpensnackbar(true);
        setOpenDeleteDialog(false);
      }else{
        setOpensnackbar(true);
        setOpenDeleteDialog(false);
      }
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION KONVERSI',
          payload:error.message
        })
        setOpensnackbar(true);
    })
  },2000)
}

export const emptyArrSatuan = () =>(dispatch)=>{
  dispatch({
    type:'EMPTY ARRAY SATUAN KONVERSI'
  })
}