import { Box, Button, IconButton, Tooltip, Typography } from '@mui/material'
import React from 'react'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const TablePagination = ({page,totalPage,onButtonClick,handlePrev,handleNext,hiddenButton}) => {
    return (
        <Box sx={{paddingTop:'5px',width:'100%',display:'flex',justifyContent:'space-between',alignItems:'center'}}>
            
            <Box sx={{width:'50%',display:'flex',justifyContent:'flex-start'}}>
            {hiddenButton === true ? null : <Button variant='contained' color='primary' onClick={onButtonClick}>Data Baru</Button>}
            </Box>
            
            <Box sx={{width:'50%',display:'flex',justifyContent:'flex-end',alignItems:'center'}}>
                <Typography>Halaman {page} dari {totalPage} </Typography> 
                <Tooltip title="Sebelumnya">
                    <IconButton size='small' onClick={handlePrev}>
                        <NavigateBeforeIcon/>
                    </IconButton>
                </Tooltip>
                <Tooltip title="Selanjutnya">
                    <IconButton size='small' onClick={handleNext}>
                        <NavigateNextIcon/>
                    </IconButton>
                </Tooltip> 
            </Box>
        </Box>
    )
}

export default TablePagination
