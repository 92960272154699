import React from 'react'
import { LeftMenu, TopBar } from '../../components/organism';
import Content from '../../components/organism/Content';

const Home = () => {
    return (
        <div style={{display:'flex'}}>
            <TopBar/> 
            <LeftMenu/> 
            <Content/>
        </div>
    )
}

export default Home
