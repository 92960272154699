const returInitialValue = {
    isLoading :false,
    lastBuy:'',
    returMessage:'',
    cartRetur:[],
    cartPPNRetur:[],
    dataReturPembelian:[],
    dataReturPembelianById:'',
    dataReturPembelianDetails:[]
}
const returReducers = (state=returInitialValue,action)=>{
    switch(action.type){
        case 'FETCH ACTION RETUR':{
            return{
                ...state,
                isLoading:true
            }
        }
        case 'SUCCESSED GET LAST BUY PRICE':{
            return{
                ...state,
                isLoading:false,
                lastBuy:action.payload
            }
        }
        case 'EMPTY LAST BUY PRICE':{
            return{
                ...state,
                lastBuy:''
            }
        }
        case 'FAILED ACTION RETUR':{
            return{
                ...state,
                isLoading:false,
                returMessage:action.payload
            }
        }
        case 'SUCCESSED POST RETUR':{
            return{
                ...state,
                isLoading:false,
                returMessage:action.payload
            }
        }
        case 'SUCCESSED GET RETUR PEMBELIAN DETAILS':{
            return{
                ...state,
                isLoading:false,
                dataReturPembelianById:action.payload,
                dataReturPembelianDetails:action.details
            }
        }
        case 'SUCCESSED GET RETUR PEMBELIAN':{
            return{
                ...state,
                isLoading:false,
                dataReturPembelian:action.payload
            }
        }
        case 'ADD RETUR TO CART':{
            return{
                ...state,
                cartRetur:[...state.cartRetur,action.payload]
            }
        }        case 'SET RETUR MESSAGE':{
            return{
                ...state,
                returMessage:action.payload
            }
        }
        case 'SET DELETE CART RETUR':{
            return{
                ...state,
                cartRetur:state.cartRetur.filter(retur=>retur.id !== action.payload)
            }
        }
        case 'SET EMPTY CART RETUR':{
            return{
                ...state,
                cartRetur:[]
            }
        }
        default: return state
    }
}

export default returReducers;