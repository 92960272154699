const draftInitialValues={
    isLoadingDraft:false,
    draftPenjualan:[],
    draftRetur:[],
    draftMessage:'',
    pageDraftPenjualan : {
        currentPage:'1',
        totalPage:'1'
    },
    statusDraft:'Draft',
    statusDraftRetur:'',
    tempFakturDraft:'',
    cartDataPenjualan:[]
}

const draftReducers = (state=draftInitialValues,action)=>{
    switch(action.type){
        case 'FETCH DRAFT':{
            return{
                ...state,
                isLoadingDraft:true
            }
        }
        case 'UPDATE PAGE DRAFT':{
            return {
                ...state,
                pageDraftPenjualan : action.payload
            }
        }
        case 'UPDATE DATA DRAFT':{
            return{
                ...state,
                draftPenjualan:action.payload,
                isLoadingDraft:false
            }
        }
        case 'UPDATE DATA DRAFT RETUR':{
            return{
                ...state,
                draftRetur:action.draftRetur,
                isLoadingDraft:false
            }
        }
        case 'FAILED GET DRAFT':{
            return{
                ...state,
                draftPenjualan:[],
                draftRetur:[],
                draftMessage:action.message,
                isLoadingDraft:false
            }
        }
        case 'SUCCESSED DELETE DATA DRAFT':{
            return{
                ...state,
                isLoadingDraft:false,
                draftMessage:action.payload
            }
        }
        case 'FAILED DELETE DATA DRAFT':{
            return{
                ...state,
                isLoadingDraft:false,
                draftMessage:action.payload
            }
        }
        case 'SET EMPTY DRAFT MESSAGE':{
            return{
                ...state,
                draftMessage:action.payload
            }
        }
        case 'SET EMPTY DRAFT LIST':{
            return{
                ...state,
                draftPenjualan:[]
            }
        }
        case 'SET STATUS DRAFT':{
            return{
                ...state,
                statusDraft:action.status,
                tempFakturDraft:action.tempFaktur
            }
        }
        case 'SET STATUS DRAFT DEFAULT':{
            return{
                ...state,
                statusDraft:action.status
            }
        }
        case 'SUCCESSED GET DATA DRAFT PENJUALAN':{
            return{
                ...state,
                isLoadingDraft:false,
                draftMessage:action.message,
                cartDataPenjualan:action.payload
            }
        }
        case 'SUCCESSED ADD PENJUALAN DETAILS':{
            return{
                ...state,
                isLoadingDraft:false,
                draftMessage:action.payload
            }
        }
        case 'SUCCESSED DELETE PENJUALAN DETAILS':{
            return{
                ...state,
                isLoadingDraft:false,
                draftMessage:action.payload
            }
        }
        case 'SUCCESSED ACTION DRAFT':{
            return{
                ...state,
                isLoadingDraft:false,
                message:action.payload
            }
        }
        case 'FAILED ACTION DRAFT':{
            return{
                ...state,
                isLoadingDraft:false,
                message:action.payload
            }
        }
        case 'EMPTY DRAFT FORM':{
            return{
                ...state,
                isLoadingDraft:false,
                draftPenjualan:[],
                draftRetur:[],
                draftMessage:'',
                pageDraftPenjualan : {
                    currentPage:'1',
                    totalPage:'1'
                },
                statusDraft:'Draft',
                statusDraftRetur:'',
                tempFakturDraft:'',
                cartDataPenjualan:[]
            }
        }
        default: return state
    }
}

export default draftReducers;