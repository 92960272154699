import axios from 'axios';
import { apiUrl } from '../..';

export const getAllDistributor = (search,page,token,setOpensnackbar) =>(dispatch)=>{
    dispatch({
      type:'FETCH GET ALL DISTRIBUTOR',
    })
    axios.get(`${apiUrl.url}distributor/?search=${search}&page=${page}&perPage=50`,{
          headers:{
            "Authorization" : `Bearer ${token}`
          }
      })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
          type:'UPDATE DATA DISTRIBUTOR', 
          payload:ResponseAPI.data
       })
        dispatch({
          type:'UPDATE PAGE DISTRIBUTOR',
          payload:{
            currentPage: ResponseAPI.current_page,
            totalPage: Math.ceil(ResponseAPI.totalData/ResponseAPI.per_page)
          }
        })
        
    })
    .catch(error=>{
      dispatch({
        type:'FAILED GET ALL DISTRIBUTOR',
        payload:error.message
      })
      setOpensnackbar(true);
    })
  }
  export const emptyDistributorMessage = (message) =>(dispatch)=>{
    dispatch({
      type:'EMPTY DISTRIBUTOR MESSAGE',
      payload:message
    })
  }
export const addNewDistributor = (data,token,setOpensnackbar,history)=>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION DISTRIBUTOR'
  })
  setTimeout(()=>{
    axios.post(`${apiUrl.url}distributor/`,data,{
      headers:{
        "Authorization" : `Bearer ${token}`,
        'content-type':'application/x-www-form-urlencoded'
      }
    })
    .then(res=>{
      const ResponseApi = res.data;
      dispatch({
        type:'SUCCESSED ACTION DISTRIBUTOR',
        payload:ResponseApi.message
      })
      if(ResponseApi.message==='Penambahan data distributor berhasil'){
        setOpensnackbar(true);
        setTimeout(()=>{
          dispatch(getAllDistributor('',1,token,setOpensnackbar));
          history.push('/distributor');
        },2000)
      }else{
        setOpensnackbar(true);
      }
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION DISTRIBUTOR',
          payload:error.message
        })
        setOpensnackbar(true);
    })
  },2000)
  
}

export const deleteDistributor = (iddistributor,token,setOpensnackbar,search,counter,setOpenDialog)=>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION DISTRIBUTOR'
  })
  setTimeout(()=>{
    axios.delete(`${apiUrl.url}distributor/${iddistributor}`,{
      headers:{
        "Authorization" : `Bearer ${token}`,
      }
    })
    .then(res=>{
      const ResponseApi = res.data;
      dispatch({
        type:'SUCCESSED ACTION DISTRIBUTOR',
        payload:ResponseApi.message
      })
      if(ResponseApi.message === 'Penghapusan data distributor berhasil'){
        dispatch(getAllDistributor(search,counter,token,''));
        setOpensnackbar(true);
        setOpenDialog(false);
      }else{
        setOpensnackbar(true);
        setOpenDialog(false);
      }
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION DISTRIBUTOR',
          payload:error.message
        })
        setOpensnackbar(true);
    })
  })
}

export const getDetailsDistributor = (iddistributor,token,setOpensnackbar)=>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION DISTRIBUTOR'
  })
  axios.get(`${apiUrl.url}distributor/${iddistributor}`,{
    headers:{
      "Authorization" : `Bearer ${token}`,
    }
  })
  .then(res=>{
    const ResponseApi = res.data;
    dispatch({
      type:'SUCCESSED GET DISTRIBUTOR DETAILS',
      payload:{
          'npwp':ResponseApi.data.npwp,
          'nama':ResponseApi.data.nama,
          'alamat':ResponseApi.data.alamat,
          'notlp':ResponseApi.data.notlp
      },
      message:ResponseApi.message
    })
  })
  .catch(error=>{
    dispatch({
      type:'FAILED ACTION DISTRIBUTOR',
      payload:error.message
    })
    setOpensnackbar(true);
  })
}

export const updateDistributor = (iddistributor,data,token,setOpensnackbar,history)=>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION DISTRIBUTOR'
  })
  setTimeout(()=>{
    axios.patch(`${apiUrl.url}distributor/${iddistributor}`,data,{
      headers:{
        "Authorization" : `Bearer ${token}`,
        'content-type':'application/x-www-form-urlencoded'
      }
    })
    .then(res=>{
      const ResponseApi = res.data;
      dispatch({
        type:'SUCCESSED ACTION DISTRIBUTOR',
        payload:ResponseApi.message
      })
      if(ResponseApi.message==='Perubahan data distributor berhasil'){
        setOpensnackbar(true);
        setTimeout(()=>{
          dispatch(getAllDistributor('',1,token,setOpensnackbar));
          history.push('/distributor');
        },1000)
      }else{
        setOpensnackbar(true);
      }
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION DISTRIBUTOR',
          payload:error.message
        })
        setOpensnackbar(true);
    })
  },2000)
  
}
export const getAllDistributorForCombo = (search,token,setOpensnackbar) =>(dispatch)=>{
  dispatch({
    type:'FETCH ACTION DISTRIBUTOR',
  })
  axios.get(`${apiUrl.url}distributor/all/?search=${search}`,{
        headers:{
          "Authorization" : `Bearer ${token}`
        }
    })
  .then(res=>{
      const ResponseAPI = res.data;
      dispatch({
        type:'SUCCESSED GET DISTRIBUTOR FOR COMBO', 
        payload:ResponseAPI.data
     })
      
  })
  .catch(error=>{
    dispatch({
      type:'FAILED ACTION DISTRIBUTOR',
      payload:error.message
    })
    setOpensnackbar(true);
  })
}
export const emptyComboDistributor = () =>(dispatch)=>{
  dispatch({
    type:'EMPTY DATA COMBO DISTRIBUTOR'
  })
}