import { Button,  CircularProgress, Container, Divider, Snackbar, TextField, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import { useFormik } from 'formik';
import React, { useState } from 'react'
import * as yup from 'yup';
import MuiAlert from '@mui/material/Alert';
import { addNewPelanggan, emptyPelangganMessage } from '../../../config/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import NumberFormat from 'react-number-format';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });


const PelangganBaru = () => {
    
    const theme = useTheme();
    const boxWapper = {
        display:'flex',
        justifyContent:'center',
        [theme.breakpoints.up('sm')]:{
            width:'100%',
            maxHeight:'500px',
            height:'100%',
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            height:'100%',
        }
    }
    const paperInput={
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        // border:'1px solid black',
        [theme.breakpoints.up('sm')]:{
            width:"35%",
            height:'100%',
            borderRadius:'10px',
            marginTop:'10px'
        },
        [theme.breakpoints.down('sm')]:{
            width:'90%',
            height:'100%',
            borderRadius:'10px',
            marginTop:'10px'
        }
    }
    const {pelangganMessage,isLoading} = useSelector(state=>state.pelangganReducers);
    const {dataLogin} = useSelector(state=>state.penggunaReducers);
    const [openSnackbar,setOpensnackbar]=useState(false);
    const [token,setToken] = useState(dataLogin.token);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const dispatch = useDispatch();
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(emptyPelangganMessage(''));
        }, 100);
    }
    const history = useHistory();
    const schemaValidation = yup.object().shape({
        namaPelanggan:yup.string().required("Nama tidak boleh kosong"),
        alamatPelanggan:yup.string().required("Alamat tidak boleh kosong"),
        notlpPelanggan:yup.string().required("Nomor telephone tidak boleh kosong")
    })
    const formik = useFormik({
        initialValues:{
            namaPelanggan:'',
            alamatPelanggan:'',
            notlpPelanggan:''
        },
        validationSchema:schemaValidation,
        onSubmit:(values)=>{
            const data = new URLSearchParams();
            data.append('nama',values.namaPelanggan);
            data.append('alamat',values.alamatPelanggan);
            data.append('notlp',values.notlpPelanggan);
            dispatch(addNewPelanggan(data,token,setOpensnackbar,history));
        }
    })
    return (
        <Container maxWidth='xl' sx={{marginTop:'100px'}}>
        <Typography variant='h6' pl={1} mt={1}>Pendaftaran Pelanggan</Typography>
        <Divider/>
        <Box sx={boxWapper}>
            <Box sx={paperInput}>
            <TextField
                    id="namaPelanggan"
                    name="namaPelanggan"
                    label="Nama"
                    variant="standard"
                    style={{width:'80%',marginTop:'10px'}}
                    value={formik.values.namaPelanggan}
                    error={formik.touched.namaPelanggan && Boolean(formik.errors.namaPelanggan)}
                    helperText={formik.touched.namaPelanggan && formik.errors.namaPelanggan}
                    onChange={formik.handleChange}
                    />
                    <TextField
                    multiline
                    id="alamatPelanggan"
                    name="alamatPelanggan"
                    label="Alamat"
                    variant="standard"
                    style={{width:'80%',marginTop:'10px'}}
                    value={formik.values.alamatPelanggan}
                    error={formik.touched.alamatPelanggan && Boolean(formik.errors.alamatPelanggan)}
                    helperText={formik.touched.alamatPelanggan && formik.errors.alamatPelanggan}
                    onChange={formik.handleChange}
                    />
                    <NumberFormat customInput={TextField} 
                    format="#### ###########"
                    style={{width:'80%',marginTop:'10px'}}
                    id='notlpPelanggan'
                    name='notlpPelanggan'
                    label="Nomor Telephone"
                    variant="standard"
                    value={formik.values.notlpPelanggan}
                    error={formik.touched.notlpPelanggan && Boolean(formik.errors.notlpPelanggan)}
                    helperText={formik.touched.notlpPelanggan && formik.errors.notlpPelanggan}
                    onChange={formik.handleChange}
                    />
                    <Button variant='contained' sx={{marginTop:'10px',marginBottom:'10px',width:'100px'}} onClick={formik.handleSubmit} disabled={isLoading}>{isLoading === true ? <CircularProgress color='primary' size={25}/> : "SIMPAN"}</Button>
            </Box>
        </Box>
        <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={pelangganMessage === 'Penambahan data pelanggan berhasil' ? "success" : "error"}
                >
                    {pelangganMessage}
                    </Alert>
            </Snackbar>
        </Container>
    )
}

export default PelangganBaru
