const pembelianInitialValue = {
    isLoading :false,
    cartPembelian:[],
    fakturPembelian:'',
    pembelianMessage:'',
    listDiskon:[],
    dataBarang:{
        'idbarang':'',
        'namabarang':''
    },
    listDiskonTotal:[],
    ppn:0,
    listExcel:[],
    dataPembelian:[],
    dataPembelianById:'',
    pembelianDistributor:[],
    idreturpembelian:''
}
const pembelianReducers = (state=pembelianInitialValue,action)=>{
    switch(action.type){
        case 'SET PEMBELIAN MESSAGE':{
            return{
                ...state,
                pembelianMessage:action.payload
            }
        }
        case 'EMPTY CART PEMBELIAN':{
            return{
                ...state,
                cartPembelian:[]
            }
        }
        case 'ADD CART PEMBELIAN':{
            return{
                ...state,
                isLoading:false,
                cartPembelian:[...state.cartPembelian,action.payload]
            }
        }
        case 'DELETE CART PEMBELIAN':{
            return{
                ...state,
                cartPembelian:state.cartPembelian.filter(cart=>cart.id !== action.payload)
            }
        }
        case 'FETCH ACTION PEMBELIAN':{
            return{
                ...state,
                isLoading:true
            }
        }
        case 'SUCCESSED GET NO FAKTUR':{
            return{
                ...state,
                isLoading:false,
                fakturPembelian:action.payload
            }
        }
        case 'EMPTY FAKTUR PEMBELIAN':{
            return{
                ...state,
                fakturPembelian:''
            }
        }
        case 'SUCCESSED POST PEMBELIAN':{
            return{
                ...state,
                isLoading:false,
                pembelianMessage:action.payload,
                ppn:0
            }
        }
        case 'FAILED ACTION PEMBELIAN':{
            return{
                ...state,
                isLoading:false,
                pembelianMessage:action.payload
            }
        }
        case 'ADD LIST DISKON':{
            return{
                ...state,
                isLoading:false,
                listDiskon:[...state.listDiskon,action.payload]
            }
        }
        case 'DELETE DISKON':{
            return{
                ...state,
                listDiskon:state.listDiskon.filter(diskon=>diskon.id !== action.payload)
            }
        }
        case 'SET EMPTY LIST DISKON':{
            return{
                ...state,
                listDiskon:action.payload
            }
        }
        case 'ADD LIST DISKON TOTAL':{
            return{
                ...state,
                isLoading:false,
                listDiskonTotal:[...state.listDiskonTotal,action.payload]
            }
        }
        case 'DELETE DISKON TOTAL':{
            return{
                ...state,
                listDiskonTotal:state.listDiskonTotal.filter(diskon=>diskon.id !== action.payload)
            }
        } 
        case 'SET EMPTY LIST DISKON TOTAL':{
            return{
                ...state,
                listDiskonTotal:action.payload
            }
        }
        case 'GET NILAI PPN':{
            return{
                ...state,
                ppn:action.payload
            }
        }
        case 'EMPTY NILAI PPN':{
            return{
                ...state,
                ppn:action.payload
            }
        }
        case 'ADD FROM EXCEL':{
            return{
                ...state,
                listExcel:action.payload
            }
        }
        case 'CLEAR DATA FROM EXCEL':{
            return{
                ...state,
                listExcel:[]
            }
        }
        case 'DELETE DATA FROM EXCEL':{
            return{
                ...state,
                listExcel:state.listExcel.filter(list=>list.id !== action.payload)
            }
        }
        case 'SUCCESSED GET DATA PEMBELIAN':{
            return{
                ...state,
                isLoading:false,
                dataPembelian:action.payload
            }
        }
        case 'EMPTY DATA PEMBELIAN':{
            return{
                ...state,
                isLoading:false,
                dataPembelian:[]
            }
        }
        case 'SUCCESSED GET DATA PEMBELIAN BY ID':{
            return{
                ...state,
                isLoading:false,
                dataPembelianById:action.payload,
                cartPembelian:action.details
            }
        }
        case 'SUCCESSED PEMBATALAN PEMBELIAN':{
            return{
                ...state,
                isLoading:false,
                pembelianMessage:action.payload
            }
        }
        case 'SUCCESSED GET PEMBELIAN DISTRIBUTOR':{
            return{
                ...state,
                pembelianDistributor:action.payload,
                isLoading:false
            }
        }
        case 'SUCCESSED GET ID RETUR PEMBELIAN':{
            return{
                ...state,
                idreturpembelian:action.payload,
                isLoading:false
            }
        }
        case 'FAILED GET ID RETUR PEMBELIAN':{
            return{
                ...state,
                message:action.message,
                idreturpembelian:'',
                isLoading:false
            }
        }
        case 'EMPTY ID RETUR PEMBELIAN':{
            return{
                ...state,
                idreturpembelian:''
            }
        }
        case 'EMPTY PEMBELIAN DISTRIBUTOR':{
            return{
                ...state,
                pembelianDistributor:[]
            }
        }
        default: return state
    }
}

export default pembelianReducers;