const distributorInitialState = {
    isLoading:false,
    pageDistributor : {
        currentPage:'1',
        totalPage:'1'
    },
    dataDistributor:[],
    detailsDistributor:'',
    distributorMessage:'',
    dataComboDistributor:[]
}
const distributorReducers = (state=distributorInitialState,action)=>{
    switch(action.type){
        case 'EMPTY DISTRIBUTOR MESSAGE':{
            return{
                ...state,
                distributorMessage:action.payload
            }
        }
        case 'UPDATE PAGE DISTRIBUTOR':{
            return {
                ...state,
                pageDistributor:action.payload
            }
        }
        case 'UPDATE DATA DISTRIBUTOR':{
            return{
                ...state,
                isLoading:false,
                dataDistributor:action.payload
            }
        }
        case 'FETCH GET ALL DISTRIBUTOR':{
            return{
                ...state,
                isLoading:true
            }
        }
        case 'FAILED GET ALL DISTRIBUTOR':{
            return{
                ...state,
                isLoading:false,
                distributorMessage:action.payload
            }
        }
        case 'FETCH ACTION DISTRIBUTOR':{
            return{
                ...state,
                isLoading:true
            }
        }
        case 'SUCCESSED ACTION DISTRIBUTOR':{
            return{
                ...state,
                isLoading:false,
                distributorMessage:action.payload
            }
        }
        case 'FAILED ACTION DISTRIBUTOR':{
            return{
                ...state,
                isLoading:false,
                distributorMessage:action.payload
            }
        }
        case 'SUCCESSED GET DISTRIBUTOR DETAILS':{
            return{
                ...state,
                isLoading:false,
                detailsDistributor:action.payload,
                distributorMessage:action.message
            }
        }
        case 'SUCCESSED GET DISTRIBUTOR FOR COMBO':{
            return{
                ...state,
                isloading:false,
                dataComboDistributor:action.payload
            }
        }
        case 'EMPTY DATA COMBO DISTRIBUTOR':{
            return{
                dataComboDistributor:[]
            }
        }
        default: return state
    }
}
export default distributorReducers;