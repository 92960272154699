import React, { Fragment, useEffect,useState } from 'react'
import {Container,Typography,Divider,TextField, useTheme, Box,Autocomplete, styled, TableCell, TableRow, tableCellClasses, TableContainer, Paper, Table, TableHead, IconButton, Checkbox, TableBody, Tooltip, Snackbar, Button, Switch, Backdrop, CircularProgress, FormControlLabel} from '@mui/material';
import * as yup from 'yup';
import {useFormik} from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { getFakturPenjualan,getAllPelanggan, deleteItemPenjualan, getSatuanKonversiList, emptyArrSatuan, getHargaJual, emptyHargaJual, addToCartPenjualan, setPenjualanMessage, getLastStokQty, postPenjualan, postReturPenjualan, scanBarcode, MinusButton, clearLastStok, getLastQtyTable, postDraftPenjualan, setStatusDraft, getAllDataDraft, getDataDraftPenjualan, setEmptyDraftList, delPenjualanDetails, addPenjualanDetails, editDraftPenjualanDetails, setEmptyCartReturPenjualan, saveSameDraft, saveDraftPlusRetur, transferDraftToPenjualan, transferDraftReturPenjualan, editReturPenjualan, transferWithRetur, getNilaiPPNPenjualan, setEmptyDataPelanggan, setEmptyPenjualanToPrint, searchForSales} from '../../../config/redux/actions';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { format, parseISO } from 'date-fns';
import Cookies from 'js-cookie';
import MuiAlert from '@mui/material/Alert';
import { AddTodo, DeleteData, MinusItem, PlusItem, viewList } from '../../../components/assets';
// import AuthorizationDialog from '../../../components/molecules/authorizationDialog';
import ReturPenjualan from '../../ModulRetur/ReturPenjualan';
import { useHistory } from 'react-router-dom';
import { DraftPenjualanList } from '../../../components/molecules';
import ReplayCircleFilledIcon from '@mui/icons-material/ReplayCircleFilled';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'grey',
      color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const PenjualanPage = () => {
  const theme = useTheme();
  const boxWapper = {
    display:'flex',
    width:'100%',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'flex-start',
    // border:'1px solid black'
  }
const paperInput={
    display:'flex',
    justifyContent:'flex-start',
    marginLeft:'70px',
    [theme.breakpoints.up('sm')]:{
        width:"70%",
        borderRadius:'10px',
        marginTop:'10px'
    },
    [theme.breakpoints.down('sm')]:{
        width:'90%',
        borderRadius:'10px',
        marginTop:'10px'
    }
}
const paperTable = {
  marginTop:'10px',
  [theme.breakpoints.up('sm')]:{
      width:'90%',
      height:'350px',
  }, 
  [theme.breakpoints.down('sm')]:{
      width:'100%',
      height:'360px'
  },
  scrollbarWidth:'thin'
}
const todoList = {
  [theme.breakpoints.down('lg')]:{
    width:'100%',
    display:'flex',
    marginRight:'10px',
    justifyContent:'flex-start',
    marginBottom:'10px'
  },
  [theme.breakpoints.up('lg')]:{
    width:'90%',
    display:'flex',
    marginRight:'10px',
    justifyContent:'flex-start',
    marginBottom:'10px'
  }
}
// const jenisHarga = [
//   {
//     value: 'Harga Jual',
//     label: 'Retail',
//   },
//   {
//     value: 'Harga Grosir',
//     label: 'Grosir',
//   }
// ];
const pembayaran = [
  {
    value: 'Tunai',
    label: 'Tunai',
  },
  {
    value: 'Transfer',
    label: 'Transfer',
  },
  {
    value: 'Kredit',
    label: 'Kredit',
  }
];
const tipeDiskon = [
  {
    value : '-',
    label :'-'
  },
  {
    value : '%',
    label :'%'
  },
  {
    value : "RP",
    label:"RP"
  }
]
const penjualanInput={
  display:'flex',
  flexDirection:'column',
  justifyContent:'center',
  alignItems:'center',
  [theme.breakpoints.up('lg')]:{
      width:"100%",
      borderRadius:'10px',
      marginTop:'10px'
  },
  [theme.breakpoints.down('lg')]:{
      width:'100%',
      borderRadius:'10px',
      marginTop:'10px'
  }
}
const titleBox={
  display:'flex',
  width:'100%'
}
  const {cartPenjualan,penjualanMessage,lastStokQty,isLoading,ppnPenjualan,} = useSelector(state=>state.penjualanReducers);
  const {cartReturPenjualan,statusDraftRetur} = useSelector(state=>state.returpenjualanReducers);
  const {timeConnection} = useSelector(state=>state.homeReducers);
  const {dataLogin,detailsPengguna} =useSelector(state=>state.penggunaReducers);
  const {dataBarang} = useSelector(state=>state.barangReducers);
  const {dataPelanggan}=useSelector(state=>state.pelangganReducers);
  const {arrSatuanKonversi}=useSelector(state=>state.konversiReducers);
  const {statusDraft,tempFakturDraft,isLoadingDraft}=useSelector(state=>state.draftReducers);
  const [token,setToken] = useState(dataLogin.token);
  const [tipeHarga,setTipeHarga]=useState('Harga Jual');
  const [openSnackbar,setOpensnackbar]=useState(false);
  const [satuanText,setSatuanText]=useState('');
  const [otorisasi,setOtorisasi]=useState(false);
  const [totalItem,setTotalItem]=useState(0);
  const [totalPenjualan,setTotalPenjualan]=useState(0);
  // const [disableTipeHarga,setDisableTipeHarga] = useState(false);
  // const [disablePembayaran,setDisablePembayaran] = useState(false);
  // const [authDialog,setAuthDialog]=useState(false);
  const [disabledDiskon,setDisabledDiskon]=useState(true);
  const [totalPPN,setTotalPPN]=useState(0);
  const [checkRetur,setCheckRetur]=useState(false);
  const [statusSave,setStatusSave]=useState("Simpan");
  const [fakturField,setFakturField]=useState(true);
  const [checkSwitch,setCheckSwitch]=useState(false);
  const [savePenjualan,setSavePenjualan]=useState('Draft');
  const [openDraftList,setOpenDraftList]=useState(false);
  const [tanggalDisable,setTanggalDisable]=useState(false);
  const [diskonTotal,setDiskonTotal]=useState(0);
  if(token ===''){
    setToken(Cookies.get('u_tkn'));
  }
  const history = useHistory();
  const validationSchema = yup.object().shape({
    fakturpenjualan:yup.string().required("Faktur penjualan tidak boleh kosong"),
    namapelanggan:yup.string().required("Pelanggan belum dipilih"),
    tanggal:yup.date().max(new Date(),"Tanggal transaksi penjualan tidak boleh lebih besar dari hari ini").min(new Date(1990,1,1),"Tanggal tidak valid"),
    jenisharga:yup.string().required("Tipe harga belum dipilih"),
    pembayaran:yup.string().required("Pembayaran belum dipilih"),
    alias:yup.string()
  })
  const formik = useFormik({
      // enableReinitialize:true,
      initialValues:{
        fakturpenjualan:'',
        idpelanggan:'',
        namapelanggan:'',
        tanggal:new Date(),
        jenisharga:'Harga Jual',
        pembayaran:'Tunai',
        norekening:'',
        alias:'',
        tipediskon:'-',
        diskon:0,
        checkdiskontotal:false
      },
      validationSchema:validationSchema,
      onSubmit:(values)=>{
          if(cartPenjualan.length<1){
            dispatch(setPenjualanMessage("Daftar penjualan belum terisi"));
            setOpensnackbar(true)
          }else{
            dispatch(setEmptyPenjualanToPrint());
            if(formik.values.idpelanggan===""){
              dispatch(setPenjualanMessage("Pelanggan wajib dipilih dari text yang muncul pada pelanggan"));
              setOpensnackbar(true)
            }else{
              if(statusDraft==="Draft"){
                const data ={
                  'idpelanggan':values.idpelanggan,
                  'user':detailsPengguna.nama,
                  'fakturpenjualan':values.fakturpenjualan,
                  'tanggal':format(values.tanggal,"yyyy-MM-dd"),
                  'tipeharga':values.jenisharga,
                  'totalItem':totalItem,
                  'totalBarangPPN':totalPPN,
                  'total':totalPenjualan,
                  'status':"Berhasil",
                  'pembayaran':values.pembayaran,
                  'alias':values.alias,
                  'tipeedit':"Kurang",
                  'listpenjualan':cartPenjualan
                }
                dispatch(postPenjualan(data,token,setOpensnackbar,formik,history,statusSave,returPenjualan,setTipeHarga));
              }else if(statusDraft==="EditDraft"){
                if(statusDraftRetur==="Yes" && cartReturPenjualan.length>0){
                  const data ={
                    'idpelanggan':values.idpelanggan,
                    'user':detailsPengguna.nama,
                    'fakturpenjualan':values.fakturpenjualan,
                    'tanggal':values.tanggal,
                    'totalItem':totalItem,
                    'totalBarangPPN':totalPPN,
                    'total':totalPenjualan,
                    'status':"Berhasil",
                    'pembayaran':values.pembayaran,
                    'alias':values.alias,
                    'tipeedit':"Kurang",
                    'listpenjualan':cartPenjualan
                  }
                  dispatch(transferDraftToPenjualan(data,token,setOpensnackbar,formik,history,statusSave,transferReturDraftToPenjualan,setTipeHarga));
                }else if(statusDraftRetur==="No" && cartReturPenjualan.length<1){
                  const data ={
                    'idpelanggan':values.idpelanggan,
                    'user':detailsPengguna.nama,
                    'fakturpenjualan':values.fakturpenjualan,
                    'tanggal':values.tanggal,
                    'totalItem':totalItem,
                    'totalBarangPPN':totalPPN,
                    'total':totalPenjualan,
                    'status':"Berhasil",
                    'pembayaran':values.pembayaran,
                    'alias':values.alias,
                    'tipeedit':"Kurang",
                    'listpenjualan':cartPenjualan
                  }
                  dispatch(transferDraftToPenjualan(data,token,setOpensnackbar,formik,history,statusSave,transferReturDraftToPenjualan,setTipeHarga));
                }else if(statusDraftRetur==="Yes" && cartReturPenjualan.length<1){
                  const data ={
                    'idpelanggan':values.idpelanggan,
                    'user':detailsPengguna.nama,
                    'fakturpenjualan':values.fakturpenjualan,
                    'tanggal':values.tanggal,
                    'totalItem':totalItem,
                    'totalBarangPPN':totalPPN,
                    'total':totalPenjualan,
                    'status':"Berhasil",
                    'pembayaran':values.pembayaran,
                    'alias':values.alias,
                    'tipeedit':"Kurang",
                    'listpenjualan':cartPenjualan
                  }
                  dispatch(transferDraftToPenjualan(data,token,setOpensnackbar,formik,history,statusSave,transferReturDraftToPenjualan,setTipeHarga));
                }else if(statusDraftRetur==="No" && cartReturPenjualan.length>0){
                  const data ={
                    'idpelanggan':values.idpelanggan,
                    'user':detailsPengguna.nama,
                    'fakturpenjualan':values.fakturpenjualan,
                    'tanggal':values.tanggal,
                    'totalItem':totalItem,
                    'totalBarangPPN':totalPPN,
                    'total':totalPenjualan,
                    'status':"Berhasil",
                    'pembayaran':values.pembayaran,
                    'alias':values.alias,
                    'tipeedit':"Kurang",
                    'listpenjualan':cartPenjualan
                  }
                  dispatch(transferWithRetur(data,token,setOpensnackbar,formik,history,statusSave,returPenjualan,setTipeHarga));
                }
              }
            }
          }
        }
  })
  const returPenjualan = (tempFakturPenjualan,tempIdPelanggan,tempTanggal) =>{
    if(cartReturPenjualan.length>0){
      let totalRetur=cartReturPenjualan.reduce((Retur,TotalP)=>{
            return Retur + TotalP.jumlah
    },0);
    let totalDPP=cartReturPenjualan.reduce((ReturDPP,TotalD)=>{
        if(TotalD.kelompok === "PPN"){
            let tempDPP =  100/(100+TotalD.ppn) * TotalD.jumlah;
            return ReturDPP +tempDPP;
        }
        else {
            return ReturDPP
        }
    },0);
    let totalPPN=cartReturPenjualan.reduce((ReturPPN,TotalP)=>{
      if(TotalP.kelompok === "PPN"){
          let tempPPN = TotalP.jumlah * TotalP.ppn / 100
          return ReturPPN + tempPPN
      }
      else {
          return ReturPPN
      }
    },0);
      const dataRetur={
          'idpelanggan':tempIdPelanggan,
          'fakturpenjualan':tempFakturPenjualan,
          'tanggal':tempTanggal,
          'total':totalRetur,
          'totaldpp':totalDPP,
          'totalppn':totalPPN,
          'status':"Berhasil",
          'listRetur':cartReturPenjualan
      }
      dispatch(postReturPenjualan(dataRetur,token,setOpensnackbar,setCheckRetur));
    }
  }
  const transferReturDraftToPenjualan = (tempFakturPenjualan,tempIdPelanggan,tempTanggal) =>{
    if(cartReturPenjualan.length>0){
    let totalRetur=cartReturPenjualan.reduce((Retur,TotalP)=>{
          return Retur + TotalP.jumlah
    },0);
    let totalDPP=cartReturPenjualan.reduce((ReturDPP,TotalD)=>{
        if(TotalD.kelompok === "PPN"){
            let tempDPP =  100/(100+TotalD.ppn) * TotalD.jumlah;
            return ReturDPP +tempDPP;
        }
        else {
            return ReturDPP
        }
    },0);
    let totalPPN=cartReturPenjualan.reduce((ReturPPN,TotalP)=>{
      if(TotalP.kelompok === "PPN"){
          let tempPPN = TotalP.jumlah * TotalP.ppn / 100
          return ReturPPN + tempPPN
      }
      else {
          return ReturPPN
      }
    },0);
      const dataRetur={
          'idpelanggan':tempIdPelanggan,
          'fakturpenjualan':tempFakturPenjualan,
          'tanggal':tempTanggal,
          'total':totalRetur,
          'totaldpp':totalDPP,
          'totalppn':totalPPN,
          'status':"Berhasil",
          'listRetur':cartReturPenjualan
      }
      dispatch(transferDraftReturPenjualan(dataRetur,token,setOpensnackbar,setCheckRetur));
    }
  }
  const draftReturPenjualan = (tempFakturPenjualan,tempIdPelanggan,tempTanggal) =>{
    if(cartReturPenjualan.length>0){
      let totalRetur=cartReturPenjualan.reduce((Retur,TotalP)=>{
            return Retur + TotalP.jumlah
    },0);
      const dataRetur={
          'idpelanggan':tempIdPelanggan,
          'fakturpenjualan':tempFakturPenjualan,
          'tanggal':tempTanggal,
          'total':totalRetur,
          'status':"Draft",
          'listRetur':cartReturPenjualan
      }
      dispatch(postReturPenjualan(dataRetur,token,setOpensnackbar,setCheckRetur));
    }
  }
  const editDraftReturPenjualan = (tempFakturPenjualan,tempIdPelanggan) =>{
    if(cartReturPenjualan.length>0){
      let totalRetur=cartReturPenjualan.reduce((Retur,TotalP)=>{
            return Retur + TotalP.jumlah
    },0);
      const dataRetur={
          'idpelanggan':tempIdPelanggan,
          'fakturpenjualan':tempFakturPenjualan,
          'total':totalRetur,
          'status':"Draft"
      }
      dispatch(editReturPenjualan(dataRetur,token,setOpensnackbar,setCheckRetur));
    }
  }
  const validationSchemaDraftPenjualan = yup.object().shape({
    fakturpenjualan:yup.string().required("Faktur penjualan tidak boleh kosong"),
    namapelanggan:yup.string().required("Pelanggan belum dipilih"),
    tanggal:yup.date().max(new Date(),"Tanggal transaksi penjualan tidak boleh lebih besar dari hari ini").min(new Date(1990,1,1),"Tanggal tidak valid"),
    jenisharga:yup.string().required("Tipe harga belum dipilih"),
    alias:yup.string()
  })
  const formikDraftPenjualan = useFormik({
      // enableReinitialize:true,
      initialValues:{
        fakturpenjualan:'',
        idpelanggan:'',
        namapelanggan:'',
        tanggal:new Date(),
        jenisharga:'Harga Jual',
        pembayaran:'Tunai',
        alias:'',
        tipediskon:'-',
        diskon:0,
        checkdiskontotal:false
      },
      validationSchema:validationSchemaDraftPenjualan,
      onSubmit:(values)=>{
          if(cartPenjualan.length<1){
            dispatch(setPenjualanMessage("Daftar penjualan belum terisi"));
            setOpensnackbar(true)
          }else{
            dispatch(setEmptyPenjualanToPrint());
            if(formikDraftPenjualan.values.idpelanggan===""){
              dispatch(setPenjualanMessage("Pelanggan wajib dipilih dari text yang muncul pada pelanggan"));
              setOpensnackbar(true)
            }else{
              if(statusDraft==="Draft"){
                const data ={
                  'idpelanggan':values.idpelanggan,
                  'user':detailsPengguna.nama,
                  'fakturpenjualan':values.fakturpenjualan,
                  'tanggal':format(values.tanggal,"yyyy-MM-dd"),
                  'tipeharga':values.jenisharga,
                  'totalItem':totalItem,
                  'totalBarangPPN':totalPPN,
                  'total':totalPenjualan,
                  'status':"Draft",
                  'pembayaran':values.pembayaran,
                  'alias':values.alias,
                  'tipeedit':"Tambah",
                  'listpenjualan':cartPenjualan
                }
                dispatch(postDraftPenjualan(data,token,setOpensnackbar,formikDraftPenjualan,history,statusSave,draftReturPenjualan,setTipeHarga));
              }
              else if(statusDraft==="EditDraft"){
                if(statusDraftRetur==="Yes" && cartReturPenjualan.length>0){
                  const data = {
                    'idpelanggan':values.idpelanggan,
                    'user':detailsPengguna.nama,
                    'fakturpenjualan':values.fakturpenjualan,
                    'totalItem':totalItem,
                    'total':totalPenjualan,
                    'pembayaran':values.pembayaran,
                    'status':"Draft",
                    'alias':values.alias
                  }
                  dispatch(saveSameDraft(data,token,statusSave,setOpensnackbar,formikDraftPenjualan,history,editDraftReturPenjualan,cartReturPenjualan,setTipeHarga));
                }else if(statusDraftRetur==="No" && cartReturPenjualan.length<1){
                  const data = {
                    'idpelanggan':values.idpelanggan,
                    'user':detailsPengguna.nama,
                    'fakturpenjualan':values.fakturpenjualan,
                    'totalItem':totalItem,
                    'total':totalPenjualan,
                    'pembayaran':values.pembayaran,
                    'status':"Draft",
                    'alias':values.alias
                  }
                  dispatch(saveSameDraft(data,token,statusSave,setOpensnackbar,formikDraftPenjualan,history,editDraftReturPenjualan,cartReturPenjualan,setTipeHarga));
                }
                else if(statusDraftRetur==="Yes" && cartReturPenjualan.length<1){
                  const data = {
                    'idpelanggan':values.idpelanggan,
                    'user':detailsPengguna.nama,
                    'fakturpenjualan':values.fakturpenjualan,
                    'totalItem':totalItem,
                    'total':totalPenjualan,
                    'pembayaran':values.pembayaran,
                    'status':"Draft",
                    'alias':values.alias
                  }
                  dispatch(saveSameDraft(data,token,statusSave,setOpensnackbar,formikDraftPenjualan,history,editDraftReturPenjualan,cartReturPenjualan,setTipeHarga));
                }else if(statusDraftRetur==="No" && cartReturPenjualan.length>0){
                  const data = {
                    'idpelanggan':values.idpelanggan,
                    'user':detailsPengguna.nama,
                    'fakturpenjualan':values.fakturpenjualan,
                    'totalItem':totalItem,
                    'total':totalPenjualan,
                    'pembayaran':values.pembayaran,
                    'tanggal':values.tanggal,
                    'status':"Draft",
                    'alias':values.alias
                  }
                  dispatch(saveDraftPlusRetur(data,token,statusSave,setOpensnackbar,formikDraftPenjualan,history,draftReturPenjualan,setTipeHarga));
                }
              }
            } 
          }
      }
  })
  const handleChangeTipeDiskon = (e) =>{
    formikCart.setFieldValue('tipediskon',e.target.value);
  }
  const handleChangeTipeDiskonTotal = (e) =>{
    if(savePenjualan==="Draft"){
      formikDraftPenjualan.setFieldValue('tipediskon',e.target.value);
    }else{
      formik.setFieldValue('tipediskon',e.target.value);
    }
  }
  const validationSchemaCart = yup.object().shape({
    idbarang:yup.string().required("ID Barang tidak boleh kosong"),
    namabarang:yup.string().required("Nama Barang tidak boleh kosong"),
    idsatuan:yup.string().required("Satuan belum dipilih"),
    qty:yup.number().required("Qty tidak boleh kosong").min(0,"Qty tidak boleh kurang dari 1"),
    harga:yup.number(),
    diskon:yup.number(),
    jumlah:yup.number().required("Jumlah belum terisi")
  })
  const formikCart = useFormik({
      initialValues:{
        idbarang:'',
        namabarang:'',
        idsatuan:'',
        harga:'',
        qty:'',
        tipediskon:'-',
        diskon:'',
        jumlah:''
      },
      validationSchema:validationSchemaCart,
      onSubmit:(values)=>{
        if(values.qty>0){
          if(lastStokQty.stok < values.qty){
            dispatch(setPenjualanMessage('Stok barang tidak mencukupi'));
            setOpensnackbar(true);
          }else if(lastStokQty.stok >= values.qty){
             const existBarang = cartPenjualan.filter(barang =>barang.idbarang === values.idbarang && barang.idsatuan === values.idsatuan);
             let tempHarga=parseFloat(values.harga)-parseFloat(values.diskon);
            //  let newModal;
            //  if(lastStokQty.kelompok==="PPN"){
            //   const getPPN = parseInt(ppnPenjualan)/100;
            //   const getNilai = lastStokQty.modalbarang *getPPN;
            //   newModal = Math.ceil(lastStokQty.modalbarang + getNilai);
            //  }else if(lastStokQty.kelompok==="NON PPN"){
            //   newModal = Math.ceil(lastStokQty.modalbarang);
            //  }
             if(existBarang.length>0){
                  dispatch(setPenjualanMessage('Barang dengan satuan ini telah terdaftar'));
                  setOpensnackbar(true);
                  formikCart.handleReset();
                  // setAuthDialog(false);
                  setOtorisasi(false);
                  setDisabledDiskon(true);
             }else if(tempHarga<lastStokQty.modalbarang){
                  dispatch(setPenjualanMessage('Harga jual kurang dari modal'));
                  setOpensnackbar(true);
                  formikCart.handleReset();
                  // setAuthDialog(false);
                  setOtorisasi(false);
                  setDisabledDiskon(true);
             }else{
               let tempTk=parseFloat(lastStokQty.modalbarang)*parseInt(values.qty);
               if(statusDraft==="Draft"){
                var tempId;
                if(cartPenjualan.length>0){
                  tempId=cartPenjualan[cartPenjualan.length-1].id+1;
                }else{
                  tempId=0;
                }
                  const data = {
                    'id':tempId,
                    'idbarang':values.idbarang,
                    'namabarang':values.namabarang,
                    'idsatuan':values.idsatuan,
                    'tempPPN':lastStokQty.kelompok==="PPN"?ppnPenjualan:0,
                    'kelompok':lastStokQty.kelompok,
                    'satuan':satuanText,
                    'harga':values.harga,
                    'qty':values.qty,
                    'diskon':values.diskon,
                    'jumlah':values.jumlah,
                    'mk':lastStokQty.modalbarang,
                    'tk':tempTk
                  }
                  dispatch(addToCartPenjualan(data,formikCart));
              }else if(statusDraft==="EditDraft"){
                let tempTanggal = parseISO(formikDraftPenjualan.values.tanggal);
                const data = {
                  'fakturpenjualan':formikDraftPenjualan.values.fakturpenjualan,
                  'tanggal':format(tempTanggal,"yyyy-MM-dd"),
                  'id':cartPenjualan[cartPenjualan.length-1].id+1,
                  'idbarang':values.idbarang,
                  'namabarang':values.namabarang,
                  'idsatuan':values.idsatuan,
                  'tempPPN':lastStokQty.kelompok==="PPN"?ppnPenjualan:0,
                  'kelompok':lastStokQty.kelompok,
                  'satuan':satuanText,
                  'harga':values.harga,
                  'qty':values.qty,
                  'diskon':values.diskon,
                  'jumlah':values.jumlah,
                  'mk':lastStokQty.modalbarang,
                  'tk':tempTk
                }
                dispatch(addPenjualanDetails(data,token,setOpensnackbar,formikCart));  
              }
              // setAuthDialog(false);
              setOtorisasi(false);
              setDisabledDiskon(true);
              dispatch(clearLastStok());
              formikCart.handleReset();
             }
          }
        }else {
          dispatch(setPenjualanMessage('Qty tidak boleh kurang dari 1'));
          setOpensnackbar(true);
        }
        dispatch(setEmptyDataPelanggan());
      }
  })
  const dispatch = useDispatch();
  useEffect(()=>{
    if(savePenjualan==="Draft"){
      if(formikDraftPenjualan.values.fakturpenjualan==="" && statusDraft === "Draft"){
        dispatch(getFakturPenjualan(format(new Date(),'yyyy-MM-dd'),token,setOpensnackbar,savePenjualan,formik,formikDraftPenjualan));
      }else if(formikDraftPenjualan.values.fakturpenjualan==="" && statusDraft !== "Draft"){
        formikDraftPenjualan.setFieldValue('fakturpenjualan',tempFakturDraft);
        const data = {
          'fakturpenjualan':tempFakturDraft
        }
        dispatch(getDataDraftPenjualan(data,token,setOpensnackbar,formikDraftPenjualan,setCheckRetur,setTipeHarga));
      }
    }else if(savePenjualan==="Penjualan"){
      if(formik.values.fakturpenjualan===""){
        dispatch(getFakturPenjualan(format(new Date(),'yyyy-MM-dd'),token,setOpensnackbar,savePenjualan,formik,formikDraftPenjualan));
      }
    }
    if(cartPenjualan.length>0){
      let totalPenjualan=cartPenjualan.reduce((Penjualan,TotalP)=>{
          return Penjualan + TotalP.jumlah;
      },0);
        let totalPenjualanPPN=cartPenjualan.reduce((Penjualan,TotalP)=>{
          if(TotalP.kelompok === "PPN"){
            return Penjualan + TotalP.jumlah;
          }else{
            return Penjualan;
          }
        },0);
        setTotalPenjualan(totalPenjualan-diskonTotal);
        setTotalPPN(totalPenjualanPPN);
        setTotalItem(cartPenjualan.length);
        // setDisableTipeHarga(true);
    }else{
        setTotalPenjualan(0);
        setTotalItem(0);
        setTotalPPN(0);
        // setDisableTipeHarga(false);
    }
    const listener = event => {
        if (event.code === "F2") {
          if(savePenjualan==="Draft"){
            event.preventDefault();
              setOpenDraftList(true);
              setTanggalDisable(false);
              dispatch(getAllDataDraft("",1,token,setOpensnackbar));
              dispatch(setStatusDraft("Draft",""));
            }else if(savePenjualan==="Penjualan"){
              dispatch(setPenjualanMessage("Fitur ini hanya berlaku untuk mode draft penjualan"));
              setOpensnackbar(true);
            }
        }
    };
      document.addEventListener("keydown", listener);
    
    return () => {
        document.removeEventListener("keydown", listener);
    };
  },[cartPenjualan,diskonTotal,totalItem,dispatch,formik,token,statusDraft,formikDraftPenjualan])
  const handleClickViewList = () =>{
    if(savePenjualan==="Draft"){
        setOpenDraftList(true);
        setTanggalDisable(false);
        dispatch(getAllDataDraft("",1,token,setOpensnackbar));
        dispatch(setStatusDraft("Draft",""));
      }else if(savePenjualan==="Penjualan"){
        dispatch(setPenjualanMessage("Fitur ini hanya berlaku untuk mode draft penjualan"));
        setOpensnackbar(true);
      }
  }
  const handleChangeTanggal = (e)=>{
    if(savePenjualan==="Draft"){
      formikDraftPenjualan.setFieldValue('tanggal',e);
    }else{
      formik.setFieldValue('tanggal',e);
    }
    dispatch(getFakturPenjualan(format(e,'yyyy-MM-dd'),token,setOpensnackbar,savePenjualan,formik,formikDraftPenjualan));
    if(format(e,'yyyy-MM-dd')<format(Date.now(),'yyyy-MM-dd')){
      setFakturField(false);
    }else{
      setFakturField(true);
    }
  }
  const onCloseDraftDialog = ()=>{
    setOpenDraftList(false);
    dispatch(setEmptyDraftList());
  }
  const onSelectedDraft = () =>{
    formikDraftPenjualan.setFieldValue('fakturpenjualan',tempFakturDraft);
    setTanggalDisable(true);
    setOpenDraftList(false);
    dispatch(setEmptyDraftList());
  }
const handleSearchBarang = (e)=>{
  formikCart.setFieldValue('namabarang',e.target.value);
  dispatch(searchForSales(e.target.value,1,token,"",50));
 }
 
// const handleChangeTipeHarga = (e)=>{
//   if(e.target.value === 'Harga Jual'){
//     if(savePenjualan==="Draft"){
//       formikDraftPenjualan.setFieldValue('jenisharga',e.target.value)
//     }else{
//       formik.setFieldValue('jenisharga','Harga Jual')
//     }
    
//   }else if(e.target.value === 'Harga Grosir'){
//     if(savePenjualan==="Draft"){
//       formikDraftPenjualan.setFieldValue('jenisharga',e.target.value)
//     }else{
//       formik.setFieldValue('jenisharga','Harga Grosir')
//     }
//   }
//   const data={
//     'idbarang':formikCart.values.idbarang,
//     'idsatuan':formikCart.values.idsatuan,
//     'tipeharga':e.target.value
//   }
//   dispatch(getHargaJual(data,token,formikCart));
// }
const handleChangePembayaran = (e)=>{
  if(e.target.value === 'Tunai'){
    if(savePenjualan==="Draft"){
      formikDraftPenjualan.setFieldValue('pembayaran',e.target.value)
    }else{
      formik.setFieldValue('pembayaran','Tunai')
    }
  }else if(e.target.value === 'Transfer'){
    if(savePenjualan==="Draft"){
      formikDraftPenjualan.setFieldValue('pembayaran',e.target.value)
    }else{
      formik.setFieldValue('pembayaran','Transfer')
    }
  }
  else if(e.target.value === 'Kredit'){
    if(savePenjualan==="Draft"){
      formikDraftPenjualan.setFieldValue('pembayaran',e.target.value)
    }else{
      formik.setFieldValue('pembayaran','Kredit')
    }
    
  }
}
const handleChangeSatuan = (e)=>{
  setSatuanText(e.target.options[e.target.selectedIndex].text);
  formikCart.setFieldValue('idsatuan',e.target.value);
  if(savePenjualan==="Draft"){
    const data={
      'idbarang':formikCart.values.idbarang,
      'idsatuan':e.target.value,
      'tipeharga':formikDraftPenjualan.values.jenisharga
    }
    dispatch(getHargaJual(data,token,formikCart));
    dispatch(getNilaiPPNPenjualan(token,setOpensnackbar));
    formikCart.setFieldValue('qty',0);
    formikCart.setFieldValue('diskon',0);
    formikCart.setFieldValue('jumlah',0);
  }else{
    const data={
      'idbarang':formikCart.values.idbarang,
      'idsatuan':e.target.value,
      'tipeharga':formik.values.jenisharga
    }
    dispatch(getHargaJual(data,token,formikCart));
    dispatch(getNilaiPPNPenjualan(token,setOpensnackbar));
    formikCart.setFieldValue('qty',0);
    formikCart.setFieldValue('diskon',0);
    formikCart.setFieldValue('jumlah',0);
  }
  // dispatch(getHargaJual(data,token,formikCart));
  // dispatch(getNilaiPPNPenjualan(token,setOpensnackbar));
  // formikCart.setFieldValue('qty',0);
  // formikCart.setFieldValue('diskon',0);
  // formikCart.setFieldValue('jumlah',0);
}
const handleChangeQty = (e) =>{
    if(e.target.value !== "" ){
      let tempJumlah = parseInt(e.target.value) * parseFloat(formikCart.values.harga);
      formikCart.setFieldValue('qty',e.target.value);
      formikCart.setFieldValue('jumlah',tempJumlah);
      const dataLastStok = {
        'idbarang':formikCart.values.idbarang,
        'idsatuan':formikCart.values.idsatuan,
        'qty':e.target.value
      }
      dispatch(getLastStokQty(dataLastStok,token,setOpensnackbar));
    }else if(e.target.value === ""){
      formikCart.setFieldValue('qty',e.target.value);
      formikCart.setFieldValue('jumlah',0);
    }
}
const handleChangeHarga = (e)=>{
  if(e.target.value !== "" ){
    if(otorisasi===true){
      if(formikCart.values.tipediskon==="RP"){
        let tempSetelahDiskon = parseInt(e.target.value)-parseFloat(formikCart.values.diskon);
        let tempJumlah = parseFloat(formikCart.values.qty) * tempSetelahDiskon;
        formikCart.setFieldValue('harga',e.target.value);
        formikCart.setFieldValue('jumlah',tempJumlah);
        const dataLastStok = {
          'idbarang':formikCart.values.idbarang,
          'idsatuan':formikCart.values.idsatuan,
          'qty':formikCart.values.qty
        }
        dispatch(getLastStokQty(dataLastStok,token,setOpensnackbar));
      }else if(formikCart.values.tipediskon==="%"){
        let tempDiskon = parseFloat(e.target.value) * parseInt(formikCart.values.diskon);
        let tempDiskonLanjutan = tempDiskon/100;
        let tempSetelahDiskon = parseFloat(e.target.value)-tempDiskonLanjutan;
        let tempJumlah = parseInt(formikCart.values.qty) * tempSetelahDiskon;
        formikCart.setFieldValue('harga',e.target.value);
        formikCart.setFieldValue('jumlah',tempJumlah);
        const dataLastStok = {
          'idbarang':formikCart.values.idbarang,
          'idsatuan':formikCart.values.idsatuan,
          'qty':formikCart.values.qty
        }
        dispatch(getLastStokQty(dataLastStok,token,setOpensnackbar));
      }
    }else if(otorisasi===false){
      let tempJumlah = parseInt(e.target.value) * parseFloat(formikCart.values.qty);
      formikCart.setFieldValue('harga',e.target.value);
      formikCart.setFieldValue('jumlah',tempJumlah);
      const dataLastStok = {
        'idbarang':formikCart.values.idbarang,
        'idsatuan':formikCart.values.idsatuan,
        'qty':formikCart.values.qty
      }
      dispatch(getLastStokQty(dataLastStok,token,setOpensnackbar));
    }
  }else if(e.target.value === ""){
    formikCart.setFieldValue('harga',e.target.value);
    formikCart.setFieldValue('jumlah',0);
  }
}
const handleChangeDiskon = (e) =>{
  if(formikCart.values.tipediskon==='RP'){
    let tempHarga=parseFloat(formikCart.values.harga)-parseFloat(e.target.value);
    let tempJumlah = parseInt(formikCart.values.qty) * parseFloat(tempHarga);
    formikCart.setFieldValue('diskon',e.target.value);
    formikCart.setFieldValue('jumlah',tempJumlah);
  }else if(formikCart.values.tipediskon==='-'){
    let tempJumlah = parseInt(formikCart.values.qty) * parseFloat(formikCart.values.harga);
    formikCart.setFieldValue('diskon',e.target.value);
    formikCart.setFieldValue('jumlah',tempJumlah);
  }else if(formikCart.values.tipediskon==='%'){
    let tempPotDiskon = parseFloat(formikCart.values.harga) * parseFloat(e.target.value);
    let tempDiskon = tempPotDiskon/100;
    let tempHarga=parseFloat(formikCart.values.harga)-parseFloat(tempDiskon);
    let tempJumlah = parseInt(formikCart.values.qty) * parseFloat(tempHarga);
    formikCart.setFieldValue('diskon',e.target.value);
    formikCart.setFieldValue('jumlah',tempJumlah);
  }
}
const handleChangeDiskonTotal = (e) =>{
    if(savePenjualan==="Draft"){
        if(formikDraftPenjualan.values.tipediskon==='RP'){
          formikDraftPenjualan.setFieldValue('diskon',e.target.value);
        }else if(formikDraftPenjualan.values.tipediskon==='-'){
          formikDraftPenjualan.setFieldValue('diskon',e.target.value);
        }else if(formikDraftPenjualan.values.tipediskon==='%'){
          formikDraftPenjualan.setFieldValue('diskon',e.target.value);
        }
    }else{
        if(formik.values.tipediskon==='RP'){
          formik.setFieldValue('diskon',e.target.value);
        }else if(formik.values.tipediskon==='-'){
          formik.setFieldValue('diskon',e.target.value);
        }else if(formik.values.tipediskon==='%'){
          formik.setFieldValue('diskon',e.target.value);
        }
    }
}
const handleEnterDiskonTotal = (e) =>{
  if(e.key==="Enter"){
    if(savePenjualan==="Draft"){
      if(formikDraftPenjualan.values.tipediskon==='RP'){
        formikDraftPenjualan.setFieldValue('diskon',e.target.value);
        setDiskonTotal(parseFloat(e.target.value));
      }else if(formikDraftPenjualan.values.tipediskon==='-'){
        formikDraftPenjualan.setFieldValue('diskon',e.target.value);
        setDiskonTotal(parseFloat(0));
      }else if(formikDraftPenjualan.values.tipediskon==='%'){
        let tempPotDiskon = parseFloat(e.target.value) * parseFloat(totalPenjualan);
        let tempDiskon = tempPotDiskon/100;
        formikDraftPenjualan.setFieldValue('diskon',e.target.value);
        setDiskonTotal(parseFloat(tempDiskon));
      }else{
        setDiskonTotal(0);
      }
    }else{
      if(formik.values.tipediskon==='RP'){
        formik.setFieldValue('diskon',e.target.value);
        setDiskonTotal(parseFloat(e.target.value));
      }else if(formik.values.tipediskon==='-'){
        formik.setFieldValue('diskon',e.target.value);
        setDiskonTotal(parseFloat(0));
      }else if(formik.values.tipediskon==='%'){
        let tempPotDiskon = parseFloat(e.target.value) * parseFloat(totalPenjualan);
        let tempDiskon = tempPotDiskon/100;
        formik.setFieldValue('diskon',e.target.value);
        setDiskonTotal(tempDiskon);
      }else{
        setDiskonTotal(0);
      }
    }
  }
}
const handleEnterQty = (e)=>{
  if(e.key==="Enter"){
    const regex= new RegExp('^[0-9]*$');
    const result = regex.test(formikCart.values.qty);
    if(result===true){
      const dataLastStok = {
        'idbarang':formikCart.values.idbarang,
        'idsatuan':formikCart.values.idsatuan,
        'qty':formikCart.values.qty
      }
      dispatch(getLastStokQty(dataLastStok,token,setOpensnackbar));
      formikCart.handleSubmit();
    }else if(result===false){
      dispatch(setPenjualanMessage('Jumlah harus numerik tidak diperbolehkan desimal'));
      setOpensnackbar(true)
      formikCart.setFieldValue('qty',0);
      formikCart.setFieldValue('jumlah',0);
    }
  }
}
const handleAddClick = (e)=>{
    const regex= new RegExp('^[0-9]*$');
    const result = regex.test(formikCart.values.qty);
    if(result===true){
      const dataLastStok = {
        'idbarang':formikCart.values.idbarang,
        'idsatuan':formikCart.values.idsatuan,
        'qty':formikCart.values.qty
      }
      dispatch(getLastStokQty(dataLastStok,token,setOpensnackbar));
      formikCart.handleSubmit();
    }else if(result===false){
      dispatch(setPenjualanMessage('Jumlah harus numerik tidak diperbolehkan desimal'));
      setOpensnackbar(true)
      formikCart.setFieldValue('qty',0);
      formikCart.setFieldValue('jumlah',0);
    }
}
const handleCloseSnackbar = (event,reason) =>{
  if(reason === 'clickaway'){
      return;
  }
  setOpensnackbar(false);
  setTimeout(() => {
      dispatch(setPenjualanMessage(''));
  }, 100);
}
const handleClickDeletePenjualan = (id,idpenjualandetails,idbarang,idsatuan,qty) =>{
  if(statusDraft==="Draft"){
      const data = {
        'idpenjualandetails':idpenjualandetails,
        'idbarang':idbarang,
        'idsatuan':idsatuan,
        'qty':qty
      }
      dispatch(deleteItemPenjualan(id,data,token,setOpensnackbar));
    }else if(statusDraft==="EditDraft"){
        const data = {
          'idpenjualandetails':idpenjualandetails,
          'idbarang':idbarang,
          'idsatuan':idsatuan,
          'qty':qty
        }
        dispatch(delPenjualanDetails(id,data,token,setOpensnackbar));
    }
}
const handleSelectDiskon = (e) =>{
  if(e.target.checked===true){
    setOtorisasi(true);
    setDisabledDiskon(false);
    formikCart.setFieldValue('tipediskon',"-");
    formikCart.setFieldValue('diskon',0);
  }else if(e.target.checked===false){
    let tempJumlah = parseInt(formikCart.values.qty) * parseFloat(formikCart.values.harga);
    setOtorisasi(false);
    setDisabledDiskon(true);
    formikCart.setFieldValue('tipediskon',"-");
    formikCart.setFieldValue('diskon',0);
    formikCart.setFieldValue('jumlah',tempJumlah);
  }
}
const handleSelectDiskonTotal = (e) =>{
  if(e.target.checked===true){
    if(savePenjualan==="Draft"){
      formikDraftPenjualan.setFieldValue('tipediskon',"-");
      formikDraftPenjualan.setFieldValue('diskon',0);
    }else{
      formik.setFieldValue('tipediskon',"-");
      formik.setFieldValue('diskon',0);
    }
    setOtorisasi(true);
    setDisabledDiskon(false);
  }else if(e.target.checked===false){
    // let tempJumlah = parseInt(formikCart.values.qty) * parseFloat(formikCart.values.harga);
    setOtorisasi(false);
    setDisabledDiskon(true);
    setDiskonTotal(0);
    if(savePenjualan==="Draft"){
      formikDraftPenjualan.setFieldValue('tipediskon',"-");
      formikDraftPenjualan.setFieldValue('diskon',0);
    }else{
      formik.setFieldValue('tipediskon',"-");
      formik.setFieldValue('diskon',0);
    }
  }
}
const handleCheckRetur = (e) =>{
  if(formik.values.idpelanggan===""&&formikDraftPenjualan.values.idpelanggan===""){
      dispatch(setPenjualanMessage("Data pelanggan belum dipilih"));
      setOpensnackbar(true);
      dispatch(setEmptyCartReturPenjualan());
  }else{
    setCheckRetur(!checkRetur);
  }
}
const handleChangeSwitch = (e) =>{
  if(e.target.checked===true){
    if(statusDraft==="Draft"){
      setSavePenjualan('Penjualan');
      formik.setFieldValue('fakturpenjualan',formikDraftPenjualan.values.fakturpenjualan);
      formik.setFieldValue('idpelanggan',formikDraftPenjualan.values.idpelanggan);
      formik.setFieldValue('namapelanggan',formikDraftPenjualan.values.namapelanggan);
      formik.setFieldValue('alias',formikDraftPenjualan.values.alias);
      formik.setFieldValue('tanggal',formikDraftPenjualan.values.tanggal);
      formik.setFieldValue('jenisharga',formikDraftPenjualan.values.jenisharga);
      formik.setFieldValue('pembayaran',formikDraftPenjualan.values.pembayaran);
    }else if(statusDraft==="EditDraft"){
      formik.setFieldValue('fakturpenjualan',formikDraftPenjualan.values.fakturpenjualan);
      formik.setFieldValue('idpelanggan',formikDraftPenjualan.values.idpelanggan);
      formik.setFieldValue('namapelanggan',formikDraftPenjualan.values.namapelanggan);
      formik.setFieldValue('alias',formikDraftPenjualan.values.alias);
      formik.setFieldValue('tanggal',formikDraftPenjualan.values.tanggal);
      formik.setFieldValue('jenisharga',formikDraftPenjualan.values.jenisharga);
      formik.setFieldValue('pembayaran',formikDraftPenjualan.values.pembayaran);
    }
  }else if(e.target.checked===false){
    setSavePenjualan('Draft');
    formikDraftPenjualan.setFieldValue('fakturpenjualan',formik.values.fakturpenjualan);
    formikDraftPenjualan.setFieldValue('idpelanggan',formik.values.idpelanggan);
    formikDraftPenjualan.setFieldValue('namapelanggan',formik.values.namapelanggan);
    formikDraftPenjualan.setFieldValue('alias',formik.values.alias);
    formikDraftPenjualan.setFieldValue('tanggal',formik.values.tanggal);
    formikDraftPenjualan.setFieldValue('jenisharga',formik.values.jenisharga);
    formikDraftPenjualan.setFieldValue('pembayaran',formik.values.pembayaran);
  }
  setCheckSwitch(!checkSwitch);
}
const handleClickSave = () =>{
  setSavePenjualan('Penjualan');
  setStatusSave("Simpan");
  formik.handleSubmit();
}
const handleClickSavePrint = () =>{
  setSavePenjualan('Penjualan');
  setStatusSave("SimpanPrint");
  formik.handleSubmit();
}
const plusButtonClick = (id,idpenjualandetails,idbarang,idsatuan,qty,harga) =>{
  const dataLastStok = {
      'idpenjualandetails':idpenjualandetails,
      'idbarang':idbarang,
      'idsatuan':idsatuan,
      'qty':parseInt(qty),
      'harga':harga
    }
    dispatch(getLastQtyTable(id,dataLastStok,token,setOpensnackbar,statusDraft));
}
const minusButtonClick = (id,idpenjualandetails,qty,harga,idbarang,idsatuan) =>{
    if(qty>1){
      if(statusDraft==="Draft"){
        const editBookingStok={
          'idbarang':idbarang,
          'idsatuan':idsatuan,
          'qty':1,
          'status':"Kurang"
        }
      dispatch(MinusButton(id,editBookingStok,token,setOpensnackbar,"Minus"));
    }else if(statusDraft==="EditDraft"){
        let newQty = qty-1;
        let newJumlah = newQty * harga;
        const data={
          'idpenjualandetails':idpenjualandetails,
          'qty':newQty,
          'jumlah':newJumlah,
        }
        const editBookingStok={
          'idbarang':idbarang,
          'idsatuan':idsatuan,
          'qty':1,
          'status':"Kurang"
        }
        dispatch(editDraftPenjualanDetails(id,data,editBookingStok,token,setOpensnackbar,"Minus"));
    }
  }else {
    dispatch(setPenjualanMessage("Untuk menghapus barang silahkan tekan tombol X"));
    setOpensnackbar(true);
  }
}
const handleClickRecalculate = () =>{
  if(cartPenjualan.length>0){
    let totalPenjualan=cartPenjualan.reduce((Penjualan,TotalP)=>{
        return Penjualan + TotalP.jumlah;
    },0);
    let totalPenjualanPPN=cartPenjualan.reduce((Penjualan,TotalP)=>{
      if(TotalP.kelompok === "PPN"){
        return Penjualan + TotalP.jumlah;
      }else{
        return Penjualan;
      }
    },0);
    setTotalPenjualan(totalPenjualan);
    setTotalPPN(totalPenjualanPPN);
    setTotalItem(cartPenjualan.length);
    // setDisableTipeHarga(true);
  }else{
    setTotalPenjualan(0);
    setTotalItem(0);
    setTotalPPN(0);
    // setDisableTipeHarga(false);
  }
}
const displayPenjualanButton = (
  <Fragment>
    <Box sx={{width:'100%',display:'flex',justifyContent:'center'}}>
        <Button 
        variant='contained' 
        color='primary' 
        sx={{marginBottom:"10px",marginTop:'10px',marginRight:'20px'}} 
        disabled={timeConnection<=100 ? false:true}
        onClick={handleClickSave}>Simpan</Button>
        <Button 
        variant='contained' 
        color='primary' 
        sx={{marginBottom:"10px",marginTop:'10px'}} 
        disabled={timeConnection<=100 ? false:true}
        onClick={handleClickSavePrint}>Simpan & Print</Button>
    </Box>
  </Fragment>
)
const handleClickSaveDraft = () =>{
  setSavePenjualan('Draft');
  setStatusSave("Simpan");
  formikDraftPenjualan.handleSubmit();
}
const handleClickSavePrintDraft = () =>{
  setSavePenjualan('Draft');
  setStatusSave("SimpanPrint");
  formikDraftPenjualan.handleSubmit();
}
const displayDraftButton = (
  <Fragment>
    <Box sx={{width:'100%',display:'flex',justifyContent:'center'}}>
    <Button 
    variant='contained' 
    color='primary' 
    sx={{marginBottom:"10px",marginTop:'10px',marginRight:'20px'}} 
    disabled={timeConnection<=100 ? isLoading : true} 
    onClick={handleClickSaveDraft}>Simpan Draft</Button>
    <Button 
    variant='contained' 
    color='primary' 
    sx={{marginBottom:"10px",marginTop:'10px'}} 
    disabled={timeConnection<=100 ? isLoading : true} 
    onClick={handleClickSavePrintDraft}>Simpan Draft & Print</Button>
    </Box>
  </Fragment>
)
const daftarPenjualan = (
  <TableBody>
          {cartPenjualan.slice(0).reverse().map((penjualan) => (
              <StyledTableRow key={penjualan.id}>
              <StyledTableCell width={120} align="center">{penjualan.idbarang}</StyledTableCell>
              <StyledTableCell width={200} align="center">{penjualan.namabarang}</StyledTableCell>
              <StyledTableCell width={120} align="center">{penjualan.satuan}</StyledTableCell>
              {/* <StyledTableCell width={10} align="center"></StyledTableCell> */}
              <StyledTableCell width={120} align="center">
                <IconButton
                disabled={timeConnection<=100?isLoadingDraft:true} 
                onClick={()=>minusButtonClick(penjualan.id,penjualan.idpenjualandetails,penjualan.qty,penjualan.harga,penjualan.idbarang,penjualan.idsatuan)}><img src={MinusItem} alt="minusItem" style={{border:'1px'}}/></IconButton>
                {new Intl.NumberFormat().format(penjualan.qty)}
                <IconButton
                disabled={timeConnection<=100?isLoadingDraft:true} 
                onClick={()=>plusButtonClick(penjualan.id,penjualan.idpenjualandetails,penjualan.idbarang,penjualan.idsatuan,penjualan.qty,penjualan.harga)}><img src={PlusItem} alt="plusitem" style={{border:'1px'}}/></IconButton>
                </StyledTableCell>
              {/* <StyledTableCell width={10} align="center"></StyledTableCell> */}
              <StyledTableCell width={120} align="center">{new Intl.NumberFormat().format(penjualan.harga)}</StyledTableCell>
              <StyledTableCell width={120} align="center">{new Intl.NumberFormat().format(penjualan.diskon)}</StyledTableCell>
              <StyledTableCell width={120} align="center">{new Intl.NumberFormat().format(penjualan.jumlah)}</StyledTableCell>
              <StyledTableCell width={20} align="center">
               {cartPenjualan.length===1 && statusDraft==="EditDraft" ? 
                 <IconButton 
                 disabled={true}
                >
                    <img src={DeleteData} alt="deleteData"/>
                </IconButton>
                : 
                  <Tooltip
                  disableHoverListener={cartPenjualan.length===1 ? true : false}
                   title={"Hapus data"}>
                      <IconButton 
                      disabled={timeConnection<=100?false:true} 
                      onClick={()=>handleClickDeletePenjualan(penjualan.id,penjualan.idpenjualandetails,penjualan.idbarang,penjualan.idsatuan,penjualan.qty)}
                      >
                          <img src={DeleteData} alt="deleteData"/>
                      </IconButton>
                  </Tooltip>
                  }
              </StyledTableCell>
              </StyledTableRow>
          ))}
      </TableBody>
)
const onSearchPelanggan = (e)=>{
      if(savePenjualan==="Draft"){
        formikDraftPenjualan.setFieldValue('namapelanggan',e.target.value);
      }else{
        formik.setFieldValue('namapelanggan',e.target.value);
      }
      dispatch(getAllPelanggan(e.target.value,1,token,""));
}
const handleEnterId = (e) =>{
  if(e.key==="Enter"){
    const data={
      'barcode':e.target.value
      }
      dispatch(scanBarcode(data,tipeHarga,token,setOpensnackbar,formikCart,getSatuanKonversiList,setSatuanText));
      dispatch(getNilaiPPNPenjualan(token,setOpensnackbar));
  }
}
const handleChangeIdBarang = (e)=>{
  if(e.target.value===""){
    formikCart.setFieldValue('idbarang',e.target.value);
    dispatch(emptyArrSatuan());
    dispatch(emptyHargaJual());
    formikCart.setFieldValue('namabarang',"");
    formikCart.setFieldValue('qty',0);
    formikCart.setFieldValue('harga',"");
    formikCart.setFieldValue('diskon',0);
    formikCart.setFieldValue('jumlah',0);
  }else{
      formikCart.setFieldValue('idbarang',e.target.value);
      dispatch(searchForSales(e.target.value,1,token,"",50));
      if(dataBarang.length>0){
        formikCart.setFieldValue('namabarang',dataBarang.namabarang);
      }else{
        formikCart.setFieldValue('namabarang',"");
      }
      formikCart.setFieldValue('idsatuan',"");
  }
}
const noRekeningField = (
  <TextField
    id="norekening"
    name="norekening"
    label="No Rekening"
    variant="standard"
    style={{width:'150px',marginRight:'10px'}}
    value={savePenjualan==="Draft" ? formikDraftPenjualan.values.norekening : formik.values.norekening}
    error={savePenjualan==="Draft" ? formikDraftPenjualan.touched.norekening && Boolean(formikDraftPenjualan.errors.norekening) : formik.touched.norekening && Boolean(formik.errors.norekening)}
    helperText={savePenjualan==="Draft" ? formikDraftPenjualan.touched.norekening && formikDraftPenjualan.errors.norekening : formik.touched.norekening && formik.errors.norekening}
    onChange={savePenjualan==="Draft" ? formikDraftPenjualan.handleChange: formik.handleChange}/>
                 
)
  return (
    <Container maxWidth='xl' sx={{marginTop:'50px'}}>
      <Box sx={titleBox} mt={1}>
          <Typography variant='h6' pl={1}>Penjualan</Typography>
          <Box sx={{width:'100%',display:'flex',justifyContent:'center'}}>
            <Typography>Draft Penjualan</Typography>
            <Switch size='small' 
            checked={checkSwitch} 
            onChange={(e)=>handleChangeSwitch(e)}
            />
            <Typography>Penjualan</Typography>
            </Box>
            <Box sx={{width:"250px"}}>
              <IconButton onClick={()=>handleClickViewList()}><img src={viewList} alt="viewList"/></IconButton><Typography textAlign="right" variant='caption' fontStyle="italic" fontWeight="bold">Draft penjualan (F2)</Typography>
            </Box>
       </Box>
        <Divider/>
        <Box sx={boxWapper}>
            <Box sx={paperInput}>
                <TextField
                id="fakturpenjualan"
                name="fakturpenjualan"
                label="Nomor Faktur"
                variant="standard"
                disabled={fakturField}
                style={{width:'250px',marginRight:'10px'}}
                value={savePenjualan==="Draft" ? formikDraftPenjualan.values.fakturpenjualan ||"": formik.values.fakturpenjualan||""}
                error={savePenjualan==="Draft" ? formikDraftPenjualan.touched.fakturpenjualan && Boolean(formikDraftPenjualan.errors.fakturpenjualan) :formik.touched.fakturpenjualan && Boolean(formik.errors.fakturpenjualan)}
                helperText={ savePenjualan==="Draft" ? formikDraftPenjualan.touched.fakturpenjualan && formikDraftPenjualan.errors.fakturpenjualan : formik.touched.fakturpenjualan && formik.errors.fakturpenjualan}
                onChange={savePenjualan==="Draft" ? formikDraftPenjualan.handleChange:formik.handleChange}/>
              <Autocomplete
                  id="idpelanggan"
                  name='idpelanggan'
                  variant="standard"
                  inputValue={savePenjualan==="Draft" ? formikDraftPenjualan.values.namapelanggan : formik.values.namapelanggan}
                  sx={{width:'250px',marginRight:'10px'}}
                  options= {dataPelanggan}
                  getOptionLabel= {(option) => option.nama}
                  isOptionEqualToValue={(option,value)=>option.id===value.id}
                  onChange={(event, newValue) => {
                          if(newValue!==null){
                              if(savePenjualan==="Draft"){
                                  formikDraftPenjualan.setFieldValue('idpelanggan',newValue.idpelanggan);
                                  formikDraftPenjualan.setFieldValue('namapelanggan',newValue.nama);
                                }else if(savePenjualan==="Penjualan"){
                                  formik.setFieldValue('idpelanggan',newValue.idpelanggan);
                                  formik.setFieldValue('namapelanggan',newValue.nama);
                                }
                          }else{
                              if(savePenjualan==="Draft"){
                                formikDraftPenjualan.setFieldValue('idpelanggan','');
                                formikDraftPenjualan.setFieldValue('namapelanggan','');
                              }else if(savePenjualan==="Penjualan"){
                                formik.setFieldValue('idpelanggan','');
                                formik.setFieldValue('namapelanggan','');
                              }
                          }
                    }}
                  renderInput={(params)=><TextField
                      id='namapelanggan'
                      name='namapelanggan'
                      variant='standard' 
                      {...params} 
                      label="Pelanggan"
                      value={savePenjualan==="Draft" ? formikDraftPenjualan.values.namapelanggan : formik.values.namapelanggan}
                      error={savePenjualan==="Draft" ? formikDraftPenjualan.touched.namapelanggan && Boolean(formikDraftPenjualan.errors.namapelanggan) : formik.touched.namapelanggan && Boolean(formik.errors.namapelanggan)}
                      helperText={savePenjualan==="Draft" ? formikDraftPenjualan.touched.namapelanggan && formikDraftPenjualan.errors.namapelanggan : formik.touched.namapelanggan && formik.errors.namapelanggan}
                      onChange={(e)=>onSearchPelanggan(e)}
                      />}
                  />
                  <TextField
                  id="alias"
                  name="alias"
                  label="Alias"
                  variant="standard"
                  style={{width:'200px',marginRight:'10px'}}
                  value={savePenjualan==="Draft" ? formikDraftPenjualan.values.alias : formik.values.alias}
                  error={savePenjualan==="Draft" ? formikDraftPenjualan.touched.alias && Boolean(formikDraftPenjualan.errors.alias) : formik.touched.alias && Boolean(formik.errors.alias)}
                  helperText={savePenjualan==="Draft" ? formikDraftPenjualan.touched.alias && formikDraftPenjualan.errors.alias : formik.touched.alias && formik.errors.alias}
                  onChange={savePenjualan==="Draft" ? formikDraftPenjualan.handleChange: formik.handleChange}/>
                 <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                    label="Tanggal"
                    inputFormat="dd/MM/yyyy"
                    disabled={tanggalDisable}
                    renderInput={(params) => 
                    <TextField {...params} sx={{width:'200px',marginRight:'10px'}} variant="standard"
                    error={savePenjualan==="Draft" ? formikDraftPenjualan.touched.tanggal && Boolean(formikDraftPenjualan.errors.tanggal) :formik.touched.tanggal && Boolean(formik.errors.tanggal)}
                    helperText={savePenjualan==="Draft" ? formikDraftPenjualan.touched.tanggal && formikDraftPenjualan.errors.tanggal : formik.touched.tanggal && formik.errors.tanggal}
                    />}
                    value={savePenjualan==="Draft" ? formikDraftPenjualan.values.tanggal :formik.values.tanggal}
                    onChange={(e)=>handleChangeTanggal(e)}
                    />
                    </LocalizationProvider>
                    <TextField
                    id='pembayaran'
                    select
                    name='pembayaran'
                    variant="standard" 
                    // disabled={disablePembayaran}
                    label="Pembayaran"
                    style={{width:'200px',marginRight:'10px'}}
                    SelectProps={{
                        native: true,
                        }}
                    value={savePenjualan==="Draft" ? formikDraftPenjualan.values.pembayaran : formik.values.pembayaran}
                    error={savePenjualan==="Draft" ? formikDraftPenjualan.touched.pembayaran && Boolean(formikDraftPenjualan.errors.pembayaran) : formik.touched.pembayaran && Boolean(formik.errors.pembayaran)}
                    helperText={savePenjualan==="Draft" ? formikDraftPenjualan.touched.pembayaran && formikDraftPenjualan.errors.pembayaran :  formik.touched.pembayaran && formik.errors.pembayaran}
                    onChange={(e)=>handleChangePembayaran(e)}
                    >   
                        {pembayaran.map((option)=>(
                            <option key ={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </TextField>
                    {savePenjualan==="Draft" && formikDraftPenjualan.values.pembayaran==='Transfer'?noRekeningField:null}
                    {savePenjualan==="Penjualan" && formik.values.pembayaran==='Transfer'?noRekeningField:null}
            </Box>
        </Box>
        <Box sx={penjualanInput}>
            <Box sx={todoList}>
                <TextField
                  id="idbarang"
                  name="idbarang"
                  label="ID Barang/Barcode"
                  variant="standard"
                  disabled={timeConnection<=100?false:true} 
                  style={{width:'250px',marginRight:'10px'}}
                  value={formikCart.values.idbarang||""}
                  error={formikCart.touched.idbarang && Boolean(formikCart.errors.idbarang)}
                  helperText={formikCart.touched.idbarang && formikCart.errors.idbarang}
                  onChange={(e)=>handleChangeIdBarang(e)}
                  onKeyPress={handleEnterId}
                  />
                  <Autocomplete
                  id="namabarang"
                  name='namabarang'
                  variant="standard"
                  sx={{marginRight:'10px'}}
                  inputValue={formikCart.values.namabarang||""}
                  options= {dataBarang}
                  getOptionLabel= {(option) => option.namabarang}
                  isOptionEqualToValue={(option,value)=>option.id===value.id}
                  onChange={(event, newValue) => {
                          if(newValue!==null){
                              formikCart.setFieldValue('idbarang',newValue.idbarang);
                              formikCart.setFieldValue('namabarang',newValue.namabarang);
                              dispatch(getSatuanKonversiList(newValue.idbarang,token,""));
                              formikCart.setFieldValue('idsatuan',"");
                              formikCart.setFieldValue('harga',"");
                          }else{
                            formikCart.setFieldValue('idbarang',"");
                            formikCart.setFieldValue('namabarang',"");
                            formikCart.setFieldValue('harga',"");
                            formikCart.setFieldValue('idsatuan',"");
                            formikCart.setFieldValue('harga',"");
                              dispatch(emptyArrSatuan());
                              dispatch(emptyHargaJual());
                          }
                    }}
                  renderInput={(params)=><TextField
                      id='namabarang'
                      name='namabarang'
                      variant='standard' 
                      sx={{width:'250px'}} 
                      {...params} 
                      label="Nama Barang"
                      disabled={timeConnection<=100?false:true} 
                      value={formikCart.values.namabarang}
                      error={formikCart.touched.namabarang && Boolean(formikCart.errors.namabarang)}
                      helperText={formikCart.touched.namabarang && formikCart.errors.namabarang}
                      onChange={(e)=>handleSearchBarang(e)}
                      />}
                  />
                  <TextField
                  id='idsatuan'
                  select
                  name='idsatuan'
                  variant="standard" 
                  label="Satuan"
                  disabled={timeConnection<=100?false:true} 
                  sx={{width:'100px',marginRight:'10px'}}
                  SelectProps={{
                      native: true,
                      }}
                  value={formikCart.values.idsatuan}
                  error={formikCart.touched.idsatuan && Boolean(formikCart.errors.idsatuan)}
                  helperText={formikCart.touched.idsatuan && formikCart.errors.idsatuan}
                  onChange={(e)=>handleChangeSatuan(e)}
                  >   
                      <option aria-label="None" value=""/>
                      {arrSatuanKonversi.map((option)=>(
                          <option key ={option.idsatuan} value={option.idsatuan}>
                              {option.satuan}
                          </option>
                      ))}
                  </TextField>
                  <TextField
                  id="harga"
                  name="harga"
                  label="Harga"
                  variant="standard"
                  disabled={detailsPengguna.hakakses === "Pemilik" ? false: true}
                  style={{width:'150px',marginRight:'10px'}}
                  value={formikCart.values.harga||""}
                  error={formikCart.touched.harga && Boolean(formikCart.errors.harga)}
                  helperText={formikCart.touched.harga && formikCart.errors.harga}
                  onChange={(e)=>handleChangeHarga(e)}
                  />
                  <TextField
                  id="qty"
                  name="qty"
                  label="Qty"
                  variant="standard"
                  style={{width:'70px',marginRight:'10px'}}
                  disabled={timeConnection<=100?false:true} 
                  onKeyDown={handleEnterQty}
                  autoFocus={formikCart.values.harga!==0 ? true : false}
                  value={formikCart.values.qty}
                  error={formikCart.touched.qty && Boolean(formikCart.errors.qty)}
                  helperText={formikCart.touched.qty && formikCart.errors.qty}
                  onChange={(e)=>handleChangeQty(e)}
                  />
                  <Box sx={{width:'300px',display:'flex',marginRight:'10px'}}>
                  <Checkbox
                    size="small"
                    sx={{marginTop:'10px'}}
                    id='checkdiskon'
                    name='checkdiskon'
                    disabled={timeConnection<=100?false:true} 
                    checked={otorisasi}
                    value={otorisasi}
                    onClick={(e)=>handleSelectDiskon(e)}
                    />
                    <TextField
                    id='tipediskon'
                    select
                    name='tipediskon'
                    variant="standard" 
                    disabled={disabledDiskon}
                    label="-"
                    style={{width:'50px',marginRight:'10px'}}
                    SelectProps={{
                        native: true,
                        }}
                    value={savePenjualan==="Draft" ? formikCart.values.tipediskon : formikCart.values.tipediskon}
                    error={savePenjualan==="Draft" ? formikCart.touched.tipediskon && Boolean(formikCart.errors.tipediskon) : formikCart.touched.tipediskon && Boolean(formikCart.errors.tipediskon)}
                    helperText={savePenjualan==="Draft" ? formikCart.touched.tipediskon && formikCart.errors.tipediskon :  formikCart.touched.tipediskon && formikCart.errors.tipediskon}
                    onChange={(e)=>handleChangeTipeDiskon(e)}
                    >   
                        {tipeDiskon.map((option)=>(
                          <option key ={option.value} value={option.value}>
                              {option.label}
                          </option>
                        ))}
                    </TextField>
                  <TextField
                  id="diskon"
                  name="diskon"
                  label="Diskon"
                  variant="standard"
                  disabled={disabledDiskon}
                  style={{width:'150px',marginRight:'10px'}}
                  onKeyDown={handleEnterQty}
                  value={formikCart.values.diskon}
                  error={formikCart.touched.diskon && Boolean(formikCart.errors.diskon)}
                  helperText={formikCart.touched.diskon && formikCart.errors.diskon}
                  onChange={(e)=>handleChangeDiskon(e)}
                  />
                  </Box>
                  <TextField
                  id="jumlah"
                  name="jumlah"
                  label="Jumlah"
                  variant="standard"
                  disabled={true}
                  style={{width:'150px',marginRight:'10px'}}
                  value={formikCart.values.jumlah}
                  error={formikCart.touched.jumlah && Boolean(formikCart.errors.jumlah)}
                  helperText={formikCart.touched.jumlah && formikCart.errors.jumlah}
                  onChange={formikCart.handleChange}
                  />
                  <IconButton sx={{display:'flex',marginTop:'15px'}}
                  disabled={timeConnection<=100?false:true} 
                  onClick={handleAddClick}>
                    <img src={AddTodo} alt="addRetur"/>
                  </IconButton>
            </Box>
            <TableContainer component={Paper} sx={paperTable}>
                <Table stickyHeader aria-label="customized table" size='small'>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell width={120} align="center">ID Barang</StyledTableCell>
                            <StyledTableCell width={200} align="center">Nama Barang</StyledTableCell>
                            <StyledTableCell width={120} align="center">Satuan</StyledTableCell>
                            <StyledTableCell width={120} align="center">Qty</StyledTableCell>
                            <StyledTableCell width={120} align="center">Harga</StyledTableCell>
                            <StyledTableCell width={120} align="center">Diskon</StyledTableCell>
                            <StyledTableCell width={120} align="center">Jumlah</StyledTableCell>
                            <StyledTableCell width={20} align="center"/>
                        </TableRow>
                    </TableHead>
                        {daftarPenjualan} 
                </Table>
            </TableContainer>
            <Box sx={{width:'90%',display:'flex',justifyContent:'flex-end'}}>
              <Box sx={{width:'200px',textAlign:'right'}}> 
                <IconButton onClick={()=>handleClickRecalculate()}>
                  <Typography>Hitung Ulang</Typography> 
                  <ReplayCircleFilledIcon/>
                </IconButton>
              </Box>
            </Box>
                <Box sx={{width:'90%',display:'flex',flexDirection:'row',justifyContent:'flex-end',alignItems:'flex-end'}}>
                  <Box sx={{width:'500px',marginBottom:'10px',textAlign:'right',display:'flex',justifyContent:'flex-end',alignItems:'flex-end'}}>
                    <FormControlLabel control={
                      <Checkbox
                      size="small"
                      id='checkdiskontotal'
                      name='checkdiskontotal'
                      disabled={timeConnection<=100 && new Intl.NumberFormat().format(totalPenjualan)>0?false:true} 
                      checked={otorisasi}
                      value={otorisasi}
                      label="Diskon"
                      onClick={(e)=>handleSelectDiskonTotal(e)}
                      />
                    }
                    label="Diskon :"
                    />
                    <TextField
                    id='tipediskon'
                    select
                    name='tipediskon'
                    variant="standard" 
                    disabled={disabledDiskon}
                    style={{width:'50px',height:'100%',marginRight:'10px'}}
                    SelectProps={{
                        native: true,
                        }}
                    value={savePenjualan==="Draft" ? formikDraftPenjualan.values.tipediskon : formik.values.tipediskon}
                    error={savePenjualan==="Draft" ? formikDraftPenjualan.touched.tipediskon && Boolean(formikDraftPenjualan.errors.tipediskon) : formik.touched.tipediskon && Boolean(formik.errors.tipediskon)}
                    helperText={savePenjualan==="Draft" ? formikDraftPenjualan.touched.tipediskon && formikDraftPenjualan.errors.tipediskon :  formik.touched.tipediskon && formik.errors.tipediskon}
                    onChange={(e)=>handleChangeTipeDiskonTotal(e)}
                    >   
                        {tipeDiskon.map((option)=>(
                          <option key ={option.value} value={option.value}>
                              {option.label}
                          </option>
                        ))}
                    </TextField>
                  <TextField
                  id="diskon"
                  name="diskon"
                  label="Diskon"
                  variant="standard"
                  disabled={disabledDiskon}
                  style={{width:'100px'}}
                  value={savePenjualan==="Draft" ? formikDraftPenjualan.values.diskon : formik.values.diskon}
                  error={savePenjualan==="Draft" ? formikDraftPenjualan.touched.diskon && Boolean(formikDraftPenjualan.errors.diskon) : formik.touched.diskon && Boolean(formik.errors.diskon)}
                  helperText={savePenjualan==="Draft" ? formikDraftPenjualan.touched.diskon && formikDraftPenjualan.errors.diskon :  formik.touched.diskon && formik.errors.diskon}
                  onKeyDown={(e)=>handleEnterDiskonTotal(e)}
                  onChange={(e)=>handleChangeDiskonTotal(e)}
                  />
                </Box>
                </Box>
                <Box sx={{width:'90%',display:'flex',justifyContent:'flex-end'}}>
                    <Box sx={{width:'200px',textAlign:'right'}}>
                        <Typography>Diskon:</Typography>
                    </Box>
                    <Box sx={{width:'180px',textAlign:'right'}}>
                        <Typography>Rp {diskonTotal}</Typography>
                    </Box>
                </Box>
                <Box sx={{width:'90%',display:'flex',justifyContent:'flex-end'}}>
                    <Box sx={{width:'200px',textAlign:'right'}}>
                        <Typography>Total Item:</Typography>
                    </Box>
                    <Box sx={{width:'180px',textAlign:'right'}}>
                        <Typography>{totalItem} Item</Typography> 
                    </Box>
                </Box>
                
                  <Box sx={{width:'90%',display:'flex',justifyContent:'flex-end'}}>
                    <Box sx={{width:'200px',textAlign:'right'}}>
                        <Typography>Total Penjualan:</Typography>
                    </Box>
                    <Box sx={{width:'180px',textAlign:'right'}}>
                        <Typography>Rp {new Intl.NumberFormat().format(totalPenjualan)}</Typography>
                    </Box>
                </Box> 
            <Box sx={{width:'90%',display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
                <Checkbox
                size="small"
                id='cekretur'
                name='cekretur'
                checked={checkRetur}
                onClick={(e)=>handleCheckRetur(e)}
                value={checkRetur}
                />
                <Typography sx={{marginRight:'10px'}}>Retur Penjualan</Typography>
            </Box>
            <Box sx={{width:'90%',display:'flex',justifyContent:'flex-start',alignItems:'center'}}>
            {checkRetur === true ? <ReturPenjualan 
            idpel={savePenjualan==="Draft" ? formikDraftPenjualan.values.idpelanggan : formik.values.idpelanggan}
            totalPenjualan={totalPenjualan} 
            statusDraft={statusDraft}/> : null}
            </Box>
            <Box sx={{width:'100%',display:'flex',justifyContent:'center'}}>
                {
                checkSwitch===false ? displayDraftButton : 
                displayPenjualanButton
                }
            </Box>
          </Box>
          <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={penjualanMessage === "Transaksi penjualan berhasil" ? "success" : "error"}
                sx={{whiteSpace:'pre-line',textAlign:'center'}}
                >
                    {penjualanMessage}
                    </Alert>
            </Snackbar>
            <DraftPenjualanList openDraftDialog={openDraftList} closeDraftDialog={onCloseDraftDialog} selectedDraft={onSelectedDraft}/>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={savePenjualan==="Draft" ? isLoadingDraft : isLoading}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
    </Container>
  )
}

export default PenjualanPage