import { Box, Button, Dialog, DialogContent,TextField, Divider, IconButton, Paper, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Tooltip, Typography, useTheme, Snackbar } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MuiAlert from '@mui/material/Alert';
import Cookies from 'js-cookie';
import { useSelector,useDispatch } from 'react-redux';
import { format, parseISO } from 'date-fns';
import {deleteDataDraft, emptyDraftMessage, getAllDataDraft, setStatusDraft} from '../../../config/redux/actions';
import { ChecklistDraft,DeleteData } from '../../assets';
import DeleteDialog from '../DeleteDialog';
import TablePagination from '../TablePagination';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'grey',
      color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const DraftPenjualanList = ({openDraftDialog,closeDraftDialog,selectedDraft,...rest}) => {
  const theme = useTheme();
  const paperList={
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    width:"100%",
    minWidth:'450px',
    height:'100%',
    borderRadius:'10px',
} 
const paperTable = {
  marginTop:'10px',
  marginBottom:'10px',
  [theme.breakpoints.up('sm')]:{
      width:'100%',
      height:'370px',
  }, 
  [theme.breakpoints.down('sm')]:{
      width:'80%',
      height:'360px'
  },
  scrollbarWidth:'thin'
}
const boxLayout = {
  display:'flex'
}
const textBoxField={
  width:'100%',
  justifyContent:'right',
  display:'flex',
  alignItems:'flex-end',
  border:'1px',
  marginBottom:'2px',
}
const boxSearch = {
  [theme.breakpoints.up('sm')]:{
      display:'flex',
      justifyContent:'space-between',
      alignItems:'flex-end',
      width:'90%',
  }, 
  [theme.breakpoints.down('sm')]:{
      display:'flex',
      justifyContent:'space-between',
      alignItems:'flex-end',
      width:'100%',
  }
}
const {dataLogin} =useSelector(state=>state.penggunaReducers);
const {isLoadingDraft,draftMessage,draftPenjualan,pageDraftPenjualan}=useSelector(state=>state.draftReducers);
const dispatch = useDispatch();
const [token,setToken] = useState(dataLogin.token);
const [openSnackbar,setOpensnackbar]=useState(false);
const [counter,setCounter]=useState(1);
const [search,setSearch]=useState("");
const [openDialog,setOpenDialog]=useState(false);
const [messageDialog,setMessageDialog]=useState('');
const [tempFaktur,setTempFaktur]=useState('');
if(token ===''){
  setToken(Cookies.get('u_tkn'));
}

useEffect(()=>{
  if(openDraftDialog===true){
    dispatch(getAllDataDraft(search,counter,token,setOpensnackbar));
  }
},[search,counter,token,openSnackbar,openDraftDialog])
const handleClickDeleteDraft = (faktur) =>{
  setOpenDialog(true);
  setMessageDialog(`Apakah anda yakin akan menghapus draft untuk faktur ${faktur} ?`);
  setTempFaktur(faktur);
}
const handlePrev = () =>{
  setCounter(counter <= 1? 1 : counter - 1);
}
const handleNext = () =>{
  setCounter(counter === pageDraftPenjualan.totalPage ? pageDraftPenjualan.totalPage : counter + 1);
}
const handleDeleteDraft = () =>{
  dispatch(deleteDataDraft(tempFaktur,search,1,token,setOpensnackbar,setOpenDialog));
}
const handleCloseDialog = () =>{
  setOpenDialog(false);
}
const handleClickSelectDraft=(faktur)=>{
  dispatch(setStatusDraft('EditDraft',faktur));
  selectedDraft();
}
const tableDraft = (
  <TableBody>
      {draftPenjualan.map((drafts) => (
          <StyledTableRow key={drafts.fakturpenjualan}>
          <StyledTableCell width={120} style={{fontSize:'12px'}} align="center">{drafts.fakturpenjualan}</StyledTableCell>
          <StyledTableCell width={120} style={{fontSize:'12px'}} align="center">{drafts.alias==="" ? drafts.nama : drafts.nama+"("+drafts.alias+")"}</StyledTableCell>
          <StyledTableCell width={100} style={{fontSize:'12px'}} align="center">{format(parseISO(drafts.tanggal),'dd/MM/yyyy')}</StyledTableCell>
          <StyledTableCell width={120} style={{fontSize:'12px'}} align="center">{new Intl.NumberFormat().format(drafts.total)}</StyledTableCell>
          <StyledTableCell width={10} style={{fontSize:'12px'}} align="center">
            <Box sx={boxLayout}>
              <Tooltip title="Pilih Draft" >
                  <IconButton 
                  onClick={()=>handleClickSelectDraft(drafts.fakturpenjualan)}
                  >
                      <img src={ChecklistDraft} alt="checklist"/>
                  </IconButton>
               </Tooltip>
               <Tooltip title="Hapus Draft" >
                  <IconButton 
                  onClick={()=>handleClickDeleteDraft(drafts.fakturpenjualan)}
                  >
                      <img src={DeleteData} alt="checklist"/>
                  </IconButton>
               </Tooltip>
            </Box>
          </StyledTableCell>
          </StyledTableRow>
      ))}
  </TableBody>
)
const handleChangeSearch=(e)=>{
    setSearch(e.target.value);
    setCounter(1);
}
const handleCloseSnackbar = (event,reason) =>{
  if(reason === 'clickaway'){
      return;
  }
  setOpensnackbar(false);
  setTimeout(() => {
      dispatch(emptyDraftMessage(''));
  }, 100);
}
  return (
    <Dialog open={openDraftDialog}{...rest} maxWidth="xl">
      <DialogContent>
          <Typography variant='h5' sx={{textAlign:'center'}}>DRAFT PENJUALAN</Typography>
          <Divider/>
          <Box sx={textBoxField}>
                <TextField
                id='search'
                name='search'
                label='Pelanggan / Alias'
                variant='standard'
                onChange={(e)=>handleChangeSearch(e)}
                />
          </Box>
        <Box sx={paperList}>
        <TableContainer component={Paper} sx={paperTable}>
                <Table stickyHeader aria-label="customized table" size='small'>
                    <TableHead>
                    <TableRow>
                        <StyledTableCell width={120} style={{fontSize:'12px'}} align="center">Faktur</StyledTableCell>
                        <StyledTableCell width={120} style={{fontSize:'12px'}} align="center">Pelanggan</StyledTableCell>
                        <StyledTableCell width={100} style={{fontSize:'12px'}} align="center">Tanggal</StyledTableCell>
                        <StyledTableCell width={120} style={{fontSize:'12px'}} align="center">Total</StyledTableCell>
                        <StyledTableCell width={10} style={{fontSize:'12px'}} align="center"/>
                    </TableRow>
                    </TableHead>
                    {tableDraft}
                </Table>
            </TableContainer>
            <Box sx={boxSearch}>
            <TablePagination
            page={counter}
            totalPage={pageDraftPenjualan.totalPage >= 1 ? pageDraftPenjualan.totalPage : pageDraftPenjualan.currentPage}
            handlePrev={handlePrev}
            handleNext={handleNext}
            hiddenButton={true}
            />
            </Box>
          <Button variant='contained' color='error' onClick={closeDraftDialog}>Close</Button>
        </Box>
      </DialogContent>
      <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={draftMessage === 'Penghapusan data draft penjualan berhasil'? 'success':'error'}
                >
                    {draftMessage}
                    </Alert>
            </Snackbar>
      <DeleteDialog 
            openDialog={openDialog} 
            message={messageDialog}
            handleCloseDialog={handleCloseDialog}
            handleClick={handleDeleteDraft}
            isLoading={isLoadingDraft}
            textHapus={"Ya"}
            textKeluar={"Tidak"}/>
    </Dialog>
  )
}

export default DraftPenjualanList