import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Container, Divider, Fab, IconButton, Paper, Snackbar, styled, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography,useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { format } from 'date-fns';
import { getAllDataRetur, setEmptyAddDataReturPenjualan, setPenjualanMessage, setReturPenjualanMessage } from '../../../config/redux/actions';
import { DetailsIcon } from '../../../components/assets';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosOutlined';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
      backgroundColor: 'grey',
      color: theme.palette.common.black
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));
const LaporanReturPenjualan = () => {
  const theme = useTheme();
  const boxWapper = {
    display:'flex',
    flexDirection:'column',
    justifyContent:'center',
    alignItems:'center',
    [theme.breakpoints.up('sm')]:{
        width:'100%',
        maxHeight:'500px',
        height:'100%',
    },
    [theme.breakpoints.down('lg')]:{
        width:'100%',
        height:'100%',
    }
  }
  const paperInput={
      display:'flex',
      justifyContent:'center',
      [theme.breakpoints.up('sm')]:{
          width:"90%",
          height:'100%',
          borderRadius:'10px',
          marginTop:'10px'
      },
      [theme.breakpoints.down('sm')]:{
          width:'100%',
          height:'100%',
          borderRadius:'10px',
          marginTop:'10px'
      }
  }
  const paperTable = {
    marginTop:'10px',
    [theme.breakpoints.up('sm')]:{
        width:'90%',
        height:'400px',
    }, 
    [theme.breakpoints.down('sm')]:{
        width:'100%',
        height:'360px'
    },
    scrollbarWidth:'thin'
}
  const {dataAllReturPenjualan,returpenjualanMessage}=useSelector(state=>state.returpenjualanReducers);
  const {dataLogin} =useSelector(state=>state.penggunaReducers);
  const [token,setToken] = useState(dataLogin.token);
  const [openSnackbar,setOpensnackbar]=useState(false);
  const [tanggalawal,setTanggalawal]=useState(new Date());
  const [tanggalakhir,setTanggalakhir]=useState(new Date());
  
  const dispatch = useDispatch();
  if(token ===''){
    setToken(Cookies.get('u_tkn'));
  }
  useEffect(()=>{
        const data={
          'tglawal':format(tanggalawal,"yyyy-MM-dd"),
          'tglakhir':format(tanggalakhir,"yyyy-MM-dd")
          }
          dispatch(getAllDataRetur(data,token,setOpensnackbar))
      
      if(tanggalawal>tanggalakhir){
        dispatch(setReturPenjualanMessage('Tanggal tidak sesuai'));
        setOpensnackbar(true);
        setTanggalawal(new Date());
        setTanggalakhir(new Date());
      }
        dispatch(setEmptyAddDataReturPenjualan());
  },[tanggalawal,tanggalakhir,dispatch,token])
  const handleCloseSnackbar = (event,reason) =>{
    if(reason === 'clickaway'){
        return;
    }
    setOpensnackbar(false);
    setTimeout(() => {
        dispatch(setPenjualanMessage(''));
    }, 100);
  }
  const handleChangeTanggalAwal = (e)=>{
    setTanggalawal(e);
  }
  const handleChangeTanggalAkhir = (e)=>{
    setTanggalakhir(e);
  }
  const history = useHistory();
  const handleClickDetails = (faktur) =>{
     history.push(`/laporan/returpenjualan/${faktur}`);
  }
  const tableDataPenjualan = (
    <TableBody>
        {dataAllReturPenjualan.map((returpenjualan) => (
            <StyledTableRow key={returpenjualan.fakturreturpelanggan}>
            <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{format(new Date(returpenjualan.tanggal),'dd/MM/yyyy')}</Typography></StyledTableCell>
            <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{returpenjualan.fakturreturpelanggan}</Typography></StyledTableCell>
            <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{returpenjualan.fakturpenjualan}</Typography></StyledTableCell>
            <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{returpenjualan.nama}</Typography></StyledTableCell>
            <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{new Intl.NumberFormat().format(returpenjualan.total)}</Typography></StyledTableCell>
            <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>{returpenjualan.status}</Typography></StyledTableCell>
            <StyledTableCell width={120} align="center">
              <Tooltip title="Rincian Penjualan">
                <IconButton 
                onClick={()=>handleClickDetails(returpenjualan.fakturpenjualan)}
                >
                  <img src={DetailsIcon} alt="print"/>
                </IconButton>
              </Tooltip>
              </StyledTableCell>
            </StyledTableRow>
        ))}
    </TableBody>
)
  return (
    <Container maxWidth='xl' sx={{marginTop:'50px'}}>
        <Typography variant='h6' pl={1} mt={1}>Laporan Retur Penjualan</Typography>
        <Divider/>
        <Box sx={boxWapper}>
          <Box sx={paperInput}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
          label="Tanggal Awal"
          inputFormat="dd/MM/yyyy"
          renderInput={(params) => <TextField {...params} sx={{width:'150px',marginRight:'20px'}} variant="standard"/>}
          value={tanggalawal}
          onChange={(e)=>handleChangeTanggalAwal(e)}
          />
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
          label="Tanggal Akhir"
          inputFormat="dd/MM/yyyy"
          renderInput={(params) => <TextField {...params} sx={{width:'150px',marginRight:'10px'}} variant="standard"/>}
          value={tanggalakhir}
          onChange={(e)=>handleChangeTanggalAkhir(e)}
          />
          </LocalizationProvider>
         
          </Box>
          <TableContainer component={Paper} sx={paperTable}>
                  <Table stickyHeader aria-label="customized table" size='small'>
                      <TableHead>
                      <TableRow>
                          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Tanggal</Typography></StyledTableCell>
                          <StyledTableCell width={200} align="center"><Typography sx={{fontSize:12}}>Faktur Retur</Typography></StyledTableCell>
                          <StyledTableCell width={200} align="center"><Typography sx={{fontSize:12}}>Faktur Penjualan</Typography></StyledTableCell>
                          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Pelanggan</Typography></StyledTableCell>
                          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Total</Typography></StyledTableCell>
                          <StyledTableCell width={120} align="center"><Typography sx={{fontSize:12}}>Status</Typography></StyledTableCell>
                          <StyledTableCell width={20} align="center"></StyledTableCell>
                      </TableRow>
                      </TableHead>
                          {tableDataPenjualan} 
                  </Table>
               </TableContainer>
        </Box>
        <Snackbar 
            open={openSnackbar} 
            autoHideDuration={1000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={returpenjualanMessage === "Berhasil menampilkan data retur pelanggan" ? "success" : "error"}
                >
                    {returpenjualanMessage}
                    </Alert>
            </Snackbar>
            <Fab sx={{position: 'fixed',bottom: theme.spacing(5),left: theme.spacing(3)}} size="small" color="primary" aria-label="add" onClick={()=>history.push('/laporan/')}>
                <ArrowBackIcon/>
            </Fab>
    </Container>
  )
}

export default LaporanReturPenjualan