import axios from 'axios';
import { apiUrl } from '../..';

export const getFakturPembelian = (tanggal,token,formik) =>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PEMBELIAN'
    })
    axios.get(`${apiUrl.url}pembelian/nofaktur/?tgl=${tanggal}`,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESSED GET NO FAKTUR',
            payload:ResponseAPI.data
        })
        formik.setFieldValue('fakturpembelian',ResponseAPI.data)
    })
}
export const emptyfaktuPembelian = () =>(dispatch) =>{
    dispatch({
        type:'EMPTY FAKTUR PEMBELIAN'
    })
}
export const setMessagePembelian = (message) =>(dispatch)=>{
    dispatch({
        type:'SET PEMBELIAN MESSAGE',
        payload:message
    })
}
export const addToCartPembelian = (data,setOpensnackbar)=>(dispatch)=>{
    dispatch({
        type:'ADD CART PEMBELIAN',
        payload:data
    })
    dispatch({
        type:'SET PEMBELIAN MESSAGE',
        payload:'Barang berhasil dimasukkan ke daftar'
    })
    setOpensnackbar(true);
}
export const addListDiskon = (id,jenisdiskon,diskon,) =>(dispatch)=>{
    dispatch({
        type:'ADD LIST DISKON',
        payload:{
            'id':id,
            'jenisdiskon':jenisdiskon,
            'diskon':diskon
        }
    })
}
export const deleteDiskon = (id)=>(dispatch)=>{
    dispatch({
      type:'DELETE DISKON',
      payload:id
    })
  }
export const setEmptyListDiskon = () =>(dispatch)=>{
    dispatch({
        type:'SET EMPTY LIST DISKON',
        payload:[]
    })
}
export const deleteItemPembelian = (id)=>(dispatch)=>{
    dispatch({
      type:'DELETE CART PEMBELIAN',
      payload:id
    })
  }
export const addListDiskonTotal = (id,jenisdiskon,diskon) =>(dispatch)=>{
    dispatch({
        type:'ADD LIST DISKON TOTAL',
        payload:{
            'id':id,
            'jenisdiskon':jenisdiskon,
            'diskon':diskon,
        }
    })
}
export const deleteDiskonTotal = (id)=>(dispatch)=>{
    dispatch({
      type:'DELETE DISKON TOTAL',
      payload:id
    })
  }
export const setEmptyListDiskonTotal = () =>(dispatch)=>{
    dispatch({
        type:'SET EMPTY LIST DISKON TOTAL',
        payload:[]
    })
}
export const getNilaiPPN = (token,setOpensnackbar) =>(dispatch)=>{
    axios.get(`${apiUrl.url}config/`,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'GET NILAI PPN',
            payload:ResponseAPI.data[0].value
        })
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION PEMBELIAN',
          payload:error.message
        })
        setOpensnackbar(true);
    })
}
export const emptyNilaiPPN = (value)=>(dispatch)=>{
    dispatch({
        type:'EMPTY NILAI PPN',
        payload:value
      })
}
export const editNilaiPPN = (value,token)=>(dispatch)=>{
    const data = {
        'value':value
    }
    axios.patch(`${apiUrl.url}config/1`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
}
export const postPembelian = (data,token,setOpensnackbar,formik,setAutoId,setDisableFaktur,setCheckPPN,setCheckSwitch,setLabelSwitch,setOpenDialog) => (dispatch)=>{
    dispatch({
        type:'FETCH ACTION PEMBELIAN'
    })
    setTimeout(()=>{
        axios.post(`${apiUrl.url}pembelian/`,data,{
            headers:{
                "Authorization" : `Bearer ${token}`,
            }
        })
        .then(res=>{
            const ResponseAPI = res.data;
            if(ResponseAPI.message==='Transaksi pembelian berhasil dilakukan'){
                dispatch({
                    type:'SUCCESSED POST PEMBELIAN',
                    payload:ResponseAPI.message
                })
                dispatch({
                    type:'EMPTY FAKTUR PEMBELIAN'
                })
                dispatch({
                    type:'SET EMPTY LIST DISKON TOTAL',
                    payload:[]
                })
                dispatch({
                    type:'EMPTY CART PEMBELIAN'
                })
                axios.get(`${apiUrl.url}pembelian/nofaktur/?tgl=${data.tanggal}`,{
                    headers:{
                        "Authorization" : `Bearer ${token}`,
                    }
                })
                .then(res=>{
                    const ResponseAPI = res.data;
                    dispatch({
                        type:'SUCCESSED GET NO FAKTUR',
                        payload:ResponseAPI.data
                    })
                    formik.setFieldValue('fakturpembelian',ResponseAPI.data)
                    formik.handleReset();
                    setAutoId(false);
                    setDisableFaktur(false);
                    setCheckPPN(false);
                    setCheckSwitch(false);
                    setLabelSwitch("Tanpa PPN");
                    setOpenDialog(false);
                    setOpensnackbar(true);
                    dispatch({
                        type:'EMPTY DATA COMBO DISTRIBUTOR'
                    })
                })
            }else{
                dispatch({
                    type:'FAILED ACTION PEMBELIAN',
                    payload:ResponseAPI.message
                  })
                  setOpensnackbar(true);
            }
        })
        .catch(error=>{
            dispatch({
              type:'FAILED ACTION PEMBELIAN',
              payload:error.message
            })
            setOpensnackbar(true);
        })
    },2000)
}

export const addPembelianFromExcel = (data) => (dispatch) =>{
    dispatch({
        type:'ADD FROM EXCEL',
        payload:data
      })
}
export const deletePembelianFromExcel = (id) => (dispatch) =>{
    dispatch({
        type:'DELETE DATA FROM EXCEL',
        payload:id
      })
}
export const emptyListFromExcel = () => (dispatch) =>{
    dispatch({
        type:'CLEAR DATA FROM EXCEL'
      })
}

export const getDataPembelian = (data,token,setOpensnackbar)=>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PEMBELIAN'
    })
    axios.post(`${apiUrl.url}pembelian/getAll`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseAPI=res.data;
        dispatch({
            type:'SUCCESSED GET DATA PEMBELIAN',
            payload:ResponseAPI.data
        })
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION PEMBELIAN',
          payload:error.message
        })
        setOpensnackbar(true);
    })
}
export const getDataPembelianById = (fakturpembelian,token,setOpensnackbar)=>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PEMBELIAN'
    })
    axios.get(`${apiUrl.url}pembelian/id/${fakturpembelian}`,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseAPI=res.data;
        axios.get(`${apiUrl.url}pembelian/${fakturpembelian}`,{
            headers:{
                "Authorization" : `Bearer ${token}`,
            }
        })
        .then(res=>{
            const ResponseAPIDet=res.data;
            dispatch({
                type:'SUCCESSED GET DATA PEMBELIAN BY ID',
                payload:ResponseAPI.data[0],
                details:ResponseAPIDet.data
            })
        })
        .catch(error=>{
            dispatch({
              type:'FAILED ACTION PEMBELIAN',
              payload:error.message
            })
            setOpensnackbar(true);
        })
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION PEMBELIAN',
          payload:error.message
        })
        setOpensnackbar(true);
    })
}
export const pembatalanPembelian = (data,token,setOpensnackbar,setOpenDialog)=>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PEMBELIAN'
    })
    axios.post(`${apiUrl.url}pembelian/pembatalan`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESSED PEMBATALAN PEMBELIAN',
            payload:ResponseAPI.message
        })
        setOpensnackbar(true);
        setOpenDialog(false);
        axios.post(`${apiUrl.url}pembelian/getAll`,data,{
            headers:{
                "Authorization" : `Bearer ${token}`,
            }
        })
        .then(res=>{
            const ResponseAPI=res.data;
            dispatch({
                type:'SUCCESSED GET DATA PEMBELIAN',
                payload:ResponseAPI.data
            })
        })
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION PEMBELIAN',
          payload:error.message
        })
        setOpensnackbar(true);
    })
}
export const pembatalanPembelianByDist = (data,token,setOpensnackbar,handleCloseDialog)=>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PEMBELIAN'
    })
    axios.post(`${apiUrl.url}pembelian/pembatalan`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
            type:'SUCCESSED PEMBATALAN PEMBELIAN',
            payload:ResponseAPI.message
        })
        setOpensnackbar(true);
        handleCloseDialog();
    })
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION PEMBELIAN',
          payload:error.message
        })
        setOpensnackbar(true);
    })
}
export const getDataPembelianDistributor = (data,token,setOpensnackbar)=>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION PEMBELIAN'
    })
    axios.post(`${apiUrl.url}pembelian/pembeliandistributor`,data,{
        headers:{
            "Authorization" : `Bearer ${token}`,
        }
    })
    .then(res=>{
        const ResponseAPI=res.data;
        dispatch({
            type:'SUCCESSED GET PEMBELIAN DISTRIBUTOR',
            payload:ResponseAPI.data
        })
    }) 
    .catch(error=>{
        dispatch({
          type:'FAILED ACTION PEMBELIAN',
          payload:error.message
        })
        setOpensnackbar(true);
    })
}
export const setEmptyPembelianDistributor = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY PEMBELIAN DISTRIBUTOR'
    })
}

export const getIdReturPembelian = (tempFaktur,token,setOpensnackbar) =>(dispatch)=>{
    dispatch({
        type:'FETCH ACTION RETUR'
    })
    axios.get(`${apiUrl.url}returpembelian/getidretur/${tempFaktur}`,{
        headers:{
            "Authorization" : `Bearer ${token}`
          }
    })
    .then(res=>{
        const ResponseAPI=res.data;
        dispatch({
            type:'SUCCESSED GET ID RETUR PEMBELIAN',
            payload:ResponseAPI.data.fakturRetur
        })
    })
    .catch(error=>{
        dispatch({
          type:'FAILED GET ID RETUR PEMBELIAN',
          payload:error.message
        })
        setOpensnackbar(true);
    })
}
export const emptyPembelianForm = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY FAKTUR PEMBELIAN'
    })
    dispatch({
        type:'SET EMPTY LIST DISKON TOTAL',
        payload:[]
    })
    dispatch({
        type:'EMPTY CART PEMBELIAN'
    })
}
export const setEmptyDataPembelian = () =>(dispatch)=>{
    dispatch({
        type:'EMPTY DATA PEMBELIAN'
    })
}