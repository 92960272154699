const pelangganInitialState = {
    isLoading:false,
    pagePelanggan : {
        currentPage:'1',
        totalPage:'1'
    },
    dataPelanggan:[],
    detailsPelanggan:'',
    pelangganMessage:''
}
const pelangganReducers = (state=pelangganInitialState,action)=>{
    switch(action.type){
        case 'EMPTY PELANGGAN MESSAGE':{
            return{
                ...state,
                pelangganMessage:action.payload
            }
        }
        case 'UPDATE PAGE PELANGGAN':{
            return {
                ...state,
                pagePelanggan:action.payload
            }
        }
        case 'UPDATE DATA PELANGGAN':{
            return{
                ...state,
                isLoading:false,
                dataPelanggan:action.payload
            }
        }
        case 'FETCH GET ALL PELANGGAN':{
            return{
                ...state,
                isLoading:true
            }
        }
        case 'FAILED GET ALL PELANGGAN':{
            return{
                ...state,
                isLoading:false,
                pelangganMessage:action.payload
            }
        }
        case 'FETCH ACTION PELANGGAN':{
            return{
                ...state,
                isLoading:true
            }
        }
        case 'SUCCESSED ACTION PELANGGAN':{
            return{
                ...state,
                isLoading:false,
                pelangganMessage:action.payload
            }
        }
        case 'FAILED ACTION PELANGGAN':{
            return{
                ...state,
                isLoading:false,
                pelangganMessage:action.payload
            }
        }
        case 'SUCCESSED GET PELANGGAN DETAILS':{
            return{
                ...state,
                isLoading:false,
                detailsPelanggan:action.payload,
                pelangganMessage:action.message
            }
        }
        case 'EMPTY DATA PELANGGAN':{
            return{
                ...state,
                dataPelanggan:[]
            }
        }
        default: return state
    }
}
export default pelangganReducers;