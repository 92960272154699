import React, { useEffect, useState } from 'react'
import {Container,Typography,styled, useTheme,Divider,Box, TextField, TableContainer, Table, TableHead, TableRow, TableCell, tableCellClasses, Paper, TableBody, Tooltip, IconButton, Snackbar, Button} from '@mui/material';
import Cookies from 'js-cookie';
import MuiAlert from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { Barcode, DeleteData, EditData, EditStok, KonversiSatuan } from '../../../components/assets';
import { useHistory } from 'react-router-dom';
import { DeleteDialog,TablePagination } from '../../../components/molecules';
import { deleteBarang, emptyBarangExcel, emptyBarangMessage, emptyToDoSatuan, getAllBarang, getAllSatuanBarang, getDetailsBarang} from '../../../config/redux/actions';
import KonversiBarangDialog from '../../../components/molecules/konversiBarangDialog';
import AddBarcodeDialog from '../../../components/molecules/addBarcodeDialog';
import UbahBarang from '../UbahBarang';
import UbahSaldoAwal from '../UbahSaldoAwal';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'grey',
        color: theme.palette.common.black
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
const BarangPage = () => {
    const theme = useTheme();
    const paperTable = {
        marginTop:'10px',
        [theme.breakpoints.up('sm')]:{
            width:'90%',
            height:'370px',
        }, 
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            height:'360px'
        },
        scrollbarWidth:'thin'
    }
    const boxSearch = {
        [theme.breakpoints.up('sm')]:{
            display:'flex',
            justifyContent:'space-between',
            alignItems:'flex-end',
            width:'90%',
        }, 
        [theme.breakpoints.down('sm')]:{
            display:'flex',
            justifyContent:'space-between',
            alignItems:'flex-end',
            width:'100%',
        }
    }
    const {dataBarang,pageBarang,messageBarang,isLoading} = useSelector(state=>state.barangReducers);
    const {dataLogin} =useSelector(state=>state.penggunaReducers);
    const history = useHistory();
    const dispatch = useDispatch();
    const [token,setToken] = useState(dataLogin.token);
    const [search,setSearch] = useState('');
    const [counter,setCounter] = useState(1);
    const [openSnackbar,setOpensnackbar]=useState(false);
    const [openDialog,setOpenDialog]=useState(false);
    const [messageDialog,setMessageDialog]=useState('');
    const [tempIdBarang,setTempIdBarang] = useState('');
    const [openKonversi,setOpenKonversi] = useState(false);
    const [namaBarangKonv,setNamaBarangKonv]=useState('');
    const [idBarangKonv,setIDBarangKonv]=useState('');
    const [openBarcode,setOpenBarcode] = useState(false);
    const [idBarangBarcode,setIdBarangBarcode] = useState('');
    const [namaBarangBarcode,setNamaBarangBarcode] = useState('');
    const [openUbahBarang,setOpenUbahBarang]=useState(false);
    const [idbarangUbah,setIdBarangUbah]=useState('');
    const [namabarangUbah,setNamaBarangUbah]=useState('');
    const [openUbahSaldo,setOpenUbahSaldo]=useState(false);
    const [idBarangUbahSaldo,setIdBarangUbahSaldo]=useState('');
    const [namabarangUbahSaldo,setNamaBarangUbahSaldo]=useState('');
    const handleChangeSearch = (e) =>{
        setSearch(e.target.value);
        setCounter(1);
        dispatch(getAllBarang(e.target.value,counter,token,setOpensnackbar));
    }
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    useEffect(()=>{
        dispatch(getAllBarang(search,counter,token,setOpensnackbar));
    },[search,counter,token,dispatch])
    const handlePrev = () =>{
        setCounter(counter <= 1? 1 : counter - 1);
    }
    const handleNext = () =>{
        setCounter(counter === pageBarang.totalPage ? pageBarang.totalPage : counter + 1);
    }
    const handleEditBarang = (idbarang,namabarang) =>{
        dispatch(getDetailsBarang(idbarang,token,setOpensnackbar));
        setIdBarangUbah(idbarang);
        setNamaBarangUbah(namabarang);
        setOpenUbahBarang(true);
    }
    const handleEditStok = (idbarang,namabarang) =>{
        dispatch(getAllSatuanBarang(idbarang,token,setOpensnackbar));
        dispatch(emptyToDoSatuan());
        setIdBarangUbahSaldo(idbarang);
        setNamaBarangUbahSaldo(namabarang);
        setOpenUbahSaldo(true);
    }
    const handleCloseUbahSaldo=()=>{
        setIdBarangUbahSaldo('');
        setNamaBarangUbahSaldo('');
        setOpenUbahSaldo(false);
        dispatch(getAllBarang(search,counter,token,setOpensnackbar));
    }
    const handleOpenDialog = (idbarang,namabarang)=>{
        setMessageDialog(`Apakah anda yakin akan menghapus data ${namabarang}(${idbarang})?`);
        setTempIdBarang(idbarang);
        setOpenDialog(true);
    }
    const handleCloseDialog = ()=>{
        setOpenDialog(false);
    }
    const handleOpenBarcode = (idbarang,namabarang)=>{
        setIdBarangBarcode(idbarang);
        setNamaBarangBarcode(namabarang);
        setOpenBarcode(true);
    }
    const handleCloseBarcode = ()=>{
        setIdBarangBarcode('');
        setNamaBarangBarcode('');
        setOpenBarcode(false);
    }
    const handleClickKonversiSatuan = (idbarang,namabarang) =>{
        setOpenKonversi(true);
        setIDBarangKonv(idbarang);
        setNamaBarangKonv(namabarang);
    }
    const handleCloseKonversi=()=>{
        setOpenKonversi(false); 
        setIDBarangKonv('');
        setNamaBarangKonv('');
    }
    const handleCloseUbahBarang=()=>{
        setOpenUbahBarang(false);
        setIdBarangUbah('');
        setNamaBarangUbah('');
    }
    const handleDeleteBarang = () =>{
        dispatch(deleteBarang(tempIdBarang,token,setOpensnackbar,search,counter,setOpenDialog));
    }
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(emptyBarangMessage(''));
        }, 100);
    }
    const handleClickBarangExcel = () =>{
        dispatch(emptyBarangExcel());
        dispatch(emptyToDoSatuan());
        history.push('/barang/excel');
    }
    const handleClickBarangBaru = () =>{
        dispatch(emptyToDoSatuan());
        history.push('/barang/baru');
    }
    const handleClickUbahPPN = () =>{
        history.push('/barang/perubahanppn');
    }
    const tableBarang = (
        <TableBody>
            {dataBarang.map((barangs) => (
                <StyledTableRow key={barangs.idbarang}>
                <StyledTableCell width={120} align="center">{barangs.idbarang}</StyledTableCell>
                <StyledTableCell width={120} align="center">{barangs.namabarang}</StyledTableCell>
                <StyledTableCell width={120} align="center">{barangs.kelompok}</StyledTableCell>
                <StyledTableCell width={120} align="center" key={barangs.iddistributor}>{barangs.nama}</StyledTableCell>
                <StyledTableCell width={160} align="center">
                    <Tooltip title="Konversi Satuan" >
                        <IconButton 
                        onClick={()=>handleClickKonversiSatuan(barangs.idbarang,barangs.namabarang)}>
                            <img src={KonversiSatuan} alt="konversi"/>
                        </IconButton>
                     </Tooltip>
                     <Tooltip title="Barcode" >
                        <IconButton 
                        onClick={()=>handleOpenBarcode(barangs.idbarang,barangs.namabarang)}>
                            <img src={Barcode} alt="barcode"/>
                        </IconButton>
                     </Tooltip>
                    <Tooltip title="Ubah data" >
                        <IconButton 
                        onClick={()=>handleEditBarang(barangs.idbarang,barangs.namabarang)}>
                            <img src={EditData} alt="editData"/>
                        </IconButton>
                     </Tooltip>
                     <Tooltip title="Ubah Stok Awal" >
                        <IconButton 
                        onClick={()=>handleEditStok(barangs.idbarang,barangs.namabarang)}>
                            <img src={EditStok} alt="editData"/>
                        </IconButton>
                     </Tooltip>
                    <Tooltip title="Hapus data">
                        <IconButton onClick={()=>handleOpenDialog(barangs.idbarang,barangs.namabarang)}>
                            <img src={DeleteData} alt="deleteData"/>
                        </IconButton>
                    </Tooltip>
                </StyledTableCell>
                </StyledTableRow>
            ))}
        </TableBody>
    )
    return (
        <Container maxWidth='xl' sx={{marginTop:'50px'}}>
            <Typography variant='h6' pl={1} mt={1}>Data Barang</Typography>
            <Divider/>
            <Box sx={{marginTop:'5px',width:'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
            <Box sx={boxSearch}>
                <div>
                <Button
                sx={{height:'30px'}}
                size='small'
                variant="contained"
                color="primary"
                onClick={handleClickBarangExcel}
                >
                    Dari File Excel
                </Button>
                <Button
                sx={{height:'30px',marginLeft:'15px'}}
                size='small'
                variant="contained"
                color="primary"
                onClick={handleClickUbahPPN}
                >
                    Ubah PPN
                </Button>
                </div>
                <TextField
                id='search'
                name='search'
                label='Cari Barang'
                variant='standard'
                onChange={handleChangeSearch}
                />
            </Box>
            <TableContainer component={Paper} sx={paperTable}>
                <Table stickyHeader aria-label="customized table" size='small'>
                    <TableHead>
                    <TableRow>
                        <StyledTableCell width={120} align="center">ID Barang</StyledTableCell>
                        <StyledTableCell width={120} align="center">Nama Barang</StyledTableCell>
                        <StyledTableCell width={120} align="center">Kelompok</StyledTableCell>
                        <StyledTableCell width={120} align="center">Distributor</StyledTableCell>
                        <StyledTableCell width={160} align="center"/>
                    </TableRow>
                    </TableHead>
                        {tableBarang} 
                </Table>
            </TableContainer>
            <Box sx={boxSearch}>
            <TablePagination
            page={counter}
            totalPage={pageBarang.totalPage >= 1 ? pageBarang.totalPage : pageBarang.currentPage}
            handlePrev={handlePrev}
            handleNext={handleNext}
            hiddenButton={false}
            onButtonClick={handleClickBarangBaru}
            />
            </Box>
            </Box>
            <KonversiBarangDialog openKonversiDialog={openKonversi} closKonversiDialog={handleCloseKonversi}
            idbarang={idBarangKonv}
            namabarang={namaBarangKonv}/>
            <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={messageBarang === 'Penghapusan data barang berhasil'? 'success':'error'}
                >
                    {messageBarang}
                    </Alert>
            </Snackbar>
            <DeleteDialog 
            openDialog={openDialog} 
            message={messageDialog}
            handleCloseDialog={handleCloseDialog}
            handleClick={handleDeleteBarang}
            isLoading={isLoading}
            textHapus={"Ya"}
            textKeluar={"Tidak"}/>
            <AddBarcodeDialog 
            openBarcodeDialog={openBarcode} 
            closeBarcodeDialog={handleCloseBarcode}
            idbarang={idBarangBarcode}
            namabarang={namaBarangBarcode}/>
            <UbahBarang 
            openUbahBarang={openUbahBarang} 
            closeUbahBarang={handleCloseUbahBarang} 
            idbarang={idbarangUbah} 
            namabarang={namabarangUbah}/>
            <UbahSaldoAwal
            openUbahSaldo={openUbahSaldo} 
            closeUbahSaldo={handleCloseUbahSaldo} 
            idbarang={idBarangUbahSaldo} 
            namabarang={namabarangUbahSaldo}/>
        </Container>
    )
}

export default BarangPage
