import { Divider, ListItemButton, Typography } from '@mui/material';
import React from 'react';

const MenuList = ({icon,alt,label,id,handleClick,disabled,...rest}) => {
    
    return (
        <React.Fragment>
        <ListItemButton disabled={disabled} {...rest} sx={{width:'100%',backgroundColor:'inherit',":hover":{backgroundColor:'#5e5c5c'}}}
        onClick={handleClick}>
            <img src={icon} alt={alt}/>
            <Typography pl={2} variant='body' component="h4" sx={{color:'white'}}>{label}</Typography>
        </ListItemButton>
        <Divider sx={{width:'100%',height:'1px',backgroundColor:'grey'}}/>
        </React.Fragment>
    )
}

export default MenuList
