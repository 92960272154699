const penjualanInitialValue = {
    isLoading:false,
    cartPenjualan:[],
    penjualanMessage:'',
    TempFakturPenjualan:'',
    hargajual:'',
    lastStokQty:'',
    dataPenjualan:[],
    penjualanToPrint:[],
    detailsPenjualanToPrint:[],
    penjualanSingle:[],
    penjualanPelanggan:[],
    returPelanggan:[],
    penjualanDetails:[],
    dataBarcode:'',
    dataAllPenjualanDetails:[],
    ppnPenjualan:0,
    loadFakturPenjualan:''
}
const penjualanReducers = (state=penjualanInitialValue,action)=>{
    switch(action.type){
        case 'FETCH ACTION PENJUALAN':{
            return{
                ...state,
                isLoading:true
            }
        }
        case 'SET PENJUALAN MESSAGE':{
            return{
                ...state,
                penjualanMessage:action.payload
            }
        }
        case 'SUCCESSED GET NO FAKTUR PENJUALAN':{
            return{
                ...state,
                isLoading:false,
                TempFakturPenjualan:action.payload
            }
        }
        case 'SUCCESSED GET DATA PENJUALAN':{
            return{
                ...state,
                isLoading:false,
                dataPenjualan:action.payload
            }
        }
        case 'EMPTY FAKTUR PENJUALAN':{
            return{
                ...state,
                TempFakturPenjualan:'',
                isLoading:false
            }
        }
        case 'EMPTY PENJUALAN TO PRINT':{
            return{
                ...state,
                detailsPenjualanToPrint:[],
                penjualanToPrint:[],
                isLoading:false
            }
        }
        case 'GET HARGA JUAL':{
            return{
                ...state,
                hargajual:action.payload,
                isLoading:false
            }
        }
        case 'EMPTY HARGA JUAL':{
            return{
                ...state,
                hargajual:'',
                isLoading:false
            }
        }
        case 'ADD TO CART PENJUALAN':{
            return{
                ...state,
                cartPenjualan:[...state.cartPenjualan,action.payload]
            }
        }
        case 'ADD DRAFT TO CART':{
            return{
                ...state,
                cartPenjualan:action.payload
            }
        }
        case 'SUCCESSED POST PENJUALAN':{
            return{
                ...state,
                penjualanMessage:action.payload,
                TempFakturPenjualan:action.faktursave,
                isLoading:false
            }
        }
        case 'EMPTY CART PENJUALAN':{
            return{
                ...state,
                cartPenjualan:[]
            }
        }
        case 'SUCCESSED GET LAST STOK QTY':{
            return{
                ...state,
                lastStokQty:action.payload,
                isLoading:false,
            }
        }
        case 'EMPTY LAST STOK QTY':{
            return{
                ...state,
                lastStokQty:"",
                penjualanMessage:"",
                isLoading:false,
            }
        }
        case 'FAILED ACTION PENJUALAN':{
            return{
                ...state,
                isLoading:false,
                penjualanMessage:action.payload
            }
        }
        case 'DELETE CART PENJUALAN':{
            return{
                ...state,
                cartPenjualan:state.cartPenjualan.filter(penjualan=>penjualan.id !== action.payload)
            }
        }
        case 'SUCCESSED GET PENJUALAN TO PRINT':{
            return{
                ...state,
                penjualanToPrint:action.payload,
                detailsPenjualanToPrint:action.details,
                isLoading:false
            }
        }
        case 'SUCCESSED GET PENJUALAN PELANGGAN':{
            return{
                ...state,
                penjualanPelanggan:action.payload,
                returPelanggan:action.retur,
                isLoading:false
            }
        }
        case 'EMPTY PENJUALAN PELANGGAN':{
            return{
                ...state,
                penjualanPelanggan:[],
                returPelanggan:[],
                isLoading:false
            }
        }
        case 'SUCCESSED GET PENJUALAN':{
            return{
                ...state,
                penjualanSingle:action.payload,
                isLoading:false
            }
        }
        case 'SUCCESSED GET PENJUALAN DETAILS':{
            return{
                ...state,
                penjualanDetails:action.payload,
                isLoading:false
            }
        }
        case 'EMPTY DATA PENJUALAN':{
            return{
                ...state,
                isLoading:false,
                dataPenjualan:[]
            }
        }
        case 'SUCCESSED PEMBATALAN PENJUALAN':{
            return{
                ...state,
                isLoading:false,
                penjualanMessage:action.payload
            }
        }
        case 'SUCESSED GET DATA BARCODE':{
            return{
                ...state,
                isLoading:false,
                dataBarcode:action.payload,
                penjualanMessage:action.message
            }
        }
        case 'SUCESSED GET ALL PENJUALAN DETAILS':{
            return{
                ...state,
                isLoading:false,
                dataAllPenjualanDetails:action.payload
            }
        }
        case 'EMPTY DATA ALL PENJUALAN DETAILS':{
            return{
                ...state,
                isLoading:false,
                dataAllPenjualanDetails:action.payload
            }
        }
        case 'UPDATE ITEM PLUS':{
            let id = action.id;
            return{
                ...state,
                cartPenjualan:state.cartPenjualan.map((item,index)=>{
                    if(item.id===id){
                        return{
                            id:id,
                            idpenjualandetails:item.idpenjualandetails,
                            idbarang:item.idbarang,
                            namabarang:item.namabarang,
                            idsatuan:item.idsatuan,
                            tempPPN:item.tempPPN,
                            kelompok:item.kelompok,
                            satuan:item.satuan,
                            harga:item.harga,
                            qty:parseInt(item.qty)+1,
                            diskon:item.diskon,
                            jumlah:(item.harga-item.diskon)*(parseInt(item.qty)+1),
                            mk:item.mk,
                            tk:item.mk*(parseInt(item.qty)+1)
                        }
                    }
                    return item
                })
                
            }
        }
        case 'UPDATE ITEM MINUS':{
            let id = action.id;
            return{
                ...state,
                cartPenjualan:state.cartPenjualan.map((item,index)=>{
                    if(item.id===id){
                        if(item.qty>1){
                            return{
                                id:id,
                                idpenjualandetails:item.idpenjualandetails,
                                idbarang:item.idbarang,
                                namabarang:item.namabarang,
                                idsatuan:item.idsatuan,
                                tempPPN:item.tempPPN,
                                kelompok:item.kelompok,
                                satuan:item.satuan,
                                harga:item.harga,
                                qty:parseInt(item.qty)-1,
                                diskon:item.diskon,
                                jumlah:(item.harga-item.diskon)*(parseInt(item.qty)-1),
                                mk:item.mk,
                                tk:item.mk*(parseInt(item.qty)-1)
                            }
                        }
                        return item
                    }
                    return item
                })
            }
        }
        case 'SET FAKTUR PENJUALAN WITH DRAFT':{
            return{
                ...state,
                TempFakturPenjualan:action.payload,
                isLoading:false
            }
        }
        case 'GET NILAI PPN PENJUALAN':{
            return{
                ...state,
                ppnPenjualan:action.payload,
                isLoading:false
            }
        }
        case 'EMPTY FROM PENJUALAN':{
            return{
                ...state,
                isLoading:false,
                cartPenjualan:[],
                penjualanMessage:'',
                TempFakturPenjualan:'',
                hargajual:'',
                lastStokQty:'',
                dataPenjualan:[],
                penjualanToPrint:[],
                detailsPenjualanToPrint:[],
                penjualanSingle:[],
                penjualanPelanggan:[],
                returPelanggan:[],
                penjualanDetails:[],
                dataBarcode:'',
                dataAllPenjualanDetails:[],
                ppnPenjualan:0
            }
        }
        default: return state
    }
}

export default penjualanReducers;