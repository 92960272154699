import { Box, Button, CircularProgress, Dialog, DialogContent, Divider, IconButton, Snackbar, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import MuiAlert from '@mui/material/Alert';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useDispatch, useSelector } from 'react-redux';
import { emptyUserMessage, otoritasUser } from '../../../config/redux/actions';
import * as yup from 'yup';
import { useFormik } from 'formik';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
const AuthorizationDialog = ({openAuthDialog,validationSuccess,closeAuthDialog,...rest}) => {
    const paperInput={
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        width:"250px",
        height:'100%',
        borderRadius:'10px',
        marginTop:'10px'
    }
    const dispatch = useDispatch();
    const {totalUser,isLoading,penggunaMessage} = useSelector(state=>state.penggunaReducers);
    const [openSnackbar,setOpensnackbar]=useState(false);
    const [showPassword,setShowPassword]=useState(false);
    const validationSchema = yup.object().shape({
        idpengguna:yup.string().required("ID Pengguna tidak boleh kosong"),
        password:yup.string().required("Kata sandi tidak boleh kosong")
    })
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            idpengguna:'',
            password:''
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const data = new URLSearchParams();
            data.append('idpengguna',values.idpengguna);
            data.append('password',values.password);
            dispatch(otoritasUser(data,setOpensnackbar,validationSuccess));
        }
    })
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(emptyUserMessage(''));
            formik.handleReset();
        }, 100);
    }
    const handleClickShowPassword = () =>{
        setShowPassword(!showPassword);
    } 
    const handleEnterPassword = (e) =>{
        if(e.key==="Enter"){
            formik.handleSubmit();
        }
    }
  return (
    <Dialog open={openAuthDialog} {...rest}>
        <DialogContent>
            <Box sx={paperInput}>
                <Typography variant='h6'>Otorisasi</Typography>
                <Divider sx={{width:'100%'}}/>
                <TextField
                id="idpengguna"
                name="idpengguna"
                label="ID Pengguna"
                variant="standard"
                style={{width:'100%',marginTop:'10px'}}
                value={formik.values.idpengguna}
                error={formik.touched.idpengguna && Boolean(formik.errors.idpengguna)}
                helperText={formik.touched.idpengguna && formik.errors.idpengguna}
                onChange={formik.handleChange}
                />
                <Box sx={{width:'100%',display:'flex',alignItems:'flex-end',marginTop:'2px'}}>
                        <TextField
                        id="password"
                        label="Kata Sandi"
                        type={showPassword ? 'text':'password'}
                        variant="standard"
                        style={{width:'85%'}}
                        disabled={totalUser===""?true:false}
                        value={formik.values.password}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        onChange={formik.handleChange}
                        onKeyDown={handleEnterPassword}
                        />
                        <IconButton 
                        arial-label='toggle-password'
                        onClick={handleClickShowPassword}
                        >
                        {showPassword?<Visibility/>:<VisibilityOff/>}
                        </IconButton>
                    </Box>
                <Box sx={{width:'100%',display:'flex',justifyContent:'space-between',marginTop:'10px'}}>
                    <Button variant="contained" color='primary' onClick={formik.handleSubmit}>
                        {isLoading === true ? <CircularProgress color='secondary' size={25}/> : "OK"}</Button>
                    <Button variant="contained" color='error' onClick={closeAuthDialog}>Keluar</Button>
                </Box>
            </Box>
            <Snackbar 
                open={openSnackbar} 
                autoHideDuration={500} 
                onClose={handleCloseSnackbar}
                anchorOrigin={{
                    vertical:'bottom',
                    horizontal:'center'
                }}>
                    <Alert 
                    onClose={handleCloseSnackbar}
                    severity={penggunaMessage === 'Akses otoritas terverifikasi'? 'success':'error'}
                    >
                        {penggunaMessage}
                        </Alert>
                </Snackbar>
        </DialogContent>
    </Dialog>
  )
}

export default AuthorizationDialog