import { useTheme,Container, Divider, Paper, styled, Table, TableCell, tableCellClasses, TableContainer, TableHead, TableRow, Typography, Box, Snackbar, TableBody, Fab } from '@mui/material'
import React, { useEffect, useState } from 'react'
import MuiAlert from '@mui/material/Alert';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { getDataPembelianById, setMessagePembelian } from '../../../config/redux/actions';
import { format } from 'date-fns';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { useHistory } from 'react-router-dom';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'grey',
        color: theme.palette.common.black
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
const RincianPembelian = () => {
    const theme = useTheme();
    const boxWapper = {
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        marginTop:'20px',
        [theme.breakpoints.up('sm')]:{
            width:'100%',
            maxHeight:'400px',
            height:'100%',
        },
        [theme.breakpoints.down('lg')]:{
            width:'100%',
            height:'100%',
        }
      }
  const paperTable = {
    marginTop:'10px',
    [theme.breakpoints.up('sm')]:{
        width:'90%',
        height:'400px',
    }, 
    [theme.breakpoints.down('sm')]:{
        width:'100%',
        height:'360px'
    },
    scrollbarWidth:'thin'
}
    const {dataPembelianById,pembelianMessage,cartPembelian}=useSelector(state=>state.pembelianReducers);
    const {dataLogin} =useSelector(state=>state.penggunaReducers);
    const [token,setToken] = useState(dataLogin.token);
    const [openSnackbar,setOpensnackbar]=useState(false);
    let {fakturpembelian}=useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    useEffect(()=>{
        dispatch(getDataPembelianById(fakturpembelian,token,setOpensnackbar));
    },[dispatch,token,fakturpembelian])
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(setMessagePembelian(''));
        }, 100);
      }
      const daftarPembelian = (
        <TableBody>
                {cartPembelian.map((pembelian) => (
                    <StyledTableRow key={pembelian.idpembeliandetails}>
                    <StyledTableCell width={120} align="center">{pembelian.idbarang}</StyledTableCell>
                    <StyledTableCell width={200} align="center">{pembelian.namabarang}</StyledTableCell>
                    <StyledTableCell width={120} align="center">{pembelian.satuan}</StyledTableCell>
                    <StyledTableCell width={120} align="center">{new Intl.NumberFormat().format(pembelian.qty)}</StyledTableCell>
                    <StyledTableCell width={120} align="center">{new Intl.NumberFormat().format(pembelian.harga)}</StyledTableCell>
                    <StyledTableCell width={120} align="center">{pembelian.diskon}</StyledTableCell>
                    <StyledTableCell width={120} align="center">{new Intl.NumberFormat().format(pembelian.harganet)}</StyledTableCell>
                    <StyledTableCell width={120} align="center">{new Intl.NumberFormat().format(pembelian.jumlah)}</StyledTableCell>
                    </StyledTableRow>
                ))}
            </TableBody>
    )
  return (
    <Container maxWidth='xl' sx={{marginTop:'50px'}}>
        <Typography variant='h6' pl={1} mt={1}>Rincian Pembelian</Typography>
        <Divider/>
        <Box sx={boxWapper}>
        <Box sx={{width:'90%',display:'flex'}}>
            <Box sx={{width:'50%',display:'flex'}}>
                <Box sx={{display:'flex',flexDirection:'column'}}>
                    <Typography>Tanggal</Typography>
                    <Typography>Faktur Pembelian</Typography>
                    <Typography>Distributor</Typography>
                </Box>
                <Box sx={{display:'flex',flexDirection:'column',marginLeft:'3px',marginRight:'3px'}}>
                    <Typography>:</Typography>
                    <Typography>:</Typography>
                    <Typography>:</Typography>
                </Box>
                <Box sx={{display:'flex',flexDirection:'column'}}>
                    <Typography>{dataPembelianById.tanggal !== undefined ? format(new Date(dataPembelianById.tanggal),'dd/MM/yyyy') : ''}</Typography>
                    <Typography>{dataPembelianById.fakturpembelian}</Typography>
                    <Typography>{dataPembelianById.nama}</Typography>
                </Box>
            </Box>
            <Box sx={{width:'50%',display:'flex'}}>
                <Box sx={{display:'flex',flexDirection:'column'}}>
                    <Typography>Jatuh Tempo</Typography>
                    <Typography>Pembayaran</Typography>
                    <Typography>Status</Typography>
                </Box>
                <Box sx={{display:'flex',flexDirection:'column',marginLeft:'3px',marginRight:'3px'}}>
                    <Typography>:</Typography>
                    <Typography>:</Typography>
                    <Typography>:</Typography>
                </Box>
                <Box sx={{display:'flex',flexDirection:'column'}}>
                    <Typography>{dataPembelianById.jatuhtempo !== undefined ? format(new Date(dataPembelianById.jatuhtempo),'dd/MM/yyyy'):''}</Typography>
                    <Typography>{dataPembelianById.pembayaran}</Typography>
                    <Typography>{dataPembelianById.status}</Typography>
                </Box>
            </Box>
        </Box>
        <TableContainer component={Paper} sx={paperTable}>
            <Table stickyHeader aria-label="customized table" size='small'>
                <TableHead>
                    <TableRow>
                        <StyledTableCell width={120} align="center">ID Barang</StyledTableCell>
                        <StyledTableCell width={200} align="center">Nama Barang</StyledTableCell>
                        <StyledTableCell width={120} align="center">Satuan</StyledTableCell>
                        <StyledTableCell width={120} align="center">Qty</StyledTableCell>
                        <StyledTableCell width={120} align="center">Harga</StyledTableCell>
                        <StyledTableCell width={120} align="center">Diskon</StyledTableCell>
                        <StyledTableCell width={120} align="center">Harga Netto</StyledTableCell>
                        <StyledTableCell width={120} align="center">Jumlah</StyledTableCell>
                    </TableRow>
                </TableHead>
                    {daftarPembelian} 
            </Table>
        </TableContainer>
            <Box sx={{width:'90%',display:'flex',justifyContent:'flex-end'}}>
                <Box sx={{display:'flex',flexDirection:'column'}}>
                    <Typography>Diskon</Typography>
                    <Typography>PPN</Typography>
                    <Typography>Total</Typography>
                </Box>
                <Box sx={{display:'flex',flexDirection:'column',marginLeft:'3px',marginRight:'3px'}}>
                    <Typography>:</Typography>
                    <Typography>:</Typography>
                    <Typography>:</Typography>
                </Box>
                <Box sx={{display:'flex',flexDirection:'column'}}>
                    <Typography>{dataPembelianById.ketdiskon}</Typography>
                    <Typography>{dataPembelianById.ppn !==0 ? dataPembelianById.ppn : 0} %</Typography>
                    <Typography>{new Intl.NumberFormat().format(dataPembelianById.total)}</Typography>
                </Box>
            </Box>
        </Box>
        <Snackbar 
            open={openSnackbar} 
            autoHideDuration={1000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={pembelianMessage === "Transaksi penjualan berhasil" ? "success" : "error"}
                >
                    {pembelianMessage}
                    </Alert>
            </Snackbar>
            <Fab sx={{position: 'fixed',bottom: theme.spacing(5),left: theme.spacing(3)}} size="small" color="primary" aria-label="add" onClick={()=>history.push('/laporan/pembelian')}>
                <ArrowBackIcon/>
            </Fab>
    </Container>
  )
}

export default RincianPembelian