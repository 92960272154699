import axios from 'axios';
import { apiUrl } from '../..';

export const getStokRetur = (search,page,token,setOpensnackbar) =>(dispatch)=>{
    dispatch({
      type:'FECTH ACTION STOK RETUR',
    })
    axios.get(`${apiUrl.url}stokretur/?search=${search}&page=${page}&perPage=50`,{
          headers:{
            "Authorization" : `Bearer ${token}`
          }
      })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
          type:'UPDATE DATA STOK RETUR', 
          payload:ResponseAPI.data
       })
        dispatch({
          type:'UPDATE PAGE STOK RETUR',
          payload:{
            currentPage: ResponseAPI.current_page,
            totalPage: Math.ceil(ResponseAPI.totalData/ResponseAPI.per_page)
          }
        })
        
    })
    .catch(error=>{
      dispatch({
        type:'FAILED GET DATA STOK RETUR',
        payload:error.message
      })
      setOpensnackbar(true);
    })
  }
  export const searchStokRetur = (search,page,token,setOpensnackbar) =>(dispatch)=>{
    dispatch({
      type:'FECTH ACTION STOK RETUR',
    })
    axios.get(`${apiUrl.url}stokretur/stok/?search=${search}&page=${page}&perPage=50`,{
          headers:{
            "Authorization" : `Bearer ${token}`
          }
      })
    .then(res=>{
        const ResponseAPI = res.data;
        dispatch({
          type:'UPDATE DATA STOK RETUR', 
          payload:ResponseAPI.data
       })
    })
    .catch(error=>{
      dispatch({
        type:'FAILED GET DATA STOK RETUR',
        payload:error.message
      })
      setOpensnackbar(true);
    })
  }
export const emptyStokReturMessage = (message) =>(dispatch)=>{
    dispatch({
        type:'EMPTY STOK RETUR MESSAGE',
        payload:message
    })
}
export const emptyDataStokRetur = () =>(dispatch)=>{
  dispatch({
      type:'EMPTY LIST STOK RETUR'
  })
}
export const emptySatuanStokRetur = () =>(dispatch)=>{
  dispatch({
      type:'EMPTY SATUAN STOK RETUR'
  })
}
export const getSatuanStokRetur = (idbarang,token,setOpensnackbar) =>(dispatch)=>{
  dispatch({
    type:'FECTH ACTION STOK RETUR',
  })
  axios.get(`${apiUrl.url}stokretur/${idbarang}`,{
        headers:{
          "Authorization" : `Bearer ${token}`
        }
    })
  .then(res=>{
      const ResponseAPI = res.data;
      dispatch({
        type:'SUCCESSED GET SATUAN STOK RETUR', 
        payload:ResponseAPI.data
     })
  })
  .catch(error=>{
    dispatch({
      type:'FAILED GET DATA STOK RETUR',
      payload:error.message
    })
    setOpensnackbar(true);
  })
}
export const editStokRetur = (data,token,setOpensnackbar)=> (dispatch)=>{
  dispatch({
    type:'FECTH ACTION STOK RETUR',
  })
  axios.post(`${apiUrl.url}stokretur/editstok/`,data,{
    headers:{
      "Authorization" : `Bearer ${token}`
    }
  })
  .then(res=>{
    const ResponseAPI = res.data;
    dispatch({
      type:'SUCCESSED EDIT STOK RETUR', 
      payload:ResponseAPI.message
  })
  })
  .catch(error=>{
    dispatch({
      type:'FAILED GET DATA STOK RETUR',
      payload:error.message
    })
    setOpensnackbar(true);
  })
}
export const singleEditStokRetur = (data,token,setOpensnackbar,closeDialogUbah)=> (dispatch)=>{
  dispatch({
    type:'FECTH ACTION STOK RETUR',
  })
  axios.post(`${apiUrl.url}stokretur/singleeditstok/`,data,{
    headers:{
      "Authorization" : `Bearer ${token}`
    }
  })
  .then(res=>{
    const ResponseAPI = res.data;
    dispatch({
      type:'SUCCESSED EDIT STOK RETUR', 
      payload:ResponseAPI.message
    })
    setOpensnackbar(true);
    setTimeout(() => {
      closeDialogUbah();
    }, 1000);
  })
  .catch(error=>{
    dispatch({
      type:'FAILED GET DATA STOK RETUR',
      payload:error.message
    })
    setOpensnackbar(true);
  })
}