import { Autocomplete, Button,  Checkbox,  CircularProgress, Container, Divider, Fab, FormControl, FormControlLabel, IconButton, List, ListItem, ListItemText, Radio, RadioGroup, Snackbar, TextField, Typography, useTheme } from '@mui/material'
import { Box} from '@mui/system'
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import * as yup from 'yup';
import MuiAlert from '@mui/material/Alert';
import { getAllDistributorForCombo, getAllSatuanForCombo, addToDoSatuan, errorMessageTodo, emptyBarangMessage, deleteTodoSatuan, addNewBarang } from '../../../config/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { useHistory } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { AddTodo } from '../../../components/assets';
import {format} from 'date-fns';
import ArrowBackIcon from '@mui/icons-material/ArrowBackIosOutlined';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });


const BarangBaru = () => {
    
    const theme = useTheme();
    const boxWapper = {
        display:'flex',
        justifyContent:'center',
        [theme.breakpoints.up('sm')]:{
            width:'100%',
            maxHeight:'500px',
            height:'100%',
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            height:'100%',
        }
    }
    const paperInput={
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        // border:'1px solid black',
        [theme.breakpoints.up('sm')]:{
            width:"35%",
            height:'100%',
            borderRadius:'10px',
            marginTop:'10px'
        },
        [theme.breakpoints.down('sm')]:{
            width:'90%',
            height:'100%',
            borderRadius:'10px',
            marginTop:'10px'
        }
    }
    const boxTodo = {
        [theme.breakpoints.up('sm')]:{
            width:'660px',
            marginTop:'10px',
            display:'flex',
            justifyContent:'space-between',
            alignItems:'flex-end'
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            marginTop:'10px',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center'
        }
    }
    const boxListTodo = {
        [theme.breakpoints.up('sm')]:{
            width:'600px',
            marginTop:'10px',
            display:'flex',
            flexDirection:'column',
            justifyContent:'space-between',
            alignItems:'flex-end'
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            marginTop:'10px',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center'
        }
    }
    const widthTodo ={
        [theme.breakpoints.up('sm')]:{
            width:"120px"
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%',
        }
    }
    const widthListTodo ={
        [theme.breakpoints.up('sm')]:{
            width:"150px"
        },
        [theme.breakpoints.down('sm')]:{
            width:'250px',
            textAlign:'center'
        }
    }
    //getDataDistributor
    const {dataComboDistributor}=useSelector(state=>state.distributorReducers);
    const {dataComboSatuan}=useSelector(state=>state.satuanReducers);
    const {isLoading,todoSatuan,messageBarang} = useSelector(state=>state.barangReducers);
    const {dataLogin} =useSelector(state=>state.penggunaReducers);
    const [token,setToken] = useState(dataLogin.token);
    const [openSnackbar,setOpensnackbar]=useState(false);
    const [autoId,setAutoId]=useState(false);
    const [disabledIdBarang,setDisabledIdBarang]=useState(false);
    const [satuanText,setSatuanText]=useState('');
    const [valueRadio, setValueRadio] = useState('PPN');
    const history = useHistory();
    const dispatch = useDispatch();
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    useEffect(()=>{
        dispatch(getAllSatuanForCombo(token,setOpensnackbar));
    },[token,dispatch])
    const schemaValidation = yup.object().shape({
        idbarang:yup.string(),
        namabarang:yup.string().required('Nama barang tidak boleh kosong'),
        iddistributor:yup.string().required('Nama distributor belum dipilih'),
    })
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            idbarang:'',
            namabarang:'',
            iddistributor:'',
            ppn:'PPN'
        },
        validationSchema:schemaValidation,
        onSubmit:(values)=>{
            if(todoSatuan.length>0){
                const data = {
                    'idbarang':values.idbarang,
                    'namabarang':values.namabarang,
                    'iddistributor':values.iddistributor,
                    'tanggal':format(new Date(),'yyyy-MM-dd'),
                    'ppn':values.ppn,
                    'listsatuan':todoSatuan
                }
                dispatch(addNewBarang("",data,token,setOpensnackbar,history,"singleinput",""));
            }else{
                dispatch(errorMessageTodo("Data yang dimasukkan belum lengkap",setOpensnackbar));
            }
        }
    })
    const schemaValidationTodo = yup.object().shape({
        idsatuan:yup.string(),
        stokawal:yup.number().min(0,'Nilai stok awal tidak boleh kurang dari 0'),
        modalawal:yup.number().min(0,'Nilai modal awal tidak boleh kurang dari 0'),
        hargadasar:yup.number().min(0,'Nilai harga jual tidak boleh kurang dari 0'),
        hargagrosir:yup.number().min(0,'Nilai harga grosir tidak boleh kurang dari 0'),
    })
    const formikTodo = useFormik({
        initialValues:{
            idsatuan:'',
            stokawal:'',
            modalawal:'',
            hargadasar:'',
            hargagrosir:'',
        },
        validationSchema:schemaValidationTodo,
        onSubmit:(values)=>{
            const existSatuan = todoSatuan.filter(satuan =>satuan.idsatuan === values.idsatuan);
            if(existSatuan.length > 0){
                dispatch(errorMessageTodo("Satuan untuk barang ini telah terdaftar",setOpensnackbar));
            }else{
                if(values.idsatuan === "" || values.hargadasar==="" || values.hargagrosir==="" || values.stokawal==="" || values.modalawal===""){
                    dispatch(errorMessageTodo("Data yang di masukkan tidak sesuai",setOpensnackbar));
                }else{    
                    if(values.stokawal>0 && values.modalawal<=0){
                        dispatch(errorMessageTodo("Modal awal tidak boleh kosong",setOpensnackbar));
                    }else if(values.stokawal>=0 && values.modalawal>=0){
                        dispatch(addToDoSatuan(values.idsatuan,satuanText,parseFloat(values.hargadasar),parseFloat(values.hargagrosir),parseInt(values.stokawal),parseFloat(values.modalawal),formikTodo));
                    }
                }
            }
        }
    })
    const handleChange = (event) => {
        setValueRadio(event.target.value);
        formik.setFieldValue('ppn',event.target.value);
    };
    const onSelectSatuanHandleChange = (e) =>{
        formikTodo.setFieldValue('idsatuan',e.target.value);
        setSatuanText(e.target.options[e.target.selectedIndex].text);
    }
    const handleChangeChackeAutoId = (e)=>{
        setAutoId(!autoId);
        setDisabledIdBarang(e.target.checked);
    }
    const handleDeleteSatuanTodo = (idsatuan)=>{
        dispatch(deleteTodoSatuan(idsatuan));
    }
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(emptyBarangMessage(''));
        }, 100);
    }
    const listSatuan = (
        <List sx={boxListTodo}>
            {todoSatuan.map((list)=>(
                <ListItem key={list.idsatuan}
                disableGutters
                sx={{width:'100%',display:'flex',flexDirection:'column'}}
                >
                    <Box sx={{width:'100%',display:'flex'}}>
                        <ListItemText sx={widthListTodo} primary={list.satuan}/>
                        <ListItemText sx={widthListTodo} primary={new Intl.NumberFormat().format(list.hargadasar)}/>
                        <ListItemText sx={widthListTodo} primary={new Intl.NumberFormat().format(list.hargagrosir)}/>
                        <ListItemText sx={widthListTodo} primary={list.stokawal}/>
                        <ListItemText sx={widthListTodo} primary={new Intl.NumberFormat().format(list.modalawal)}/>
                        <IconButton size='small' 
                        onClick={()=>handleDeleteSatuanTodo(list.idsatuan)}
                        >
                        <DeleteIcon sx={{color:'red'}}/>
                        </IconButton>
                    </Box>
                        <Divider sx={{width:'100%'}}/>
                </ListItem>
            ))}
        </List>
    )
    const onSearchDistributor = (e)=>{
        if(dataComboDistributor.length<1){
            dispatch(getAllDistributorForCombo(e.target.value,token,setOpensnackbar));
        }
    }
    return (
        <Container maxWidth='xl' sx={{marginTop:'100px'}}>
        <Typography variant='h6' pl={1} mt={1}>Pendaftaran Barang</Typography>
        <Divider/>
        <Box sx={boxWapper}>
            <Box sx={paperInput}>
                <Box sx={{width:'80%',marginTop:'10px',display:'flex',justifyContent:'space-between',alignItems:'flex-end'}}>
                    <Checkbox
                    size="small"
                    sx={{marginTop:'7px'}}
                    id='autoid'
                    name='autoid'
                    checked={autoId}
                    onClick={(e)=>handleChangeChackeAutoId(e)}
                    value={autoId}/>
                    <TextField
                    id="idbarang"
                    name="idbarang"
                    label="ID Barang"
                    variant="standard"
                    style={{width:'100%'}}
                    disabled={disabledIdBarang}
                    value={formik.values.idbarang}
                    error={formik.touched.idbarang && Boolean(formik.errors.idbarang)}
                    helperText={formik.touched.idbarang && formik.errors.idbarang}
                    onChange={formik.handleChange}
                    />
                </Box>
                <TextField
                id="namabarang"
                name="namabarang"
                label="Nama Barang"
                variant="standard"
                style={{width:'80%',marginTop:'10px'}}
                value={formik.values.namabarang}
                error={formik.touched.namabarang && Boolean(formik.errors.namabarang)}
                helperText={formik.touched.namabarang && formik.errors.namabarang}
                onChange={formik.handleChange}
                />
                 <Autocomplete
                  id="iddistributor"
                  selectOnFocus
                  name='iddistributor'
                  label="Distributor"
                  variant="standard"
                  sx={{width:'80%',marginTop:'10px'}}
                  options= {dataComboDistributor}
                  getOptionLabel= {(option) => option.nama}
                  isOptionEqualToValue={(option,value)=>option.id===value.id}
                  onChange={(event, newValue) => {
                          if(newValue!==null){
                              formik.setFieldValue('iddistributor',newValue.iddistributor);
                          }else{
                              formik.setFieldValue('iddistributor','');
                          }
                    }}
                  renderInput={(params)=><TextField
                      id='iddistributor'
                      name='iddistributor'
                      variant='standard' 
                      {...params} 
                      label="Distributor"
                      value={formik.values.iddistributor}
                      error={formik.touched.iddistributor && Boolean(formik.errors.iddistributor)}
                      helperText={formik.touched.iddistributor && formik.errors.iddistributor}
                      onChange={(e)=>onSearchDistributor(e)}
                      />}
                  />
                {/* <TextField
                id='iddistributor'
                select
                name='iddistributor'
                variant="standard" 
                label="Distributor"
                style={{width:'80%',marginTop:'10px'}}
                SelectProps={{
                    native: true,
                    }}
                value={formik.values.iddistributor}
                error={formik.touched.iddistributor && Boolean(formik.errors.iddistributor)}
                helperText={formik.touched.iddistributor && formik.errors.iddistributor}
                onChange={(e)=>onSelectDistributorHandleChange(e)}
                >   
                    <option aria-label="None" value="" />
                    {dataComboDistributor.map((option)=>(
                        <option key ={option.iddistributor} value={option.iddistributor}>
                            {option.nama}
                        </option>
                    ))}
                </TextField> */}
                <FormControl sx={{width:'80%',marginTop:'10px'}}>
                    <RadioGroup 
                    row 
                    name="ppn"
                    value={valueRadio}
                    onChange={handleChange}>
                        <FormControlLabel value="PPN" control={<Radio />} label="PPN" />
                        <FormControlLabel value="NON PPN" control={<Radio />} label="NON PPN" />
                    </RadioGroup>
                </FormControl>
                <Box sx={boxTodo}>
                    <TextField
                    id='idsatuan'
                    select
                    name='idsatuan'
                    variant="standard" 
                    label="Satuan"
                    sx={widthTodo}
                    SelectProps={{
                        native: true,
                      }}
                    value={formikTodo.values.idsatuan}
                    error={formikTodo.touched.idsatuan && Boolean(formikTodo.errors.idsatuan)}
                    helperText={formikTodo.touched.idsatuan && formikTodo.errors.idsatuan}
                    onChange={(e)=>onSelectSatuanHandleChange(e)}
                    >   
                        <option aria-label="None" value="" />
                        {dataComboSatuan.map((option)=>(
                            <option key ={option.idsatuan} value={option.idsatuan}>
                                {option.satuan}
                            </option>
                        ))}
                    </TextField>
                    <TextField
                    id="hargadasar"
                    name="hargadasar"
                    label="Harga Eceran"
                    variant="standard"
                    sx={widthTodo}
                    value={formikTodo.values.hargadasar}
                    error={formikTodo.touched.hargadasar && Boolean(formikTodo.errors.hargadasar)}
                    helperText={formikTodo.touched.hargadasar && formikTodo.errors.hargadasar}
                    onChange={formikTodo.handleChange}
                    />
                    <TextField
                    id="hargagrosir"
                    name="hargagrosir"
                    label="Harga Grosir"
                    variant="standard"
                    sx={widthTodo}
                    value={formikTodo.values.hargagrosir}
                    error={formikTodo.touched.hargagrosir && Boolean(formikTodo.errors.hargagrosir)}
                    helperText={formikTodo.touched.hargagrosir && formikTodo.errors.hargagrosir}
                    onChange={formikTodo.handleChange}
                    />
                    <TextField
                    id="stokawal"
                    name="stokawal"
                    label="Stok"
                    variant="standard"
                    sx={widthTodo}
                    value={formikTodo.values.stokawal}
                    error={formikTodo.touched.stokawal && Boolean(formikTodo.errors.stokawal)}
                    helperText={formikTodo.touched.stokawal && formikTodo.errors.stokawal}
                    onChange={formikTodo.handleChange}
                    />
                    <TextField
                    id="modalawal"
                    name="modalawal"
                    label="Modal"
                    variant="standard"
                    sx={widthTodo}
                    value={formikTodo.values.modalawal}
                    error={formikTodo.touched.modalawal && Boolean(formikTodo.errors.modalawal)}
                    helperText={formikTodo.touched.modalawal && formikTodo.errors.modalawal}
                    onChange={formikTodo.handleChange}
                    />
                    <IconButton onClick={formikTodo.handleSubmit}>
                        <img src={AddTodo} alt='addtodo'/>
                    </IconButton>
                    </Box>
                    {listSatuan}
                    <Button 
                    variant='contained' 
                    sx={{marginTop:'10px',marginBottom:'10px',width:'100px'}} 
                    onClick={formik.handleSubmit} 
                    disabled={isLoading}
                    >
                        {isLoading === true ? <CircularProgress color='primary' size={25}/> : "SIMPAN"}
                    </Button>
                </Box>
        </Box>
        <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={messageBarang === 'Penambahan data barang berhasil' ? "success" : "error"}
                >
                    {messageBarang}
                    </Alert>
            </Snackbar>
            
        <Fab sx={{position: 'fixed',bottom: theme.spacing(5),left: theme.spacing(3)}} size="small" color="primary" aria-label="add" onClick={()=>history.push('/barang')}>
            <ArrowBackIcon/>
        </Fab>
        </Container>
    )
}

export default BarangBaru
