import { Backdrop, Button, CircularProgress, Dialog, Divider, IconButton, List, ListItem, ListItemText, Snackbar, TextField, Typography, useTheme } from '@mui/material'
import { Box } from '@mui/system'
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import * as yup from 'yup';
import MuiAlert from '@mui/material/Alert';
import {getSatuanKonversiList, getDataBarcode, emptyBarangMessage, setBarangMessage,deleteDataBarcode} from '../../../config/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import DeleteIcon from '@mui/icons-material/Delete';
import { DeleteDialog } from '../../../components/molecules';
import { AddTodo } from '../../../components/assets';
import { postDataBarcode } from '../../../config/redux/actions/barangActions';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });


const AddBarcodeDialog = ({openBarcodeDialog,closeBarcodeDialog,idbarang,namabarang,...rest}) => {
    const theme = useTheme();
    const boxWapper = {
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        [theme.breakpoints.up('sm')]:{
            width:'100%',
            maxHeight:'500px',
            height:'100%',
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            height:'100%',
        }
    }
    const paperInput={
        display:'flex',
        flexDirection:'column',
        justifyContent:'center',
        alignItems:'center',
        // border:'1px solid black',
        [theme.breakpoints.up('sm')]:{
            width:"35%",
            height:'100%',
            borderRadius:'10px',
            marginTop:'10px'
        },
        [theme.breakpoints.down('sm')]:{
            width:'90%',
            height:'100%',
            borderRadius:'10px',
            marginTop:'10px'
        }
    }
    const boxTodo = {
        [theme.breakpoints.up('sm')]:{
            width:'400px',
            marginTop:'10px',
            display:'flex',
            justifyContent:'center',
            alignItems:'flex-end'
        },
        [theme.breakpoints.down('sm')]:{
            width:'70%',
            marginTop:'10px',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center'
        }
    }
    const boxList = {
        [theme.breakpoints.up('sm')]:{
            width:'320px',
            marginTop:'10px',
            display:'flex',
            flexDirection:'column',
            justifyContent:'space-between',
            alignItems:'flex-end'
        },
        [theme.breakpoints.down('sm')]:{
            width:'80%',
            marginTop:'10px',
            display:'flex',
            flexDirection:'column',
            justifyContent:'center',
            alignItems:'center'
        }
    }
    const widthListTodo ={
        [theme.breakpoints.up('sm')]:{
            width:"120px"
        },
        [theme.breakpoints.down('sm')]:{
            width:'250px',
            textAlign:'center'
        }
    }
    const widthTodo ={
        [theme.breakpoints.up('sm')]:{
            width:"100px"
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%',
        }
    }
    const barcodeTodo ={
        [theme.breakpoints.up('sm')]:{
            width:'200px',
            marginLeft:'5px'
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%',
        }
    }
    const {arrBarcode,isLoading,messageBarang} = useSelector(state=>state.barangReducers);
    const {arrSatuanKonversi} = useSelector(state=>state.konversiReducers);
    const {dataLogin} = useSelector(state=>state.penggunaReducers);
    const [openSnackbar,setOpensnackbar]=useState(false);
    const [token,setToken] = useState(dataLogin.token);
    const [openDeleteDialog,setOpenDeleteDialog]=useState(false);
    const [messageDialog,setMessageDialog]=useState('');
    const [tempIdBarang,setTempIdBarang]=useState('');
    const [tempIdSatuan,setTempIdSatuan]=useState('');
    const dispatch = useDispatch();
    useEffect(()=>{
        const data={
            'idbarang':idbarang
        }
        dispatch(getSatuanKonversiList(idbarang,token,setOpensnackbar));
        dispatch(getDataBarcode(data,token,setOpensnackbar));
    },[token,dispatch,idbarang])
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        const data={
            'idbarang':idbarang
        }
        dispatch(getDataBarcode(data,token,setOpensnackbar));
        setTimeout(() => {
            dispatch(emptyBarangMessage());
        }, 100);
    }
    
    const handleOpenDialog = (idbarang,idsatuan)=>{
        setMessageDialog(`Apakah anda yakin akan menghapus data barcode ini?`);
        setTempIdBarang(idbarang);
        setTempIdSatuan(idsatuan);
        setOpenDeleteDialog(true);
    }
    const handleCloseDialog = ()=>{
        setOpenDeleteDialog(false);
    }
    const handleDeleteKonversi = () =>{
        const data={
            'idbarang':tempIdBarang,
            'idsatuan':tempIdSatuan,
            'barcode':'',
            'action':"DELETE"
        }
        dispatch(deleteDataBarcode(data,token,setOpensnackbar,handleCloseDialog));
    }
    const listBarcode = (
        <List sx={boxList}>
            {arrBarcode.map((list)=>(
                <ListItem key={list.idsatuanbarang}
                disableGutters
                sx={{width:'100%',display:'flex',flexDirection:'column'}}
                >
                    <Box sx={{width:'100%',display:'flex'}}>
                        <ListItemText sx={widthListTodo} primary={list.satuan}/>
                        <ListItemText sx={{width:'200px'}} primary={list.barcode}/>
                        <IconButton size='small' 
                        onClick={()=>handleOpenDialog(list.idbarang,list.idsatuan)}
                        >
                        <DeleteIcon sx={{color:'red'}}/>
                        </IconButton>
                    </Box>
                        <Divider sx={{width:'100%'}}/>
                </ListItem>
            ))}
        </List>
    )
    const schemaValidation = yup.object().shape({
        idbarang:yup.string(),
        idsatuan:yup.string(),
        barcode:yup.string().required("Barcode barang tidak boleh kosong")
    })
    const formik = useFormik({
        enableReinitialize:true,
        initialValues:{
            tempIdbarang:idbarang||'',
            idsatuan:'',
            barcode:''
        },
        validationSchema:schemaValidation,
        onSubmit:(values)=>{
            const existDataBarcode = arrBarcode.filter(arrBarcode=>arrBarcode.idsatuan === values.idsatuan);
            if(existDataBarcode.length > 0){
                dispatch(setBarangMessage("Barcode untuk satuan ini telah terdaftar",setOpensnackbar));
            }else{
                const data={
                    'idbarang':idbarang,
                    'idsatuan':values.idsatuan,
                    'barcode':values.barcode,
                    'action':"EDIT"
                }
                dispatch(postDataBarcode(data,token,setOpensnackbar));
                formik.handleReset();
            }
        }
    })
    return (
        <Dialog open={openBarcodeDialog}{...rest}>
        <Box width='500px' height='100%' marginTop='10px' marginLeft='10px' marginRight='10px'>
        <Typography variant='h6' pl={1} mt={1}>Data Barcode Barang</Typography>
        <Divider/>
        <Box sx={boxWapper}>
            <Box sx={paperInput}>
                <TextField
                id="idbarang"
                name="idbarang"
                label="ID Barang"
                variant="standard"
                disabled={true}
                style={{width:'80%',marginTop:'10px'}}
                value={formik.values.tempIdbarang}
                />
                 <TextField
                id="namabarang"
                name="namabarang"
                label="Nama Barang"
                variant="standard"
                disabled={true}
                style={{width:'80%',marginTop:'10px'}}
                value={namabarang}
                />
            </Box>
            <Box sx={boxTodo}>
                    <TextField
                    id='idsatuan'
                    select
                    name='idsatuan'
                    variant="standard" 
                    label="Satuan"
                    sx={widthTodo}
                    SelectProps={{
                        native: true,
                      }}
                    value={formik.values.idsatuan}
                    error={formik.touched.idsatuan && Boolean(formik.errors.idsatuan)}
                    helperText={formik.touched.idsatuan && formik.errors.idsatuan}
                    onChange={formik.handleChange}
                    >   
                        <option aria-label="None" value="" />
                        {arrSatuanKonversi.map((option)=>(
                            <option key ={option.idsatuan} value={option.idsatuan}>
                                {option.satuan}
                            </option>
                        ))}
                    </TextField>
                    <TextField
                    id="barcode"
                    name="barcode"
                    label="Barcode"
                    variant="standard"
                    sx={barcodeTodo}
                    value={formik.values.barcode}
                    error={formik.touched.barcode && Boolean(formik.errors.barcode)}
                    helperText={formik.touched.barcode && formik.errors.barcode}
                    onChange={formik.handleChange}
                    />
                    <IconButton onClick={formik.handleSubmit}>
                        <img src={AddTodo} alt='addtodo'/>
                    </IconButton>
                    </Box>
                    {arrBarcode.length>0 ? listBarcode : <Typography variant='body' sx={{marginTop:'10px'}}>Belum terdapat barcode untuk barang ini</Typography>}
                    <Button 
                    variant='contained' 
                    sx={{marginTop:'10px',marginBottom:'10px'}} 
                    color='error'
                    onClick={closeBarcodeDialog}>Keluar</Button>
                </Box>
        <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={messageBarang === 'Berhasil menghapus barcode' || messageBarang === 'Berhasil menambahkan barcode' ? "success" : "error"}
                >
                    {messageBarang}
                    </Alert>
            </Snackbar>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                >
                <CircularProgress color="inherit" />
            </Backdrop>
            <DeleteDialog 
            openDialog={openDeleteDialog} 
            message={messageDialog}
            handleCloseDialog={handleCloseDialog}
            handleClick={handleDeleteKonversi}
            isLoading={isLoading}/>
        </Box>
        </Dialog>
    )
}

export default AddBarcodeDialog
