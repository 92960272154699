import { Container} from '@mui/material'
import React from 'react'
import StrukPenjualan from '../StrukPenjualan';

const ChoosePrint = () => {
    // const [checkSwitch,setCheckSwitch] = useState(false);
    // const [choosePrint,setChoosePrint]=useState("Faktur");
    // const handleChangeSwitch = (e) =>{
    //     setCheckSwitch(e.target.checked);
    //     if(e.target.checked===false){
    //         setChoosePrint('Faktur')
    //     }else{
    //         setChoosePrint('Struk');
    //     }
    // }
  return (
    <Container maxWidth='xl' sx={{marginTop:'100px',display:'flex',flexDirection:'column',justifyContent:'center'}}>
        <StrukPenjualan/>
    </Container>
  )
}

export default ChoosePrint