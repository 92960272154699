import { DesktopDatePicker, LocalizationProvider } from '@mui/lab';
import { Box, Button, Dialog, DialogContent, Divider, Snackbar, TextField, Typography, useTheme } from '@mui/material'
import { useFormik } from 'formik';
import React, { useState } from 'react'
import * as Yup from 'yup';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { format } from 'date-fns';
import { emptyBarangMessage, updateAllPPN, updateSinglePPN } from '../../../config/redux/actions';
import MuiAlert from '@mui/material/Alert';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
const UbahPPNBarangDialog = ({openEditPPNDialog,closeEditPPNDialog,valueStatusEditPPN,tempIdBarang,tempIdSatuan,tempIdDist,tempNamaDist,...rest}) => {
    const kelompokPPN = [
        {
          value: 'PPN',
          label: 'NON PPN ke PPN',
        },
        {
          value: 'NON PPN',
          label: 'PPN ke NON PPN',
        }
    ];
    const tipePerubahan = [
        {
          value: 'Tanggal',
          label: 'Tanggal perubahan yang diinginkan',
        },
        {
          value: 'Pembelian',
          label: 'Dari pembelian terakhir',
        },
        {
          value: 'SaldoAwal',
          label: 'Dari mulai saldo awal barang',
        }
    ];
    const theme = useTheme();
    const textBoxCss = {
        [theme.breakpoints.up('sm')]:{
            display:'flex',
            width:'100%',
            marginRight:'20px'
        }, 
        [theme.breakpoints.down('sm')]:{
            display:'flex',
            width:'100%',
        }
    }
    const [tanggalDisable,setTanggalDisable]=useState(true);
    const {dataLogin} =useSelector(state=>state.penggunaReducers);
    const {messageBarang,isLoading}=useSelector(state=>state.barangReducers);
    const [token,setToken] = useState(dataLogin.token);
    const [key,SetKey]=useState("");
    const dispatch = useDispatch();
    const [openSnackbar,setOpensnackbar]=useState(false);
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(emptyBarangMessage(''));
        }, 100);
    }
    const schemaValidation = Yup.object().shape({
        kelompokpajak:Yup.string().required('Perubahan PPN belum dipilih'),
        tipeperubahan:Yup.string().required('Tipe perubahan belum ditentukan'), 
        tanggal:Yup.date().max(new Date(),"Tanggal transaksi penjualan tidak boleh lebih besar dari hari ini"),
    })
    const formik = useFormik({
        initialValues:{
            kelompokpajak:'',
            tipeperubahan:'',
            tanggal:new Date()
        },
        validationSchema:schemaValidation,
        onSubmit:(values)=>{
            if(valueStatusEditPPN === "Single"){
                const data ={
                    'idbarang':tempIdBarang,
                    'idsatuan':tempIdSatuan,
                    'jenisPerubahan':values.tipeperubahan,
                    'paramKey':key,
                    'newkelompok':values.kelompokpajak
                }
                dispatch(updateSinglePPN(data,token,setOpensnackbar,handleCloseDialog));
            }else if(valueStatusEditPPN==="All"){
                const data={
                    'iddistributor':tempIdDist,
                    'jenisPerubahan':values.tipeperubahan,
                    'paramKey':key,
                    'newkelompok':values.kelompokpajak
                }
                dispatch(updateAllPPN(data,token,setOpensnackbar,handleCloseDialog));
            }
        }
    })
    const handleCloseDialog = () =>{
        formik.handleReset();
        setTanggalDisable(true);
        closeEditPPNDialog();
        handleCloseSnackbar();
    }
    const handleChangePerubahan = (e)=>{
        if(e.target.value==="Tanggal"){
            setTanggalDisable(false);
            formik.setFieldValue('tipeperubahan',e.target.value);
            formik.setFieldValue('tanggal',new Date());
            SetKey(format(new Date(),'yyyy-MM-dd'));
        }else{
            if(e.target.value==="SaldoAwal"){
                SetKey("Saldo awal");
            }else if(e.target.value==="Pembelian"){
                SetKey("Pembelian");
            }
            setTanggalDisable(true);
            formik.setFieldValue('tipeperubahan',e.target.value);
            formik.setFieldValue('tanggal',new Date());
        }
      }
      const handleChangeTanggal = (e)=>{
        if(formik.values.tipeperubahan==="Tanggal"){
            SetKey(format(e,'yyyy-MM-dd'));
        }
          formik.setFieldValue('tanggal',e);
      }
  return (
    <Dialog open={openEditPPNDialog}{...rest}>
        <Box sx={{width:'350px',display:'flex'}}>
            <DialogContent>
                {valueStatusEditPPN === 'Single' ? <Typography align='center'>Perubahan PPN untuk barang {tempIdBarang}</Typography> : <Typography align='center'>Perubahan PPN untuk semua barang dari {tempNamaDist}</Typography>}
                <Divider sx={{width:'100%'}}/>
                <TextField
                id='kelompokpajak'
                select
                name='kelompokpajak'
                variant="standard"
                sx={textBoxCss}
                label="Kelompok pajak"
                SelectProps={{
                    native: true,
                    }}
                value={formik.values.kelompokpajak}
                error={formik.touched.kelompokpajak && Boolean(formik.errors.kelompokpajak)}
                helperText={formik.touched.kelompokpajak && formik.errors.kelompokpajak}
                onChange={formik.handleChange}
                >   <option aria-label="None" value="" />
                    {kelompokPPN.map((kelompok)=>(
                        <option key ={kelompok.value} value={kelompok.value}>
                            {kelompok.label}
                        </option>
                    ))}
                </TextField>
                <TextField
                id='tipeperubahan'
                select
                name='tipeperubahan'
                variant="standard"
                sx={textBoxCss}
                label="Tipe Perubahan"
                SelectProps={{
                    native: true,
                    }}
                value={formik.values.tipeperubahan}
                error={formik.touched.tipeperubahan && Boolean(formik.errors.tipeperubahan)}
                helperText={formik.touched.tipeperubahan && formik.errors.tipeperubahan}
                onChange={(e)=>handleChangePerubahan(e)}
                >   
                <option aria-label="None" value="" />
                    {tipePerubahan.map((tipe)=>(
                        <option key ={tipe.value} value={tipe.value}>
                            {tipe.label}
                        </option>
                    ))}
                </TextField>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                    label="Tanggal"
                    inputFormat="dd/MM/yyyy"
                    disabled={tanggalDisable}
                    renderInput={(params) => <TextField {...params} sx={{width:'100%',marginRight:'10px'}} variant="standard"/>}
                    value={formik.values.tanggal}
                    error={Boolean(formik.errors.tanggal)&& Boolean(formik.errors.tanggal)}
                    helperText={formik.errors.tanggal && formik.errors.tanggal}
                    onChange={(e)=>handleChangeTanggal(e)}
                    />
                    </LocalizationProvider>
                <Box sx={{width:'100%',display:'flex',justifyContent:'space-between',marginTop:'20px'}}>
                    <Button disabled={isLoading} variant='contained' color='primary' onClick={formik.handleSubmit}>Proses</Button>
                    <Button disabled={isLoading} variant='contained' color='error' onClick={handleCloseDialog}>Keluar</Button>
                </Box>
            </DialogContent>
        </Box>
        <Snackbar 
            open={openSnackbar} 
            autoHideDuration={2000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={messageBarang === 'Perubahan PPN barang berhasil' ? 'success':'error'}
                >
                    {messageBarang}
                    </Alert>
            </Snackbar>
    </Dialog>
  )
}

export default UbahPPNBarangDialog