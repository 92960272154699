import React, {useState } from 'react'
import {Typography,Divider,Dialog,DialogContent, Box, Button, useTheme, styled, TableCell, TableRow, TableContainer, Table, TableHead, tableCellClasses, Paper, TableBody, Tooltip, IconButton, TextField, List, ListItem, ListItemText, Snackbar} from '@mui/material';
import XLSX from 'xlsx'
import { addPembelianFromExcel, deletePembelianFromExcel } from '../../../config/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import { AddTodo, DeleteData } from '../../assets';
import MuiAlert from '@mui/material/Alert';
import * as yup from 'yup';
import { useFormik } from 'formik';
import Cookies from 'js-cookie';
import { addToCartPembelian,addListDiskon, getSatuanKonversiList, setMessagePembelian, setEmptyListDiskon } from '../../../config/redux/actions';


const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'grey',
        color: theme.palette.common.black
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
const ImportExcelDialog = ({openExcelDialog,handleCloseExcelDialog,...rest}) => {
    const theme = useTheme();
    const inputFile = {
        display:'flex',
        marginTop:'20px',
        [theme.breakpoints.up('sm')]:{
            width:'100%',
        }, 
        [theme.breakpoints.down('sm')]:{
            width:'100%',
        },
    }
    const paperTable = {
        marginTop:'10px',
        [theme.breakpoints.up('sm')]:{
            width:'100%',
            height:'380px',
        }, 
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            height:'360px'
        },
        scrollbarWidth:'thin'
    }
    const {listExcel} = useSelector(state=>state.pembelianReducers);
    const {arrSatuanKonversi}=useSelector(state=>state.konversiReducers);
    const {dataLogin} =useSelector(state=>state.penggunaReducers);
    const {pembelianMessage,listDiskon,cartPembelian}=useSelector(state=>state.pembelianReducers);
    const [openSnackbar,setOpensnackbar]=useState(false);
    const {timeConnection} = useSelector(state=>state.homeReducers);
    const [token,setToken] = useState(dataLogin.token);
    const [satuanText,setSatuanText]=useState('');
    const [countCart,setCountCart]=useState(0);
    const [status,setStatus] = useState('importlist');
    const dispatch = useDispatch();
    const readExcel = (file) =>{
        const promise = new Promise((resolve,reject)=>{
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload= (e)=>{
                const bufferArray=e.target.result;
                const wb=XLSX.read(bufferArray,{type:'buffer'});

                const wsname=wb.SheetNames[0];
                const ws=wb.Sheets[wsname];
                const data = XLSX.utils.sheet_to_json(ws);
                resolve(data);
            }
            fileReader.onerror=(error)=>{
                reject(error);
            }
        })
        promise.then((d)=>{
            dispatch(addPembelianFromExcel(d));
        })
    }
    const handleChange = (e) =>{
        readExcel(e.target.files[0]);
    }
    if(token ===''){
        setToken(Cookies.get('u_tkn'));
    }
    const validationSchema=yup.object().shape({
        'idsatuan':yup.string().required(),
        'harga':yup.number().required("Harga tidak boleh kosong").min(1,"Harga tidak boleh kurang dari 0"),
        'qty':yup.number().required('Jumlah tidak boleh kosong').min(0,"Jumlah tidak boleh kurang dari 0"),
        'harganet':yup.number().required().min(0,"Harga Nett tidak boleh kurang dari 0"),
        'jumlah':yup.number().required('Subtotal belum terhitung').min(0,"Subtotal tidak boleh kurang dari 0")
    });
    const formik = useFormik({
        initialValues:{
            idbarang:'',
            namabarang:'',
            idsatuan:'',
            harga:'',
            harganet:'',
            qty:'',
            jumlah:''
        },
        validationSchema:validationSchema,
        onSubmit:(values)=>{
            const existBarang = cartPembelian.filter(barang =>barang.idbarang === values.idbarang && barang.idsatuan === values.idsatuan);
            if(existBarang.length>0){
                dispatch(setMessagePembelian('Barang dengan satuan ini telah terdaftar'));
                setOpensnackbar(true);
            }else{
                setCountCart(countCart+1);
                const data = {
                    'id':countCart,
                    'idbarang':values.idbarang,
                    'namabarang':values.namabarang,
                    'idsatuan':values.idsatuan,
                    'satuan':satuanText,
                    'harga':values.harga,
                    'diskon':listDiskon,
                    'harganet':values.harganet,
                    'qty':values.qty,
                    'jumlah':values.jumlah
                }
                dispatch(addToCartPembelian(data,setOpensnackbar));
                formik.handleReset();
                dispatch(setEmptyListDiskon());
                setCountDis(1);
                setTimeout(()=>{
                    changeAfterInsert();
                },1000)
            }
        }
    })
    const handleAddBarang = (id,nama,qty,harga)=>{
        setStatus("addbarangtolist");
        formik.setFieldValue('idbarang',id);
        formik.setFieldValue('namabarang',nama);
        formik.setFieldValue('qty',qty);
        formik.setFieldValue('harga',harga);
        dispatch(getSatuanKonversiList(id,token,""));
    }
    const tablePembelian = (
        <TableBody>
            {listExcel.map((barangs) => (
                <StyledTableRow key={barangs.id}>
                <StyledTableCell width={150} align="center">{barangs.id}</StyledTableCell>
                <StyledTableCell width={200} align="center">{barangs.nama}</StyledTableCell>
                <StyledTableCell width={200} align="center">{barangs.qty}</StyledTableCell>
                <StyledTableCell width={200} align="center">{new Intl.NumberFormat().format(barangs.harga)}</StyledTableCell>
                <StyledTableCell width={50} align="center">
                     <Tooltip title="Tambah Barang" >
                        <IconButton 
                        onClick={()=>handleAddBarang(barangs.id,barangs.nama,barangs.qty,barangs.harga)}
                        >
                            <img src={AddTodo} alt="editData"/>
                        </IconButton>
                     </Tooltip>
                </StyledTableCell>
                </StyledTableRow>
            ))}
        </TableBody>
    )
    const viewImport = (
        <DialogContent>
            <Typography variant='h6' pl={1} mt={1}>DATA IMPORT</Typography>
                <Divider/>
                <Box sx={{width:'100%',display:'flex',flexDirection:'column'}}>
                    <Box sx={inputFile}>
                        <Typography sx={{marginRight:'20px'}}>Silahkan Pilih File</Typography>
                            <input 
                            type="file"
                            accept=".csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            onChange={(e)=>handleChange(e)}/>
                    </Box>
                    <TableContainer component={Paper} sx={paperTable}>
                        <Table stickyHeader aria-label="customized table" size='small'>
                            <TableHead>
                            <TableRow>
                                <StyledTableCell width={150} align="center">ID Barang</StyledTableCell>
                                <StyledTableCell width={200} align="center">Nama Barang</StyledTableCell>
                                <StyledTableCell width={200} align="center">QTY</StyledTableCell>
                                <StyledTableCell width={200} align="center">Harga</StyledTableCell>
                                <StyledTableCell width={50} align="center"/>
                            </TableRow>
                            </TableHead>
                                {tablePembelian} 
                        </Table>
                    </TableContainer>
                    <Box sx={{width:'100%', display:'flex',justifyContent:"center",marginTop:'20px'}}>
                        <Button variant='contained' color='error' onClick={handleCloseExcelDialog}>Keluar</Button>
                    </Box>
                </Box>
        </DialogContent>
    )
    const changeAfterInsert = () =>{
        dispatch(deletePembelianFromExcel(formik.values.idbarang));
        setStatus('importlist');
    }
    const ChangeStatusBarang = () =>{
        setStatus('importlist');
    }
    const handleCloseSnackbar = (event,reason) =>{
        if(reason === 'clickaway'){
            return;
        }
        setOpensnackbar(false);
        setTimeout(() => {
            dispatch(setMessagePembelian(''));
        }, 100);
    }
    const jenisDiskon = [
        {'label':'%','value':'%'},
        {'label':'Rp','value':'Rp'}
    ]
    const [labelDiskon,setLabelDiskon]=useState('');
    const [disabledDiskon,setDisabledDIskon] = useState(true);
    const [disabledAddDiskon,setDisabledAddDiskon]=useState(true);
    const [placeholderDiskon,setPlaceholderDiskon]=useState('');
    const [countDis,setCountDis]=useState(1);
    const validationSchemaDiskon = yup.object().shape({
        'diskon':yup.number().min(0,'Diskon tidak boleh kurang dari 0')
    })
    const formikDiskon = useFormik({
        initialValues:{
            jenisdiskon:'',
            diskon:''
        },
        validationSchema:validationSchemaDiskon,
        onSubmit:(values)=>{
            if(formik.values.harganet===""){
                dispatch(setMessagePembelian('Harga Netto belum terisi'));
                setOpensnackbar(true);
            }else if(formik.values.harga!==""){
            setCountDis(countDis+1);
            dispatch(addListDiskon(countDis,values.jenisdiskon,values.diskon));
            if(values.jenisdiskon==="%" && formik.values.harga===formik.values.harganet){
                let tempPersen = parseInt(formik.values.harga) * (parseFloat(values.diskon)/100);
                let tempNett = parseInt(formik.values.harga)-tempPersen;
                formik.setFieldValue('harganet',tempNett);
            }else if(values.jenisdiskon==="%" && formik.values.harga!==formik.values.harganet){
                let tempPersen = parseInt(formik.values.harganet) * (parseFloat(values.diskon)/100);
                let tempNett = parseInt(formik.values.harganet)-tempPersen;
                formik.setFieldValue('harganet',tempNett);
            }else if(values.jenisdiskon==="Rp" && formik.values.harga===formik.values.harganet){
                let tempNett = parseInt(formik.values.harga)-parseFloat(values.diskon);
                formik.setFieldValue('harganet',tempNett);
            }else if(values.jenisdiskon==="Rp" && formik.values.harga!==formik.values.harganet){
                let tempNett = parseInt(formik.values.harganet)-parseFloat(values.diskon);
                formik.setFieldValue('harganet',tempNett);
            }
            formikDiskon.setFieldValue('jenisdiskon',"");
            setLabelDiskon("");
            formikDiskon.setFieldValue('diskon',"");
            setDisabledDIskon(true);
            setDisabledAddDiskon(true);
            formik.setFieldValue('jumlah',"");
            setPlaceholderDiskon('');
        }
        }
    })
    const handleChangeSatuan = (e)=>{
        setSatuanText(e.target.options[e.target.selectedIndex].text);
        formik.setFieldValue('idsatuan',e.target.value);
    }
    const handleChangeJenisDiskon = (e) =>{
        formikDiskon.setFieldValue('jenisdiskon',e.target.value);
        if(e.target.value === '%'){
            setLabelDiskon('Jumlah');
            setDisabledDIskon(false);
            setDisabledAddDiskon(false);
            setPlaceholderDiskon('Nilai persentase');
        }else if(e.target.value === 'Rp'){
            setLabelDiskon('Nominal')
            setDisabledDIskon(false);
            setDisabledAddDiskon(false);
            setPlaceholderDiskon('Nominal diskon');
        }else{
            setLabelDiskon("");
            formikDiskon.setFieldValue('diskon',"");
            setDisabledDIskon(true);
            setDisabledAddDiskon(true);
            setPlaceholderDiskon('');
        }
    }
    const handleDeleteDiskon = () =>{
        dispatch(setEmptyListDiskon());
        formik.setFieldValue('harganet',formik.values.harga);
        if(countDis===1){
            setCountDis(1);
        }else{
            setCountDis(countDis-1);
        }
    }
    const handleEnterHarga = (e) =>{
        if(e.key === 'Enter'){
            if(listDiskon.length<1){
                formik.setFieldValue('harganet',formik.values.harga);
            }else if(listDiskon.length>0){
                let tempHarga=formik.values.harga;
                let tempNett;
                listDiskon.forEach(dis => {
                    if(dis.jenisdiskon==='%'){
                        let tempPersen = parseInt(tempHarga) * (parseFloat(dis.diskon)/100);
                        tempNett = parseInt(tempHarga)-tempPersen;
                        formik.setFieldValue('harganet',tempNett);
                    }else if(dis.jenisdiskon==="Rp"){
                        tempNett = parseInt(tempHarga)-parseFloat(dis.diskon);
                        formik.setFieldValue('harganet',tempNett);
                    }
                    tempHarga=tempNett;
                });
            }
        }
    }
    const handleEnterJumlah = (e) =>{
        if(e.key === 'Enter'){
            let tempJumlah = formik.values.qty * formik.values.harganet;
            formik.setFieldValue('jumlah',tempJumlah);
        }
    }
    const viewDiskon = (
        <List sx={{width:'100%',display:'flex',justifyContent:'center'}}>
            {listDiskon.map((diskon)=>(
                <ListItem sx={{textAlign:'center'}} key={diskon.id}
                disableGutters>
                    {diskon.jenisdiskon === '%' ? <ListItemText primary={diskon.diskon +diskon.jenisdiskon}/>: <ListItemText primary={new Intl.NumberFormat().format(diskon.diskon)}/>}
                </ListItem>
            ))}
                {listDiskon.length>0 ?
                <IconButton size='small' 
                    onClick={()=>handleDeleteDiskon()}
                    >
                    <img src={DeleteData} alt="addTodo"/>
                </IconButton>: null}
        </List>
    )
    const addToCart = (
        <DialogContent>
            <Box sx={{width:'100%',display:'flex',flexDirection:'column'}}>
            <TextField
            id='idbarang'
            name='idbarang'
            disabled={true}
            variant="standard"
            label="ID Barang"
            sx={{marginTop:'10px'}}
            value={formik.values.idbarang}
            error={formik.touched.idbarang && Boolean(formik.errors.idbarang)}
            helperText={formik.touched.idbarang && formik.errors.idbarang}
            onChange={formik.handleChange}
            />
            <TextField
            id='namabarang'
            name='namabarang'
            disabled={true}
            variant="standard"
            label="Nama Barang"
            sx={{marginTop:'10px'}}
            value={formik.values.namabarang}
            error={formik.touched.namabarang && Boolean(formik.errors.namabarang)}
            helperText={formik.touched.namabarang && formik.errors.namabarang}
            onChange={formik.handleChange}
            />
            <TextField
            id='idsatuan'
            select
            name='idsatuan'
            variant="standard" 
            label="Satuan"
            sx={{marginTop:'10px'}}
            SelectProps={{
                native: true,
                }}
            value={formik.values.idsatuan}
            error={formik.touched.idsatuan && Boolean(formik.errors.idsatuan)}
            helperText={formik.touched.idsatuan && formik.errors.idsatuan}
            onChange={(e)=>handleChangeSatuan(e)}
            >   
                <option aria-label="None" value="" />
                {arrSatuanKonversi.map((option)=>(
                    <option key ={option.idsatuan} value={option.idsatuan}>
                        {option.satuan}
                    </option>
                ))}
            </TextField>
            <TextField
            id='harga'
            name='harga'
            variant="standard"
            label="Harga"
            placeholder='Tekan enter untuk menghitung'
            sx={{marginTop:'10px'}}
            value={formik.values.harga}
            error={formik.touched.harga && Boolean(formik.errors.harga)}
            helperText={formik.touched.harga && formik.errors.harga}
            onChange={formik.handleChange}
            onKeyDown={handleEnterHarga}
            />
            <Typography sx={{marginTop:'10px'}}>Diskon</Typography>
            <Box sx={{display:'flex',justifyContent:'space-between',alignItems:"flex-end"}}>
                <TextField
                id='jenisdiskon'
                select
                name='jenisdiskon'
                variant="standard"
                value={formikDiskon.values.jenisdiskon}
                error={formikDiskon.touched.jenisdiskon && Boolean(formikDiskon.errors.jenisdiskon)}
                helperText={formikDiskon.touched.jenisdiskon && formikDiskon.errors.jenisdiskon}
                sx={{marginRight:'5px'}}
                SelectProps={{
                    native: true,
                    }}
                onChange={(e)=>handleChangeJenisDiskon(e)}
                >   
                    <option aria-label="None" value="" />
                    {jenisDiskon.map((option)=>(
                    <option key ={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
                </TextField>
                <TextField
                id='diskon'
                name='diskon'
                disabled={disabledDiskon}
                placeholder={placeholderDiskon}
                variant="standard"
                label={labelDiskon}
                value={formikDiskon.values.diskon}
                error={formikDiskon.touched.diskon && Boolean(formikDiskon.errors.diskon)}
                helperText={formikDiskon.touched.diskon && formikDiskon.errors.diskon}
                onChange={formikDiskon.handleChange}
                />
                <IconButton 
                disabled={timeConnection<=100 ? disabledAddDiskon:true}
                onClick={formikDiskon.handleSubmit}
                >
                    <img src={AddTodo} alt="addTodo"/>
                </IconButton>
            </Box>
                {viewDiskon}
            <TextField
            id='harganet'
            name='harganet'
            variant="standard"
            label="Harga Netto"
            disabled={true}
            sx={{marginTop:'10px'}}
            value={formik.values.harganet}
            error={formik.touched.harganet && Boolean(formik.errors.harganet)}
            helperText={formik.touched.harganet && formik.errors.harganet}
            onChange={formik.handleChange}
            />
            <TextField
            id='qty'
            name='qty'
            variant="standard"
            label="Qty"
            placeholder='Tekan enter untuk menghitung'
            sx={{marginTop:'10px'}}
            value={formik.values.qty}
            error={formik.touched.qty && Boolean(formik.errors.qty)}
            helperText={formik.touched.qty && formik.errors.qty}
            onChange={formik.handleChange}
            onKeyDown={handleEnterJumlah}
            />
            <TextField
            id='jumlah'
            name='jumlah'
            disabled={true}
            variant="standard"
            label="Jumlah"
            sx={{marginTop:'10px'}}
            value={formik.values.jumlah}
            error={formik.touched.jumlah && Boolean(formik.errors.jumlah)}
            helperText={formik.touched.jumlah && formik.errors.jumlah}
            onChange={formik.handleChange}
            />
            </Box>
            <Box sx={{width:'100%',marginTop:'10px',display:'flex',justifyContent:'space-between'}}>
                <Button variant='contained' 
                disabled={timeConnection<=100 ? false:true}
                onClick={formik.handleSubmit}
                >Tambah</Button>
                <Button variant='contained' color='error' onClick={ChangeStatusBarang}>Keluar</Button>
            </Box>
            <Snackbar 
            open={openSnackbar} 
            autoHideDuration={6000} 
            onClose={handleCloseSnackbar}
            anchorOrigin={{
                vertical:'bottom',
                horizontal:'center'
            }}>
                <Alert 
                onClose={handleCloseSnackbar}
                severity={pembelianMessage === 'Barang berhasil dimasukkan ke daftar' ? "success" : "error"}
                >
                    {pembelianMessage}
                    </Alert>
            </Snackbar>
        </DialogContent>
    )
  return (
      <Dialog open={openExcelDialog}{...rest}>
          {status === "importlist" ? viewImport : addToCart}
      </Dialog>
  )
}

export default ImportExcelDialog