import { Button, Dialog, DialogContent, Divider, TextField, Typography,IconButton, List, ListItem, ListItemText} from '@mui/material';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import React, { useState } from 'react';
import { AddTodo} from '../../assets';
import * as yup from 'yup';
import { addListDiskonTotal, deleteDiskonTotal} from '../../../config/redux/actions';
import { useDispatch, useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';

const DiskonBarangDialog = ({openDiskonDialog,closeDiskonDialog,totalPembelian,...rest}) => {
  const jenisDiskon = [
    {'label':'%','value':'%'},
    {'label':'Rp','value':'Rp'}
  ]
  const {listDiskonTotal} = useSelector(state=>state.pembelianReducers);
  const [labelDiskon,setLabelDiskon]=useState('');
  const [disabledDiskon,setDisabledDIskon] = useState(true);
  const [disabledAddDiskon,setDisabledAddDiskon]=useState(true);
  const [placeholderDiskon,setPlaceholderDiskon]=useState('');
  const [countDis,setCountDis]=useState(1);
  const dispatch=useDispatch();
  const validationSchema = yup.object().shape({
    'diskon':yup.number().min(0,'Diskon tidak boleh kurang dari 0')
  })
  const formik=useFormik({
    enableReinitialize:true,
    initialValues:{
      total:new Intl.NumberFormat().format(totalPembelian)||0,
      jenisdiskon:'',
      diskon:''
    },
    validationSchema:validationSchema,
    onSubmit:(values)=>{
        setCountDis(countDis+1);
        dispatch(addListDiskonTotal(countDis,values.jenisdiskon,values.diskon));
        formik.setFieldValue('jenisdiskon',"");
        setLabelDiskon("");
        formik.setFieldValue('diskon',"");
        setDisabledDIskon(true);
        setDisabledAddDiskon(true);
        setPlaceholderDiskon('');
    }
  });
  const handleChangeJenisDiskon = (e) =>{
    formik.setFieldValue('jenisdiskon',e.target.value);
    if(e.target.value === '%'){
        setLabelDiskon('Jumlah');
        setDisabledDIskon(false);
        setDisabledAddDiskon(false);
        setPlaceholderDiskon('Nilai persentase');
    }else if(e.target.value === 'Rp'){
        setLabelDiskon('Nominal')
        setDisabledDIskon(false);
        setDisabledAddDiskon(false);
        setPlaceholderDiskon('Nominal diskon');
    }else{
        setLabelDiskon("");
        formik.setFieldValue('diskon',"");
        setDisabledDIskon(true);
        setDisabledAddDiskon(true);
        setPlaceholderDiskon('');
    }
}
const handleDeleteDiskon = (id,jenisdiskon,diskon) =>{
  dispatch(deleteDiskonTotal(id));
  if(countDis===1){
      setCountDis(1);
  }else{
      setCountDis(countDis-1);
  }
}
const viewDiskon = (
  <List sx={{width:'100%',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
      {listDiskonTotal.map((diskon)=>(
          <ListItem sx={{width:'50%'}} key={diskon.id}
          disableGutters>
              {diskon.jenisdiskon === '%' ? <ListItemText sx={{width:'100px'}} primary={diskon.id+". "+diskon.diskon +diskon.jenisdiskon}/>: <ListItemText sx={{width:'100px'}} primary={diskon.id+". "+new Intl.NumberFormat().format(diskon.diskon)}/>}
              <IconButton size='small' 
                  onClick={()=>handleDeleteDiskon(diskon.id,diskon.jenisdiskon,diskon.diskon)}
                  >
                  <DeleteIcon sx={{color:'red'}}/>
              </IconButton>
          </ListItem>
      ))}
  </List>
)
  return (
      <Dialog open={openDiskonDialog}{...rest}>
          <Box sx={{width:'300px',display:'flex'}}>
          <DialogContent>
              <Typography>Diskon Total</Typography>
              <Divider sx={{width:'100%'}}/>
              <TextField 
              id='total'
              name='total'
              variant='standard'
              sx={{width:'100%',marginTop:'10px'}}
              disabled={true}
              label="Total Pembelian"
              value={formik.values.total}
              error={formik.touched.total && Boolean(formik.errors.total)}
              helperText={formik.touched.total && formik.errors.total}
              onChange={formik.handleChange}/>
              <Typography sx={{marginTop:'10px'}}>Diskon</Typography>
              <Box sx={{display:'flex',justifyContent:'space-between',alignItems:"flex-end"}}>
                <TextField
                id='jenisdiskon'
                select
                name='jenisdiskon'
                variant="standard"
                value={formik.values.jenisdiskon}
                error={formik.touched.jenisdiskon && Boolean(formik.errors.jenisdiskon)}
                helperText={formik.touched.jenisdiskon && formik.errors.jenisdiskon}
                sx={{marginRight:'5px'}}
                SelectProps={{
                    native: true,
                    }}
                onChange={(e)=>handleChangeJenisDiskon(e)}
                >   
                    <option aria-label="None" value="" />
                    {jenisDiskon.map((option)=>(
                    <option key ={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
                </TextField>
                <TextField
                id='diskon'
                name='diskon'
                disabled={disabledDiskon}
                variant="standard"
                label={labelDiskon}
                placeholder={placeholderDiskon}
                value={formik.values.diskon}
                error={formik.touched.diskon && Boolean(formik.errors.diskon)}
                helperText={formik.touched.diskon && formik.errors.diskon}
                onChange={formik.handleChange}
                />
                <IconButton 
                disabled={disabledAddDiskon}
                onClick={formik.handleSubmit}
                >
                    <img src={AddTodo} alt="addTodo"/>
                </IconButton>
            </Box>
                {viewDiskon}
              <Box sx={{width:'100%',display:'flex',justifyContent:'center',marginTop:'10px'}}>
                <Button variant="contained" color="error" onClick={closeDiskonDialog}>Keluar</Button>
              </Box>
          </DialogContent>
          </Box>
      </Dialog>
  )
};

export default DiskonBarangDialog;
