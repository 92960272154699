import axios from 'axios';
import { format, parseISO } from 'date-fns';
import { apiUrl } from '../..';
import { getAllPelanggan, setEmptyDataPelanggan } from './pelangganActions';
import { addToCartPenjualan, deleteItemPenjualan, MinusButton } from './penjualanActions';

export const getAllDataDraft = (search,page,token,setOpensnackbar) =>(dispatch)=>{
    dispatch({
        type:'FETCH DRAFT'
    })
    axios.get(`${apiUrl.url}draftpenjualan/getAll?search=${search}&page=${page}&perPage=100`,{
        headers:{
            "Authorization" : `Bearer ${token}`
          }
    })
    .then(res=>{
        const ResponseAPI=res.data;
        dispatch({
            type:'UPDATE DATA DRAFT', 
            payload:ResponseAPI.data
         })
          dispatch({
            type:'UPDATE PAGE DRAFT',
            payload:{
              currentPage: ResponseAPI.current_page,
              totalPage: Math.ceil(ResponseAPI.totalData/ResponseAPI.per_page)
            }
          })
    })
    .catch(error=>{
        dispatch({
          type:'FAILED GET DRAFT',
          payload:error.message
        })
        setOpensnackbar(true);
      })
}
export const deleteDataDraft = (fakturpenjualan,search,page,token,setOpensnackbar,setOpenDialog) =>(dispatch)=>{
  dispatch({
    type:'FETCH DRAFT'
  })
  axios.delete(`${apiUrl.url}draftpenjualan/${fakturpenjualan}`,{
    headers:{
      "Authorization" : `Bearer ${token}`,
    }
  })
  .then(res=>{
    const ResponseApi = res.data;
      dispatch({
        type:'SUCCESSED DELETE DATA DRAFT',
        payload:ResponseApi.message
      })
      setOpensnackbar(true);
      setOpenDialog(false);
      axios.get(`${apiUrl.url}draftpenjualan/getAll?search=${search}&page=${page}&perPage=50`,{
        headers:{
            "Authorization" : `Bearer ${token}`
          }
    })
    .then(res=>{
        const ResponseAPI=res.data;
        dispatch({
            type:'UPDATE DATA DRAFT', 
            payload:ResponseAPI.data
         })
          dispatch({
            type:'UPDATE PAGE DRAFT',
            payload:{
              currentPage: ResponseAPI.current_page,
              totalPage: Math.ceil(ResponseAPI.totalData/ResponseAPI.per_page)
            }
          })
    })
    .catch(error=>{
        dispatch({
          type:'FAILED GET DRAFT',
          payload:error.message
        })
        setOpensnackbar(true);
      })
  })
  .catch(error=>{
    dispatch({
      type:'FAILED DELETE DATA DRAFT',
      payload:error.message
    })
    setOpensnackbar(true);
  })
}
export const emptyDraftMessage = (message) => (dispatch)=>{
  dispatch({
    type:'SET EMPTY DRAFT MESSAGE',
    payload:message
  })
}
export const setStatusDraft = (tempStatus,faktur)=>(dispatch)=>{
  dispatch({
    type:'SET STATUS DRAFT',
    status:tempStatus,
    tempFaktur:faktur
  })
}
export const setStatusDraftDefault = () =>(dispatch)=>{
  dispatch({
    type:'SET STATUS DRAFT DEFAULT',
    status:"Draft"
  })
}
export const setEmptyDraftList = () =>(dispatch)=>{
  dispatch({
    type:'SET EMPTY DRAFT LIST'
  })
}

export const getDataDraftPenjualan = (data,token,setOpensnackbar,formikDraftPenjualan,setCheckRetur,setTipeHarga)=>(dispatch)=>{
  dispatch({
    type:'FETCH DRAFT'
  })
  axios.post(`${apiUrl.url}draftPenjualan/getData`,data,{
    headers:{
      "Authorization" : `Bearer ${token}`,
    }
  })
  .then(res=>{
    const ResponseAPI=res.data;
    dispatch({
      type:'SUCCESSED GET DATA DRAFT PENJUALAN',
      message:ResponseAPI.message,
      payload:ResponseAPI.dataDetails
    })
    dispatch({
      type:'EMPTY CART PENJUALAN'
    })
    formikDraftPenjualan.setFieldValue('idpelanggan',"")
    formikDraftPenjualan.setFieldValue('namapelanggan',"");
    dispatch(getAllPelanggan(ResponseAPI.data.idpelanggan,1,token,""));
    formikDraftPenjualan.setFieldValue('idpelanggan',ResponseAPI.data.idpelanggan)
    formikDraftPenjualan.setFieldValue('namapelanggan',ResponseAPI.data.nama);
    formikDraftPenjualan.setFieldValue('jenisharga',ResponseAPI.data.tipeharga)
    formikDraftPenjualan.setFieldValue('tanggal',format(parseISO(ResponseAPI.data.tanggal),"yyyy-MM-dd"));
    formikDraftPenjualan.setFieldValue('alias',ResponseAPI.data.alias);
    setTipeHarga(ResponseAPI.data.tipeharga);
    dispatch({
      type:'ADD DRAFT TO CART',
      payload:ResponseAPI.dataDetails
    })
    axios.post(`${apiUrl.url}returpenjualan/getDraft`,data,{
      headers:{
        "Authorization" : `Bearer ${token}`,
      }
    })
    .then(resRetur=>{
      const ResponseAPI=resRetur.data;
      dispatch({
        type:'EMPTY CART RETUR PENJUALAN'
      })
      if(ResponseAPI.message==='Berhasil menampilkan data retur pelanggan'){
        dispatch({
          type:'SUCCESSED GET FAKTUR RETUR PELANGGAN',
          payload:ResponseAPI.FakturRetur,
          statusRetur:'Yes'
        })
        ResponseAPI.data.map(data=>{
          dispatch({
            type:'ADD RETUR PENJUALAN',
            payload:data
          })
          return data;
        })
        setCheckRetur(true);
      }else{
        dispatch({
          type:'SUCCESSED GET FAKTUR RETUR PELANGGAN',
          payload:"",
          statusRetur:"No"
        })
        setCheckRetur(false);
      }
    })
    .catch(error=>{
      dispatch({
        type:'FAILED ACTION RETUR PENJUALAN',
        payload:error.message
      })
      setOpensnackbar(true);
    })
  })
  .catch(error=>{
    dispatch({
      type:'FAILED DELETE DATA DRAFT',
      payload:error.message
    })
    setOpensnackbar(true);
  })
}

export const addPenjualanDetails = (data,token,setOpensnackbar,formikCart)=>(dispatch)=>{
  dispatch({
    type:'FETCH DRAFT'
  })
  axios.post(`${apiUrl.url}penjualan/insertsingledetails`,data,{
    headers:{
      "Authorization" : `Bearer ${token}`,
    }
  })
  .then(res=>{
    const ResponseAPI = res.data;
        dispatch({
          type:'SUCCESSED ACTION DRAFT',
          payload:ResponseAPI.message
        })
        const dataFormik = {
          'id':data.id,
          'idpenjualandetails':ResponseAPI.iddetails,
          'idbarang':data.idbarang,
          'namabarang':data.namabarang,
          'idsatuan':data.idsatuan,
          'tempPPN':data.tempPPN,
          'kelompok':data.kelompok,
          'satuan':data.satuan,
          'harga':data.harga,
          'qty':data.qty,
          'diskon':data.diskon,
          'jumlah':data.jumlah,
          'mk':data.mk,
          'tk':data.tk
        }
        dispatch(addToCartPenjualan(dataFormik,token,formikCart,setOpensnackbar));
        dispatch({
          type:'SUCCESSED ADD PENJUALAN DETAILS',
          payload:ResponseAPI.message
        })
  })
  .catch(error=>{
    dispatch({
      type:'FAILED DELETE DATA DRAFT',
      payload:error.message
    })
    setOpensnackbar(true);
  })
}
export const delPenjualanDetails = (id,data,token,setOpensnackbar)=>(dispatch)=>{
  dispatch({
    type:'FETCH DRAFT'
  })
  axios.post(`${apiUrl.url}draftpenjualan/details`,data,{
    headers:{
      "Authorization" : `Bearer ${token}`,
    }
  })
  .then(res=>{
    const ResponseAPI = res.data;
    
    dispatch({
      type:'SUCCESSED DELETE PENJUALAN DETAILS',
      payload:ResponseAPI.message
    })
    dispatch(deleteItemPenjualan(id,data,token,setOpensnackbar));
  })
  .catch(error=>{
    dispatch({
      type:'FAILED DELETE DATA DRAFT',
      payload:error.message
    })
    setOpensnackbar(true);
  })
}
export const editDraftPenjualanDetails = (id,data,editBookingStok,token,setOpensnackbar,status)=>(dispatch)=>{
  dispatch({
    type:'FETCH DRAFT'
  })
  axios.patch(`${apiUrl.url}draftpenjualan/editDraft`,data,{
    headers:{
      "Authorization" : `Bearer ${token}`,
    }
  })
  .then(res=>{
        const ResponseAPI=res.data;
        dispatch({
          type:'SUCCESSED ACTION DRAFT',
          payload:ResponseAPI.message
        })
        if(status==="Minus"){
          dispatch(MinusButton(id,editBookingStok,token,setOpensnackbar,status));
        }
      })
  .catch(error=>{
    dispatch({
      type:'FAILED ACTION DRAFT',
      payload:error.message
    })
    setOpensnackbar(true);
  })
}

export const saveSameDraft = (data,token,statusSave,setOpensnackbar,formikDraftPenjualan,history,editDraftReturPenjualan,cartReturPenjualan,setTipeHarga) => (dispatch)=>{
  dispatch({
    type:'FETCH DRAFT'
  })
  axios.post(`${apiUrl.url}penjualan/editStatus`,data,{
    headers:{
      "Authorization" : `Bearer ${token}`,
    }
  })
  .then(res=>{
    const ResponseAPI=res.data;
    if(cartReturPenjualan.length>0){
      editDraftReturPenjualan(data.fakturpenjualan,data.idpelanggan,data.total,data.status);
    }
    dispatch({
      type:'EMPTY CART PENJUALAN'
    })
    dispatch({
      type:'EMPTY CART RETUR PENJUALAN'
    })
    dispatch({
      type:'SUCCESSED ACTION DRAFT',
      payload:""
    })
    dispatch({
      type:'SET PENJUALAN MESSAGE',
      payload:ResponseAPI.message
    })
    setOpensnackbar(true);
    dispatch(setStatusDraftDefault());
    dispatch(setEmptyDataPelanggan());
    let faktur = data.fakturpenjualan;
    formikDraftPenjualan.handleReset();
    setTimeout(()=>{
      if(statusSave==="SimpanPrint"){
        history.push(`/laporan/penjualan/${faktur}`)
        window.location.reload();
      }else if(statusSave==="Simpan"){
        history.push(`/penjualan/`)
        window.location.reload();
      }
      setTipeHarga("Harga Jual");
    },2000)
  })
  .catch(error=>{
    dispatch({
      type:'FAILED ACTION DRAFT',
      payload:error.message
    })
    setOpensnackbar(true);
  })
}
export const emptyFormDraft = () =>(dispatch)=>{
  dispatch({
    type:'EMPTY DRAFT FORM'
  })
}
export const saveDraftPlusRetur = (data,token,statusSave,setOpensnackbar,formikDraftPenjualan,history,draftReturPenjualan,setTipeHarga) => (dispatch)=>{
  axios.post(`${apiUrl.url}penjualan/editStatus`,data,{
    headers:{
      "Authorization" : `Bearer ${token}`,
    }
  })
  .then(res=>{
    const ResponseAPI = res.data;
    draftReturPenjualan(data.fakturpenjualan,data.idpelanggan,data.tanggal);
    dispatch({
      type:'EMPTY CART PENJUALAN'
    })
    dispatch({
      type:'EMPTY CART RETUR PENJUALAN'
    })
    dispatch({
      type:'SUCCESSED ACTION DRAFT',
      payload:""
    })
    dispatch({
      type:'SET PENJUALAN MESSAGE',
      payload:ResponseAPI.message
    })
    setOpensnackbar(true);
    dispatch(setStatusDraftDefault());
    dispatch(setEmptyDataPelanggan());
    let faktur = data.fakturpenjualan;
    formikDraftPenjualan.handleReset();
    setTimeout(()=>{
      if(statusSave==="SimpanPrint"){
        history.push(`/laporan/penjualan/${faktur}`)
        window.location.reload();
      }else if(statusSave==="Simpan"){
          history.push(`/penjualan/`)
          window.location.reload();
      }
      setTipeHarga("Harga Jual");
    },2000)
  })
  
}